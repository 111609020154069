import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  FieldTemplateType,
  ParticipantFieldGroupTemplate,
  ParticipantFieldTemplate,
  UpdateParticipantFieldTemplateRequest,
} from '@piccolohealth/pbs-common';
import { SelectOption } from '@piccolohealth/ui';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { HookedSubmitButton } from '../../../components/forms/HookedSubmitButton';
import { createModal } from '../../../components/generic/Modal';
import { useUpdateParticipantFieldTemplateMutation } from '../../../graphql/hooks/useUpdateParticipantFieldTemplateMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { ParticipantFieldTemplateForm } from './ParticipantFieldTemplateForm';

type FormValues = UpdateParticipantFieldTemplateRequest;

interface Props {
  participantFieldGroupTemplateId: string;
  participantFieldTemplate: ParticipantFieldTemplate;
  fieldGroupTemplates: ParticipantFieldGroupTemplate[];
}

const schema: z.ZodSchema<FormValues> = z.object({
  participantFieldGroupTemplateId: z.string(),
  name: z.string(),
  type: z.nativeEnum(FieldTemplateType),
  index: z.number(),
  attributes: z.any(),
});

export const ParticipantFieldTemplateUpdateModal = createModal<Props>((props) => {
  const { participantFieldGroupTemplateId, participantFieldTemplate, fieldGroupTemplates, modal } =
    props;
  const { organization, successToast, errorToast } = useAppContext();
  const { hide, remove, visible } = modal;

  const methods = useForm({
    defaultValues: {
      ...participantFieldTemplate,
      participantFieldGroupTemplateId,
    },
    mode: 'all',
    resolver: zodResolver(schema),
  });

  const mutation = useUpdateParticipantFieldTemplateMutation();

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      if (values.attributes?.options) {
        values.attributes.options = values.attributes.options.map(
          (option: SelectOption<string>) => ({
            value: option.value,
            label: option.value,
            raw: option.value,
          }),
        );
      }

      await mutation
        .mutateAsync({
          organizationId: organization.id,
          participantFieldTemplateId: participantFieldTemplate.id,
          request: values,
        })
        .then(() => {
          hide();
          successToast('Participant field template updated successfully');
        })
        .catch((err) => {
          errorToast(`Error updating participant field template: ${err.message}`);
        });
    },
    [errorToast, hide, mutation, organization.id, participantFieldTemplate.id, successToast],
  );

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={remove} size="xl">
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <Box as="form" display="contents" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <ModalHeader>
              <Flex align="start">
                <Box>
                  <Heading size="md">Update a field</Heading>
                  <Text fontSize="md" fontWeight="normal" color="secondary" mt={2}>
                    Please complete the following to proceed
                  </Text>
                </Box>
                <Spacer />
                <ModalCloseButton position="unset" top="unset" right="unset" />
              </Flex>
            </ModalHeader>
            <ModalBody>
              <ParticipantFieldTemplateForm fieldGroupTemplates={fieldGroupTemplates} />
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button size="sm" onClick={hide}>
                  Close
                </Button>
                <HookedSubmitButton size="sm">Update field</HookedSubmitButton>
              </HStack>
            </ModalFooter>
          </Box>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
