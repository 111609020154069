import { Divider, Heading, HStack, SimpleGrid, Spacer, Stack } from '@chakra-ui/react';
import * as Common from '@piccolohealth/pbs-common';
import { PiccoloError } from '@piccolohealth/pbs-common';
import { Spin, useRequiredParams } from '@piccolohealth/ui';
import React from 'react';
import { Error } from '../../components/generic/Error';
import { GridItemContainer } from '../../components/generic/GridItemContainer';
import { ContentLayout } from '../../components/layouts/ContentLayout';
import { useIncidentQuery } from '../../graphql/hooks/useIncidentQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { IncidentActionMenu } from './IncidentActionMenu';
import { IncidentCard } from './IncidentDetailsCard';
import { IncidentFilesCard } from './IncidentFilesCard';
import { IncidentTeamCard } from './IncidentTeamCard';
import { IncidentUpdateStatusControl } from './IncidentUpdateStatusControl';

export const Incident = () => {
  const { incidentId } = useRequiredParams<{ incidentId: string }>();

  const { organization } = useAppContext();

  const { data, error, isLoading } = useIncidentQuery({
    organizationId: organization.id,
    incidentId,
  });

  const incident = data?.organization?.incident as Common.Incident | undefined;

  if (isLoading) {
    return <Spin />;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (!incident) {
    return (
      <Error
        error={
          new PiccoloError({
            type: 'IncidentNotFound',
            message: 'Incident not found',
          })
        }
      />
    );
  }

  return (
    <ContentLayout
      headerContent={
        <HStack align="start">
          <Heading size="lg">{Common.renderIncidentShortId(incident.shortId)}</Heading>
          <Spacer />
          <IncidentUpdateStatusControl incident={incident} />
          <IncidentActionMenu incident={incident} />
        </HStack>
      }
    >
      <SimpleGrid
        px={6}
        columns={{
          xl: 1,
          '2xl': 2,
        }}
        spacing={4}
        gap={4}
      >
        <GridItemContainer>
          <Stack spacing={4}>
            <IncidentCard incident={incident} />
          </Stack>
        </GridItemContainer>
        <GridItemContainer>
          <Stack spacing={4}>
            <IncidentTeamCard incident={incident} />
            <IncidentFilesCard incidentId={incidentId} />
          </Stack>
        </GridItemContainer>
      </SimpleGrid>
      <Divider />
    </ContentLayout>
  );
};
