import gql from 'graphql-tag';

export const UpdateParticipantWidgetLayoutItems = gql`
  mutation UpdateParticipantWidgetLayoutItems(
    $organizationId: ID!
    $participantId: ID!
    $request: [UpdateParticipantWidgetLayoutItemRequest!]!
  ) {
    updateParticipantWidgetLayoutItems(
      organizationId: $organizationId
      participantId: $participantId
      request: $request
    ) {
      id
    }
  }
`;
