import gql from 'graphql-tag';

export const CreateParticipantUserLabel = gql`
  mutation CreateParticipantUserLabel(
    $organizationId: ID!
    $participantId: ID!
    $participantUserId: ID!
    $labelId: ID!
  ) {
    createParticipantUserLabel(
      organizationId: $organizationId
      participantId: $participantId
      participantUserId: $participantUserId
      labelId: $labelId
    ) {
      id
    }
  }
`;
