import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { CreateParticipantLabel } from '../mutations/createParticipantLabel.mutation';
import { CreateParticipantLabelMutation, CreateParticipantLabelMutationVariables } from '../types';

export const useCreateParticipantLabelMutation = (
  options?: MutationOptions<
    CreateParticipantLabelMutation,
    CreateParticipantLabelMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(CreateParticipantLabel), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('participant'),
      });
    },
  });
};
