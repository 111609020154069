import gql from 'graphql-tag';

export const DeleteParticipantBehaviour = gql`
  mutation DeleteParticipantBehaviour($organizationId: ID!, $participantBehaviourId: ID!) {
    deleteParticipantBehaviour(
      organizationId: $organizationId
      participantBehaviourId: $participantBehaviourId
    )
  }
`;
