import {
  Box,
  Button,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  HStack,
  Icon,
  Input,
  Spacer,
  Stack,
} from '@chakra-ui/react';
import { EpisodicSeverityScale } from '@piccolohealth/pbs-common';
import { dataAttr, DndKitCore, DndKitSortable, DragHandle, Sortable } from '@piccolohealth/ui';
import { P, uuid } from '@piccolohealth/util';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { useHoverDirty } from 'react-use';
import { EditableControls } from '../generic/EditableControls';

interface EpisodicSeverityScaleItemProps {
  scale: EpisodicSeverityScale;
  isActive?: boolean;
  onEdit: (description: string) => void;
  onRemove: () => void;
  onClick: () => void;
}

const EpisodicSeverityScaleItem = (props: EpisodicSeverityScaleItemProps) => {
  const { scale, isActive, onEdit, onRemove, onClick } = props;
  const [value, setValue] = React.useState(scale.description);

  const ref = React.useRef(null);
  const isHovering = useHoverDirty(ref);

  const { attributes, setNodeRef, listeners, transform, transition } = DndKitSortable.useSortable({
    id: scale.id,
  });

  return (
    <Sortable ref={setNodeRef} transform={transform} transition={transition}>
      <Editable isPreviewFocusable={false} value={value} onChange={setValue} onSubmit={onEdit}>
        <Box
          ref={ref}
          px={1.5}
          py={1.5}
          rounded="md"
          bg="white"
          _active={{
            color: 'gray.600',
            bg: 'gray.100',
          }}
          onClick={(e) => {
            e.stopPropagation();
            onClick();
          }}
          cursor="pointer"
          {...{ 'data-active': dataAttr(isActive) }}
        >
          <HStack spacing={1} h="full">
            <DragHandle listeners={listeners} attributes={attributes} size="xs" />
            <HStack w="full" pr={2}>
              <Flex
                flexDir="column"
                align="start"
                fontSize="sm"
                fontWeight="semibold"
                color="gray.600"
                w="full"
                flexGrow={1}
              >
                <EditablePreview w="full" cursor="pointer" p={0} px={1.5} py={0.5} />
                <Input
                  as={EditableInput}
                  bg="white"
                  variant="unstyled"
                  size="sm"
                  px={1.5}
                  py={0.5}
                  h="auto"
                  _focusVisible={{ bg: 'white', boxShadow: 'none' }}
                />
              </Flex>
              <Spacer />
              <EditableControls onRemove={onRemove} isHovering={isHovering} />
            </HStack>
          </HStack>
        </Box>
      </Editable>
    </Sortable>
  );
};

interface Props {
  value: EpisodicSeverityScale[];
  onChange: (value: EpisodicSeverityScale[]) => void;
}

export const EpisodicSeverityScales = (props: Props) => {
  const { value, onChange } = props;

  const [activeValue, setActiveValue] = React.useState<string | null>(null);

  const sensors = DndKitCore.useSensors(
    DndKitCore.useSensor(DndKitCore.PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  const onDragEnd = React.useCallback(
    async (event: DndKitCore.DragEndEvent) => {
      const { active, over } = event;

      if (over && active.id !== over.id) {
        const oldIndex = value.findIndex((item) => item.id === active.id);
        const newIndex = value.findIndex((item) => item.id === over.id);

        if (oldIndex === newIndex) {
          return;
        }

        const fromScale = value[oldIndex];

        if (!fromScale) {
          return;
        }

        const reorderedScales = P.moveArrayItem(value, oldIndex, newIndex);

        onChange(reorderedScales);
      }
    },
    [onChange, value],
  );

  const onAdd = React.useCallback(() => {
    onChange([...value, { id: uuid(), description: `Level ${value.length + 1}` }]);
  }, [onChange, value]);

  const onEdit = React.useCallback(
    (id: string, description: string) => {
      onChange(
        value.map((v) => {
          if (v.id === id) {
            return { ...v, description };
          }
          return v;
        }),
      );
    },
    [onChange, value],
  );

  const onRemove = React.useCallback(
    (id: string) => {
      onChange(value.filter((v) => v.id !== id));
    },
    [onChange, value],
  );

  const onClick = React.useCallback(
    (id: string) => {
      setActiveValue(id);
    },
    [setActiveValue],
  );

  return (
    <Stack w="full" py={2}>
      <DndKitCore.DndContext
        sensors={sensors}
        collisionDetection={DndKitCore.closestCenter}
        onDragEnd={onDragEnd}
      >
        <DndKitSortable.SortableContext
          strategy={DndKitSortable.verticalListSortingStrategy}
          items={value.map(({ id }) => id)}
        >
          <Stack spacing={2}>
            {value.map((value) => (
              <EpisodicSeverityScaleItem
                key={value.id}
                scale={value}
                onEdit={(description) => onEdit(value.id, description)}
                onRemove={() => onRemove(value.id)}
                onClick={() => onClick(value.id)}
                isActive={value.id === activeValue}
              />
            ))}
          </Stack>
        </DndKitSortable.SortableContext>
      </DndKitCore.DndContext>
      <Button
        size="sm"
        leftIcon={<Icon as={FaPlus} />}
        onClick={onAdd}
        isDisabled={value.length === 10}
      >
        Add new scale
      </Button>
    </Stack>
  );
};
