import { Button, HStack, SimpleGrid, Spacer, Stack } from '@chakra-ui/react';
import { Empty, Spin } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { Error } from '../../components/generic/Error';
import { showModal } from '../../components/generic/Modal';
import { useCreateParticipantNoteMutation } from '../../graphql/hooks/useCreateParticipantNoteMutation';
import { useParticipantNotesFilterQuery } from '../../graphql/hooks/useParticipantQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { useParticipantNotesFilter } from '../../hooks/useParticipantNotesFilter';
import { ParticipantNoteCard } from './ParticipantNoteCard';
import { ParticipantNoteCreateModal } from './ParticipantNoteCreateModal';
import { ParticipantNoteEditModal } from './ParticipantNoteEditModal';
import { ParticipantNotesFilterControls } from './ParticipantNotesFilterControls';

interface Props {
  participantId: string;
  isDisabled?: boolean;
}

export const ParticipantNotes = (props: Props) => {
  const { participantId, isDisabled } = props;

  const { user, organization, successToast, errorToast } = useAppContext();
  const filter = useParticipantNotesFilter();
  const { participantNotes, isLoading, error } = useParticipantNotesFilterQuery(
    participantId,
    filter,
  );
  const mutation = useCreateParticipantNoteMutation();

  const onCreateNewBlankNote = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        request: {
          id: undefined,
          participantId: participantId,
          userId: user.id,
          labelIds: [],
        },
      })
      .then((res) => {
        successToast('Note created successfully');
        showModal(ParticipantNoteEditModal, {
          participantId,
          participantNoteId: res.createParticipantNote.id,
          isDisabled,
        });
      })
      .catch((err) => {
        errorToast(`Error creating note: ${err.message}`);
      });
  }, [errorToast, isDisabled, mutation, organization.id, participantId, successToast, user.id]);

  const content = P.run(() => {
    if (isLoading) {
      return <Spin />;
    }

    if (error) {
      return <Error error={error} />;
    }

    if (P.isEmpty(participantNotes)) {
      return <Empty h="full" title="No notes found" />;
    }

    return (
      <SimpleGrid minChildWidth="2xl" spacing={8}>
        {participantNotes.map((participantNote) => (
          <ParticipantNoteCard
            key={participantNote.id}
            participantNote={participantNote}
            isDisabled={isDisabled}
          />
        ))}
      </SimpleGrid>
    );
  });

  return (
    <Stack spacing={4} px={6} py={4}>
      <HStack align="end">
        <ParticipantNotesFilterControls filter={filter} />
        <Spacer />
        <HStack>
          <Button
            colorScheme="purple"
            size="sm"
            leftIcon={<FaPlus />}
            isDisabled={isDisabled}
            onClick={() => showModal(ParticipantNoteCreateModal, { participantId })}
          >
            Add note from template
          </Button>
          <Button
            colorScheme="purple"
            size="sm"
            leftIcon={<FaPlus />}
            isDisabled={isDisabled}
            onClick={() => onCreateNewBlankNote()}
          >
            Add blank note
          </Button>
        </HStack>
      </HStack>
      {content}
    </Stack>
  );
};
