import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { DeleteParticipantFieldGroupTemplate } from '../mutations/deleteParticipantFieldGroupTemplate.mutation';
import {
  DeleteParticipantFieldGroupTemplateMutation,
  DeleteParticipantFieldGroupTemplateMutationVariables,
} from '../types';

export const useDeleteParticipantFieldGroupTemplateMutation = (
  options?: MutationOptions<
    DeleteParticipantFieldGroupTemplateMutation,
    DeleteParticipantFieldGroupTemplateMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(DeleteParticipantFieldGroupTemplate), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('participantFieldGroupTemplates'),
      });
    },
  });
};
