import { MenuItem, Text } from '@chakra-ui/react';
import React from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import { showModal } from '../../components/generic/Modal';
import { usePermission } from '../../hooks/usePermission';
import { ParticipantEditModal } from './ParticipantEditModal';

interface Props {
  participantId: string;
  isDisabled?: boolean;
}

export const ParticipantEditMenuItem = (props: Props) => {
  const { participantId, isDisabled } = props;
  const hasPermission = usePermission('update', 'participant');

  return (
    <MenuItem
      isDisabled={!hasPermission}
      onClick={() => showModal(ParticipantEditModal, { participantId, isDisabled })}
      icon={<FaPencilAlt />}
    >
      <Text>Edit</Text>
    </MenuItem>
  );
};
