import { arrayOfParam, stringParam, useQueryParams } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';

const DEFAULT_NAME_FILTER = '';
const DEFAULT_LABEL_FILTER: string[] = [];

export const participantFilesAndDocumentsFilterDefaults: ParticipantFilesAndDocumentsFilter = {
  nameFilter: DEFAULT_NAME_FILTER,
  labelFilter: DEFAULT_LABEL_FILTER,
  onNameFilter: P.noop,
  onLabelFilter: P.noop,
};

export interface ParticipantFilesAndDocumentsFilter {
  nameFilter: string;
  labelFilter: string[] | undefined;
  onNameFilter: (name: string) => void;
  onLabelFilter: (labels: string[]) => void;
}

export const useParticipantFilesAndDocumentsFilter: () => ParticipantFilesAndDocumentsFilter =
  () => {
    const [params, setParams] = useQueryParams({
      name: stringParam,
      label: arrayOfParam(stringParam, { delimiter: ',' }),
    });

    const onNameFilter = (value: string) =>
      setParams({
        name: value,
      });

    const onLabelFilter = (labels?: string[]) =>
      setParams({
        label: labels,
      });

    return {
      nameFilter: params.name ?? DEFAULT_NAME_FILTER,
      labelFilter: params.label ?? DEFAULT_LABEL_FILTER,
      onNameFilter,
      onLabelFilter,
    };
  };
