import { StyleFunctionProps } from '@chakra-ui/react';

const variants = {
  bordered: (props: StyleFunctionProps) => ({
    ...props.theme.components.Table.variants.simple(props),
    table: {
      borderWidth: '1px',
      borderColor: 'gray.200',
      borderCollapse: 'initial',
      borderSpacing: 0,
      rounded: 'lg',
      overflow: 'hidden',
    },
    thead: {
      _hover: {
        bg: 'gray.50',
      },
    },
    th: {
      borderBottomWidth: '1px',
      borderBottomColor: 'gray.200',
      color: 'gray.500',
      py: props.size === 'sm' ? 2 : undefined,
      fontFamily: 'inherit',
      fontWeight: 'semibold',
      fontSize: props.size === 'md' ? 'sm' : undefined,
      textTransform: 'inherit',
      lineHeight: 'inherit',
      letterSpacing: 'inherit',
      verticalAlign: 'middle',
    },
    caption: {
      px: 1,
      mt: 1,
    },
    tbody: {
      tr: {
        '&:last-of-type': {
          td: {
            borderWidth: 0,
          },
        },
      },
    },
  }),
};

export const Table = {
  variants,
};
