import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import { TimeSeriesChartWidget } from '@piccolohealth/pbs-common';
import React from 'react';
import { createModal } from '../../../../components/generic/Modal';
import { TimeSeriesWidgetModalContent } from './TimeSeriesWidgetModalContent';

interface Props {
  participantId: string;
  widget: TimeSeriesChartWidget;
  onEdit: (item: TimeSeriesChartWidget) => void;
}

export const TimeSeriesWidgetEditModal = createModal<Props>((props) => {
  const { modal, participantId, widget, onEdit } = props;

  const { visible, hide, remove } = modal;

  const onSubmit = (widget: TimeSeriesChartWidget) => {
    onEdit(widget);
    hide();
  };

  return (
    <Modal
      isOpen={visible}
      onClose={hide}
      onCloseComplete={remove}
      size="outline"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody p={2} overflowY="auto">
          <TimeSeriesWidgetModalContent
            participantId={participantId}
            onSubmit={onSubmit}
            widget={widget}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});
