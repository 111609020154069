import { Icon } from '@chakra-ui/react';
import { P } from '@piccolohealth/util';
import React from 'react';
import { MdBarChart, MdOutlineSsidChart, MdScatterPlot } from 'react-icons/md';
import { ChartType } from '../../utils';

interface Props {
  chartType: ChartType;
  color?: string;
  size?: number;
}

export const ChartTypeIcon = (props: Props) => {
  const icon = P.run(() => {
    switch (props.chartType) {
      case 'BarTimeSeries':
        return MdBarChart;
      case 'LineTimeSeries':
        return MdOutlineSsidChart;
      case 'ScatterTimeSeries':
        return MdScatterPlot;
    }
  });

  return <Icon as={icon} boxSize={`${props.size}px`} color={props.color} />;
};
