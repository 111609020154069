import { Box, Divider, Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import * as Common from '@piccolohealth/pbs-common';
import { LabelType, renderLabelType } from '@piccolohealth/pbs-common';
import { Spin } from '@piccolohealth/ui';
import React from 'react';
import { FormSection } from '../../components/forms/FormSection';
import { Error } from '../../components/generic/Error';
import { useLabelsQuery } from '../../graphql/hooks/useLabelsQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { LabelsForType } from './labels/LabelsForType';

const labelTypes = Object.values(LabelType);

export const LabelSettings = () => {
  const { organization } = useAppContext();

  const { data, isLoading, error } = useLabelsQuery({
    organizationId: organization.id,
    type: labelTypes,
  });

  const labels = (data?.organization?.labels ?? []) as unknown as Common.Label[];

  const tabs = labelTypes.map((type) => {
    return <Tab key={type}>{renderLabelType(type)}</Tab>;
  });

  const panels = labelTypes.map((type) => {
    const filteredLabels = labels.filter((label) => label.type === type);
    return (
      <TabPanel key={type}>
        <LabelsForType type={type} labels={filteredLabels} />
      </TabPanel>
    );
  });

  if (isLoading) {
    return <Spin showAfterDuration={2000} />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <FormSection heading="Labels" description="Create and edit different labels">
      <Divider />
      <Flex>
        <Tabs
          variant="soft-rounded"
          orientation="vertical"
          defaultIndex={0}
          size="sm"
          w="full"
          h="full"
        >
          <TabList minW="120px" flexShrink={0}>
            {tabs}
          </TabList>
          <Box minH="md" minW="lg" borderLeftColor="gray.200" borderLeftWidth="1px" pl={6} ml={6}>
            <TabPanels overflowY="auto" h="full" w="full">
              {panels}
            </TabPanels>
          </Box>
        </Tabs>
      </Flex>
    </FormSection>
  );
};
