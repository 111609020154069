import {
  Button,
  ButtonGroup,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import { createModal } from '../../components/generic/Modal';
import { useDeleteParticipantNoteMutation } from '../../graphql/hooks/useDeleteParticipantNoteMutation';
import { useAppContext } from '../../hooks/useAppContext';

interface Props {
  participantId: string;
  participantNoteId: string;
  onDelete?: () => void;
}

export const ParticipantNoteDeleteModal = createModal<Props>((props) => {
  const { participantId, participantNoteId, modal, onDelete } = props;
  const { organization, successToast, errorToast } = useAppContext();
  const mutation = useDeleteParticipantNoteMutation();
  const { hide, remove, visible } = modal;

  const onClick = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        participantId,
        participantNoteId,
      })
      .then(() => {
        successToast('Note deleted successfully');
        hide();
        onDelete?.();
      })
      .catch((err) => {
        errorToast(`Error deleting note: ${err.message}`);
      });
  }, [
    mutation,
    organization.id,
    participantId,
    participantNoteId,
    successToast,
    hide,
    onDelete,
    errorToast,
  ]);

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={remove}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Do you want to delete this note?</ModalHeader>
        <ModalCloseButton />
        <ModalFooter>
          <ButtonGroup size="sm">
            <Button key="close" onClick={hide}>
              Close
            </Button>
            <Button
              key="submit"
              colorScheme="red"
              isLoading={mutation.isLoading}
              loadingText="Deleting..."
              type="submit"
              onClick={onClick}
            >
              Delete
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
