import {
  Button,
  ButtonGroup,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { createModal } from '../../components/generic/Modal';
import { useDeleteParticipantDocumentMutation } from '../../graphql/hooks/useDeleteParticipantDocumentMutation';
import { useAppContext } from '../../hooks/useAppContext';

interface Props {
  participantId: string;
  participantDocumentId: string;
}

export const ParticipantDocumentDeleteModal = createModal<Props>((props) => {
  const { participantId, participantDocumentId, modal } = props;

  const { hide, remove, visible } = modal;
  const { organization, successToast, errorToast } = useAppContext();
  const mutation = useDeleteParticipantDocumentMutation();
  const navigate = useNavigate();

  const onDelete = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        participantId,
        participantDocumentId,
      })
      .then(async () => {
        hide();
        successToast('Document deleted successfully');
        navigate(`/organizations/${organization.id}/participants/${participantId}/documents`);
      })
      .catch((err) => {
        errorToast(`Error deleting document: ${err.message}`);
      });
  }, [
    mutation,
    organization.id,
    participantId,
    participantDocumentId,
    hide,
    successToast,
    navigate,
    errorToast,
  ]);

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={remove}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Do you want to delete this document?</ModalHeader>
        <ModalCloseButton />
        <ModalFooter>
          <ButtonGroup size="sm">
            <Button key="close" onClick={hide}>
              Close
            </Button>
            <Button
              key="submit"
              colorScheme="red"
              isLoading={mutation.isLoading}
              loadingText="Deleting..."
              type="submit"
              onClick={onDelete}
            >
              Delete
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
