import { Button, Icon, Menu, MenuButton, ButtonProps, MenuList } from '@chakra-ui/react';
import { User } from '@piccolohealth/pbs-common';
import React from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { UserDeleteMenuItem } from './UserDeleteMenuItem';
import { UserEditMenuItem } from './UserEditMenuItem';

interface Props extends ButtonProps {
  user: User;
}

export const UserActionMenu = (props: Props) => {
  const { user, ...rest } = props;

  return (
    <Menu isLazy lazyBehavior="keepMounted">
      <MenuList>
        <UserEditMenuItem user={user} />
        <UserDeleteMenuItem userId={user.id} />
      </MenuList>
      <MenuButton as={Button} variant="outline" rightIcon={<Icon as={FaAngleDown} />} {...rest}>
        Actions
      </MenuButton>
    </Menu>
  );
};
