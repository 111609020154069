import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { InviteUser } from '../mutations/inviteUser.mutation';
import { InviteUserMutation, InviteUserMutationVariables } from '../types';

export const useInviteUserMutation = (
  options?: MutationOptions<InviteUserMutation, InviteUserMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(InviteUser), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('user'),
      });
    },
  });
};
