import { Mark, mergeAttributes } from '@tiptap/core';
import * as Y from 'yjs';
import { getMarksByName } from '../../utils';

export type CommentStatus = 'open' | 'resolved';

export interface CommentThread {
  id: string;
  comments: CommentData[];
  status?: CommentStatus;
}

export interface Reaction {
  id: string;
  userId: string;
  username: string;
}

export interface CommentData {
  content: string;
  reactions?: Reaction[];
  time: number;
  userId: string;
  username: string;
  picture?: string;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    comment: {
      addCommentThread: (commentThread: CommentThread) => ReturnType;
      updateCommentThread: (commentThread: CommentThread) => ReturnType;
      removeCommentThread: (commentThread: CommentThread) => ReturnType;
      resolveCommentThread: (commentThread: CommentThread) => ReturnType;
      unresolveCommentThread: (commentThread: CommentThread) => ReturnType;
    };
  }
}

export interface CommentOptions {
  document: Y.Doc;
  HTMLAttributes: Record<string, any>;
}

/**
 * Comment mark - a mark that represents a comment thread
 * We store comment data in a Yjs document
 * so that it can be shared across multiple editors.
 * This is necessary for collaborative editing.
 *
 * The document id into the yjs doc is 'commentThreads'. The comment
 * mark stores the comment thread id in the comment attribute
 */

export const Comment = Mark.create<CommentOptions>({
  name: 'comment',

  spanning: false,
  inclusive: false,

  addOptions() {
    return {
      document: new Y.Doc(),
      HTMLAttributes: {},
    };
  },

  addStorage() {
    return {
      document: this.options.document,
    };
  },

  parseHTML() {
    return [
      {
        tag: 'span[data-comment]',
        getAttrs: (el) => !!(el as HTMLSpanElement).getAttribute('data-comment')?.trim() && null,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), 0];
  },

  addAttributes() {
    return {
      comment: {
        default: null,
        parseHTML: (el) => (el as HTMLSpanElement).getAttribute('data-comment'),
        renderHTML: (attrs) => ({ 'data-comment': attrs.comment }),
      },
      status: {
        default: 'open',
        parseHTML: (el) => (el as HTMLSpanElement).getAttribute('data-status'),
        renderHTML: (attrs) => ({ 'data-status': attrs.status }),
      },
    };
  },

  addCommands() {
    return {
      addCommentThread:
        (commentThread: CommentThread) =>
        ({ commands }) => {
          this.storage.document.getMap('commentThreads').set(commentThread.id, {
            ...commentThread,
            status: 'open',
          });
          return commands.setMark(this.name, { comment: commentThread.id });
        },

      updateCommentThread:
        (updatedComment: CommentThread) =>
        ({ chain }) => {
          this.storage.document.getMap('commentThreads').set(updatedComment.id, updatedComment);
          return chain()
            .extendMarkRange(this.name)
            .updateAttributes(this.name, { comment: updatedComment.id })
            .run();
        },

      removeCommentThread:
        (commentThread: CommentThread) =>
        ({ tr, dispatch }) => {
          this.storage.document.getMap('commentThreads').delete(commentThread.id);

          // Find all comment marks with the same comment id
          const commentMarks = getMarksByName(tr, this.name, {
            comment: commentThread.id,
          });

          // Remove all comment marks
          commentMarks.forEach(({ mark, range }) => {
            tr.removeMark(range.from, range.to, mark);
          });

          return dispatch?.(tr);
        },

      resolveCommentThread:
        (commentThread: CommentThread) =>
        ({ tr }) => {
          // Find all open comment marks with the same comment id
          const commentMarks = getMarksByName(tr, this.name, {
            comment: commentThread.id,
            status: 'open',
          });

          // Remove all open comment marks and then add new comment
          // marks, with same attributes, but resolved status
          commentMarks.forEach(({ mark, range }) => {
            tr.removeMark(range.from, range.to, mark);
            tr.addMark(
              range.from,
              range.to,
              mark.type.create({ comment: commentThread.id, status: 'resolved' }),
            );
          });

          // Set the comment thread status to resolved
          this.storage.document.getMap('commentThreads').set(commentThread.id, {
            ...commentThread,
            status: 'resolved',
          });

          return true;
        },

      unresolveCommentThread:
        (commentThread: CommentThread) =>
        ({ tr }) => {
          // Find all resolved comment marks with the same comment id
          const commentMarks = getMarksByName(tr, this.name, {
            comment: commentThread.id,
            status: 'resolved',
          });

          // Remove all resolved comment marks and then add new comment
          // marks, with same attributes, but open status
          commentMarks.forEach(({ mark, range }) => {
            tr.removeMark(range.from, range.to, mark);
            tr.addMark(
              range.from,
              range.to,
              mark.type.create({ comment: commentThread.id, status: 'open' }),
            );
          });

          // Set the comment thread status to open
          this.storage.document.getMap('commentThreads').set(commentThread.id, {
            ...commentThread,
            status: 'open',
          });

          return true;
        },
    };
  },
});

export const getCommentThread = (document: Y.Doc, id: string): CommentThread | null => {
  return (document.getMap('commentThreads').get(id) as CommentThread | undefined) ?? null;
};

export const getCommentThreads = (document: Y.Doc): CommentThread[] => {
  return Array.from(document.getMap('commentThreads').values()) as CommentThread[];
};
