import gql from 'graphql-tag';

export const CreateParticipantNoteLabel = gql`
  mutation CreateParticipantNoteLabel(
    $organizationId: ID!
    $participantId: ID!
    $participantNoteId: ID!
    $labelId: ID!
  ) {
    createParticipantNoteLabel(
      organizationId: $organizationId
      participantId: $participantId
      participantNoteId: $participantNoteId
      labelId: $labelId
    ) {
      id
    }
  }
`;
