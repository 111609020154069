import { IncidentSeverity, IncidentStatus } from '@piccolohealth/pbs-common';
import {
  arrayOfParam,
  dateTimeParam,
  numberParam,
  stringParam,
  useDebouncedCallback,
  useQueryParams,
} from '@piccolohealth/ui';
import { DateTime, P } from '@piccolohealth/util';
import { PaginationFilter } from '../graphql/fetcher';

const DEFAULT_PAGE_NUMBER = 0;
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_NAME_FILTER = '';
const DEFAULT_TIMESTAMP_FILTER = undefined;
const DEFAULT_LOCATION_FILTER: string[] = [];
const DEFAULT_PARTICIPANT_FILTER: string[] = [];
const DEFAULT_SEVERITY_FILTER: IncidentSeverity[] = [];
const DEFAULT_STATUS_FILTER: IncidentStatus[] = [];
const DEFAULT_LABEL_FILTER: string[] = [];
const DEFAULT_START_DATE_FILTER = undefined;
const DEFAULT_END_DATE_FILTER = undefined;

export const incidentsFilterDefaults: IncidentsFilter = {
  pageSizeOptions: [10, 20, 30],
  nameFilter: DEFAULT_NAME_FILTER,
  timestampFilter: DEFAULT_TIMESTAMP_FILTER,
  locationFilter: DEFAULT_LOCATION_FILTER,
  participantFilter: DEFAULT_PARTICIPANT_FILTER,
  severityFilter: DEFAULT_SEVERITY_FILTER,
  statusFilter: DEFAULT_STATUS_FILTER,
  labelFilter: DEFAULT_LABEL_FILTER,
  startDateFilter: DEFAULT_START_DATE_FILTER,
  endDateFilter: DEFAULT_END_DATE_FILTER,
  pageSize: DEFAULT_PAGE_SIZE,
  currentPageNumber: DEFAULT_PAGE_NUMBER,
  activeFilterCount: 0,
  showTotal: () => '',
  setPageSize: P.noop,
  setCurrentPageNumber: P.noop,
  onNameFilter: P.noop,
  onTimestampFilter: P.noop,
  onLocationFilter: P.noop,
  onParticipantFilter: P.noop,
  onSeverityFilter: P.noop,
  onStatusFilter: P.noop,
  onLabelFilter: P.noop,
  onStartDateFilter: P.noop,
  onEndDateFilter: P.noop,
  reset: P.noop,
};

export interface IncidentsFilter extends PaginationFilter {
  nameFilter: string;
  timestampFilter: DateTime | undefined;
  locationFilter: string[] | undefined;
  participantFilter: string[] | undefined;
  severityFilter: IncidentSeverity[] | undefined;
  statusFilter: IncidentStatus[] | undefined;
  labelFilter: string[] | undefined;
  startDateFilter: DateTime | undefined;
  endDateFilter: DateTime | undefined;
  activeFilterCount: number;
  onNameFilter: (name: string) => void;
  onTimestampFilter: (date?: DateTime) => void;
  onLocationFilter: (locations?: string[]) => void;
  onParticipantFilter: (participants?: string[]) => void;
  onSeverityFilter: (severities?: IncidentSeverity[]) => void;
  onStatusFilter: (statuses?: IncidentStatus[]) => void;
  onLabelFilter: (labels?: string[]) => void;
  onStartDateFilter: (start?: DateTime) => void;
  onEndDateFilter: (end?: DateTime) => void;
  reset: () => void;
}

export const useIncidentsFilter: () => IncidentsFilter = () => {
  const [params, setParams] = useQueryParams({
    page: numberParam,
    pageSize: numberParam,
    name: stringParam,
    timestamp: dateTimeParam,
    location: arrayOfParam(stringParam, { delimiter: ',' }),
    participant: arrayOfParam(stringParam, { delimiter: ',' }),
    severity: arrayOfParam(stringParam, { delimiter: ',' }),
    status: arrayOfParam(stringParam, { delimiter: ',' }),
    label: arrayOfParam(stringParam, { delimiter: ',' }),
    start: dateTimeParam,
    end: dateTimeParam,
  });

  const setCurrentPageNumber = (value: number) =>
    setParams({
      page: value,
    });

  const setPageSize = (value: number) =>
    setParams({
      pageSize: value,
    });

  const onNameFilter = useDebouncedCallback(
    (value: string) =>
      setParams({
        name: value,
        page: 0,
      }),
    1000,
  );

  const onTimestampFilter = (value?: DateTime) =>
    setParams({
      timestamp: value,
      page: 0,
    });

  const onLocationFilter = (value?: string[]) =>
    setParams({
      location: value,
      page: 0,
    });

  const onParticipantFilter = (value?: string[]) =>
    setParams({
      participant: value,
      page: 0,
    });

  const onSeverityFilter = (value?: IncidentSeverity[]) =>
    setParams({
      severity: value,
      page: 0,
    });

  const onStatusFilter = (value?: IncidentStatus[]) =>
    setParams({
      status: value,
      page: 0,
    });

  const onLabelFilter = (value?: string[]) =>
    setParams({
      label: value,
      page: 0,
    });

  const onStartDateFilter = (value?: DateTime) =>
    setParams({
      start: value,
      page: 0,
    });

  const onEndDateFilter = (value?: DateTime) =>
    setParams({
      end: value,
      page: 0,
    });

  const showTotal = (total: number, range: [number, number]) =>
    `${range[0]}-${range[1]} of ${total} incidents`;

  const reset = () => {
    setParams({
      page: DEFAULT_PAGE_NUMBER,
      pageSize: DEFAULT_PAGE_SIZE,
      name: DEFAULT_NAME_FILTER,
      timestamp: DEFAULT_TIMESTAMP_FILTER,
      location: DEFAULT_LOCATION_FILTER,
      participant: DEFAULT_PARTICIPANT_FILTER,
      severity: DEFAULT_SEVERITY_FILTER,
      status: DEFAULT_STATUS_FILTER,
      label: DEFAULT_LABEL_FILTER,
      start: DEFAULT_START_DATE_FILTER,
      end: DEFAULT_END_DATE_FILTER,
    });
  };

  const activeFilterCount = P.compact([
    params.name,
    params.timestamp,
    params.location,
    params.participant,
    params.severity,
    params.status,
    params.label,
    params.start,
    params.end,
  ]).length;

  return {
    pageSizeOptions: [10, 20, 30],
    nameFilter: params.name ?? DEFAULT_NAME_FILTER,
    timestampFilter: params.timestamp ?? DEFAULT_TIMESTAMP_FILTER,
    locationFilter: params.location ?? DEFAULT_LOCATION_FILTER,
    participantFilter: params.participant ?? DEFAULT_PARTICIPANT_FILTER,
    severityFilter: (params.severity as IncidentSeverity[]) ?? DEFAULT_SEVERITY_FILTER,
    statusFilter: (params.status as IncidentStatus[]) ?? DEFAULT_STATUS_FILTER,
    labelFilter: params.label ?? DEFAULT_LABEL_FILTER,
    startDateFilter: params.start ?? DEFAULT_START_DATE_FILTER,
    endDateFilter: params.end ?? DEFAULT_END_DATE_FILTER,
    pageSize: params.pageSize ?? DEFAULT_PAGE_SIZE,
    currentPageNumber: params.page ?? DEFAULT_PAGE_NUMBER,
    activeFilterCount,
    showTotal,
    setPageSize,
    setCurrentPageNumber,
    onNameFilter,
    onTimestampFilter,
    onLocationFilter,
    onParticipantFilter,
    onSeverityFilter,
    onStatusFilter,
    onLabelFilter,
    onStartDateFilter,
    onEndDateFilter,
    reset,
  };
};
