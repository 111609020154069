import React from 'react';
import { useController } from 'react-hook-form';
import { LocationChooser, LocationChooserProps } from './LocationChooser';

interface Props extends Omit<LocationChooserProps, 'onChange' | 'value'> {
  name: string;
}

export const HookedLocationChooser = (props: Props) => {
  const { name, ...rest } = props;
  const { field } = useController({ name });

  return <LocationChooser {...rest} value={field.value} onChange={field.onChange} />;
};
