import { Icon } from '@chakra-ui/react';
import { AggregationType } from '@piccolohealth/pbs-common';
import { P } from '@piccolohealth/util';
import React from 'react';

interface SvgProps {
  size: number;
}

const Average = (props: SvgProps) => {
  return (
    <svg
      width={`${props.size}px`}
      height={`${props.size}px`}
      viewBox={`0 0 32 32`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M23,24c-3.5991,0-5.0293-4.1758-6.4126-8.2139C15.2764,11.9583,13.92,8,11,8a3.44,3.44,0,0,0-3.0532,2.3215L6.0513,9.6838C6.1016,9.5334,7.3218,6,11,6c4.3491,0,6.0122,4.8547,7.48,9.1379C19.6885,18.6667,20.83,22,23,22a3.44,3.44,0,0,0,3.0532-2.3215l1.8955.6377C27.8984,20.4666,26.6782,24,23,24Z" />
      <path d="M4,28V17H6V15H4V2H2V28a2,2,0,0,0,2,2H30V28Z" />
      <rect x={8} y={15} width={2} height={2} />
      <rect x={12} y={15} width={2} height={2} />
      <rect x={20} y={15} width={2} height={2} />
      <rect x={24} y={15} width={2} height={2} />
      <rect x={28} y={15} width={2} height={2} />
    </svg>
  );
};

const Minimum = (props: SvgProps) => {
  return (
    <svg
      width={`${props.size}px`}
      height={`${props.size}px`}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4,28V26H6V24H4V2H2V28a2,2,0,0,0,2,2H30V28Z" />
      <rect x="8" y="24" width="2" height="2" />
      <rect x="12" y="24" width="2" height="2" />
      <rect x="20" y="24" width="2" height="2" />
      <rect x="24" y="24" width="2" height="2" />
      <rect x="28" y="24" width="2" height="2" />
      <path d="M27.9463,4.3281,25.9727,4C23.9487,16.1084,21.0137,22,17,22S10.0513,16.1084,8.0271,4l-1.9734.3281C7.51,13.0566,9.9631,22.93,16,23.9131V26h2V23.9131C24.0371,22.93,26.4907,13.0566,27.9463,4.3281Z" />
    </svg>
  );
};

const Maximum = (props: SvgProps) => {
  return (
    <svg
      width={`${props.size}px`}
      height={`${props.size}px`}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="8" y="6" width="2" height="2" />
      <rect x="12" y="6" width="2" height="2" />
      <rect x="20" y="6" width="2" height="2" />
      <rect x="24" y="6" width="2" height="2" />
      <rect x="28" y="6" width="2" height="2" />
      <path d="M27.989,28l-.0271-.1631C26.5105,19.0742,24.0662,9.0894,18,8.0889V6H16V8.085c-6.084.978-8.533,10.9775-9.9863,19.7519L5.9866,28H4V8H6V6H4V2H2V28a2,2,0,0,0,2,2H30V28ZM8.0151,28c2.024-12.1084,4.959-18,8.9727-18s6.9487,5.8916,8.9726,18Z" />
    </svg>
  );
};

const Sum = (props: SvgProps) => {
  return (
    <svg
      width={`${props.size}px`}
      height={`${props.size}px`}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polygon points="24 5 7 5 7 7.414 15.586 16 7 24.586 7 27 24 27 24 25 9.414 25 18.414 16 9.414 7 24 7 24 5" />
    </svg>
  );
};

interface Props {
  aggregationType: AggregationType;
  color?: string;
  size?: number;
}

export const AggregationTypeIcon = (props: Props) => {
  const icon = P.run(() => {
    switch (props.aggregationType) {
      case AggregationType.Average:
        return Average;
      case AggregationType.Min:
        return Minimum;
      case AggregationType.Max:
        return Maximum;
      case AggregationType.Sum:
        return Sum;
    }
  });

  return <Icon as={icon} color={props.color} size={props.size ?? 16} />;
};
