import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { FastForwardParticipant } from '../mutations/fastForwardParticipant.mutation';
import { FastForwardParticipantMutation, FastForwardParticipantMutationVariables } from '../types';

export const useFastForwardParticipantMutation = (
  options?: MutationOptions<
    FastForwardParticipantMutation,
    FastForwardParticipantMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(FastForwardParticipant), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('participant'),
      });
    },
  });
};
