import { Stack, Wrap, WrapItem } from '@chakra-ui/react';
import { ParticipantBehaviour } from '@piccolohealth/pbs-common';
import { Spin } from '@piccolohealth/ui';
import React from 'react';
import { Error } from '../../components/generic/Error';
import { showModal } from '../../components/generic/Modal';
import { useParticipantBehavioursQuery } from '../../graphql/hooks/useParticipantQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { ParticipantBehaviourCard } from './ParticipantBehaviourCard';
import { ParticipantBehaviourCreateButton } from './ParticipantBehaviourCreateButton';
import { ParticipantBehaviourCreateModal } from './ParticipantBehaviourCreateModal';

interface Props {
  participantId: string;
}

export const ParticipantBehaviours = (props: Props) => {
  const { participantId } = props;
  const { organization } = useAppContext();

  const { data, isLoading, error } = useParticipantBehavioursQuery({
    organizationId: organization.id,
    participantId,
  });

  const participantBehaviours =
    (data?.organization?.participant?.behaviours as ParticipantBehaviour[]) ?? [];

  if (isLoading) {
    return <Spin />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <Stack px={6} py={4}>
      <Wrap spacing={4}>
        {participantBehaviours.map((participantBehaviour) => (
          <WrapItem key={participantBehaviour.id} w="full" maxW="lg" minH="sm">
            <ParticipantBehaviourCard
              participantId={participantId}
              participantBehaviour={participantBehaviour}
            />
          </WrapItem>
        ))}
        <WrapItem w="full" maxW="lg" minH="sm">
          <ParticipantBehaviourCreateButton
            onClick={() =>
              showModal(ParticipantBehaviourCreateModal, {
                participantId,
              })
            }
          />
        </WrapItem>
      </Wrap>
    </Stack>
  );
};
