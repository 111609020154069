import { Button, Icon, Menu, MenuButton, ButtonProps, MenuList } from '@chakra-ui/react';
import React from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { ParticipantDeleteMenuItem } from './ParticipantDeleteMenuItem';
import { ParticipantEditMenuItem } from './ParticipantEditMenuItem';

interface Props extends ButtonProps {
  participantId: string;
  isDisabled?: boolean;
}

export const ParticipantActionMenu = (props: Props) => {
  const { participantId, isDisabled, ...rest } = props;

  return (
    <Menu isLazy lazyBehavior="keepMounted">
      <MenuList>
        <ParticipantEditMenuItem participantId={participantId} isDisabled={isDisabled} />
        <ParticipantDeleteMenuItem participantId={participantId} />
      </MenuList>
      <MenuButton as={Button} variant="outline" rightIcon={<Icon as={FaAngleDown} />} {...rest}>
        Actions
      </MenuButton>
    </Menu>
  );
};
