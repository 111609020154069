import { Editor, EditorOptions, Extensions, useEditor as useTiptapEditor } from '@tiptap/react';
import { DependencyList } from 'react';
import { monkeyPatch } from '../monkeyPatch';

// Monkey patching is required to fix a bug in tiptap
// https://github.com/ueberdosis/tiptap/issues/4492
monkeyPatch();

export interface UseEditorOptions extends Partial<EditorOptions> {
  extensions: Extensions;
  isEditable?: boolean;
}

export interface UseEditorReturn {
  editor: Editor | null;
}

export const useEditor = (options: UseEditorOptions, deps?: DependencyList): UseEditorReturn => {
  const { extensions, isEditable, ...rest } = options;

  const editor = useTiptapEditor(
    {
      ...rest,
      extensions,
      editable: isEditable,
    },
    deps,
  );

  return { editor };
};
