import gql from 'graphql-tag';

export const DeleteParticipantFileLabel = gql`
  mutation DeleteParticipantFileLabel(
    $organizationId: ID!
    $participantId: ID!
    $participantFileId: ID!
    $labelId: ID!
  ) {
    deleteParticipantFileLabel(
      organizationId: $organizationId
      participantId: $participantId
      participantFileId: $participantFileId
      labelId: $labelId
    )
  }
`;
