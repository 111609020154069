import gql from 'graphql-tag';

export const Templates = gql`
  query Templates($organizationId: ID!, $request: GetPaginatedTemplatesRequest) {
    organization(organizationId: $organizationId) {
      templates(request: $request) {
        results {
          id
          versionId
          type
          name
          status
          creator {
            id
            name
            email
            picture
          }
          labels {
            id
            name
            description
            color
          }
          thumbnailUrl
          createdAt
          updatedAt
        }
      }
    }
  }
`;
