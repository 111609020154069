import { Box, Divider, Flex } from '@chakra-ui/react';
import * as Common from '@piccolohealth/pbs-common';
import { Spin } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { Error } from '../../components/generic/Error';
import { FormSection } from '../../components/forms/FormSection';
import { useParticipantFieldGroupTemplatesQuery } from '../../graphql/hooks/useParticipantFieldGroupTemplatesQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { ParticipantFieldTemplates } from './fields/ParticipantFieldTemplates';
import { ParticipantFieldGroupTemplates } from './groups/ParticipantFieldGroupTemplates';

export const FieldSettings = () => {
  const { organization } = useAppContext();

  const { data, isLoading, error } = useParticipantFieldGroupTemplatesQuery({
    organizationId: organization.id,
  });

  const fieldGroupTemplates = React.useMemo(
    () =>
      (data?.organization
        ?.participantFieldGroupTemplates as unknown as Common.ParticipantFieldGroupTemplate[]) ??
      [],
    [data],
  );

  const [currentFieldGroupTemplateId, setCurrentFieldGroupTemplateId] = React.useState<string>('');

  // Set the field group template to be the first in the list of group templates. If there are none
  // at all, reset the current group template to nothing.
  React.useEffect(() => {
    if (P.isEmpty(fieldGroupTemplates)) {
      return setCurrentFieldGroupTemplateId('');
    }

    const startingFieldGroupTemplate = fieldGroupTemplates[0].id;

    if (!currentFieldGroupTemplateId) {
      setCurrentFieldGroupTemplateId(startingFieldGroupTemplate);
    }
  }, [currentFieldGroupTemplateId, fieldGroupTemplates]);

  if (isLoading) {
    return <Spin />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <FormSection heading="Fields" description="Create groups and assign fields to them">
      <Divider />
      <Flex h="xl" align="start">
        <Box minW="xs" flexGrow={0}>
          <ParticipantFieldGroupTemplates
            fieldGroupTemplates={fieldGroupTemplates}
            currentFieldGroupTemplateId={currentFieldGroupTemplateId}
            onChangeCurrentFieldGroupTemplateId={setCurrentFieldGroupTemplateId}
          />
        </Box>
        <Box minH="md" minW="lg" borderLeftColor="gray.200" borderLeftWidth="1px" pl={6} ml={6}>
          <ParticipantFieldTemplates
            fieldGroupTemplateId={currentFieldGroupTemplateId}
            fieldGroupTemplates={fieldGroupTemplates}
          />
        </Box>
      </Flex>
    </FormSection>
  );
};
