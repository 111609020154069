import {
  BubbleMenu as TiptapBubbleMenu,
  Editor,
  findParentNodeClosestToPos,
  isNodeSelection,
  posToDOMRect,
} from '@tiptap/react';
import React from 'react';

import 'tippy.js/animations/shift-away.css';
import { TableMenu } from './TableMenu';

interface Props {
  editor: Editor;
}

export const TableBubbleMenu = (props: Props) => {
  const { editor } = props;

  const getReferenceClientRect = React.useMemo(
    () =>
      editor
        ? () => {
            const nearestTableParent = editor.isActive('table')
              ? findParentNodeClosestToPos(
                  editor.state.selection.$anchor,
                  (node) => node.type.name === 'table',
                )
              : null;

            if (nearestTableParent) {
              const wrapperDomNode = editor.view.nodeDOM(nearestTableParent.pos) as
                | HTMLElement
                | null
                | undefined;

              // The DOM node of a Tiptap table node is a div wrapper, which contains a `table` child.
              // The div wrapper is a block element that fills the entire row, but the table may not be
              // full width, so we want to get our bounding rectangle based on the `table` (to align it
              // with the table itself), not the div. See
              // https://github.com/ueberdosis/tiptap/blob/40a9404c94c7fef7900610c195536384781ae101/packages/extension-table/src/TableView.ts#L69-L71
              const tableDomNode = wrapperDomNode?.querySelector('table');
              if (tableDomNode) {
                return tableDomNode.getBoundingClientRect();
              }
            }

            // Since we weren't able to find a table from the current user position, that means the user
            // hasn't put their cursor in a table. We'll be hiding the table in this case, but we need
            // to return a bounding rect regardless (can't return `null`), so we use the standard logic
            // based on the current cursor position/selection instead.
            const { ranges } = editor.state.selection;
            const from = Math.min(...ranges.map((range) => range.$from.pos));
            const to = Math.max(...ranges.map((range) => range.$to.pos));
            return posToDOMRect(editor.view, from, to);
          }
        : null,
    [editor],
  );

  const isTableActive = props.editor.isActive('table');

  return (
    <TiptapBubbleMenu
      editor={editor}
      tippyOptions={{
        duration: 100,
        maxWidth: 'calc(100vw - 100px)',
        placement: 'bottom',
        arrow: false,
        appendTo: 'parent',
        animation: 'shift-away',
        inertia: true,
        zIndex: 1,

        getReferenceClientRect,
      }}
      shouldShow={({ view, state, editor }) => {
        const { selection } = state;

        const emptySelection = selection.empty;
        const hasEditorFocus = view.hasFocus();

        if (editor.isActive('comment')) {
          return false;
        }

        if (editor.isActive('table')) {
          return true;
        }

        if (!hasEditorFocus || emptySelection || !editor.isEditable || isNodeSelection(selection)) {
          return false;
        }

        return true;
      }}
    >
      {isTableActive && <TableMenu editor={editor} />}
    </TiptapBubbleMenu>
  );
};
