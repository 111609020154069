import {
  Button,
  ButtonGroup,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { TemplateStatus } from '@piccolohealth/pbs-common';
import React from 'react';
import { createModal } from '../../components/generic/Modal';
import { useUpdateTemplateMutation } from '../../graphql/hooks/useUpdateTemplateMutation';
import { useAppContext } from '../../hooks/useAppContext';

interface Props {
  templateId: string;
}

export const TemplateDeleteModal = createModal<Props>((props) => {
  const { templateId, modal } = props;
  const { organization, successToast, errorToast } = useAppContext();
  const mutation = useUpdateTemplateMutation();
  const { hide, remove, visible } = modal;

  const onClick = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        templateId,
        request: {
          status: TemplateStatus.Archived,
        },
      })
      .then(async () => {
        hide();
        successToast('Template deleted successfully');
      })
      .catch((err) => {
        errorToast(`Error deleting template: ${err.message}`);
      });
  }, [mutation, organization.id, templateId, hide, successToast, errorToast]);

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={remove}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Do you want to delete this template?</ModalHeader>
        <ModalCloseButton />
        <ModalFooter>
          <ButtonGroup size="sm">
            <Button key="close" onClick={hide}>
              Close
            </Button>
            <Button
              key="submit"
              colorScheme="red"
              isLoading={mutation.isLoading}
              loadingText="Deleting..."
              type="submit"
              onClick={onClick}
            >
              Delete
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
