import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { DeleteIncidentLabel } from '../mutations/deleteIncidentLabel.mutation';
import { DeleteIncidentLabelMutation, DeleteIncidentLabelMutationVariables } from '../types';

export const useDeleteIncidentLabelMutation = (
  options?: MutationOptions<DeleteIncidentLabelMutation, DeleteIncidentLabelMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(DeleteIncidentLabel), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('incident'),
      });
    },
  });
};
