import { Box, Flex, HStack, IconButton, Image } from '@chakra-ui/react';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import React from 'react';
import { FaAlignCenter, FaAlignLeft, FaAlignRight, FaTrash } from 'react-icons/fa';
import { ResizeHandleContainer } from './ResizeHandleContainer';

const MAX_IMAGE_WIDTH = 48;

const getAlign = (align: 'left' | 'right' | 'center') => {
  switch (align) {
    case 'left':
      return 'flex-start';
    case 'right':
      return 'flex-end';
    case 'center':
      return 'center';
  }
};

export const ImageNodeView = (props: NodeViewProps) => {
  const { node, editor, updateAttributes } = props;

  const ref = React.useRef<HTMLImageElement | null>(null);
  const [resizing, setResizing] = React.useState<boolean>(false);
  const [resizeInitialWidth, setResizeInitialWidth] = React.useState<number>(0);
  const [resizeInitialMouseX, setResizeInitialMouseX] = React.useState<number>(0);
  const [resizeInitialHeight, setResizeInitialHeight] = React.useState<number>(0);

  const onStartResize = React.useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    setResizing(true);
    setResizeInitialMouseX(e.clientX);

    if (ref.current) {
      setResizeInitialWidth(ref.current.offsetWidth);
      setResizeInitialHeight(ref.current.offsetHeight);
    }
  }, []);

  const onResize = React.useCallback(
    (e: MouseEvent) => {
      if (!resizing) {
        return;
      }

      e.preventDefault();

      const dx = e.clientX - resizeInitialMouseX;

      const newWidth = Math.max(resizeInitialWidth + dx, MAX_IMAGE_WIDTH);
      const newHeight = (resizeInitialHeight / resizeInitialWidth) * newWidth;

      props.updateAttributes({
        width: newWidth,
        height: newHeight,
      });
    },
    [props, resizeInitialHeight, resizeInitialMouseX, resizeInitialWidth, resizing],
  );

  const onEndResize = React.useCallback(() => {
    setResizing(false);
    setResizeInitialMouseX(0);
    setResizeInitialWidth(0);
  }, []);

  const onDelete = React.useCallback(() => {
    editor.chain().deleteSelection().focus().run();
  }, [editor]);

  const onAlign = React.useCallback(
    (align: 'left' | 'center' | 'right') => {
      updateAttributes({
        align,
      });
    },
    [updateAttributes],
  );

  React.useEffect(() => {
    window.addEventListener('mousemove', onResize);
    window.addEventListener('mouseup', onEndResize);

    return () => {
      window.removeEventListener('mousemove', onResize);
      window.removeEventListener('mouseup', onEndResize);
    };
  }, [onResize, onEndResize]);

  // Set the height on attrs if it is not set
  React.useLayoutEffect(() => {
    if (!ref.current || props.node.attrs.height) {
      return;
    }

    if (!ref.current.complete) {
      return;
    }

    // When image is loaded
    const height = ref.current.clientHeight;

    updateAttributes({
      height,
    });
  }, [props, updateAttributes]);

  return (
    <Flex
      as={NodeViewWrapper}
      w="full"
      flexDirection="column"
      alignItems={getAlign(node.attrs.align)}
    >
      <Box
        draggable={true}
        contentEditable={false}
        data-drag-handle
        w={`${node.attrs.width}px`}
        pos="relative"
      >
        <Image
          ref={ref}
          src={node.attrs.src}
          alt={node.attrs.alt}
          title={node.attrs.title}
          w={`${node.attrs.width}px`}
          h={`${node.attrs.height}px`}
          shadow={
            (props.selected || resizing) && editor.isEditable
              ? 'inset 0px 0px 0px 3px #68cef8'
              : undefined
          }
          p="3px"
        />

        {editor.isEditable && (
          <>
            <ResizeHandleContainer onMouseDown={onStartResize} left={0} />
            <ResizeHandleContainer onMouseDown={onStartResize} right={0} />
            <Box
              pos="absolute"
              top={1}
              left={1}
              rounded="lg"
              bg="white"
              shadow="popover"
              p={1}
              opacity={props.selected ? 1 : 0}
              transition="opacity 0.1s, transform 0.1s"
              transform={`scale(${props.selected ? 'translateY(0)' : 'translateY(10px)'})`}
              transitionDelay="0.1s"
              overflow="hidden"
            >
              <HStack bg="white" rounded="lg" spacing={0} overflow="hidden">
                <IconButton
                  icon={<FaAlignLeft />}
                  aria-label="Align left"
                  variant="ghost"
                  size="sm"
                  rounded="none"
                  onClick={() => onAlign('left')}
                />
                <IconButton
                  icon={<FaAlignCenter />}
                  aria-label="Align center"
                  variant="ghost"
                  size="sm"
                  rounded="none"
                  onClick={() => onAlign('center')}
                />
                <IconButton
                  icon={<FaAlignRight />}
                  aria-label="Align right"
                  variant="ghost"
                  size="sm"
                  rounded="none"
                  onClick={() => onAlign('right')}
                />
                <IconButton
                  icon={<FaTrash />}
                  aria-label="Delete"
                  variant="ghost"
                  size="sm"
                  rounded="none"
                  onClick={() => onDelete()}
                />
              </HStack>
            </Box>
          </>
        )}
      </Box>
    </Flex>
  );
};
