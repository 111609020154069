import gql from 'graphql-tag';

export const CreateParticipantFieldTemplate = gql`
  mutation CreateParticipantFieldTemplate(
    $organizationId: ID!
    $request: CreateParticipantFieldTemplateRequest!
  ) {
    createParticipantFieldTemplate(organizationId: $organizationId, request: $request) {
      id
      name
      type
      attributes
      index
      createdAt
      updatedAt
    }
  }
`;
