import { Button, ButtonGroup, forwardRef, IconButton, Square } from '@chakra-ui/react';
import { TimeSeries } from '@piccolohealth/pbs-common';
import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { BadgeItem } from '../../../../components/generic/BadgeItem';
import { UseTimeseriesChartReturn } from '../../../participantData/hooks/useTimeseriesChart';
import {
  AGGREGATION_TYPE_OPTIONS_MAP,
  CHART_TYPE_OPTIONS_MAP,
  METHOD_TYPE_OPTIONS_MAP,
} from '../../../participantData/utils';
import { AggregationTypeIcon } from './AggregationTypeIcon';
import { ChartTypeIcon } from './ChartTypeIcon';

interface Props {
  chart: UseTimeseriesChartReturn;
  series: TimeSeries;
  onClick: () => void;
}

export const TimeSeriesButtonGroup = forwardRef<Props, 'div'>((props, ref) => {
  const { chart, series, onClick } = props;

  const chartType = CHART_TYPE_OPTIONS_MAP[series.__typename!];
  const aggregationType = AGGREGATION_TYPE_OPTIONS_MAP[series.aggregation];
  const method = METHOD_TYPE_OPTIONS_MAP[series.source.method];

  const onRemove = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    chart.onRemoveSeries(series.id);
  };

  return (
    <ButtonGroup ref={ref} size="sm" isAttached variant="outline" onClick={onClick}>
      <Button p={1.5}>
        <Square rounded={4} bg={series.color} size={5} />
      </Button>
      <Button px={1.5}>
        <BadgeItem label={method.label} color={method.color} />
      </Button>
      <Button px={1.5}>
        <BadgeItem
          label={chartType.label}
          color={chartType.color}
          icon={<ChartTypeIcon chartType={chartType.raw} size={16} />}
        />
      </Button>
      <Button px={1.5}>
        <BadgeItem
          label={aggregationType.label}
          color={aggregationType.color}
          icon={<AggregationTypeIcon aggregationType={aggregationType.raw} size={16} />}
        />
      </Button>
      <IconButton aria-label="Remove method" icon={<FaTimes />} onClick={onRemove} />
    </ButtonGroup>
  );
});
