import { HStack, Spacer } from '@chakra-ui/react';
import { Editor } from '@tiptap/react';
import React from 'react';
import { AlignmentSelect } from './AlignmentSelect';
import { CommentButton } from './comment/CommentButton';
import { IndentSelect } from './IndentSelect';
import { MarkSelect } from './MarkSelect';
import { NodeTypeSelect } from './NodeTypeSelect';
import { TextColorSelect } from './TextColorSelect';
import { UndoRedo } from './UndoRedo';
import { VerticalDivider } from './VerticalDivider';

interface Props {
  editor: Editor;
}

export const EditorMenu = (props: Props) => {
  const { editor } = props;

  return (
    <HStack bg="white" w="full" rounded="lg" overflow="hidden" p={1} spacing={0} shadow="popover">
      <HStack spacing={2} w="full">
        <NodeTypeSelect editor={editor} />
        <VerticalDivider />
        <MarkSelect editor={editor} />
        <VerticalDivider />
        <AlignmentSelect editor={editor} />
        <VerticalDivider />
        <IndentSelect editor={editor} />
        <VerticalDivider />
        <TextColorSelect editor={editor} />
        <VerticalDivider />
        <CommentButton editor={editor} />
        <Spacer />
        <UndoRedo editor={editor} />
      </HStack>
    </HStack>
  );
};
