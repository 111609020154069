import {
  MethodType,
  ParticipantBehaviour,
  ParticipantBehaviourRecording,
  TableSeries,
  TableWidget,
} from '@piccolohealth/pbs-common';
import { DateTime } from '@piccolohealth/util';
import React from 'react';

export interface UseTableOptions {
  widget: TableWidget;
  participantBehaviours: ParticipantBehaviour[];
  startDate: DateTime;
  endDate: DateTime;
  setWidget: React.Dispatch<React.SetStateAction<TableWidget>>;
}

export const useTable = (opts: UseTableOptions) => {
  const { participantBehaviours, widget, startDate, endDate, setWidget } = opts;

  const onAddSeries = React.useCallback(
    (series: TableSeries) => {
      setWidget((prev) => ({
        ...prev,
        series: [...prev.series, series],
      }));
    },
    [setWidget],
  );

  const onEditSeries = React.useCallback(
    (seriesId: string, series: TableSeries) => {
      setWidget((prev) => ({
        ...prev,
        series: prev.series.map((s) => (s.id === seriesId ? series : s)),
      }));
    },
    [setWidget],
  );

  const onRemoveSeries = React.useCallback(
    (seriesId: string) => {
      setWidget((prev) => ({
        ...prev,
        series: prev.series.filter((s) => s.id !== seriesId),
      }));
    },
    [setWidget],
  );

  const onSetTitle = React.useCallback(
    (title: string) => {
      setWidget((prev) => ({
        ...prev,
        title,
      }));
    },
    [setWidget],
  );

  const recordings: ParticipantBehaviourRecording[] = React.useMemo(() => {
    const data = widget.series.flatMap((series) => {
      const behaviourData = participantBehaviours.find(
        (pb) => pb.id === series.source.participantBehaviourId,
      );

      if (!behaviourData) {
        return [];
      }

      return behaviourData.recordings.filter((recording) => {
        switch (series.source.method) {
          case MethodType.Abc:
            return recording.__typename === 'ParticipantBehaviourRecordingAbc';
          case MethodType.Duration:
            return recording.__typename === 'ParticipantBehaviourRecordingDuration';
          case MethodType.Frequency:
            return recording.__typename === 'ParticipantBehaviourRecordingFrequency';
          case MethodType.EpisodicSeverity:
            return recording.__typename === 'ParticipantBehaviourRecordingEpisodicSeverity';
        }
      });
    });

    return data.filter((recording) => {
      const timestamp = DateTime.fromISO(recording.timestamp.toString());
      return timestamp >= startDate && timestamp <= endDate;
    });
  }, [endDate, participantBehaviours, startDate, widget.series]);

  return {
    participantBehaviours,
    startDate,
    endDate,
    widget,
    recordings,
    onAddSeries,
    onEditSeries,
    onRemoveSeries,
    onSetTitle,
  };
};

export type UseTableReturn = ReturnType<typeof useTable>;
