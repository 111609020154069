import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { CreateForm } from '../mutations/createForm.mutation';
import { CreateFormMutation, CreateFormMutationVariables } from '../types';

export const useCreateFormMutation = (
  options?: MutationOptions<CreateFormMutation, CreateFormMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(CreateForm), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('organization'),
      });
    },
  });
};
