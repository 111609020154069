import {
  Button,
  ButtonGroup,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import { createModal } from '../../../../components/generic/Modal';
import { useAppContext } from '../../../../hooks/useAppContext';

interface Props {
  onDelete: () => void;
}

export const ParticipantWidgetDeleteModal = createModal<Props>((props) => {
  const { onDelete, modal } = props;
  const { successToast } = useAppContext();
  const { hide, remove, visible } = modal;

  const onClick = React.useCallback(async () => {
    onDelete();
    successToast('Widget removed successfully');
    hide();
  }, [hide, onDelete, successToast]);

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={remove}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Do you want to remove this widget?</ModalHeader>
        <ModalCloseButton />
        <ModalFooter>
          <ButtonGroup size="sm">
            <Button key="close" onClick={hide}>
              Close
            </Button>
            <Button
              key="submit"
              colorScheme="red"
              loadingText="Deleting..."
              type="submit"
              onClick={onClick}
            >
              Delete
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
