import gql from 'graphql-tag';

export const ParticipantFieldGroupTemplate = gql`
  query ParticipantFieldGroupTemplate($organizationId: ID!, $participantFieldGroupTemplateId: ID!) {
    organization(organizationId: $organizationId) {
      id
      participantFieldGroupTemplate(
        participantFieldGroupTemplateId: $participantFieldGroupTemplateId
      ) {
        id
        name
        fields {
          id
          name
          type
          attributes
          index
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
