import gql from 'graphql-tag';

export const DeleteParticipantNoteLabel = gql`
  mutation DeleteParticipantNoteLabel(
    $organizationId: ID!
    $participantId: ID!
    $participantNoteId: ID!
    $labelId: ID!
  ) {
    deleteParticipantNoteLabel(
      organizationId: $organizationId
      participantId: $participantId
      participantNoteId: $participantNoteId
      labelId: $labelId
    )
  }
`;
