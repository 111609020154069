import { MenuItem, ToastId } from '@chakra-ui/react';
import { ParticipantDocument, ParticipantDocumentStatus } from '@piccolohealth/pbs-common';
import { Async } from '@piccolohealth/util';
import React from 'react';
import { FaRegFilePdf } from 'react-icons/fa';
import { useGenerateParticipantDocumentPdfMutation } from '../../graphql/hooks/useGenerateParticipantDocumentPdfMutation';
import { useAppContext } from '../../hooks/useAppContext';

interface Props {
  participantDocument: ParticipantDocument;
}

export const ParticipantDocumentPdfMenuItem = (props: Props) => {
  const { participantDocument } = props;
  const { toast, organization } = useAppContext();
  const startToastRef = React.useRef<ToastId>();
  const finishToastRef = React.useRef<ToastId>();
  const mutation = useGenerateParticipantDocumentPdfMutation();

  const isDraft = participantDocument.status === ParticipantDocumentStatus.Draft;

  const startToast = React.useCallback(() => {
    startToastRef.current = toast({
      title: 'Generating PDF...',
      isClosable: true,
      position: 'top',
      duration: null,
    });
  }, [toast]);

  const finishToast = React.useCallback(() => {
    finishToastRef.current = toast({
      title: 'PDF successfully generated',
      status: 'success',
      isClosable: true,
      position: 'top',
      duration: null,
    });
  }, [toast]);

  const closeStartToast = React.useCallback(() => {
    if (startToastRef.current) {
      toast.close(startToastRef.current);
    }
  }, [toast]);

  const closeFinishToast = React.useCallback(() => {
    if (finishToastRef.current) {
      toast.close(finishToastRef.current);
    }
  }, [toast]);

  const onClick = React.useCallback(() => {
    startToast();
    mutation
      .mutateAsync({
        organizationId: organization.id,
        participantId: participantDocument.participantId,
        participantDocumentId: participantDocument.id,
      })
      .then(async (resp) => {
        closeStartToast();
        await Async.delay(500);
        finishToast();
        await Async.delay(500);
        const { viewUrl } = resp.generateParticipantDocumentPdf;
        window.open(viewUrl || '', '_blank');
        closeFinishToast();
      });
  }, [
    startToast,
    mutation,
    organization.id,
    participantDocument.participantId,
    participantDocument.id,
    closeStartToast,
    finishToast,
    closeFinishToast,
  ]);

  return (
    <MenuItem icon={<FaRegFilePdf />} onClick={onClick}>
      {isDraft ? 'Generate draft PDF' : 'Generate PDF'}
    </MenuItem>
  );
};
