import {
  Button,
  ButtonGroup,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { NiceModalHandler } from '@ebay/nice-modal-react';
import React from 'react';

interface Props {
  modal: NiceModalHandler<Record<string, unknown>>;
  isLoading: boolean;
  onDelete: () => void;
}

export const FileDeleteModal = (props: Props) => {
  const { modal, onDelete, isLoading } = props;
  const { hide, remove, visible } = modal;

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={remove}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Do you want to delete this file?</ModalHeader>
        <ModalCloseButton />
        <ModalFooter>
          <ButtonGroup size="sm">
            <Button key="close" onClick={hide}>
              Close
            </Button>
            <Button
              key="submit"
              colorScheme="red"
              isLoading={isLoading}
              loadingText="Deleting..."
              type="submit"
              onClick={onDelete}
            >
              Delete
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
