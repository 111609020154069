import { useAuth0 } from '@auth0/auth0-react';
import {
  FeatureFlag,
  hasFeature as hasFeatureFlag,
  Organization,
  OrganizationMembership,
  PiccoloError,
} from '@piccolohealth/pbs-common';
import { Spin, useRequiredParams } from '@piccolohealth/ui';
import { P, Settings as DateTimeSettings } from '@piccolohealth/util';
import * as Sentry from '@sentry/browser';
import React from 'react';
import { useIntercom } from 'react-use-intercom';
import { useOrganizationQuery } from '../graphql/hooks/useOrganizationQuery';
import { useUser } from './UserContext';
import { Error } from '../components/generic/Error';

export interface OrganizationContext extends Organization {
  organizationMembership: OrganizationMembership;
  hasFeature: (id: FeatureFlag) => boolean;
  isLoading: boolean;
}

export const OrganizationContext = React.createContext<OrganizationContext>(null as any);

export const OrganizationContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const { organizationId } = useRequiredParams<{ organizationId: string }>();
  const { isAuthenticated } = useAuth0();

  const { update: updateIntercom } = useIntercom();
  const user = useUser();

  const organizationMembership = user?.organizationMemberships.find(
    (om) => om.organizationId === organizationId,
  ) as OrganizationMembership;

  const { isLoading, fetchStatus, data, error } = useOrganizationQuery(
    { organizationId },
    { enabled: isAuthenticated && !P.isNil(user) && !P.isNil(organizationMembership) },
  );

  const organization = data?.organization as Organization;

  React.useEffect(() => {
    if (isAuthenticated && !isLoading && user && organization) {
      DateTimeSettings.defaultLocale = organization.locale;
      DateTimeSettings.defaultZone = organization.timezone;

      Sentry.setUser({
        id: user.id,
        username: user.name,
        email: user.email,
        organizationId: organization.id,
      });

      Sentry.setTags({
        username: user.name,
        email: user.email,
        organization: organization.name,
      });

      updateIntercom({
        company: {
          companyId: organization.id,
          name: organization.name,
          customAttributes: {
            roles: organizationMembership.roles,
          },
        },
        companies: user.organizationMemberships.map((organizationMembership) => ({
          companyId: organizationMembership.organizationId,
          name: organizationMembership.name,
        })),
      });
    }
  }, [organization, user, updateIntercom, isAuthenticated, isLoading, organizationMembership]);

  const hasFeature = (id: FeatureFlag) => {
    return hasFeatureFlag(organization?.featureFlags, id) || hasFeatureFlag(user?.featureFlags, id);
  };

  const value: OrganizationContext = {
    organizationMembership,
    hasFeature,
    isLoading,
    ...organization,
  };

  const content = () => {
    if (error) {
      return <Error error={error} />;
    }

    if (isLoading && fetchStatus != 'idle') {
      return <Spin />;
    }

    if (!organizationMembership) {
      return (
        <Error
          error={
            new PiccoloError({
              type: 'Unauthorized',
              message: 'Sorry, you are not authorized to access this resource',
            })
          }
        />
      );
    }

    return children;
  };

  return <OrganizationContext.Provider value={value}>{content()}</OrganizationContext.Provider>;
};

export const useOrganization: () => OrganizationContext = () => {
  return React.useContext(OrganizationContext);
};
