import React from 'react';
import { createModal } from '../../components/generic/Modal';
import { useDeleteIncidentFileMutation } from '../../graphql/hooks/useDeleteIncidentFileMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { FileDeleteModal } from '../files/FileDeleteModal';

interface Props {
  incidentId: string;
  incidentFileId: string;
}

export const IncidentFileDeleteModal = createModal<Props>((props) => {
  const { incidentId, incidentFileId, ...rest } = props;

  const { hide } = rest.modal;
  const { organization, successToast, errorToast } = useAppContext();
  const mutation = useDeleteIncidentFileMutation();

  const onDelete = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        incidentId,
        incidentFileId,
      })
      .then(async () => {
        hide();
        successToast('File deleted successfully');
      })
      .catch((err) => {
        errorToast(`Error deleting file: ${err.message}`);
      });
  }, [mutation, organization.id, incidentId, incidentFileId, hide, successToast, errorToast]);

  return <FileDeleteModal onDelete={onDelete} isLoading={mutation.isLoading} {...rest} />;
});
