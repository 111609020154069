import {
  Button,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { FaAngleDown, FaKey, FaSignOutAlt } from 'react-icons/fa';
import { UserAvatar } from '../../components/user/UserAvatar';
import { useCreatePasswordChangeTicketUrlMutation } from '../../graphql/hooks/useCreatePasswordChangeTicketUrlMutation';
import { useAppContext } from '../../hooks/useAppContext';

interface Props {
  isShrunk: boolean;
}

export const UserMenu = (props: Props) => {
  const { isShrunk } = props;
  const { auth, config, user, organization } = useAppContext();
  const resetPasswordMutation = useCreatePasswordChangeTicketUrlMutation();

  const resetPassword = async () => {
    const resp = await resetPasswordMutation.mutateAsync({ organizationId: organization.id });
    window.location.href = resp.createPasswordChangeTicketUrl;
  };

  return (
    <Menu placement="auto-end">
      <MenuButton
        as={Button}
        variant="ghost"
        px={1}
        py={6}
        justifyContent="start"
        w="full"
        rightIcon={!isShrunk ? <Icon as={FaAngleDown} /> : undefined}
      >
        <HStack spacing={2} fontSize="sm">
          <UserAvatar
            secondary={user.email}
            picture={user.picture}
            name={user.name}
            w="28px"
            h="28px"
            showTooltip={false}
          />
          {!isShrunk && (
            <Stack spacing={1} lineHeight={1} align="start" overflow="hidden">
              <Text fontWeight="semibold">{user.name}</Text>
              <Text color="secondary" fontSize="xs" fontWeight="normal">
                {user.email}
              </Text>
            </Stack>
          )}
        </HStack>
      </MenuButton>
      <MenuList>
        <MenuItem icon={<FaKey />} onClick={resetPassword}>
          Reset password
        </MenuItem>
        <MenuItem
          icon={<FaSignOutAlt />}
          onClick={() => auth.logout({ returnTo: config.auth.logoutRedirectUri })}
        >
          Logout
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
