import { TextareaProps } from '@chakra-ui/react';
import React from 'react';
import { useController } from 'react-hook-form';
import { TextareaAutosize } from './TextareaAutosize';

interface Props extends TextareaProps {
  name: string;
  minRows?: number;
}

export const HookedTextArea = (props: Props) => {
  const { name, ...rest } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({ name });

  return <TextareaAutosize {...rest} {...inputProps} value={inputProps.value ?? ''} />;
};
