import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { UpdateLocation } from '../mutations/updateLocation.mutation';
import { UpdateLocationMutation, UpdateLocationMutationVariables } from '../types';

export const useUpdateLocationMutation = (
  options?: MutationOptions<UpdateLocationMutation, UpdateLocationMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(UpdateLocation), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('organization'),
      });
    },
  });
};
