import { PiccoloError, renderPiccoloError } from '@piccolohealth/pbs-common';
import * as UI from '@piccolohealth/ui';
import React from 'react';
import { sendExceptionToSentry } from '../../utils/errors';

interface Props extends UI.ErrorProps {
  error: PiccoloError;
}

export const Error = (props: Props) => {
  const { error, ...rest } = props;

  const rendered = renderPiccoloError(error);

  if (rendered.type === 'UnknownError') {
    sendExceptionToSentry(error);
  }

  return <UI.Error title={rendered.type} description={rendered.message} {...rest} />;
};
