import { Card, CardBody, CardHeader, Heading, HStack, Spacer, Stack } from '@chakra-ui/react';
import { ParticipantBehaviour, ParticipantBehaviourStatus } from '@piccolohealth/pbs-common';
import React from 'react';
import { ParticipantBehaviourStatusTag } from './components/ParticipantBehaviourStatusTag';
import { ParticipantBehaviourActionMenu } from './ParticipantBehaviourActionMenu';
import { ParticipantBehaviourDataSummary } from './ParticipantBehaviourDataSummary';

interface Props {
  participantId: string;
  participantBehaviour: ParticipantBehaviour;
}

export const ParticipantBehaviourCard = (props: Props) => {
  const { participantId, participantBehaviour } = props;

  return (
    <Card w="full" h="full">
      <CardHeader>
        <Stack>
          <HStack align="stretch">
            <Stack spacing={0}>
              <Heading size="md" noOfLines={1}>
                {participantBehaviour.name}
              </Heading>
            </Stack>
            <Spacer />
            <ParticipantBehaviourActionMenu participantBehaviour={participantBehaviour} />
          </HStack>
          <ParticipantBehaviourStatusTag
            status={ParticipantBehaviourStatus.Active}
            w="fit-content"
          />
        </Stack>
      </CardHeader>

      <CardBody>
        <ParticipantBehaviourDataSummary participantBehaviour={participantBehaviour} />
      </CardBody>
    </Card>
  );
};
