import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Stack,
  StackProps,
} from '@chakra-ui/react';
import { P } from '@piccolohealth/util';
import React from 'react';

export interface FormItemProps extends FormControlProps {
  label?: string;
  error?: string;
  helperText?: string;
  layout?: 'vertical' | 'horizontal';
  labelSize?: 'sm' | 'md';
}

export const FormItem = (props: React.PropsWithChildren<FormItemProps>) => {
  const { label, labelSize, helperText, children, error, layout, ...rest } = props;

  const stackProps: Partial<StackProps> = {
    direction: layout === 'horizontal' ? 'row' : 'column',
    align: layout === 'horizontal' ? 'center' : undefined,
    spacing: 2,
    flexGrow: 1,
  };

  return (
    <FormControl display="flex" isInvalid={!P.isNil(error)} {...rest}>
      <Stack {...stackProps}>
        {label && (
          <FormLabel fontWeight="semibold" mb={0} color="gray.700" fontSize={labelSize}>
            {label}
          </FormLabel>
        )}

        {children}
        {error && <FormErrorMessage>{error}</FormErrorMessage>}
        {helperText && (
          <FormHelperText fontSize="sm" whiteSpace="pre-wrap">
            {helperText}
          </FormHelperText>
        )}
      </Stack>
    </FormControl>
  );
};
