import gql from 'graphql-tag';

export const RestoreParticipantNoteVersion = gql`
  mutation RestoreParticipantNoteVersion(
    $organizationId: ID!
    $participantId: ID!
    $participantNoteId: ID!
    $versionId: ID!
  ) {
    restoreParticipantNoteVersion(
      organizationId: $organizationId
      participantId: $participantId
      participantNoteId: $participantNoteId
      versionId: $versionId
    ) {
      id
      versionId
      participantId
    }
  }
`;
