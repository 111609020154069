import { arrayOfParam, stringParam, useQueryParams } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';

const DEFAULT_CONTENT_FILTER = '';
const DEFAULT_LABEL_FILTER: string[] = [];

export const participantNotesFilterDefaults: ParticipantNotesFilter = {
  contentFilter: DEFAULT_CONTENT_FILTER,
  labelFilter: DEFAULT_LABEL_FILTER,
  onContentFilter: P.noop,
  onLabelFilter: P.noop,
};

export interface ParticipantNotesFilter {
  contentFilter: string;
  labelFilter: string[] | undefined;
  onContentFilter: (name: string) => void;
  onLabelFilter: (labels: string[]) => void;
}

export const useParticipantNotesFilter: () => ParticipantNotesFilter = () => {
  const [params, setParams] = useQueryParams({
    content: stringParam,
    label: arrayOfParam(stringParam, { delimiter: ',' }),
  });

  const onContentFilter = (value: string) =>
    setParams({
      content: value,
    });

  const onLabelFilter = (labels?: string[]) =>
    setParams({
      label: labels,
    });

  return {
    contentFilter: params.content ?? DEFAULT_CONTENT_FILTER,
    labelFilter: params.label ?? DEFAULT_LABEL_FILTER,
    onContentFilter,
    onLabelFilter,
  };
};
