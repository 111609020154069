import { Actions, Subjects } from '@piccolohealth/pbs-common';
import { Spin } from '@piccolohealth/ui';
import React from 'react';
import { useAppContext } from '../../hooks/useAppContext';
import { usePermission } from '../../hooks/usePermission';
import { PageError } from './PageError';

interface Props {
  actions: Actions;
  subject: Subjects;
}

export const PermissionRoute = (props: React.PropsWithChildren<Props>) => {
  const { children, subject, actions } = props;
  const { organization } = useAppContext();

  const hasPermission = usePermission(actions, subject);

  if (organization.isLoading) {
    return <Spin />;
  }

  if (!organization || !hasPermission) {
    return <PageError type="Forbidden" />;
  }

  return <>{children}</>;
};
