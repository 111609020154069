import { HStack, Text, Icon, Spinner } from '@chakra-ui/react';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';

interface Props {
  isSaving: boolean;
}

export const SaveStatusIndicator = (props: Props) => {
  const { isSaving } = props;
  const content = P.run(() => {
    if (isSaving) {
      return (
        <>
          <Spinner size="sm" />
          <Text lineHeight={1}>Saving...</Text>
        </>
      );
    }

    return (
      <>
        <Icon as={FaCheckCircle} />
        <Text lineHeight={1}>Saved</Text>
      </>
    );
  });

  return (
    <HStack alignItems="center" fontSize="sm" fontWeight="semibold" color="gray.600">
      {content}
    </HStack>
  );
};
