import { Box, Button, ButtonProps, Icon } from '@chakra-ui/react';
import data, { Emoji } from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { FloatingPopover } from '@piccolohealth/ui';
import React from 'react';
import { MdAddReaction } from 'react-icons/md';

interface Props extends Omit<ButtonProps, 'onSelect'> {
  onSelect: (emoji: Emoji) => void;
}

export const EmojiPickerButton = (props: Props) => {
  const { onSelect, ...rest } = props;

  return (
    <FloatingPopover
      isPortal={true}
      isRoot={true}
      placement="bottom"
      render={() => (
        <Box shadow="popover" rounded="lg" overflow="hidden">
          <Picker
            data={data}
            onEmojiSelect={onSelect}
            previewPosition="none"
            theme="light"
            autoFocus
          />
        </Box>
      )}
    >
      <Button size="sm" p="2" rounded="full" bg="white" color="gray.500" {...rest}>
        <Icon as={MdAddReaction} fontSize="14px" />
      </Button>
    </FloatingPopover>
  );
};
