import { DateTime } from '@piccolohealth/util';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: DateTime;
  JSON: any;
};

export type AbcMethod = {
  __typename?: 'AbcMethod';
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type AbcRecordingRequest = {
  antecedent: Scalars['String'];
  behaviour: Scalars['String'];
  consequence: Scalars['String'];
};

export enum AggregationType {
  Average = 'Average',
  Max = 'Max',
  Min = 'Min',
  Sum = 'Sum',
}

export type AuditTrailEntry = {
  __typename?: 'AuditTrailEntry';
  createdAt: Scalars['DateTime'];
  event: AuditTrailEvent;
  id: Scalars['ID'];
  message: Scalars['String'];
  organizationId: Scalars['ID'];
  payload: Scalars['JSON'];
  timestamp: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  user: User;
};

export enum AuditTrailEvent {
  BehaviourCreated = 'BEHAVIOUR_CREATED',
  BehaviourDeleted = 'BEHAVIOUR_DELETED',
  BehaviourUpdated = 'BEHAVIOUR_UPDATED',
  FormCreated = 'FORM_CREATED',
  FormDeleted = 'FORM_DELETED',
  FormUpdated = 'FORM_UPDATED',
  IncidentsExported = 'INCIDENTS_EXPORTED',
  IncidentCreated = 'INCIDENT_CREATED',
  IncidentDeleted = 'INCIDENT_DELETED',
  IncidentFileCreated = 'INCIDENT_FILE_CREATED',
  IncidentFileDeleted = 'INCIDENT_FILE_DELETED',
  IncidentFileUpdated = 'INCIDENT_FILE_UPDATED',
  IncidentLabelCreated = 'INCIDENT_LABEL_CREATED',
  IncidentLabelDeleted = 'INCIDENT_LABEL_DELETED',
  IncidentStatusUpdated = 'INCIDENT_STATUS_UPDATED',
  IncidentUpdated = 'INCIDENT_UPDATED',
  LabelCreated = 'LABEL_CREATED',
  LabelDeleted = 'LABEL_DELETED',
  LabelUpdated = 'LABEL_UPDATED',
  LocationCreated = 'LOCATION_CREATED',
  LocationDeleted = 'LOCATION_DELETED',
  LocationUpdated = 'LOCATION_UPDATED',
  LocationUserCreated = 'LOCATION_USER_CREATED',
  LocationUserDeleted = 'LOCATION_USER_DELETED',
  LocationUserUpdated = 'LOCATION_USER_UPDATED',
  OrganizationUpdated = 'ORGANIZATION_UPDATED',
  ParticipantsExported = 'PARTICIPANTS_EXPORTED',
  ParticipantBehaviourCreated = 'PARTICIPANT_BEHAVIOUR_CREATED',
  ParticipantBehaviourDeleted = 'PARTICIPANT_BEHAVIOUR_DELETED',
  ParticipantBehaviourRecordingCreated = 'PARTICIPANT_BEHAVIOUR_RECORDING_CREATED',
  ParticipantBehaviourRecordingDeleted = 'PARTICIPANT_BEHAVIOUR_RECORDING_DELETED',
  ParticipantBehaviourRecordingMethodCreated = 'PARTICIPANT_BEHAVIOUR_RECORDING_METHOD_CREATED',
  ParticipantBehaviourRecordingMethodDeleted = 'PARTICIPANT_BEHAVIOUR_RECORDING_METHOD_DELETED',
  ParticipantBehaviourRecordingMethodUpdated = 'PARTICIPANT_BEHAVIOUR_RECORDING_METHOD_UPDATED',
  ParticipantBehaviourRecordingUpdated = 'PARTICIPANT_BEHAVIOUR_RECORDING_UPDATED',
  ParticipantBehaviourUpdated = 'PARTICIPANT_BEHAVIOUR_UPDATED',
  ParticipantCreated = 'PARTICIPANT_CREATED',
  ParticipantDeleted = 'PARTICIPANT_DELETED',
  ParticipantDocumentCreated = 'PARTICIPANT_DOCUMENT_CREATED',
  ParticipantDocumentDeleted = 'PARTICIPANT_DOCUMENT_DELETED',
  ParticipantDocumentLabelCreated = 'PARTICIPANT_DOCUMENT_LABEL_CREATED',
  ParticipantDocumentLabelDeleted = 'PARTICIPANT_DOCUMENT_LABEL_DELETED',
  ParticipantDocumentUpdated = 'PARTICIPANT_DOCUMENT_UPDATED',
  ParticipantFastForwarded = 'PARTICIPANT_FAST_FORWARDED',
  ParticipantFieldGroupTemplateCreated = 'PARTICIPANT_FIELD_GROUP_TEMPLATE_CREATED',
  ParticipantFieldGroupTemplateDeleted = 'PARTICIPANT_FIELD_GROUP_TEMPLATE_DELETED',
  ParticipantFieldGroupTemplateUpdated = 'PARTICIPANT_FIELD_GROUP_TEMPLATE_UPDATED',
  ParticipantFieldGroupUpdated = 'PARTICIPANT_FIELD_GROUP_UPDATED',
  ParticipantFieldTemplateCreated = 'PARTICIPANT_FIELD_TEMPLATE_CREATED',
  ParticipantFieldTemplateDeleted = 'PARTICIPANT_FIELD_TEMPLATE_DELETED',
  ParticipantFieldTemplateUpdated = 'PARTICIPANT_FIELD_TEMPLATE_UPDATED',
  ParticipantFileCreated = 'PARTICIPANT_FILE_CREATED',
  ParticipantFileDeleted = 'PARTICIPANT_FILE_DELETED',
  ParticipantFileLabelCreated = 'PARTICIPANT_FILE_LABEL_CREATED',
  ParticipantFileLabelDeleted = 'PARTICIPANT_FILE_LABEL_DELETED',
  ParticipantFileUpdated = 'PARTICIPANT_FILE_UPDATED',
  ParticipantLabelCreated = 'PARTICIPANT_LABEL_CREATED',
  ParticipantLabelDeleted = 'PARTICIPANT_LABEL_DELETED',
  ParticipantNoteCreated = 'PARTICIPANT_NOTE_CREATED',
  ParticipantNoteDeleted = 'PARTICIPANT_NOTE_DELETED',
  ParticipantNoteUpdated = 'PARTICIPANT_NOTE_UPDATED',
  ParticipantUpdated = 'PARTICIPANT_UPDATED',
  ParticipantUsersCreated = 'PARTICIPANT_USERS_CREATED',
  ParticipantUserCreated = 'PARTICIPANT_USER_CREATED',
  ParticipantUserDeleted = 'PARTICIPANT_USER_DELETED',
  ParticipantUserUpdated = 'PARTICIPANT_USER_UPDATED',
  ParticipantWidgetLayoutItemsUpdated = 'PARTICIPANT_WIDGET_LAYOUT_ITEMS_UPDATED',
  TemplateCreated = 'TEMPLATE_CREATED',
  TemplateDeleted = 'TEMPLATE_DELETED',
  TemplateUpdated = 'TEMPLATE_UPDATED',
  UserDeleted = 'USER_DELETED',
  UserInvited = 'USER_INVITED',
  UserUpdated = 'USER_UPDATED',
}

export type AuthedRecordingApp = {
  __typename?: 'AuthedRecordingApp';
  createParticipantBehaviourRecording: ParticipantBehaviourRecording;
  organization: OrganizationRecordingInfo;
  organizations: Array<OrganizationRecordingInfo>;
  undoParticipantBehaviourRecording: Scalars['Boolean'];
};

export type AuthedRecordingAppCreateParticipantBehaviourRecordingArgs = {
  organizationId: Scalars['ID'];
  participantBehaviourId: Scalars['ID'];
  participantId: Scalars['ID'];
  request: CreateParticipantBehaviourRecordingRequest;
};

export type AuthedRecordingAppOrganizationArgs = {
  organizationId: Scalars['ID'];
};

export type AuthedRecordingAppUndoParticipantBehaviourRecordingArgs = {
  organizationId: Scalars['ID'];
  participantBehaviourId: Scalars['ID'];
  participantId: Scalars['ID'];
  recordingId: Scalars['ID'];
};

export type BarTimeSeries = {
  __typename?: 'BarTimeSeries';
  aggregation: AggregationType;
  color: Scalars['String'];
  id: Scalars['ID'];
  source: DataSource;
  symbol: SymbolType;
  trendline: Maybe<Trendline>;
};

export type BehaviourRecordingRequestPayload = {
  abc?: InputMaybe<AbcRecordingRequest>;
  duration?: InputMaybe<DurationRecordingRequest>;
  episodicSeverity?: InputMaybe<EpisodicSeverityRecordingRequest>;
  frequency?: InputMaybe<FrequencyRecordingRequest>;
};

export type BillingAction = {
  __typename?: 'BillingAction';
  createdAt: Scalars['DateTime'];
  id: BillingActionType;
  stripeProductId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum BillingActionType {
  Participant = 'Participant',
}

export type BillingProduct = {
  __typename?: 'BillingProduct';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type BillingSubscriptionItem = {
  __typename?: 'BillingSubscriptionItem';
  id: Scalars['ID'];
  product: BillingProduct;
};

export type CreateAbcMethodsRequest = {
  enabled: Scalars['Boolean'];
};

export type CreateAuditTrailEntryRequest = {
  __typename?: 'CreateAuditTrailEntryRequest';
  event: AuditTrailEvent;
  message: Scalars['String'];
  organizationId: Scalars['ID'];
  payload: Maybe<Scalars['JSON']>;
  user: User;
};

export type CreateBehaviourRequest = {
  color?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organizationId: Scalars['ID'];
};

export type CreateDurationMethodsRequest = {
  enabled: Scalars['Boolean'];
};

export type CreateEpisodicSeverityMethodsRequest = {
  enabled: Scalars['Boolean'];
  scales: Array<CreateEpisodicSeverityScaleRequest>;
};

export type CreateEpisodicSeverityScaleRequest = {
  description: Scalars['String'];
  id: Scalars['ID'];
};

export type CreateFormRequest = {
  externalId?: InputMaybe<Scalars['ID']>;
  labelMappings: FormLabelMappingsRequest;
  name: Scalars['String'];
  type: FormType;
  url?: InputMaybe<Scalars['String']>;
};

export type CreateFrequencyMethodsRequest = {
  enabled: Scalars['Boolean'];
};

export type CreateIncidentRequest = {
  description: Scalars['String'];
  labelIds: Array<Scalars['ID']>;
  ndisReportableIncident: Scalars['Boolean'];
  participantId?: InputMaybe<Scalars['ID']>;
  reviewerIds: Array<Scalars['ID']>;
  setting: Scalars['String'];
  severity: IncidentSeverity;
  timestamp: Scalars['DateTime'];
  witnesses: Array<CreateIncidentWitnessInput>;
};

export type CreateIncidentWitnessInput = {
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type CreateLabelRequest = {
  color: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  type: LabelType;
};

export type CreateLocationRequest = {
  color: Scalars['String'];
  name: Scalars['String'];
};

export type CreateOrganizationRequest = {
  locale: Scalars['String'];
  logo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  status: OrganizationStatus;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionId?: InputMaybe<Scalars['String']>;
  supportEmail: Scalars['String'];
  timezone: Scalars['String'];
};

export type CreateParticipantBehaviourMethodsRequest = {
  abc: CreateAbcMethodsRequest;
  duration: CreateDurationMethodsRequest;
  episodicSeverity: CreateEpisodicSeverityMethodsRequest;
  frequency: CreateFrequencyMethodsRequest;
};

export type CreateParticipantBehaviourRecordingRequest = {
  comment?: InputMaybe<Scalars['String']>;
  payload: BehaviourRecordingRequestPayload;
  timestamp: Scalars['DateTime'];
};

export type CreateParticipantBehaviourRequest = {
  description: Scalars['String'];
  example: Scalars['String'];
  methods: CreateParticipantBehaviourMethodsRequest;
  name: Scalars['String'];
  nonExample: Scalars['String'];
  participantId: Scalars['ID'];
};

export type CreateParticipantDocumentRequest = {
  authorIds: Array<Scalars['ID']>;
  labelIds: Array<Scalars['ID']>;
  name: Scalars['String'];
  participantId: Scalars['ID'];
  reviewerIds: Array<Scalars['ID']>;
  status: ParticipantDocumentStatus;
  templateId: Scalars['ID'];
};

export type CreateParticipantFieldGroupRequest = {
  fields: Array<CreateParticipantFieldRequest>;
  participantFieldGroupTemplateId: Scalars['ID'];
};

export type CreateParticipantFieldGroupTemplateRequest = {
  index: Scalars['Int'];
  name: Scalars['String'];
};

export type CreateParticipantFieldRequest = {
  participantFieldTemplateId: Scalars['ID'];
  value?: InputMaybe<Scalars['JSON']>;
};

export type CreateParticipantFieldTemplateRequest = {
  attributes?: InputMaybe<Scalars['JSON']>;
  index: Scalars['Int'];
  name: Scalars['String'];
  participantFieldGroupTemplateId: Scalars['ID'];
  type: FieldTemplateType;
};

export type CreateParticipantKeyContactRequest = {
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  labelIds?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type CreateParticipantNoteRequest = {
  id?: InputMaybe<Scalars['ID']>;
  labelIds: Array<Scalars['ID']>;
  participantId: Scalars['ID'];
  templateId?: InputMaybe<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type CreateParticipantRequest = {
  address?: InputMaybe<Scalars['String']>;
  dob: Scalars['DateTime'];
  fieldGroups: Array<CreateParticipantFieldGroupRequest>;
  firstName: Scalars['String'];
  gender: Gender;
  keyContacts: Array<CreateParticipantKeyContactRequest>;
  labelIds: Array<Scalars['ID']>;
  lastName: Scalars['String'];
  locationId: Scalars['ID'];
  ndisNumber?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  picture?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  status: ParticipantStatus;
  suburb?: InputMaybe<Scalars['String']>;
};

export type CreateParticipantUserRequest = {
  labelIds: Array<Scalars['ID']>;
  participantId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type CreateTemplateRequest = {
  labelIds: Array<Scalars['ID']>;
  name: Scalars['String'];
  status: TemplateStatus;
  type: TemplateType;
};

export type DataSource = ParticipantBehaviourDataSource;

export type DurationMethod = {
  __typename?: 'DurationMethod';
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type DurationRecordingRequest = {
  value: Scalars['Int'];
};

export type EpisodicSeverityMethod = {
  __typename?: 'EpisodicSeverityMethod';
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  scales: Array<EpisodicSeverityScale>;
};

export type EpisodicSeverityRecordingRequest = {
  level: Scalars['Int'];
};

export type EpisodicSeverityScale = {
  __typename?: 'EpisodicSeverityScale';
  description: Scalars['String'];
  id: Scalars['ID'];
};

export enum ExtraPermission {
  Support = 'SUPPORT',
}

export enum FeatureFlag {
  Behaviours = 'BEHAVIOURS',
  Placeholder = 'PLACEHOLDER',
}

export enum FieldTemplateType {
  Datepicker = 'DATEPICKER',
  Input = 'INPUT',
  MultiSelect = 'MULTI_SELECT',
  Select = 'SELECT',
  Textarea = 'TEXTAREA',
}

export type Form = {
  __typename?: 'Form';
  id: Scalars['ID'];
  labelMappings: FormLabelMappings;
  name: Scalars['String'];
  type: FormType;
  url: Maybe<Scalars['String']>;
};

export enum FormEvent {
  Create = 'Create',
  Update = 'Update',
}

export type FormLabelMappings = {
  __typename?: 'FormLabelMappings';
  create: Array<Label>;
  update: Array<Label>;
};

export type FormLabelMappingsRequest = {
  create: Array<Scalars['ID']>;
  update: Array<Scalars['ID']>;
};

export enum FormType {
  Participant = 'Participant',
}

export type FrequencyMethod = {
  __typename?: 'FrequencyMethod';
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type FrequencyRecordingRequest = {
  value: Scalars['Int'];
};

export enum Gender {
  Female = 'Female',
  GenderNeutral = 'GenderNeutral',
  Male = 'Male',
  NonBinary = 'NonBinary',
  NotSpecified = 'NotSpecified',
  Transgender = 'Transgender',
}

export type GetPaginatedAuditTrailEntriesRequest = {
  end?: InputMaybe<Scalars['DateTime']>;
  events?: InputMaybe<Array<AuditTrailEvent>>;
  message?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<PaginationRequest>;
  payload?: InputMaybe<Scalars['JSON']>;
  start?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<Array<Scalars['String']>>;
};

export type GetPaginatedIncidentsRequest = {
  end?: InputMaybe<Scalars['DateTime']>;
  label?: InputMaybe<Array<Scalars['ID']>>;
  location?: InputMaybe<Array<Scalars['ID']>>;
  pagination?: InputMaybe<PaginationRequest>;
  participant?: InputMaybe<Array<Scalars['ID']>>;
  severity?: InputMaybe<Array<IncidentSeverity>>;
  start?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Array<IncidentStatus>>;
  timestamp?: InputMaybe<Scalars['DateTime']>;
};

export type GetPaginatedParticipantAuditTrailEntriesRequest = {
  end?: InputMaybe<Scalars['DateTime']>;
  pagination?: InputMaybe<PaginationRequest>;
  start?: InputMaybe<Scalars['DateTime']>;
};

export type GetPaginatedParticipantDocumentVersionsRequest = {
  pagination?: InputMaybe<PaginationRequest>;
};

export type GetPaginatedParticipantNoteVersionsRequest = {
  pagination?: InputMaybe<PaginationRequest>;
};

export type GetPaginatedParticipantRecordingInfoRequest = {
  name?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<PaginationRequest>;
  qrs?: InputMaybe<Array<Scalars['ID']>>;
};

export type GetPaginatedParticipantsRequest = {
  assignedTo?: InputMaybe<Array<Scalars['ID']>>;
  assignedToLabel?: InputMaybe<Array<Scalars['ID']>>;
  dob?: InputMaybe<Scalars['DateTime']>;
  end?: InputMaybe<Scalars['DateTime']>;
  hasBehaviours?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Array<Scalars['ID']>>;
  location?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  ndisNumber?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<PaginationRequest>;
  qrs?: InputMaybe<Array<Scalars['ID']>>;
  start?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<Array<ParticipantStatus>>;
};

export type GetPaginatedTemplateVersionsRequest = {
  pagination?: InputMaybe<PaginationRequest>;
};

export type GetPaginatedTemplatesRequest = {
  pagination?: InputMaybe<PaginationRequest>;
  status?: InputMaybe<Array<TemplateStatus>>;
  type?: InputMaybe<Array<TemplateType>>;
};

export type GetPaginatedUsersRequest = {
  name?: InputMaybe<Scalars['String']>;
  pagination?: InputMaybe<PaginationRequest>;
  participantId?: InputMaybe<Scalars['ID']>;
  roles?: InputMaybe<Array<Role>>;
};

export type Incident = {
  __typename?: 'Incident';
  actions: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  files: Array<IncidentFile>;
  id: Scalars['ID'];
  isArchived: Scalars['Boolean'];
  labels: Array<Label>;
  location: Maybe<Location>;
  ndisReportableIncident: Scalars['Boolean'];
  organizationId: Scalars['ID'];
  participant: Maybe<Participant>;
  reporter: User;
  reviewers: Array<User>;
  setting: Scalars['String'];
  severity: IncidentSeverity;
  shortId: Scalars['Int'];
  status: IncidentStatus;
  timestamp: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  witnesses: Array<IncidentWitness>;
};

export type IncidentFile = {
  __typename?: 'IncidentFile';
  createdAt: Scalars['DateTime'];
  downloadUrl: Scalars['String'];
  id: Scalars['ID'];
  incidentId: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  uploader: User;
  viewUrl: Scalars['String'];
};

export enum IncidentSeverity {
  High = 'High',
  Low = 'Low',
  Medium = 'Medium',
}

export enum IncidentStatus {
  AwaitingReview = 'AwaitingReview',
  Reviewed = 'Reviewed',
}

export type IncidentWitness = {
  __typename?: 'IncidentWitness';
  createdAt: Scalars['DateTime'];
  description: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  phone: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type InviteUserRequest = {
  email: Scalars['String'];
  locations: Array<Scalars['ID']>;
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  picture?: InputMaybe<Scalars['String']>;
  practitionerNumber?: InputMaybe<Scalars['String']>;
  roles: Array<Role>;
  title: Scalars['String'];
};

export type Label = {
  __typename?: 'Label';
  color: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  type: LabelType;
  updatedAt: Scalars['DateTime'];
};

export enum LabelType {
  Incident = 'Incident',
  Participant = 'Participant',
  ParticipantDocument = 'ParticipantDocument',
  ParticipantKeyContact = 'ParticipantKeyContact',
  ParticipantNote = 'ParticipantNote',
  ParticipantUser = 'ParticipantUser',
  Template = 'Template',
}

export type LineTimeSeries = {
  __typename?: 'LineTimeSeries';
  aggregation: AggregationType;
  color: Scalars['String'];
  id: Scalars['ID'];
  source: DataSource;
  symbol: SymbolType;
  trendline: Maybe<Trendline>;
};

export type Location = {
  __typename?: 'Location';
  color: Scalars['String'];
  id: Scalars['ID'];
  isArchived: Scalars['Boolean'];
  name: Scalars['String'];
};

export type Method = AbcMethod | DurationMethod | EpisodicSeverityMethod | FrequencyMethod;

export enum MethodType {
  Abc = 'Abc',
  Duration = 'Duration',
  EpisodicSeverity = 'EpisodicSeverity',
  Frequency = 'Frequency',
}

export type Mutation = {
  __typename?: 'Mutation';
  createForm: Form;
  createIncident: Incident;
  createIncidentLabel: Label;
  createLabel: Label;
  createLocation: Location;
  createOrganization: Organization;
  createParticipant: Participant;
  createParticipantBehaviour: ParticipantBehaviour;
  createParticipantBehaviourRecording: ParticipantBehaviourRecording;
  createParticipantDocument: ParticipantDocument;
  createParticipantDocumentLabel: Label;
  createParticipantFieldGroupTemplate: ParticipantFieldGroupTemplate;
  createParticipantFieldTemplate: ParticipantFieldTemplate;
  createParticipantFileLabel: Label;
  createParticipantLabel: Label;
  createParticipantNote: ParticipantNote;
  createParticipantNoteLabel: Label;
  createParticipantUser: ParticipantUser;
  createParticipantUserLabel: Label;
  createParticipantUsers: Array<ParticipantUser>;
  createPasswordChangeTicketUrl: Scalars['String'];
  createTemplate: Template;
  deleteForm: Scalars['Boolean'];
  deleteIncident: Scalars['Boolean'];
  deleteIncidentFile: Scalars['Boolean'];
  deleteIncidentFiles: Scalars['Boolean'];
  deleteIncidentLabel: Scalars['Boolean'];
  deleteLabel: Scalars['Boolean'];
  deleteLocation: Scalars['Boolean'];
  deleteParticipant: Scalars['Boolean'];
  deleteParticipantBehaviour: Scalars['Boolean'];
  deleteParticipantDocument: Scalars['Boolean'];
  deleteParticipantDocumentLabel: Scalars['Boolean'];
  deleteParticipantFieldGroupTemplate: Scalars['Boolean'];
  deleteParticipantFieldTemplate: Scalars['Boolean'];
  deleteParticipantFile: Scalars['Boolean'];
  deleteParticipantFileLabel: Scalars['Boolean'];
  deleteParticipantFiles: Scalars['Boolean'];
  deleteParticipantLabel: Scalars['Boolean'];
  deleteParticipantNote: Scalars['Boolean'];
  deleteParticipantNoteLabel: Scalars['Boolean'];
  deleteParticipantUser: Scalars['Boolean'];
  deleteParticipantUserLabel: Scalars['Boolean'];
  deleteParticipantUsers: Scalars['Boolean'];
  deleteUser: Scalars['Boolean'];
  exportIncidentsCsv: Scalars['String'];
  exportParticipantsCsv: Scalars['String'];
  fastForwardParticipant: Participant;
  generateIncidentPdf: Pdf;
  generateParticipantDocumentPdf: Pdf;
  inviteUser: User;
  notifyParticipantDocumentAuthors: Scalars['Boolean'];
  notifyParticipantDocumentReviewers: Scalars['Boolean'];
  restoreParticipantDocumentVersion: ParticipantDocument;
  restoreParticipantNoteVersion: ParticipantDocument;
  restoreTemplateVersion: Template;
  updateForm: Form;
  updateIncident: Incident;
  updateIncidentFile: IncidentFile;
  updateIncidentStatus: Incident;
  updateLabel: Label;
  updateLocation: Location;
  updateOrganization: Organization;
  updateParticipant: Participant;
  updateParticipantBehaviour: ParticipantBehaviour;
  updateParticipantDocument: ParticipantDocument;
  updateParticipantFieldGroupTemplate: ParticipantFieldTemplate;
  updateParticipantFieldTemplate: ParticipantFieldTemplate;
  updateParticipantFile: ParticipantFile;
  updateParticipantNote: ParticipantNote;
  updateParticipantWidgetLayoutItems: Array<ParticipantWidgetLayoutItem>;
  updateTemplate: Template;
  updateUser: User;
};

export type MutationCreateFormArgs = {
  organizationId: Scalars['ID'];
  request: CreateFormRequest;
};

export type MutationCreateIncidentArgs = {
  organizationId: Scalars['ID'];
  request: CreateIncidentRequest;
};

export type MutationCreateIncidentLabelArgs = {
  incidentId: Scalars['ID'];
  labelId: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export type MutationCreateLabelArgs = {
  organizationId: Scalars['ID'];
  request: CreateLabelRequest;
};

export type MutationCreateLocationArgs = {
  organizationId: Scalars['ID'];
  request: CreateLocationRequest;
};

export type MutationCreateOrganizationArgs = {
  request: CreateOrganizationRequest;
};

export type MutationCreateParticipantArgs = {
  request: CreateParticipantRequest;
};

export type MutationCreateParticipantBehaviourArgs = {
  organizationId: Scalars['ID'];
  request: CreateParticipantBehaviourRequest;
};

export type MutationCreateParticipantBehaviourRecordingArgs = {
  organizationId: Scalars['ID'];
  participantBehaviourId: Scalars['ID'];
  participantId: Scalars['ID'];
  request: CreateParticipantBehaviourRecordingRequest;
};

export type MutationCreateParticipantDocumentArgs = {
  organizationId: Scalars['ID'];
  request: CreateParticipantDocumentRequest;
};

export type MutationCreateParticipantDocumentLabelArgs = {
  labelId: Scalars['ID'];
  organizationId: Scalars['ID'];
  participantDocumentId: Scalars['ID'];
  participantId: Scalars['ID'];
};

export type MutationCreateParticipantFieldGroupTemplateArgs = {
  organizationId: Scalars['ID'];
  request: CreateParticipantFieldGroupTemplateRequest;
};

export type MutationCreateParticipantFieldTemplateArgs = {
  organizationId: Scalars['ID'];
  request: CreateParticipantFieldTemplateRequest;
};

export type MutationCreateParticipantFileLabelArgs = {
  labelId: Scalars['ID'];
  organizationId: Scalars['ID'];
  participantFileId: Scalars['ID'];
  participantId: Scalars['ID'];
};

export type MutationCreateParticipantLabelArgs = {
  labelId: Scalars['ID'];
  organizationId: Scalars['ID'];
  participantId: Scalars['ID'];
};

export type MutationCreateParticipantNoteArgs = {
  organizationId: Scalars['ID'];
  request: CreateParticipantNoteRequest;
};

export type MutationCreateParticipantNoteLabelArgs = {
  labelId: Scalars['ID'];
  organizationId: Scalars['ID'];
  participantId: Scalars['ID'];
  participantNoteId: Scalars['ID'];
};

export type MutationCreateParticipantUserArgs = {
  organizationId: Scalars['ID'];
  request: CreateParticipantUserRequest;
};

export type MutationCreateParticipantUserLabelArgs = {
  labelId: Scalars['ID'];
  organizationId: Scalars['ID'];
  participantId: Scalars['ID'];
  participantUserId: Scalars['ID'];
};

export type MutationCreateParticipantUsersArgs = {
  organizationId: Scalars['ID'];
  requests: Array<CreateParticipantUserRequest>;
};

export type MutationCreatePasswordChangeTicketUrlArgs = {
  organizationId: Scalars['ID'];
};

export type MutationCreateTemplateArgs = {
  organizationId: Scalars['ID'];
  request: CreateTemplateRequest;
};

export type MutationDeleteFormArgs = {
  formId: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export type MutationDeleteIncidentArgs = {
  incidentId: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export type MutationDeleteIncidentFileArgs = {
  incidentFileId: Scalars['ID'];
  incidentId: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export type MutationDeleteIncidentFilesArgs = {
  incidentFileIds: Array<Scalars['ID']>;
  incidentId: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export type MutationDeleteIncidentLabelArgs = {
  incidentId: Scalars['ID'];
  labelId: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export type MutationDeleteLabelArgs = {
  labelId: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export type MutationDeleteLocationArgs = {
  locationId: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export type MutationDeleteParticipantArgs = {
  organizationId: Scalars['ID'];
  participantId: Scalars['ID'];
};

export type MutationDeleteParticipantBehaviourArgs = {
  organizationId: Scalars['ID'];
  participantBehaviourId: Scalars['ID'];
};

export type MutationDeleteParticipantDocumentArgs = {
  organizationId: Scalars['ID'];
  participantDocumentId: Scalars['ID'];
  participantId: Scalars['ID'];
};

export type MutationDeleteParticipantDocumentLabelArgs = {
  labelId: Scalars['ID'];
  organizationId: Scalars['ID'];
  participantDocumentId: Scalars['ID'];
  participantId: Scalars['ID'];
};

export type MutationDeleteParticipantFieldGroupTemplateArgs = {
  organizationId: Scalars['ID'];
  participantFieldGroupTemplateId: Scalars['ID'];
};

export type MutationDeleteParticipantFieldTemplateArgs = {
  organizationId: Scalars['ID'];
  participantFieldTemplateId: Scalars['ID'];
};

export type MutationDeleteParticipantFileArgs = {
  organizationId: Scalars['ID'];
  participantFileId: Scalars['ID'];
  participantId: Scalars['ID'];
};

export type MutationDeleteParticipantFileLabelArgs = {
  labelId: Scalars['ID'];
  organizationId: Scalars['ID'];
  participantFileId: Scalars['ID'];
  participantId: Scalars['ID'];
};

export type MutationDeleteParticipantFilesArgs = {
  organizationId: Scalars['ID'];
  participantFileIds: Array<Scalars['ID']>;
  participantId: Scalars['ID'];
};

export type MutationDeleteParticipantLabelArgs = {
  labelId: Scalars['ID'];
  organizationId: Scalars['ID'];
  participantId: Scalars['ID'];
};

export type MutationDeleteParticipantNoteArgs = {
  organizationId: Scalars['ID'];
  participantId: Scalars['ID'];
  participantNoteId: Scalars['ID'];
};

export type MutationDeleteParticipantNoteLabelArgs = {
  labelId: Scalars['ID'];
  organizationId: Scalars['ID'];
  participantId: Scalars['ID'];
  participantNoteId: Scalars['ID'];
};

export type MutationDeleteParticipantUserArgs = {
  organizationId: Scalars['ID'];
  participantId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationDeleteParticipantUserLabelArgs = {
  labelId: Scalars['ID'];
  organizationId: Scalars['ID'];
  participantId: Scalars['ID'];
  participantUserId: Scalars['ID'];
};

export type MutationDeleteParticipantUsersArgs = {
  organizationId: Scalars['ID'];
  participantId: Scalars['ID'];
  userIds: Array<Scalars['ID']>;
};

export type MutationDeleteUserArgs = {
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationExportIncidentsCsvArgs = {
  organizationId: Scalars['ID'];
  request?: InputMaybe<GetPaginatedIncidentsRequest>;
};

export type MutationExportParticipantsCsvArgs = {
  organizationId: Scalars['ID'];
  request?: InputMaybe<GetPaginatedParticipantsRequest>;
};

export type MutationFastForwardParticipantArgs = {
  organizationId: Scalars['ID'];
  participantId: Scalars['ID'];
};

export type MutationGenerateIncidentPdfArgs = {
  incidentId: Scalars['ID'];
  organizationId: Scalars['ID'];
};

export type MutationGenerateParticipantDocumentPdfArgs = {
  organizationId: Scalars['ID'];
  participantDocumentId: Scalars['ID'];
  participantId: Scalars['ID'];
};

export type MutationInviteUserArgs = {
  request: InviteUserRequest;
};

export type MutationNotifyParticipantDocumentAuthorsArgs = {
  organizationId: Scalars['ID'];
  participantDocumentId: Scalars['ID'];
  participantId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationNotifyParticipantDocumentReviewersArgs = {
  organizationId: Scalars['ID'];
  participantDocumentId: Scalars['ID'];
  participantId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type MutationRestoreParticipantDocumentVersionArgs = {
  organizationId: Scalars['ID'];
  participantDocumentId: Scalars['ID'];
  participantId: Scalars['ID'];
  versionId: Scalars['ID'];
};

export type MutationRestoreParticipantNoteVersionArgs = {
  organizationId: Scalars['ID'];
  participantId: Scalars['ID'];
  participantNoteId: Scalars['ID'];
  versionId: Scalars['ID'];
};

export type MutationRestoreTemplateVersionArgs = {
  organizationId: Scalars['ID'];
  templateId: Scalars['ID'];
  versionId: Scalars['ID'];
};

export type MutationUpdateFormArgs = {
  formId: Scalars['ID'];
  organizationId: Scalars['ID'];
  request: UpdateFormRequest;
};

export type MutationUpdateIncidentArgs = {
  incidentId: Scalars['ID'];
  organizationId: Scalars['ID'];
  request: UpdateIncidentRequest;
};

export type MutationUpdateIncidentFileArgs = {
  incidentFileId: Scalars['ID'];
  incidentId: Scalars['ID'];
  organizationId: Scalars['ID'];
  request: UpdateIncidentFileRequest;
};

export type MutationUpdateIncidentStatusArgs = {
  incidentId: Scalars['ID'];
  organizationId: Scalars['ID'];
  request: UpdateIncidentStatusRequest;
};

export type MutationUpdateLabelArgs = {
  labelId: Scalars['ID'];
  organizationId: Scalars['ID'];
  request: UpdateLabelRequest;
};

export type MutationUpdateLocationArgs = {
  locationId: Scalars['ID'];
  organizationId: Scalars['ID'];
  request: UpdateLocationRequest;
};

export type MutationUpdateOrganizationArgs = {
  organizationId: Scalars['ID'];
  request: UpdateOrganizationRequest;
};

export type MutationUpdateParticipantArgs = {
  organizationId: Scalars['ID'];
  participantId: Scalars['ID'];
  request: UpdateParticipantRequest;
};

export type MutationUpdateParticipantBehaviourArgs = {
  organizationId: Scalars['ID'];
  participantBehaviourId: Scalars['ID'];
  request: UpdateParticipantBehaviourRequest;
};

export type MutationUpdateParticipantDocumentArgs = {
  organizationId: Scalars['ID'];
  participantDocumentId: Scalars['ID'];
  participantId: Scalars['ID'];
  request: UpdateParticipantDocumentRequest;
};

export type MutationUpdateParticipantFieldGroupTemplateArgs = {
  organizationId: Scalars['ID'];
  participantFieldGroupTemplateId: Scalars['ID'];
  request: UpdateParticipantFieldGroupTemplateRequest;
};

export type MutationUpdateParticipantFieldTemplateArgs = {
  organizationId: Scalars['ID'];
  participantFieldTemplateId: Scalars['ID'];
  request: UpdateParticipantFieldTemplateRequest;
};

export type MutationUpdateParticipantFileArgs = {
  organizationId: Scalars['ID'];
  participantFileId: Scalars['ID'];
  participantId: Scalars['ID'];
  request: UpdateParticipantFileRequest;
};

export type MutationUpdateParticipantNoteArgs = {
  organizationId: Scalars['ID'];
  participantId: Scalars['ID'];
  participantNoteId: Scalars['ID'];
  request: UpdateParticipantNoteRequest;
};

export type MutationUpdateParticipantWidgetLayoutItemsArgs = {
  organizationId: Scalars['ID'];
  participantId: Scalars['ID'];
  request: Array<UpdateParticipantWidgetLayoutItemRequest>;
};

export type MutationUpdateTemplateArgs = {
  organizationId: Scalars['ID'];
  request: UpdateTemplateRequest;
  templateId: Scalars['ID'];
};

export type MutationUpdateUserArgs = {
  organizationId: Scalars['ID'];
  request: UpdateUserRequest;
  userId: Scalars['ID'];
};

export type Organization = {
  __typename?: 'Organization';
  auditTrail: PaginatedAuditTrailEntries;
  createdAt: Scalars['DateTime'];
  featureFlags: Array<FeatureFlag>;
  forms: Array<Form>;
  id: Scalars['ID'];
  incident: Maybe<Incident>;
  incidents: PaginatedIncidents;
  label: Maybe<Label>;
  labels: Array<Label>;
  locale: Scalars['String'];
  location: Maybe<Location>;
  locations: Array<Location>;
  logo: Maybe<Scalars['String']>;
  name: Scalars['String'];
  participant: Maybe<Participant>;
  participantFieldGroupTemplate: Maybe<ParticipantFieldGroupTemplate>;
  participantFieldGroupTemplates: Array<ParticipantFieldGroupTemplate>;
  participants: PaginatedParticipants;
  status: OrganizationStatus;
  stripeCustomerId: Maybe<Scalars['String']>;
  stripeSubscriptionId: Maybe<Scalars['String']>;
  template: Maybe<Template>;
  templates: PaginatedTemplates;
  timezone: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: Maybe<User>;
  users: PaginatedUsers;
};

export type OrganizationAuditTrailArgs = {
  request?: InputMaybe<GetPaginatedAuditTrailEntriesRequest>;
};

export type OrganizationIncidentArgs = {
  incidentId: Scalars['ID'];
};

export type OrganizationIncidentsArgs = {
  request?: InputMaybe<GetPaginatedIncidentsRequest>;
};

export type OrganizationLabelArgs = {
  labelId: Scalars['ID'];
};

export type OrganizationLabelsArgs = {
  type: Array<LabelType>;
};

export type OrganizationLocationArgs = {
  locationId: Scalars['ID'];
};

export type OrganizationParticipantArgs = {
  participantId: Scalars['ID'];
};

export type OrganizationParticipantFieldGroupTemplateArgs = {
  participantFieldGroupTemplateId: Scalars['ID'];
};

export type OrganizationParticipantsArgs = {
  request?: InputMaybe<GetPaginatedParticipantsRequest>;
};

export type OrganizationTemplateArgs = {
  templateId: Scalars['ID'];
  versionId?: InputMaybe<Scalars['ID']>;
};

export type OrganizationTemplatesArgs = {
  request?: InputMaybe<GetPaginatedTemplatesRequest>;
};

export type OrganizationUserArgs = {
  userId: Scalars['ID'];
};

export type OrganizationUsersArgs = {
  request?: InputMaybe<GetPaginatedUsersRequest>;
};

export type OrganizationMembership = {
  __typename?: 'OrganizationMembership';
  locations: Array<Location>;
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  roles: Array<Role>;
};

export type OrganizationRecordingInfo = {
  __typename?: 'OrganizationRecordingInfo';
  id: Scalars['ID'];
  locale: Scalars['String'];
  logo: Maybe<Scalars['String']>;
  name: Scalars['String'];
  participant: Maybe<ParticipantRecordingInfo>;
  participants: PaginatedParticipantRecordingInfo;
  timezone: Scalars['String'];
};

export type OrganizationRecordingInfoParticipantArgs = {
  participantId: Scalars['ID'];
  qr?: InputMaybe<Scalars['ID']>;
};

export type OrganizationRecordingInfoParticipantsArgs = {
  request: GetPaginatedParticipantRecordingInfoRequest;
};

export enum OrganizationStatus {
  Active = 'ACTIVE',
  Trial = 'TRIAL',
}

export type PaginatedAuditTrailEntries = {
  __typename?: 'PaginatedAuditTrailEntries';
  pagination: Pagination;
  results: Array<AuditTrailEntry>;
};

export type PaginatedIncidents = {
  __typename?: 'PaginatedIncidents';
  pagination: Pagination;
  results: Array<Incident>;
};

export type PaginatedParticipantAuditTrailEntries = {
  __typename?: 'PaginatedParticipantAuditTrailEntries';
  pagination: Pagination;
  results: Array<AuditTrailEntry>;
};

export type PaginatedParticipantDocumentVersions = {
  __typename?: 'PaginatedParticipantDocumentVersions';
  pagination: Pagination;
  results: Array<ParticipantDocumentVersion>;
};

export type PaginatedParticipantNoteVersions = {
  __typename?: 'PaginatedParticipantNoteVersions';
  pagination: Pagination;
  results: Array<ParticipantNoteVersion>;
};

export type PaginatedParticipantRecordingInfo = {
  __typename?: 'PaginatedParticipantRecordingInfo';
  pagination: RecordingPagination;
  results: Array<ParticipantRecordingInfo>;
};

export type PaginatedParticipants = {
  __typename?: 'PaginatedParticipants';
  pagination: Pagination;
  results: Array<Participant>;
};

export type PaginatedTemplateVersions = {
  __typename?: 'PaginatedTemplateVersions';
  pagination: Pagination;
  results: Array<TemplateVersion>;
};

export type PaginatedTemplates = {
  __typename?: 'PaginatedTemplates';
  pagination: Pagination;
  results: Array<Template>;
};

export type PaginatedUsers = {
  __typename?: 'PaginatedUsers';
  pagination: Pagination;
  results: Array<User>;
};

export type Pagination = {
  __typename?: 'Pagination';
  currentPage: Scalars['Int'];
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  remaining: Scalars['Int'];
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type PaginationRequest = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type Participant = {
  __typename?: 'Participant';
  address: Maybe<Scalars['String']>;
  assignedUsers: Array<ParticipantUser>;
  behaviour: Maybe<ParticipantBehaviour>;
  behaviours: Array<ParticipantBehaviour>;
  createdAt: Scalars['DateTime'];
  dob: Scalars['DateTime'];
  document: Maybe<ParticipantDocument>;
  documents: Array<ParticipantDocument>;
  entries: PaginatedParticipantAuditTrailEntries;
  fieldGroups: Array<ParticipantFieldGroup>;
  file: Maybe<ParticipantFile>;
  files: Array<ParticipantFile>;
  firstName: Scalars['String'];
  gender: Gender;
  id: Scalars['ID'];
  isOnLatestFields: Scalars['Boolean'];
  keyContacts: Array<ParticipantKeyContact>;
  label: Maybe<Label>;
  labels: Array<Label>;
  lastName: Scalars['String'];
  location: Location;
  ndisNumber: Maybe<Scalars['String']>;
  note: Maybe<ParticipantNote>;
  notes: Array<ParticipantNote>;
  organizationId: Scalars['ID'];
  picture: Maybe<Scalars['String']>;
  postcode: Maybe<Scalars['String']>;
  state: Maybe<Scalars['String']>;
  status: ParticipantStatus;
  statusChangeEvents: Array<ParticipantStatusChangeEvent>;
  suburb: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  widgets: Array<ParticipantWidgetLayoutItem>;
};

export type ParticipantBehaviourArgs = {
  participantBehaviourId: Scalars['ID'];
};

export type ParticipantDocumentArgs = {
  participantDocumentId: Scalars['ID'];
  versionId?: InputMaybe<Scalars['ID']>;
};

export type ParticipantEntriesArgs = {
  request?: InputMaybe<GetPaginatedParticipantAuditTrailEntriesRequest>;
};

export type ParticipantFileArgs = {
  participantFileId: Scalars['ID'];
};

export type ParticipantLabelArgs = {
  labelId: Scalars['ID'];
};

export type ParticipantNoteArgs = {
  participantNoteId: Scalars['ID'];
  versionId?: InputMaybe<Scalars['ID']>;
};

export type ParticipantBehaviour = {
  __typename?: 'ParticipantBehaviour';
  description: Scalars['String'];
  example: Scalars['String'];
  id: Scalars['ID'];
  methods: ParticipantBehaviourMethods;
  name: Scalars['String'];
  nonExample: Scalars['String'];
  recordings: Array<ParticipantBehaviourRecording>;
  status: ParticipantBehaviourStatus;
};

export type ParticipantBehaviourDataSource = {
  __typename?: 'ParticipantBehaviourDataSource';
  method: MethodType;
  participantBehaviourId: Scalars['ID'];
};

export type ParticipantBehaviourInfo = {
  __typename?: 'ParticipantBehaviourInfo';
  description: Scalars['String'];
  example: Scalars['String'];
  history: Array<ParticipantBehaviourRecording>;
  id: Scalars['ID'];
  methods: ParticipantBehaviourMethodsInfo;
  name: Scalars['String'];
  nonExample: Scalars['String'];
};

export type ParticipantBehaviourMethods = {
  __typename?: 'ParticipantBehaviourMethods';
  abc: AbcMethod;
  duration: DurationMethod;
  episodicSeverity: EpisodicSeverityMethod;
  frequency: FrequencyMethod;
};

export type ParticipantBehaviourMethodsInfo = {
  __typename?: 'ParticipantBehaviourMethodsInfo';
  abc: RecordingAbcMethod;
  duration: RecordingDurationMethod;
  episodicSeverity: RecordingEpisodicSeverityMethod;
  frequency: RecordingFrequencyMethod;
};

export type ParticipantBehaviourRecording =
  | ParticipantBehaviourRecordingAbc
  | ParticipantBehaviourRecordingDuration
  | ParticipantBehaviourRecordingEpisodicSeverity
  | ParticipantBehaviourRecordingFrequency;

export type ParticipantBehaviourRecordingAbc = {
  __typename?: 'ParticipantBehaviourRecordingAbc';
  antecedent: Scalars['String'];
  behaviour: Scalars['String'];
  comment: Maybe<Scalars['String']>;
  consequence: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  method: AbcMethod;
  participantBehaviourId: Scalars['ID'];
  timestamp: Scalars['DateTime'];
  user: RecordingUser;
};

export type ParticipantBehaviourRecordingDuration = {
  __typename?: 'ParticipantBehaviourRecordingDuration';
  comment: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  method: DurationMethod;
  participantBehaviourId: Scalars['ID'];
  timestamp: Scalars['DateTime'];
  user: RecordingUser;
  value: Scalars['Int'];
};

export type ParticipantBehaviourRecordingEpisodicSeverity = {
  __typename?: 'ParticipantBehaviourRecordingEpisodicSeverity';
  comment: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  method: EpisodicSeverityMethod;
  participantBehaviourId: Scalars['ID'];
  timestamp: Scalars['DateTime'];
  user: RecordingUser;
  value: Scalars['Int'];
};

export type ParticipantBehaviourRecordingFrequency = {
  __typename?: 'ParticipantBehaviourRecordingFrequency';
  comment: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  method: FrequencyMethod;
  participantBehaviourId: Scalars['ID'];
  timestamp: Scalars['DateTime'];
  user: RecordingUser;
  value: Scalars['Int'];
};

export enum ParticipantBehaviourStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export type ParticipantDocument = {
  __typename?: 'ParticipantDocument';
  authors: Array<User>;
  createdAt: Scalars['DateTime'];
  creator: User;
  id: Scalars['ID'];
  label: Maybe<Label>;
  labels: Array<Label>;
  name: Scalars['String'];
  participantId: Scalars['ID'];
  reviewers: Array<User>;
  status: ParticipantDocumentStatus;
  updatedAt: Scalars['DateTime'];
  value: Scalars['JSON'];
  variables: Array<Variable>;
  version: Maybe<ParticipantDocumentVersion>;
  versionId: Maybe<Scalars['ID']>;
  versions: PaginatedParticipantDocumentVersions;
};

export type ParticipantDocumentLabelArgs = {
  labelId: Scalars['ID'];
};

export type ParticipantDocumentVersionsArgs = {
  request?: InputMaybe<GetPaginatedParticipantDocumentVersionsRequest>;
};

export enum ParticipantDocumentStatus {
  Draft = 'DRAFT',
  Finalized = 'FINALIZED',
}

export type ParticipantDocumentVersion = {
  __typename?: 'ParticipantDocumentVersion';
  createdAt: Scalars['DateTime'];
  users: Array<User>;
  versionId: Scalars['ID'];
};

export type ParticipantField = {
  __typename?: 'ParticipantField';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  template: ParticipantFieldTemplate;
  updatedAt: Scalars['DateTime'];
  value: Maybe<Scalars['JSON']>;
};

export type ParticipantFieldGroup = {
  __typename?: 'ParticipantFieldGroup';
  createdAt: Scalars['DateTime'];
  fields: Array<ParticipantField>;
  id: Scalars['ID'];
  template: ParticipantFieldGroupTemplate;
  updatedAt: Scalars['DateTime'];
};

export type ParticipantFieldGroupTemplate = {
  __typename?: 'ParticipantFieldGroupTemplate';
  createdAt: Scalars['DateTime'];
  fields: Array<ParticipantFieldTemplate>;
  id: Scalars['ID'];
  index: Scalars['Int'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  versionId: Scalars['ID'];
};

export type ParticipantFieldTemplate = {
  __typename?: 'ParticipantFieldTemplate';
  attributes: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  index: Scalars['Int'];
  name: Scalars['String'];
  type: FieldTemplateType;
  updatedAt: Scalars['DateTime'];
  versionId: Scalars['ID'];
};

export type ParticipantFile = {
  __typename?: 'ParticipantFile';
  createdAt: Scalars['DateTime'];
  downloadUrl: Scalars['String'];
  id: Scalars['ID'];
  label: Maybe<Label>;
  labels: Array<Label>;
  name: Scalars['String'];
  participantId: Scalars['ID'];
  size: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  uploader: Maybe<User>;
  viewUrl: Scalars['String'];
};

export type ParticipantFileLabelArgs = {
  labelId: Scalars['ID'];
};

export type ParticipantKeyContact = {
  __typename?: 'ParticipantKeyContact';
  createdAt: Scalars['DateTime'];
  description: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  labels: Array<Label>;
  name: Scalars['String'];
  phone: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type ParticipantNote = {
  __typename?: 'ParticipantNote';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  labels: Array<Label>;
  participantId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  user: Maybe<User>;
  value: Scalars['JSON'];
  version: Maybe<ParticipantNoteVersion>;
  versionId: Maybe<Scalars['ID']>;
  versions: PaginatedParticipantNoteVersions;
};

export type ParticipantNoteVersionsArgs = {
  request?: InputMaybe<GetPaginatedParticipantNoteVersionsRequest>;
};

export type ParticipantNoteVersion = {
  __typename?: 'ParticipantNoteVersion';
  createdAt: Scalars['DateTime'];
  users: Array<User>;
  versionId: Scalars['ID'];
};

export type ParticipantRecordingInfo = {
  __typename?: 'ParticipantRecordingInfo';
  behaviours: Array<ParticipantBehaviourInfo>;
  createdAt: Scalars['DateTime'];
  dob: Scalars['DateTime'];
  firstName: Scalars['String'];
  gender: Gender;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  ndisNumber: Maybe<Scalars['String']>;
  organizationId: Scalars['ID'];
  picture: Maybe<Scalars['String']>;
  qr: Maybe<Qr>;
};

export enum ParticipantStatus {
  Active = 'Active',
  Archived = 'Archived',
  Deleted = 'Deleted',
  Intake = 'Intake',
  New = 'New',
  OnHold = 'OnHold',
  Waitlist = 'Waitlist',
}

export type ParticipantStatusChangeEvent = {
  __typename?: 'ParticipantStatusChangeEvent';
  status: ParticipantStatus;
  timestamp: Scalars['DateTime'];
};

export type ParticipantUser = {
  __typename?: 'ParticipantUser';
  id: Scalars['ID'];
  labels: Array<Label>;
  user: User;
};

export type ParticipantWidgetLayoutItem = {
  __typename?: 'ParticipantWidgetLayoutItem';
  h: Scalars['Int'];
  id: Scalars['ID'];
  w: Scalars['Int'];
  widget: Widget;
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type Pdf = {
  __typename?: 'Pdf';
  downloadUrl: Scalars['String'];
  viewUrl: Scalars['String'];
};

export type PhaseChangeLine = {
  __typename?: 'PhaseChangeLine';
  color: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  timestamp: Scalars['DateTime'];
  type: PhaseChangeType;
};

export enum PhaseChangeType {
  Major = 'Major',
  Minor = 'Minor',
}

export type Qr = {
  __typename?: 'QR';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  organizationId: Scalars['ID'];
  participantId: Scalars['ID'];
  permissions: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  organization: Maybe<Organization>;
  organizations: Array<Organization>;
  recording: Recording;
  user: Maybe<User>;
};

export type QueryOrganizationArgs = {
  organizationId: Scalars['ID'];
};

export type Recording = {
  __typename?: 'Recording';
  authed: Maybe<AuthedRecordingApp>;
  unauthed: UnauthedRecordingApp;
};

export type RecordingAbcMethod = {
  __typename?: 'RecordingAbcMethod';
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type RecordingDurationMethod = {
  __typename?: 'RecordingDurationMethod';
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type RecordingEpisodicSeverityMethod = {
  __typename?: 'RecordingEpisodicSeverityMethod';
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  scales: Array<EpisodicSeverityScale>;
};

export type RecordingEpisodicSeverityScale = {
  __typename?: 'RecordingEpisodicSeverityScale';
  description: Scalars['String'];
  id: Scalars['ID'];
};

export type RecordingFrequencyMethod = {
  __typename?: 'RecordingFrequencyMethod';
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type RecordingMethod =
  | RecordingAbcMethod
  | RecordingDurationMethod
  | RecordingEpisodicSeverityMethod
  | RecordingFrequencyMethod;

export type RecordingPagination = {
  __typename?: 'RecordingPagination';
  currentPage: Scalars['Int'];
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  remaining: Scalars['Int'];
  total: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export type RecordingUser = UnauthedRecordingUser | User;

export enum Role {
  Administrator = 'Administrator',
  Support = 'Support',
  TeamLeader = 'TeamLeader',
  TeamMember = 'TeamMember',
}

export type ScatterTimeSeries = {
  __typename?: 'ScatterTimeSeries';
  aggregation: AggregationType;
  color: Scalars['String'];
  id: Scalars['ID'];
  source: DataSource;
  symbol: SymbolType;
  trendline: Maybe<Trendline>;
};

export enum SymbolType {
  Circle = 'Circle',
  Diamond = 'Diamond',
  Rect = 'Rect',
  Triangle = 'Triangle',
}

export type TableSeries = {
  __typename?: 'TableSeries';
  id: Scalars['ID'];
  source: DataSource;
};

export type TableWidget = {
  __typename?: 'TableWidget';
  id: Scalars['ID'];
  series: Array<TableSeries>;
  title: Scalars['String'];
};

export type Template = {
  __typename?: 'Template';
  createdAt: Scalars['DateTime'];
  creator: User;
  id: Scalars['ID'];
  labels: Array<Label>;
  name: Scalars['String'];
  status: TemplateStatus;
  thumbnailUrl: Maybe<Scalars['String']>;
  type: TemplateType;
  updatedAt: Scalars['DateTime'];
  value: Scalars['JSON'];
  version: Maybe<TemplateVersion>;
  versionId: Maybe<Scalars['ID']>;
  versions: PaginatedTemplateVersions;
};

export type TemplateVersionArgs = {
  versionId: Scalars['ID'];
};

export type TemplateVersionsArgs = {
  request?: InputMaybe<GetPaginatedTemplateVersionsRequest>;
};

export enum TemplateStatus {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
}

export enum TemplateType {
  ParticipantDocument = 'ParticipantDocument',
  ParticipantNote = 'ParticipantNote',
}

export type TemplateVersion = {
  __typename?: 'TemplateVersion';
  createdAt: Scalars['DateTime'];
  users: Array<User>;
  versionId: Scalars['ID'];
};

export type TimeSeries = BarTimeSeries | LineTimeSeries | ScatterTimeSeries;

export type TimeSeriesChartWidget = {
  __typename?: 'TimeSeriesChartWidget';
  id: Scalars['ID'];
  phaseChangeLines: Array<PhaseChangeLine>;
  series: Array<TimeSeries>;
  title: Scalars['String'];
};

export type Trendline = {
  __typename?: 'Trendline';
  color: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  type: TrendlineType;
};

export enum TrendlineType {
  Linear = 'Linear',
  Polynomial = 'Polynomial',
}

export type UnauthedRecordingApp = {
  __typename?: 'UnauthedRecordingApp';
  createParticipantBehaviourRecording: ParticipantBehaviourRecording;
  organization: OrganizationRecordingInfo;
  organizations: Array<OrganizationRecordingInfo>;
  undoParticipantBehaviourRecording: Scalars['Boolean'];
};

export type UnauthedRecordingAppCreateParticipantBehaviourRecordingArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  organizationId: Scalars['ID'];
  participantBehaviourId: Scalars['ID'];
  qr: Scalars['ID'];
  request: CreateParticipantBehaviourRecordingRequest;
};

export type UnauthedRecordingAppOrganizationArgs = {
  organizationId: Scalars['ID'];
};

export type UnauthedRecordingAppOrganizationsArgs = {
  organizationIds: Array<Scalars['ID']>;
};

export type UnauthedRecordingAppUndoParticipantBehaviourRecordingArgs = {
  organizationId: Scalars['ID'];
  participantBehaviourId: Scalars['ID'];
  qr: Scalars['ID'];
  recordingId: Scalars['ID'];
};

export type UnauthedRecordingUser = {
  __typename?: 'UnauthedRecordingUser';
  email: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateAbcMethodsRequest = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type UpdateBehaviourRequest = {
  color?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateDurationMethodsRequest = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type UpdateEpisodicSeverityMethodsRequest = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  scales?: InputMaybe<Array<UpdateEpisodicSeverityScaleRequest>>;
};

export type UpdateEpisodicSeverityScaleRequest = {
  description: Scalars['String'];
  id: Scalars['ID'];
};

export type UpdateFormRequest = {
  labelMappings?: InputMaybe<FormLabelMappingsRequest>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<FormType>;
  url?: InputMaybe<Scalars['String']>;
};

export type UpdateFrequencyMethodsRequest = {
  enabled?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
};

export type UpdateIncidentFileRequest = {
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateIncidentRequest = {
  description?: InputMaybe<Scalars['String']>;
  labelIds?: InputMaybe<Array<Scalars['ID']>>;
  ndisReportableIncident?: InputMaybe<Scalars['Boolean']>;
  participantId?: InputMaybe<Scalars['ID']>;
  reviewerIds?: InputMaybe<Array<Scalars['ID']>>;
  setting?: InputMaybe<Scalars['String']>;
  severity?: InputMaybe<IncidentSeverity>;
  timestamp?: InputMaybe<Scalars['DateTime']>;
  witnesses?: InputMaybe<Array<CreateIncidentWitnessInput>>;
};

export type UpdateIncidentStatusRequest = {
  actions?: InputMaybe<Scalars['String']>;
  status: IncidentStatus;
};

export type UpdateLabelRequest = {
  color?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateLocationRequest = {
  color?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateOrganizationRequest = {
  locale?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<OrganizationStatus>;
  stripeCustomerId?: InputMaybe<Scalars['String']>;
  stripeSubscriptionId?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
};

export type UpdateParticipantBehaviourMethodsRequest = {
  abc?: InputMaybe<UpdateAbcMethodsRequest>;
  duration?: InputMaybe<UpdateDurationMethodsRequest>;
  episodicSeverity?: InputMaybe<UpdateEpisodicSeverityMethodsRequest>;
  frequency?: InputMaybe<UpdateFrequencyMethodsRequest>;
};

export type UpdateParticipantBehaviourRequest = {
  description: Scalars['String'];
  example: Scalars['String'];
  methods: UpdateParticipantBehaviourMethodsRequest;
  name: Scalars['String'];
  nonExample: Scalars['String'];
};

export type UpdateParticipantDocumentRequest = {
  authorIds?: InputMaybe<Array<Scalars['ID']>>;
  labelIds?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  reviewerIds?: InputMaybe<Array<Scalars['ID']>>;
  status?: InputMaybe<ParticipantDocumentStatus>;
  templateId?: InputMaybe<Scalars['ID']>;
  value?: InputMaybe<Scalars['JSON']>;
};

export type UpdateParticipantFieldGroupRequest = {
  fields: Array<UpdateParticipantFieldRequest>;
  id: Scalars['ID'];
};

export type UpdateParticipantFieldGroupTemplateRequest = {
  index?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateParticipantFieldRequest = {
  id: Scalars['ID'];
  value?: InputMaybe<Scalars['JSON']>;
};

export type UpdateParticipantFieldTemplateRequest = {
  attributes?: InputMaybe<Scalars['JSON']>;
  index?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  participantFieldGroupTemplateId?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<FieldTemplateType>;
};

export type UpdateParticipantFileRequest = {
  labelIds?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateParticipantKeyContactRequest = {
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  labelIds?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type UpdateParticipantNoteRequest = {
  labelIds?: InputMaybe<Array<Scalars['ID']>>;
  value?: InputMaybe<Scalars['JSON']>;
};

export type UpdateParticipantRequest = {
  address?: InputMaybe<Scalars['String']>;
  dob?: InputMaybe<Scalars['DateTime']>;
  fieldGroups?: InputMaybe<Array<UpdateParticipantFieldGroupRequest>>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  keyContacts?: InputMaybe<Array<UpdateParticipantKeyContactRequest>>;
  labelIds?: InputMaybe<Array<Scalars['ID']>>;
  lastName?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['ID']>;
  ndisNumber?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  postcode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ParticipantStatus>;
  suburb?: InputMaybe<Scalars['String']>;
};

export type UpdateParticipantUserRequest = {
  labelIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateParticipantWidgetLayoutItemRequest = {
  h: Scalars['Int'];
  id: Scalars['ID'];
  w: Scalars['Int'];
  widget: Scalars['JSON'];
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type UpdateTemplateRequest = {
  labelIds?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TemplateStatus>;
  value?: InputMaybe<Scalars['JSON']>;
};

export type UpdateUserRequest = {
  email?: InputMaybe<Scalars['String']>;
  locations?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  picture?: InputMaybe<Scalars['String']>;
  practitionerNumber?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<Role>>;
  settings?: InputMaybe<UpdateUserSettingsRequest>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateUserSettingsRequest = {
  defaultOrganization?: InputMaybe<Scalars['ID']>;
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  extraPermissions: Array<ExtraPermission>;
  featureFlags: Array<FeatureFlag>;
  id: Scalars['ID'];
  intercomHash: Scalars['String'];
  name: Scalars['String'];
  organizationMemberships: Array<OrganizationMembership>;
  picture: Maybe<Scalars['String']>;
  practitionerNumber: Maybe<Scalars['String']>;
  settings: UserSettings;
  title: Scalars['String'];
};

export type UserSettings = {
  __typename?: 'UserSettings';
  defaultOrganization: Maybe<Scalars['ID']>;
};

export type Variable = {
  __typename?: 'Variable';
  category: Scalars['String'];
  id: Scalars['ID'];
  label: Scalars['String'];
  value: Scalars['JSON'];
};

export type Widget = TableWidget | TimeSeriesChartWidget;
