import { P } from '@piccolohealth/util';
import { getSelectionRanges, NodeRangeSelection } from '@tiptap-pro/extension-node-range';
import { Node } from '@tiptap/pm/model';
import { SelectionRange } from '@tiptap/pm/state';
import { EditorView } from '@tiptap/pm/view';
import { Editor } from '@tiptap/react';
import React from 'react';
import tippy, { Instance } from 'tippy.js';

const findTopMostNode = (editor: EditorView, targetNode: Element): Element => {
  let currentNode = targetNode;

  while (currentNode && currentNode.parentNode && currentNode.parentNode !== editor.dom) {
    if (currentNode.parentNode) {
      currentNode = currentNode.parentNode as Element;
    }
  }

  return currentNode;
};

function clamp(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}

const getTargetInfo = (options: {
  editor: Editor;
  x: number;
  y: number;
}): {
  resultElement: Element | null;
  resultNode: Node | null;
  pos: number | null;
} => {
  const { y, editor } = options;
  let resultElement = null;
  let resultNode = null;
  let resultPosition = null;
  const relevantElements = editor.view.dom.children;

  for (const element of relevantElements) {
    // if the y position is inside the element then return it
    if (element.getBoundingClientRect().top < y && element.getBoundingClientRect().bottom > y) {
      resultElement = element;
      resultPosition = editor.view.posAtDOM(element, 0);

      if (resultPosition >= 0) {
        resultNode = editor.view.state.doc.nodeAt(Math.max(resultPosition - 1, 0));
        if (resultNode?.isText) {
          resultNode = editor.view.state.doc.nodeAt(Math.max(resultPosition - 1, 0));
        }
        if (!resultNode) {
          resultNode = editor.view.state.doc.nodeAt(Math.max(resultPosition, 0));
        }
        break;
      }
    }
  }

  return {
    resultElement,
    resultNode,
    pos: null != resultPosition ? resultPosition : null,
  };
};

const getComputedStyle = (element: HTMLElement, selector: keyof CSSStyleDeclaration): string => {
  return window.getComputedStyle(element)[selector] as string;
};

const calculateAdjustedCoords = (view: EditorView, x: number, y: number) => {
  const paddingLeft = parseInt(getComputedStyle(view.dom, 'paddingLeft'), 10);
  const paddingRight = parseInt(getComputedStyle(view.dom, 'paddingRight'), 10);
  const borderLeftWidth = parseInt(getComputedStyle(view.dom, 'borderLeftWidth'), 10);
  const borderRightWidth = parseInt(getComputedStyle(view.dom, 'borderRightWidth'), 10);
  const domRect = view.dom.getBoundingClientRect();

  return {
    left: clamp(
      x,
      domRect.left + paddingLeft + borderLeftWidth,
      domRect.right - paddingRight - borderRightWidth,
    ),
    top: y,
  };
};

const calculateSelectedRanges = (
  editor: Editor,
  clientX: number,
  clientY: number,
): SelectionRange[] => {
  const { doc } = editor.view.state;

  const targetInfo = getTargetInfo({
    editor,
    x: clientX,
    y: clientY,
  });

  if (!targetInfo.resultNode || null === targetInfo.pos) {
    return [];
  }

  const adjustedCoords = calculateAdjustedCoords(editor.view, clientX, clientY);

  const coordPos = editor.view.posAtCoords(adjustedCoords);

  if (!coordPos) {
    return [];
  }
  const { pos } = coordPos;

  if (!doc.resolve(pos).parent) {
    return [];
  }

  const fromPos = doc.resolve(targetInfo.pos);
  const toPos = doc.resolve(targetInfo.pos + 1);

  return getSelectionRanges(fromPos, toPos, 0);
};

const getNodeRangeSelection = (editor: Editor, clientX: number, clientY: number) => {
  const { empty, $from, $to } = editor.view.state.selection;
  const selectedRanges = calculateSelectedRanges(editor, clientX, clientY);
  const selectionRanges = getSelectionRanges($from, $to, 0);
  const hasOverlap = selectionRanges.some((range) =>
    selectedRanges.find(
      (selectedRange) => selectedRange.$from === range.$from && selectedRange.$to === range.$to,
    ),
  );
  const dragRanges = empty || !hasOverlap ? selectedRanges : selectionRanges;

  if (P.isEmpty(dragRanges)) {
    return null;
  }

  const startPos = dragRanges[0].$from.pos;
  const endPos = dragRanges[dragRanges.length - 1].$to.pos;
  const nodeRangeSelection = NodeRangeSelection.create(editor.view.state.doc, startPos, endPos);

  return nodeRangeSelection;
};

interface Props {
  childrenRef: React.RefObject<HTMLDivElement>;
  dragHandleRef: React.RefObject<HTMLDivElement>;
  editor: Editor;
  isLocked?: boolean;
  onSelection?: (nodeRangeSelection: NodeRangeSelection | null) => void;
}

export const useDragHandle = (props: Props) => {
  const { editor, isLocked, childrenRef, dragHandleRef, onSelection } = props;

  const tippyInstanceRef = React.useRef<Instance | null>(null);
  const [targetElement, setTargetElement] = React.useState<Element | null>(null);

  const onDragStart = React.useCallback(
    (event: React.DragEvent) => {
      if (!dragHandleRef.current) {
        return;
      }

      if (!event.dataTransfer) {
        return;
      }

      const nodeRangeSelection = getNodeRangeSelection(editor, event.clientX, event.clientY);

      if (!nodeRangeSelection) {
        return;
      }

      editor.chain().setNodeSelection(nodeRangeSelection.from).run();

      const slice = nodeRangeSelection.content();
      const { tr } = editor.view.state;

      event.dataTransfer.clearData();
      event.dataTransfer.effectAllowed = 'move';
      editor.view.dragging = {
        slice,
        move: event.ctrlKey,
      };
      tr.setSelection(nodeRangeSelection);
      editor.view.dispatch(tr);
    },
    [dragHandleRef, editor],
  );

  const onDragClick = React.useCallback(
    (event: React.MouseEvent) => {
      const nodeRangeSelection = getNodeRangeSelection(editor, event.clientX, event.clientY);

      if (!nodeRangeSelection) {
        return;
      }

      editor.chain().setNodeSelection(nodeRangeSelection.from).run();
    },
    [editor],
  );

  const onMouseMove = React.useCallback(
    (event: MouseEvent) => {
      if (isLocked || !dragHandleRef.current) {
        return;
      }

      const targetInfo = getTargetInfo({
        x: event.clientX,
        y: event.clientY,
        editor: editor,
      });

      if (!targetInfo.resultElement) {
        return;
      }

      const targetElement = findTopMostNode(editor.view, targetInfo.resultElement);

      if (targetElement === editor.view.dom || !targetElement || targetElement.nodeType !== 1) {
        return;
      }

      setTargetElement(targetElement);
      const nodeRangeSelection = getNodeRangeSelection(editor, event.clientX, event.clientY);
      onSelection?.(nodeRangeSelection);
    },
    [dragHandleRef, editor, isLocked, onSelection],
  );

  React.useEffect(() => {
    if (!tippyInstanceRef.current || !targetElement) {
      return;
    }

    tippyInstanceRef.current.setProps({
      getReferenceClientRect: () => targetElement.getBoundingClientRect(),
    });
    tippyInstanceRef.current.show();
  }, [targetElement]);

  React.useEffect(() => {
    addEventListener('mousemove', onMouseMove);

    return () => {
      removeEventListener('mousemove', onMouseMove);
    };
  }, [onMouseMove]);

  React.useEffect(() => {
    if (!childrenRef.current || !dragHandleRef.current || !editor.view.dom.parentElement) {
      return;
    }

    if (tippyInstanceRef.current) {
      return;
    }

    tippyInstanceRef.current = tippy(dragHandleRef.current, {
      trigger: 'manual',
      appendTo: editor.view.dom.parentElement,
      content: childrenRef.current,
      hideOnClick: false,
      interactive: true,
      placement: 'left-start',
      offset: [0, 20],
      zIndex: 1000,
      popperOptions: {
        modifiers: [
          {
            name: 'flip',
            enabled: false,
          },
          {
            name: 'preventOverflow',
            options: {
              rootBoundary: 'document',
              mainAxis: false,
            },
          },
        ],
      },
    });

    return () => {
      if (tippyInstanceRef.current && tippyInstanceRef.current) {
        tippyInstanceRef.current.destroy();
        tippyInstanceRef.current = null;
      }
    };
  }, [childrenRef, dragHandleRef, editor.view.dom.parentElement]);

  return {
    onDragStart,
    onDragClick,
  };
};
