import { MethodType } from '@piccolohealth/pbs-common';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { FormSection } from '../../../../components/forms/FormSection';
import { FormStack } from '../../../../components/forms/FormStack';
import { HookedFormItem } from '../../../../components/forms/HookedFormItem';
import { HookedSelect } from '../../../../components/forms/HookedSelect';
import { UseTableReturn } from '../../hooks/useTable';
import { methodTypenameToMethodType, METHOD_TYPE_OPTIONS } from '../../utils';
import { MethodOption } from '../timeseries/MethodOption';

interface Props {
  table: UseTableReturn;
}

export const TableSeriesForm = (props: Props) => {
  const { table } = props;

  const participantBehaviourId = useWatch({ name: 'source.participantBehaviourId' });

  const methodCount = React.useMemo(() => {
    const participantBehaviour = table.participantBehaviours.find((pb) =>
      pb.recordings.find((r) => r.participantBehaviourId === participantBehaviourId),
    );

    return (participantBehaviour?.recordings ?? []).reduce((acc, recording) => {
      const method = methodTypenameToMethodType(recording.method.__typename);
      acc[method] = (acc[method] ?? 0) + 1;
      return acc;
    }, {} as Record<MethodType, number | undefined>);
  }, [participantBehaviourId, table]);

  return (
    <FormStack w="full">
      <FormSection heading="Data" description="Select the data to display on the chart.">
        <FormStack orientation="horizontal">
          <HookedFormItem name="source.method" label="Method">
            <HookedSelect
              name="source.method"
              options={METHOD_TYPE_OPTIONS}
              components={{
                Value: (ps) => (
                  <MethodOption option={ps.option} count={methodCount[ps.option.raw] ?? 0} />
                ),
                Option: (ps) => (
                  <MethodOption option={ps.option} count={methodCount[ps.option.raw] ?? 0} />
                ),
              }}
            />
          </HookedFormItem>
        </FormStack>
      </FormSection>
    </FormStack>
  );
};
