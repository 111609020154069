import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { IncidentSeverity, PiccoloError, UpdateIncidentRequest } from '@piccolohealth/pbs-common';
import { Spin } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { HookedSubmitButton } from '../../components/forms/HookedSubmitButton';
import { Error } from '../../components/generic/Error';
import { createModal } from '../../components/generic/Modal';
import { useIncidentQuery } from '../../graphql/hooks/useIncidentQuery';
import { useUpdateIncidentMutation } from '../../graphql/hooks/useUpdateIncidentMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { dateTimeSchema } from '../../utils/zod';
import { IncidentForm } from './IncidentForm';

type FormValues = UpdateIncidentRequest & {
  participant?: {
    id: string;
  } | null;
  reviewers?: string[];
};

const schema: z.ZodSchema<FormValues> = z.object({
  description: z.string(),
  setting: z.string(),
  participant: z
    .object({
      id: z.string(),
    })
    .nullish(),
  reviewers: z.array(z.string()),
  severity: z.nativeEnum(IncidentSeverity),
  ndisReportableIncident: z.boolean(),
  timestamp: dateTimeSchema,
  witnesses: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      email: z.string().email().nullish(),
      phone: z.string().nullish(),
      description: z.string().nullish(),
    }),
  ),
  labelIds: z.array(z.string()).nullish(),
});

interface Props {
  incidentId: string;
}

export const IncidentEditModal = createModal<Props>((props) => {
  const { modal, incidentId } = props;
  const { errorToast, successToast, organization } = useAppContext();

  const { hide, remove, visible } = modal;

  const { data, isLoading, error } = useIncidentQuery({
    organizationId: organization.id,
    incidentId: props.incidentId,
  });

  const incident = data?.organization?.incident;

  const defaultValues = {
    description: incident?.description,
    participant: incident?.participant,
    reviewers: incident?.reviewers.map((r) => r.id),
    setting: incident?.setting,
    severity: incident?.severity,
    ndisReportableIncident: incident?.ndisReportableIncident,
    timestamp: incident?.timestamp,
    witnesses: incident?.witnesses,
    labelIds: incident?.labels?.map((label) => label.id) ?? [],
  };

  const methods = useForm<FormValues>({
    defaultValues: defaultValues,
    mode: 'all',
    resolver: zodResolver(schema),
  });

  const mutation = useUpdateIncidentMutation();

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      const { participant, reviewers, ...rest } = values;

      await mutation
        .mutateAsync({
          organizationId: organization.id,
          incidentId: incidentId,
          request: {
            ...P.deepTrim(rest),
            participantId: participant?.id,
            reviewerIds: reviewers,
          },
        })
        .then(() => {
          hide();
          successToast('Incident updated successfully');
        })
        .catch((err) => {
          errorToast(`Error updating incident: ${err.message}`);
        });
    },
    [mutation, organization.id, incidentId, hide, successToast, errorToast],
  );

  const content = P.run(() => {
    if (isLoading) {
      return <Spin />;
    }

    if (error) {
      return <Error error={error} />;
    }

    if (!incident) {
      return (
        <Error
          error={
            new PiccoloError({
              type: 'IncidentNotFound',
              message: 'Incident not found',
            })
          }
        />
      );
    }

    return <IncidentForm />;
  });

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={remove} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <Box as="form" display="contents" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <ModalHeader>
              <Flex align="start">
                <Box>
                  <Heading size="md">Edit an incident</Heading>
                  <Text fontSize="md" fontWeight="normal" color="secondary" mt={2}>
                    Please complete the following to proceed
                  </Text>
                </Box>
                <Spacer />
                <ModalCloseButton position="unset" top="unset" right="unset" />
              </Flex>
            </ModalHeader>
            <ModalBody>{content}</ModalBody>
            <ModalFooter>
              <HStack>
                <Button size="sm" onClick={hide}>
                  Close
                </Button>
                <HookedSubmitButton size="sm">Save</HookedSubmitButton>
              </HStack>
            </ModalFooter>
          </Box>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
