import { DateTime } from '@piccolohealth/util';
import { z } from 'zod';

export const dateTimeSchema = z.custom<DateTime>(
  (val) => {
    const parsed = DateTime.fromISO(val as string);
    return parsed.isValid;
  },
  {
    message: 'Invalid date',
  },
);

// Zod combinator that will transform an empty string to null
export const stringToNull = <T extends z.ZodTypeAny>(zodType: T) => {
  return z.union([z.literal('').transform(() => null), zodType]).nullish();
};
