import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { CreateParticipantBehaviour } from '../mutations/createParticipantBehaviour.mutation';
import {
  CreateParticipantBehaviourMutation,
  CreateParticipantBehaviourMutationVariables,
} from '../types';

export const useCreateParticipantBehaviourMutation = (
  options?: MutationOptions<
    CreateParticipantBehaviourMutation,
    CreateParticipantBehaviourMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(CreateParticipantBehaviour), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('participant'),
      });
    },
  });
};
