import { Box, HStack, Spacer, Stack } from '@chakra-ui/react';
import { WebSocketStatus } from '@hocuspocus/provider';
import { ParticipantNote } from '@piccolohealth/pbs-common';
import { Editor } from '@tiptap/react';
import React from 'react';
import { SaveStatusIndicator } from '../../components/tiptap/SaveStatusIndicator';
import { UserStatusIndicator } from '../../components/tiptap/UserStatusIndicator';
import { UserDescription } from '../../components/user/UserDescription';
import { ManageParticipantNoteLabelsControl } from './ManageParticipantNoteLabelsControl';
import { ParticipantNoteActionMenu } from './ParticipantNoteActionMenu';

interface Props {
  editor: Editor;
  status: WebSocketStatus;
  isSaving: boolean;
  participantNote: ParticipantNote;
  isDisabled?: boolean;
  onDelete: () => void;
}

export const ParticipantNoteTitleBar = (props: Props) => {
  const { participantNote, editor, status, isSaving, isDisabled, onDelete } = props;

  return (
    <Stack spacing={4} mt={4}>
      <HStack fontSize="sm" spacing={4}>
        <UserDescription
          name={participantNote.user?.name ?? 'Piccolo Health'}
          secondary={participantNote.user?.email ?? 'admin@piccolohealth.com'}
          picture={participantNote?.user?.picture}
        />

        <Box w="full" alignSelf="center">
          <ManageParticipantNoteLabelsControl
            participantNote={participantNote}
            isDisabled={isDisabled}
          />
        </Box>

        <Spacer />
        <HStack flexShrink={0} spacing={4}>
          <HStack justify="end" w="70px">
            <SaveStatusIndicator isSaving={isSaving} />
          </HStack>
          <UserStatusIndicator editor={editor} status={status} />
          <ParticipantNoteActionMenu
            participantNote={participantNote}
            variant="outline"
            size="sm"
            isDisabled={isDisabled}
            onDelete={onDelete}
          />
        </HStack>
      </HStack>
    </Stack>
  );
};
