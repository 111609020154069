import { Role } from '@piccolohealth/pbs-common';
import { useOrganization } from '../context/OrganizationContext';
import { useUser } from '../context/UserContext';

export const useRole = (role: Role): boolean => {
  const user = useUser();
  const organization = useOrganization();

  const organizationMembership = user.organizationMemberships.find(
    (o) => o.organizationId === organization.id,
  );
  const roles = organizationMembership?.roles ?? [];

  return roles.includes(role);
};
