import { Box, GridItem, GridItemProps, Stack, Text } from '@chakra-ui/react';
import React from 'react';

interface Props extends React.PropsWithChildren<GridItemProps> {
  label: string;
}

export const ProfileItem = (props: Props) => {
  const { label, children, ...rest } = props;

  return (
    <GridItem {...rest}>
      <Stack spacing={0}>
        <Text fontSize="sm" fontWeight="semibold">
          {label}
        </Text>
        <Box fontSize="sm" color="gray.600">
          {children}
        </Box>
      </Stack>
    </GridItem>
  );
};
