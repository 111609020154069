import gql from 'graphql-tag';

export const CreateIncidentLabel = gql`
  mutation CreateIncidentLabel($organizationId: ID!, $incidentId: ID!, $labelId: ID!) {
    createIncidentLabel(
      organizationId: $organizationId
      incidentId: $incidentId
      labelId: $labelId
    ) {
      id
    }
  }
`;
