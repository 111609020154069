import { MenuItem, Text } from '@chakra-ui/react';
import React from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { showModal } from '../../components/generic/Modal';
import { usePermission } from '../../hooks/usePermission';
import { ParticipantDeleteModal } from './ParticipantDeleteModal';

interface Props {
  participantId: string;
}

export const ParticipantDeleteMenuItem = (props: Props) => {
  const { participantId } = props;
  const hasPermission = usePermission('delete', 'participant');

  return (
    <MenuItem
      isDisabled={!hasPermission}
      onClick={() => showModal(ParticipantDeleteModal, { participantId })}
      icon={<FaRegTrashAlt />}
    >
      <Text color="danger">Delete</Text>
    </MenuItem>
  );
};
