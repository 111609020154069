import { Button, ButtonProps, Icon, Menu, MenuButton, MenuGroup, MenuList } from '@chakra-ui/react';
import { Incident } from '@piccolohealth/pbs-common';
import React from 'react';
import { FaCaretDown } from 'react-icons/fa';
import { IncidentPdfMenuItem } from './IncidentPdfMenuItem';

interface Props extends ButtonProps {
  incident: Incident;
}

export const IncidentActionMenu = (props: Props) => {
  const { incident, ...rest } = props;

  return (
    <Menu isLazy lazyBehavior="unmount" size="sm">
      <MenuButton
        as={Button}
        variant="outline"
        size="sm"
        rightIcon={<Icon as={FaCaretDown} />}
        flexShrink={0}
        {...rest}
      >
        Actions
      </MenuButton>
      <MenuList>
        <MenuGroup title="PDFs">
          <IncidentPdfMenuItem incident={incident} />
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};
