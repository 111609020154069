import { Box, Center, HStack, IconButton, Stack, Text, Tooltip } from '@chakra-ui/react';
import { FeedItem, User } from '@knocklabs/client';
import { useKnockFeed } from '@knocklabs/react-notification-feed';
import { NotificationPayload } from '@piccolohealth/pbs-common';
import { Dot } from '@piccolohealth/ui';
import { DateTime } from '@piccolohealth/util';
import React from 'react';
import { FaArchive } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { useHoverDirty } from 'react-use';
import { UserAvatar } from '../../components/user/UserAvatar';
import { decodeHtml } from '../../utils/decodeHtml';

interface Props {
  notification: FeedItem<NotificationPayload>;
  onClose: () => void;
}

export const NotificationItem = (props: Props) => {
  const { notification, onClose } = props;

  const from = notification.actors?.[0] as User | undefined;
  const { content } = notification.data ?? {};

  const { feedClient } = useKnockFeed();
  const navigate = useNavigate();
  const ref = React.useRef(null);
  const isHovering = useHoverDirty(ref);

  const date = DateTime.fromISO(notification.inserted_at);

  // Show a relative string if younger than 24 hours, otherwise, show a  DateTime.DATETIME_SHORT
  const formattedDate =
    date.diffNow().as('hours') > -24
      ? date.toRelative()
      : date.toLocaleString(DateTime.DATETIME_SHORT);

  const onLinkClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (e.target instanceof HTMLAnchorElement) {
        const href = e.target.getAttribute('href');

        if (!href) {
          return;
        }

        const url = new URL(href);

        if (url && url.origin === window.location.origin) {
          const path = url.pathname + url.search + url.hash;
          e.preventDefault();
          navigate(path, { relative: 'path' });
          onClose();
        }
      }
    },
    [navigate, onClose],
  );

  const markNotificationAsArchived = React.useCallback(() => {
    return feedClient.markAsArchived(notification);
  }, [feedClient, notification]);

  const markNotificationAsRead = React.useCallback(() => {
    feedClient.markAsRead(notification);
  }, [feedClient, notification]);

  return (
    <HStack
      ref={ref}
      py={2}
      px={6}
      spacing={4}
      align="center"
      bg={notification.read_at ? 'white' : 'gray.50'}
      onClick={markNotificationAsRead}
      cursor={notification.read_at ? 'default' : 'pointer'}
    >
      <UserAvatar
        name={from?.name ?? 'Piccolo Health'}
        picture={from?.avatar}
        secondary={from?.email ?? undefined}
        showTooltip={false}
        size="md"
      />
      <Stack spacing={0}>
        <HStack align="baseline">
          <Text fontSize="sm" fontWeight="semibold">
            {from?.name}
          </Text>
          <Text fontSize="xs" color="secondary">
            {formattedDate}
          </Text>
        </HStack>
        <Box
          fontSize="sm"
          color="gray.700"
          __css={{
            a: {
              fontWeight: 'bold',
              color: 'purple.600',
              _hover: {
                textDecoration: 'underline',
              },
            },
          }}
          dangerouslySetInnerHTML={{
            __html: decodeHtml(content ?? ''),
          }}
          onClick={onLinkClick}
        />
      </Stack>
      <Center w={8} h={8} flexShrink={0}>
        {isHovering ? (
          <HStack spacing={1}>
            <Tooltip label="Archive this notification">
              <IconButton
                icon={<FaArchive />}
                aria-label="Archive this notification"
                size="xs"
                color="gray.500"
                variant="ghost"
                onClick={markNotificationAsArchived}
              />
            </Tooltip>
          </HStack>
        ) : (
          <Dot mt={2} size="2" bg={notification.seen_at ? 'transparent' : 'green.500'} />
        )}
      </Center>
    </HStack>
  );
};
