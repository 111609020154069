import { Box, HStack, Spacer, Stack } from '@chakra-ui/react';
import React from 'react';

interface Props {
  icon: React.ReactNode;
  title: string | React.ReactNode;
  secondary: string | React.ReactNode;
  tertiary: string | React.ReactNode;
  right: React.ReactNode;
}

export const ListItem = (props: Props) => {
  const { icon, title, secondary, tertiary, right } = props;

  return (
    <HStack spacing={4} align="start" fontSize="sm">
      {icon}
      <Stack spacing={1} w="full" align="start">
        <Stack spacing={0} ml={1}>
          <Box fontWeight="semibold" noOfLines={1}>
            {title}
          </Box>
          <Box color="secondary" fontSize="xs">
            {secondary}
          </Box>
        </Stack>
        {tertiary}
      </Stack>
      <Spacer />
      <Box flexShrink={0}>{right}</Box>
    </HStack>
  );
};
