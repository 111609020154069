import { Button, ButtonProps, Icon, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import React from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { ParticipantsFilter } from '../../hooks/useParticipantsFilter';
import { ParticipantExportCsvMenuItem } from './ParticipantExportCsvMenuItem';

interface Props extends Omit<ButtonProps, 'filter'> {
  filter: ParticipantsFilter;
}

export const ParticipantsActionMenu = (props: Props) => {
  const { filter, ...rest } = props;
  return (
    <Menu isLazy lazyBehavior="keepMounted">
      <MenuList>
        <ParticipantExportCsvMenuItem filter={filter} />
      </MenuList>
      <MenuButton as={Button} variant="outline" rightIcon={<Icon as={FaAngleDown} />} {...rest}>
        Actions
      </MenuButton>
    </Menu>
  );
};
