import gql from 'graphql-tag';

export const CreateParticipantUsers = gql`
  mutation CreateParticipantUsers(
    $organizationId: ID!
    $requests: [CreateParticipantUserRequest!]!
  ) {
    createParticipantUsers(organizationId: $organizationId, requests: $requests) {
      user {
        id
      }
      labels {
        id
        name
        color
        description
      }
    }
  }
`;
