import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { UpdateIncident } from '../mutations/updateIncident.mutation';
import { UpdateIncidentMutation, UpdateIncidentMutationVariables } from '../types';

export const useUpdateIncidentMutation = (
  options?: MutationOptions<UpdateIncidentMutation, UpdateIncidentMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(UpdateIncident), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('incident'),
      });
    },
  });
};
