import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  HStack,
  Icon,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ParticipantNote } from '@piccolohealth/pbs-common';
import { Empty, FancyDate, Spin } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FaEye, FaPlus } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Error } from '../../components/generic/Error';
import { LabelsStack } from '../../components/generic/LabelsStack';
import { ListItem } from '../../components/generic/ListItem';
import { showModal } from '../../components/generic/Modal';
import { UserAvatar } from '../../components/user/UserAvatar';
import { useCreateParticipantNoteMutation } from '../../graphql/hooks/useCreateParticipantNoteMutation';
import { useParticipantNotesQuery } from '../../graphql/hooks/useParticipantQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { ParticipantNoteActionMenu } from '../notes/ParticipantNoteActionMenu';
import { ParticipantNoteCreateModal } from '../notes/ParticipantNoteCreateModal';
import { ParticipantNoteEditModal } from '../notes/ParticipantNoteEditModal';

interface Props {
  participantId: string;
  isDisabled?: boolean;
}

export const ParticipantNotesCard = (props: Props) => {
  const { participantId, isDisabled } = props;
  const { organization, user, successToast, errorToast } = useAppContext();
  const navigate = useNavigate();

  const { isLoading, error, data } = useParticipantNotesQuery({
    organizationId: organization.id,
    participantId,
  });

  const mutation = useCreateParticipantNoteMutation();

  const onViewAllNotes = React.useCallback(() => {
    navigate(`/organizations/${organization.id}/participants/${participantId}/notes`);
  }, [navigate, organization.id, participantId]);

  const onAddNoteFromTemplate = React.useCallback(() => {
    showModal(ParticipantNoteCreateModal, { participantId });
  }, [participantId]);

  const onAddBlankNote = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        request: {
          id: undefined,
          participantId: participantId,
          userId: user.id,
          labelIds: [],
        },
      })
      .then((res) => {
        successToast('Note created successfully');
        showModal(ParticipantNoteEditModal, {
          participantId,
          participantNoteId: res.createParticipantNote.id,
          isDisabled,
        });
      })
      .catch((err) => {
        errorToast(`Error creating note: ${err.message}`);
      });
  }, [errorToast, isDisabled, mutation, organization.id, participantId, successToast, user.id]);

  const participantNotes = (data?.organization?.participant?.notes as ParticipantNote[]) ?? [];
  const recentParticipantNotes = P.take(participantNotes, 3);

  const content = P.run(() => {
    if (isLoading) {
      return <Spin />;
    }

    if (error) {
      return <Error h="full" error={error} />;
    }

    if (P.isEmpty(recentParticipantNotes)) {
      return <Empty h="full" title="No notes recorded yet" p={0} />;
    }

    return (
      <Stack spacing={6}>
        {recentParticipantNotes.map((participantNote) => {
          return (
            <ListItem
              key={participantNote.id}
              icon={
                <UserAvatar
                  secondary={participantNote.user?.email ?? 'admin@piccolohealth.com'}
                  name={participantNote.user?.name ?? 'Piccolo Health'}
                  picture={participantNote.user?.picture}
                  h={10}
                  w={10}
                />
              }
              title={participantNote.user?.name ?? 'Piccolo Health'}
              secondary={
                <FancyDate
                  date={participantNote.createdAt}
                  showHumanized={false}
                  color="secondary"
                  fontSize="xs"
                />
              }
              tertiary={<LabelsStack labels={participantNote.labels} />}
              right={
                <HStack>
                  <Button
                    size="xs"
                    variant="link"
                    w="fit-content"
                    onClick={() =>
                      showModal(ParticipantNoteEditModal, {
                        participantNoteId: participantNote.id,
                        participantId: participantId,
                        isDisabled,
                      })
                    }
                  >
                    View
                  </Button>
                  <ParticipantNoteActionMenu
                    participantNote={participantNote}
                    isDisabled={isDisabled}
                    variant="link"
                    size="xs"
                  />
                </HStack>
              }
            />
          );
        })}
      </Stack>
    );
  });

  return (
    <Card h="full">
      <CardHeader>
        <Stack spacing={0}>
          <Heading size="md">Notes</Heading>
          <Text fontSize="sm" color="secondary">
            Recent notes
          </Text>
        </Stack>
      </CardHeader>
      <CardBody as={Stack} spacing={6}>
        {content}
        <Stack spacing={4}>
          <HStack>
            <Button
              w="full"
              size="sm"
              leftIcon={<Icon as={FaPlus} />}
              onClick={onAddNoteFromTemplate}
            >
              Add note from template
            </Button>
            <Button w="full" size="sm" leftIcon={<Icon as={FaPlus} />} onClick={onAddBlankNote}>
              Add blank note
            </Button>
          </HStack>
          <Button w="full" size="sm" leftIcon={<Icon as={FaEye} />} onClick={onViewAllNotes}>
            View all
          </Button>
        </Stack>
      </CardBody>
    </Card>
  );
};
