import React from 'react';
import { NumberedBadge } from '@piccolohealth/ui';
import { useParticipantBehavioursQuery } from '../../../graphql/hooks/useParticipantQuery';
import { useAppContext } from '../../../hooks/useAppContext';

interface Props {
  participantId: string;
}

export const ParticipantBehavioursBadge = (props: Props) => {
  const { participantId } = props;
  const { organization } = useAppContext();

  const { data } = useParticipantBehavioursQuery({
    organizationId: organization.id,
    participantId,
  });

  const behaviours = data?.organization?.participant?.behaviours || [];

  return <NumberedBadge count={behaviours.length} />;
};
