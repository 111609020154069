import {
  Button,
  ButtonProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
} from '@chakra-ui/react';
import { IncidentFile } from '@piccolohealth/pbs-common';
import React from 'react';
import { FaCaretDown, FaPencilAlt, FaRegTrashAlt } from 'react-icons/fa';
import { showModal } from '../../components/generic/Modal';
import { IncidentFileDeleteModal } from './IncidentFileDeleteModal';
import { IncidentFileEditModal } from './IncidentFileEditModal';

interface Props extends ButtonProps {
  file: IncidentFile;
}

export const IncidentFileActionMenu = (props: Props) => {
  const { file, ...rest } = props;

  return (
    <Menu>
      <MenuButton as={Button} variant="link" size="sm" rightIcon={<FaCaretDown />} {...rest}>
        Actions
      </MenuButton>
      <Portal>
        <MenuList>
          <MenuItem
            icon={<FaPencilAlt />}
            onClick={() =>
              showModal(IncidentFileEditModal, {
                incidentFile: file,
              })
            }
          >
            Edit
          </MenuItem>
          <MenuItem
            color="danger"
            icon={<FaRegTrashAlt />}
            onClick={() =>
              showModal(IncidentFileDeleteModal, {
                incidentId: file.incidentId,
                incidentFileId: file.id,
              })
            }
          >
            Delete
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  );
};
