import { mergeAttributes, Node } from '@tiptap/core';
import { TextSelection } from 'prosemirror-state';

export interface PageBreakRuleOptions {
  HTMLAttributes: Record<string, any>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    pageBreak: {
      setPageBreak: () => ReturnType;
      unsetPageBreak: () => ReturnType;
    };
  }
}

export const PageBreak = Node.create<PageBreakRuleOptions>({
  name: 'pageBreak',

  addOptions() {
    return {
      HTMLAttributes: {
        style: 'page-break-after: always',
        'data-type': this.name,
      },
    };
  },

  group: 'topLevelBlock',
  selectable: true,

  parseHTML() {
    return [
      {
        tag: 'div',
        getAttrs: (node) =>
          (node as HTMLElement).style?.pageBreakAfter === 'always' &&
          (node as HTMLElement).dataset?.['data-type'] === this.name &&
          null,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['div', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes), ['hr']];
  },

  addCommands() {
    return {
      setPageBreak:
        () =>
        ({ chain }) => {
          return (
            chain()
              .insertContent({ type: this.name })
              // set cursor after page break
              .command(({ dispatch, tr }) => {
                if (dispatch) {
                  const { $to } = tr.selection;
                  const posAfter = $to.end();

                  if ($to.nodeAfter) {
                    tr.setSelection(TextSelection.create(tr.doc, $to.pos));
                  } else {
                    // add node after page break if it’s the end of the document
                    const node = $to.parent.type.contentMatch.defaultType?.create({
                      style: {
                        pageBreakAfter: 'always',
                      },
                      'data-type': this.name,
                    });

                    if (node) {
                      tr.insert(posAfter, node);
                      tr.setSelection(TextSelection.create(tr.doc, posAfter));
                    }
                  }

                  tr.scrollIntoView();
                }

                return true;
              })
              .run()
          );
        },
      unsetPageBreak:
        () =>
        ({ chain }) => {
          return chain()
            .deleteSelection()
            .command(() => true)
            .run();
        },
    };
  },
});
