import React from 'react';
import { NumberedBadge } from '@piccolohealth/ui';
import { useParticipantNotesQuery } from '../../graphql/hooks/useParticipantQuery';
import { useAppContext } from '../../hooks/useAppContext';

interface Props {
  participantId: string;
}

export const ParticipantNotesBadge = (props: Props) => {
  const { participantId } = props;
  const { organization } = useAppContext();

  const { data } = useParticipantNotesQuery({
    organizationId: organization.id,
    participantId,
  });

  const notes = data?.organization?.participant?.notes || [];

  return <NumberedBadge count={notes.length} />;
};
