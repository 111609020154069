import { Button, HStack, Spacer, Stack } from '@chakra-ui/react';
import { FloatingPopover } from '@piccolohealth/ui';
import { uuid } from '@piccolohealth/util';
import { Editor } from '@tiptap/react';
import React from 'react';
import { FaCommentDots } from 'react-icons/fa';
import { useAppContext } from '../../../../hooks/useAppContext';
import { TextareaAutosize } from '../../../forms/TextareaAutosize';
import { isExtensionEnabled } from '../../utils';

interface CommentFormProps {
  editor: Editor;
  onClose: () => void;
}

export const CommentForm = (props: CommentFormProps) => {
  const { editor, onClose } = props;
  const { user } = useAppContext();
  const [text, setText] = React.useState('');

  const onSaveComment = React.useCallback(() => {
    const commentThread = {
      id: uuid(),
      comments: [
        {
          username: user.name,
          userId: user.id,
          picture: user.picture ?? undefined,
          time: Date.now(),
          content: text,
        },
      ],
    };

    editor.chain().addCommentThread(commentThread).run();
    onClose();
    setText('');
  }, [editor, onClose, text, user]);

  const onKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        onSaveComment();
      }
    },
    [onSaveComment],
  );

  return (
    <Stack bg="white" shadow="popover" px={4} py={3} rounded="lg" w="xl">
      <TextareaAutosize
        variant="unstyled"
        placeholder="Add a comment..."
        value={text}
        onChange={(e) => setText(e.target.value)}
        onKeyDown={onKeyDown}
      />
      <HStack>
        <Spacer />
        <Button colorScheme="purple" size="xs" onClick={onSaveComment}>
          Save
        </Button>
      </HStack>
    </Stack>
  );
};

interface Props {
  editor: Editor;
}

export const CommentButton = (props: Props) => {
  const { editor } = props;

  if (!isExtensionEnabled(editor, 'comment')) {
    return null;
  }

  return (
    <FloatingPopover
      isPortal
      shouldInitialFocus
      placement="bottom"
      render={({ context }) => (
        <CommentForm editor={editor} onClose={() => context.onOpenChange(false)} />
      )}
    >
      {/* 
      This is a button rendered out as a div to the frontend. Disgusting, but FloatingUI
      has a large bug in Safari. See https://github.com/floating-ui/floating-ui/issues/2533 
      */}
      <Button
        as="div"
        tabIndex={0}
        variant="ghost"
        size="sm"
        rounded="lg"
        leftIcon={<FaCommentDots />}
      >
        Comment
      </Button>
    </FloatingPopover>
  );
};
