import gql from 'graphql-tag';

export const GenerateIncidentPdf = gql`
  mutation GenerateIncidentPdf($organizationId: ID!, $incidentId: ID!) {
    generateIncidentPdf(organizationId: $organizationId, incidentId: $incidentId) {
      downloadUrl
      viewUrl
    }
  }
`;
