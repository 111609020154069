import { Tag, TagProps } from '@chakra-ui/react';
import { FieldTemplateType } from '@piccolohealth/pbs-common';
import React from 'react';

export const fieldTemplateTypeTagColorName = (type: FieldTemplateType) => {
  switch (type) {
    case FieldTemplateType.Input:
      return ['green', 'Text'];
    case FieldTemplateType.Textarea:
      return ['yellow', 'Long text'];
    case FieldTemplateType.Select:
      return ['blue', 'Choice'];
    case FieldTemplateType.MultiSelect:
      return ['red', 'Multi Choice'];
    case FieldTemplateType.Datepicker:
      return ['orange', 'Date'];
  }
};

interface Props extends TagProps {
  type: FieldTemplateType;
}

export const FieldTemplateTypeTag = (props: Props) => {
  const { type, ...rest } = props;

  const [color, name] = fieldTemplateTypeTagColorName(type);

  return (
    <Tag colorScheme={color} {...rest}>
      {name}
    </Tag>
  );
};
