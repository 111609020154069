import { Select, SelectOption, SelectProps } from '@piccolohealth/ui';
import React from 'react';
import { useAppContext } from '../../hooks/useAppContext';

export interface LocationChooserProps
  extends Omit<SelectProps<string, unknown>, 'value' | 'onChange' | 'options' | 'selectedValues'> {
  value: string | null;
  onChange: (value: string | undefined) => void;
}

export const LocationChooser = (props: LocationChooserProps) => {
  const { value, onChange, ...rest } = props;
  const { organization } = useAppContext();

  const options: SelectOption<string>[] = organization.locations.map((location) => ({
    label: location.name,
    value: location.id,
    raw: location.id,
    color: location.color,
  }));

  const onChangePrime = React.useCallback(
    (value: SelectOption<string> | null) => {
      onChange(value?.value ?? undefined);
    },
    [onChange],
  );

  const selectedValue = React.useMemo(() => {
    return options.find((option) => option.value === value) ?? null;
  }, [options, value]);

  return <Select value={selectedValue} options={options} onChange={onChangePrime} {...rest} />;
};
