import { ChakraProvider } from '@chakra-ui/react';
import { useLagRadar } from '@piccolohealth/ui';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { createBrowserRouter, Outlet, RouterProvider } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { ErrorBoundary } from './components/generic/ErrorBoundary';
import { PageError } from './components/generic/PageError';
import { ProtectedRoute } from './components/generic/ProtectedRoute';
import { Config } from './config';
import { AuthContextProvider } from './context/AuthContext';
import { ConfigContextProvider } from './context/ConfigContext';
import { UserContextProvider } from './context/UserContext';
import { adminRoutes } from './features/admin/AdminRoutes';
import { Logout } from './features/logout/Logout';
import {
  OrganizationRedirect,
  organizationRoutes,
} from './features/organization/OrganizationRoutes';
import { theme } from './theme/theme';

const Routes = () => {
  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <ChakraProvider theme={theme} cssVarsRoot="#app">
          <ErrorBoundary>
            <AuthContextProvider>
              <Outlet />
            </AuthContextProvider>
          </ErrorBoundary>
        </ChakraProvider>
      ),
      children: [
        {
          path: '/logout',
          element: <Logout />,
        },
        {
          path: '*',
          element: <PageError type="NotFound" />,
        },
        {
          path: '/',
          element: (
            <UserContextProvider>
              <Outlet />
            </UserContextProvider>
          ),
          children: [
            {
              path: '/',
              element: (
                <ProtectedRoute>
                  <Outlet />
                </ProtectedRoute>
              ),
              children: [
                {
                  path: '/',
                  element: <OrganizationRedirect />,
                },
                {
                  path: 'organizations',
                  children: organizationRoutes,
                },
                {
                  path: 'admin',
                  children: adminRoutes,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: 'recording/*',
      lazy: () => import('./features/recording/RecordingApp'),
    },
  ]);

  return <RouterProvider router={router} />;
};

interface Props {
  config: Config;
  queryClient: QueryClient;
}

export const Root = (props: Props) => {
  const { config, queryClient } = props;

  useLagRadar(config.lagRadarEnabled ?? false);

  return (
    <ErrorBoundary>
      <ConfigContextProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <IntercomProvider
            appId={config.intercom.appId}
            shouldInitialize={config.intercom.enabled}
          >
            <Routes />
          </IntercomProvider>
        </QueryClientProvider>
      </ConfigContextProvider>
    </ErrorBoundary>
  );
};
