import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = {
  header: {
    borderBottomWidth: '1px',
    mb: 4,
  },
  dialog: {
    borderRadius: 'xl',
  },
};

export const Modal = defineMultiStyleConfig({
  baseStyle,
  sizes: {
    outline: {
      dialog: {
        maxW: '95vw',
        h: 'calc(95vh - 50px)',
      },
    },
    users: {
      dialog: {
        maxW: '96rem',
        h: 'calc(95vh - 50px)',
      },
    },
    note: {
      dialog: {
        maxW: '74rem',
        h: 'calc(95vh - 50px)',
      },
    },
  },
});
