import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Heading,
  HStack,
  Icon,
  Spacer,
  Stack,
  Tag,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { Incident, IncidentStatus, renderParticipantName } from '@piccolohealth/pbs-common';
import { FancyDate } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import { FormItem } from '../../components/forms/FormItem';
import { FormSection } from '../../components/forms/FormSection';
import { FormStack } from '../../components/forms/FormStack';
import { showModal } from '../../components/generic/Modal';
import { UserDescription } from '../../components/user/UserDescription';
import { IncidentEditModal } from './IncidentEditModal';
import { IncidentSeverityTag } from './IncidentSeverityTag';
import { IncidentStatusTag } from './IncidentStatusTag';

export interface Props {
  incident: Incident;
}

export const IncidentCard = (props: Props) => {
  const { incident } = props;

  const isReviewed = incident.status === IncidentStatus.Reviewed;

  const witnessesContent = P.run(() => {
    if (P.isEmpty(incident.witnesses)) {
      return <Text fontSize="sm">No witnesses</Text>;
    }

    return (
      <Wrap spacingX={12} spacingY={4}>
        {incident.witnesses.map((witness, index) => (
          <WrapItem key={`witness-${index}`}>
            <UserDescription
              name={P.compact([witness.name, witness.description]).join(' - ')}
              secondary={P.compact([witness.email, witness.phone]).join(' ')}
            />
          </WrapItem>
        ))}
      </Wrap>
    );
  });

  const reviewContentSummary = P.run(() => {
    if (incident.status !== IncidentStatus.Reviewed) {
      return null;
    }

    const reviewers = incident.reviewers.map((reviewer) => {
      return (
        <UserDescription
          key={reviewer.id}
          name={reviewer.name}
          secondary={reviewer.email}
          picture={reviewer.picture}
        />
      );
    });

    return (
      <>
        <Divider />
        <FormSection heading="Review summary">
          <FormStack orientation="vertical">
            <FormItem label="Reviewed by">{reviewers}</FormItem>
            <FormItem label="Actions to be taken">
              <Text whiteSpace="pre-wrap" fontSize="sm">
                {incident.actions}
              </Text>
            </FormItem>
          </FormStack>
        </FormSection>
      </>
    );
  });

  return (
    <Card h="full">
      <CardHeader>
        <HStack align="stretch">
          <Stack spacing={0}>
            <Heading size="md">Details</Heading>
            <Text fontSize="sm" color="secondary">
              View and edit basic incident details here
            </Text>
          </Stack>
          <Spacer />
        </HStack>
      </CardHeader>
      <CardBody>
        <Stack spacing={4} h="full">
          <FormSection heading="Basic info">
            <FormStack orientation="horizontal">
              <FormItem label="Severity">
                <IncidentSeverityTag severity={incident.severity} size="sm" w="fit-content" />
              </FormItem>
              <FormItem label="Status">
                <IncidentStatusTag status={incident.status} size="sm" w="fit-content" />
              </FormItem>
            </FormStack>
            <FormStack orientation="horizontal">
              <FormItem label="Clinic/Team location">
                {incident.location ? (
                  <Tag w="fit-content" size="sm" colorScheme={incident.location.color}>
                    {incident.location?.name}
                  </Tag>
                ) : (
                  <Text>-</Text>
                )}
              </FormItem>
              <FormItem label="NDIS reportable">
                <Text fontSize="sm">{incident.ndisReportableIncident ? 'Yes' : 'No'}</Text>
              </FormItem>
            </FormStack>
            <FormStack>
              <FormItem label="Labels">
                {P.isEmpty(incident.labels) ? (
                  <Text>-</Text>
                ) : (
                  <Wrap pt={2}>
                    {incident.labels.map((label) => {
                      return (
                        <WrapItem key={label.id}>
                          <Tag w="fit-content" size="sm" colorScheme={label.color}>
                            {label.name}
                          </Tag>
                        </WrapItem>
                      );
                    })}
                  </Wrap>
                )}
              </FormItem>
            </FormStack>
          </FormSection>

          <Divider />

          <FormSection heading="Incident info">
            <FormStack orientation="horizontal">
              <FormItem label="Occurred on">
                <FancyDate date={incident.timestamp} direction="row" fontSize="sm" />
              </FormItem>
              <FormItem label="Occurred at">
                <Text fontSize="sm">{incident.setting}</Text>
              </FormItem>
            </FormStack>
            <FormStack orientation="horizontal">
              <FormItem label="Participant involved">
                {incident.participant ? (
                  <UserDescription
                    name={renderParticipantName(incident.participant)}
                    secondary={incident.participant.ndisNumber ?? undefined}
                  />
                ) : (
                  <Text fontSize="sm">No participant</Text>
                )}
              </FormItem>
            </FormStack>
            <FormStack orientation="horizontal">
              <FormItem label="Description">
                <Text whiteSpace="pre-wrap" fontSize="sm">
                  {incident.description}
                </Text>
              </FormItem>
            </FormStack>
          </FormSection>

          <Divider />

          <FormSection heading="Witnesses">
            <FormStack orientation="vertical">{witnessesContent}</FormStack>
          </FormSection>

          {reviewContentSummary}

          <Divider />
          <Spacer />
          <Button
            leftIcon={<Icon as={FaPencilAlt} />}
            w="full"
            size="sm"
            isDisabled={isReviewed}
            onClick={() => showModal(IncidentEditModal, { incidentId: incident.id })}
          >
            Edit incident
          </Button>
        </Stack>
      </CardBody>
    </Card>
  );
};
