import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { UpdateParticipantDocument } from '../mutations/updateParticipantDocument.mutation';
import {
  UpdateParticipantDocumentMutation,
  UpdateParticipantDocumentMutationVariables,
} from '../types';

export const useUpdateParticipantDocumentMutation = (
  options?: MutationOptions<
    UpdateParticipantDocumentMutation,
    UpdateParticipantDocumentMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(UpdateParticipantDocument), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('participant'),
      });
    },
  });
};
