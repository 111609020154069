import {
  Box,
  Flex,
  Heading,
  HStack,
  Spacer,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Text,
} from '@chakra-ui/react';
import * as Common from '@piccolohealth/pbs-common';
import { NumberedBadge, Spin, useRequiredParams } from '@piccolohealth/ui';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Error } from '../../components/generic/Error';
import { TabsRouter } from '../../components/generic/TabsRouter';
import { ContentLayout } from '../../components/layouts/ContentLayout';
import { useParticipantMinimalQuery } from '../../graphql/hooks/useParticipantQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { useRole } from '../../hooks/useRole';
import { ParticipantBehavioursBadge } from '../behaviours/components/ParticipantBehavioursBadge';
import { ParticipantBehaviours } from '../behaviours/ParticipantBehaviours';
import { ParticipantDashboard } from '../dashboard/ParticipantDashboard';
import { ParticipantFilesAndDocuments } from '../files/ParticipantFilesAndDocuments';
import { ParticipantNotes } from '../notes/ParticipantNotes';
import { ParticipantNotesBadge } from '../notes/ParticipantNotesBadge';
import { ParticipantDataBadge } from '../participantData/components/ParticipantDataBadge';
import { ParticipantData } from '../participantData/ParticipantData';
import { ParticipantDocumentsBadge } from '../participantDocument/ParticipantDocumentsBadge';
import { ParticipantTimelineControl } from '../participantTimeline/ParticipantTimelineControl';
import { Programs } from '../program/Programs';
import { Sessions } from '../session/Sessions';

export const Participant = () => {
  const { participantId } = useRequiredParams<{ participantId: string }>();

  const { organization } = useAppContext();
  const hasSupportRole = useRole(Common.Role.Support);
  const navigate = useNavigate();
  const hasBehavioursPermission =
    organization.hasFeature(Common.FeatureFlag.Behaviours) || hasSupportRole;

  const { data, error, isLoading } = useParticipantMinimalQuery({
    organizationId: organization.id,
    participantId,
  });

  const participant = data?.organization?.participant;

  if (isLoading) {
    return <Spin />;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (!participant) {
    return (
      <Error
        error={
          new Common.PiccoloError({
            type: 'ParticipantNotFound',
            message: 'Participant not found',
          })
        }
      />
    );
  }

  const isDisabled = Common.isParticipantDisabled(participant.status);

  return (
    <ContentLayout
      headerContent={
        <HStack>
          <Heading size="lg">{Common.renderParticipantName(participant)}</Heading>
          <Spacer />
          <ParticipantTimelineControl participantId={participant.id} />
        </HStack>
      }
    >
      <Flex h="full">
        <Stack spacing={6} h="full" w="full">
          <TabsRouter
            variant="solid"
            defaultIndex={0}
            size="sm"
            w="full"
            h="full"
            display="flex"
            flexDir="column"
            isLazy
            lazyBehavior="keepMounted"
            routes={['dashboard', 'documents', 'behaviours', 'data', 'notes']}
          >
            <Box px={6}>
              <TabList>
                <Tab key="dashboard">Dashboard</Tab>
                {/* Workaround to navigate to an nested route using TabsRouter.
                Chakra doesnt let you customise the onClick hamdlers nicely. */}
                <Tab key="documents" onClick={() => navigate(`documents`)}>
                  <HStack>
                    <Text>Documents</Text>
                    <ParticipantDocumentsBadge participantId={participant.id} />
                  </HStack>
                </Tab>
                <Tab key="behaviours" isDisabled={!hasBehavioursPermission}>
                  <HStack>
                    <Text>Behaviours</Text>
                    <ParticipantBehavioursBadge participantId={participant.id} />
                  </HStack>
                </Tab>
                <Tab key="data" isDisabled={!hasBehavioursPermission}>
                  <HStack>
                    <Text>Data</Text>
                    <ParticipantDataBadge participantId={participant.id} />
                  </HStack>
                </Tab>
                <Tab key="notes" onClick={() => navigate('notes')}>
                  <HStack>
                    <Text>Notes</Text>
                    <ParticipantNotesBadge participantId={participant.id} />
                  </HStack>
                </Tab>
              </TabList>
            </Box>
            <TabPanels overflowY="auto" h="full" w="full">
              <TabPanel h="full">
                <ParticipantDashboard participantId={participant.id} isDisabled={isDisabled} />
              </TabPanel>
              <TabPanel h="full">
                <ParticipantFilesAndDocuments
                  participantId={participant.id}
                  isDisabled={isDisabled}
                />
              </TabPanel>
              <TabPanel h="full">
                <ParticipantBehaviours participantId={participant.id} />
              </TabPanel>
              <TabPanel h="full">
                <ParticipantData participantId={participant.id} />
              </TabPanel>
              <TabPanel>
                <ParticipantNotes participantId={participant.id} isDisabled={isDisabled} />
              </TabPanel>
            </TabPanels>
          </TabsRouter>
        </Stack>
      </Flex>
    </ContentLayout>
  );
};
