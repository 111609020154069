import * as Common from '@piccolohealth/pbs-common';
import React from 'react';
import { useOrganization } from '../context/OrganizationContext';
import { useUser } from '../context/UserContext';

export const usePermission = (actions: Common.Actions, subject: Common.Subjects): boolean => {
  const user = useUser();
  const organization = useOrganization();

  const ability: Common.AppAbility = React.useMemo(() => {
    const organizationMembership = user.organizationMemberships.find(
      (o) => o.organizationId === organization.id,
    );
    const roles = organizationMembership?.roles ?? [];

    const userInfo: Common.UserInfo = {
      user,
      organizationId: organization.id,
      participants: [],
      incidents: {
        all: [],
        reviewer: [],
        reporter: [],
      },
      roles,
    };

    return Common.defineAbilityFor(userInfo);
  }, [organization.id, user]);

  return ability.can(actions, subject);
};
