import { Button, Divider, Stack, useDisclosure } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  AggregationType,
  BarTimeSeries,
  DataSource,
  LineTimeSeries,
  MethodType,
  ScatterTimeSeries,
  SymbolType,
  TimeSeries,
  Trendline,
  TrendlineType,
} from '@piccolohealth/pbs-common';
import { FloatingPopover } from '@piccolohealth/ui';
import { uuid } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FaPlus } from 'react-icons/fa';
import { z } from 'zod';
import { FormSection } from '../../../../components/forms/FormSection';
import { HookedSubmitButton } from '../../../../components/forms/HookedSubmitButton';
import { UseTimeseriesChartReturn } from '../../../participantData/hooks/useTimeseriesChart';
import {
  AGGREGATION_TYPE_OPTIONS,
  COLORS,
  METHOD_TYPE_OPTIONS,
  SYMBOL_TYPE_OPTIONS,
} from '../../../participantData/utils';
import { TimeSeriesForm } from './TimeSeriesForm';

type FormValues = TimeSeries;

export const trendlineSchema: z.ZodSchema<Trendline> = z.object({
  __typename: z.literal('Trendline'),
  id: z.string(),
  name: z.string().nonempty('Please enter a name'),
  type: z.nativeEnum(TrendlineType),
  color: z.string(),
});

const sourceSchema: z.ZodSchema<DataSource> = z.object({
  __typename: z.literal('ParticipantBehaviourDataSource'),
  method: z.nativeEnum(MethodType),
  participantBehaviourId: z.string(),
});

const lineTimeSeriesSchema: z.ZodSchema<LineTimeSeries> = z.object({
  __typename: z.literal('LineTimeSeries'),
  id: z.string(),
  source: sourceSchema,
  color: z.string(),
  aggregation: z.nativeEnum(AggregationType),
  symbol: z.nativeEnum(SymbolType),
  trendline: z.nullable(trendlineSchema),
});

const scatterTimeSeriesSchema: z.ZodSchema<ScatterTimeSeries> = z.object({
  __typename: z.literal('ScatterTimeSeries'),
  id: z.string(),
  source: sourceSchema,
  color: z.string(),
  aggregation: z.nativeEnum(AggregationType),
  symbol: z.nativeEnum(SymbolType),
  trendline: z.nullable(trendlineSchema),
});

const barTimeSeriesSchema: z.ZodSchema<BarTimeSeries> = z.object({
  __typename: z.literal('BarTimeSeries'),
  id: z.string(),
  source: sourceSchema,
  color: z.string(),
  symbol: z.nativeEnum(SymbolType),
  aggregation: z.nativeEnum(AggregationType),
  trendline: z.nullable(trendlineSchema),
});

export const timeSeriesSchema: z.ZodSchema<TimeSeries> = z.union([
  barTimeSeriesSchema,
  lineTimeSeriesSchema,
  scatterTimeSeriesSchema,
]);

interface TimeSeriesAddFormProps {
  participantBehaviourId: string;
  chart: UseTimeseriesChartReturn;
  onClose: () => void;
}

export const TimeSeriesAddForm = (props: TimeSeriesAddFormProps) => {
  const { participantBehaviourId, chart, onClose } = props;

  const initialValues: TimeSeries = {
    __typename: 'LineTimeSeries',
    id: uuid(),
    source: {
      __typename: 'ParticipantBehaviourDataSource',
      method: METHOD_TYPE_OPTIONS[0].raw,
      participantBehaviourId,
    },
    color: COLORS.red,
    aggregation: AGGREGATION_TYPE_OPTIONS[0].raw,
    symbol: SYMBOL_TYPE_OPTIONS[0].raw,
    trendline: null,
  };

  const methods = useForm<TimeSeries>({
    defaultValues: initialValues,
    resolver: zodResolver(timeSeriesSchema),
  });

  const { reset } = methods;

  const onSubmit = (values: FormValues) => {
    chart.onAddSeries(values);
    reset({
      ...initialValues,
      id: uuid(),
    });
    onClose();
  };

  return (
    <Stack
      bg="white"
      spacing={0}
      w="lg"
      layerStyle="bordered"
      shadow="popover"
      rounded="md"
      overflow="hidden"
      p={4}
    >
      <FormProvider {...methods}>
        <FormSection as="form" heading="Add series" onSubmit={methods.handleSubmit(onSubmit)}>
          <Divider />
          <TimeSeriesForm chart={chart} />
          <Divider />
          <HookedSubmitButton w="full" size="sm" colorScheme="purple" isDisabled={false}>
            Add
          </HookedSubmitButton>
        </FormSection>
      </FormProvider>
    </Stack>
  );
};

interface Props {
  participantBehaviourId: string;
  chart: UseTimeseriesChartReturn;
}

export const TimeSeriesAddControl = (props: Props) => {
  const { participantBehaviourId, chart } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <FloatingPopover
      open={isOpen}
      setOpen={(v) => (v ? onOpen() : onClose())}
      render={() => (
        <TimeSeriesAddForm
          participantBehaviourId={participantBehaviourId}
          chart={chart}
          onClose={onClose}
        />
      )}
    >
      <Button
        rightIcon={<FaPlus />}
        variant="outline"
        borderStyle="dashed"
        size="sm"
        onClick={onOpen}
      >
        Add series
      </Button>
    </FloatingPopover>
  );
};
