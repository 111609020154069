import { Avatar, AvatarProps, Button, Stack } from '@chakra-ui/react';
import { blobToBase64, FileInput, readResizedImage } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useController } from 'react-hook-form';

interface Props extends Omit<AvatarProps, 'name'> {
  name: string;
  username?: string;
  direction?: 'row' | 'column';
  isDisabled?: boolean;
}

export const HookedAvatarUploader = (props: Props) => {
  const { name, username, size, direction, isDisabled } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({ name });

  const onChange = React.useCallback(
    async (files: File[]) => {
      const file = P.first(files);
      if (file) {
        const image = await readResizedImage(file, { maxWidth: 96, maxHeight: 96 });
        const base64 = await blobToBase64(image);
        inputProps.onChange(base64);
      } else {
        inputProps.onChange(null);
      }
    },
    [inputProps],
  );

  return (
    <Stack spacing={1} direction={direction}>
      <Avatar name={username} size={size} src={inputProps.value} bg="gray.300" />
      {inputProps.value ? (
        <Button variant="link" size="xs" onClick={() => onChange([])}>
          Remove
        </Button>
      ) : (
        <FileInput
          files={inputProps.value}
          onFilesChange={onChange}
          multiple={false}
          variant="link"
          size="xs"
          isDisabled={isDisabled}
        >
          Choose
        </FileInput>
      )}
    </Stack>
  );
};
