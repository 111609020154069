import {
  Text,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Square,
} from '@chakra-ui/react';
import { Dot } from '@piccolohealth/ui';
import { Editor } from '@tiptap/react';
import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { COLORS } from '../utils';

interface Props {
  editor: Editor;
}

export const TableBackgroundSelect = (props: Props) => {
  const { editor } = props;

  const items = [{ hex: 'transparent', title: 'No fill' }, ...COLORS];

  const action = (color: string) => {
    editor.chain().setCellAttribute('backgroundColor', color).run();
  };

  const activeItem =
    items.find((item) => editor.isActive('tableCell', { backgroundColor: item.hex })) ?? items[0];

  const buttonBorder =
    activeItem.hex === 'transparent' ? { borderWidth: '2px', borderColor: 'black' } : {};

  return (
    <Menu placement="bottom" isLazy>
      <MenuButton
        as={IconButton}
        icon={<Square bg={activeItem.hex} size={3} rounded="sm" {...buttonBorder} />}
        variant="ghost"
        size="sm"
        rounded="md"
        bg="white"
        fontSize="18px"
        fontWeight="light"
      />

      <MenuList minW="130px">
        {items.map((item) => {
          return (
            <MenuItem key={item.title} onClick={() => action(item.hex)} fontSize="xs" py={1} px={1}>
              <HStack w="full">
                <Dot bg={item.hex} size={3} />
                <Text>{item.title}</Text>
                <Spacer />
                {activeItem === item && <FaCheck fontSize="10px" />}
              </HStack>
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
};
