import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Icon } from '@chakra-ui/react';
import React from 'react';
import { FaAngleRight, FaHome } from 'react-icons/fa';
import { NavLink, useMatches } from 'react-router-dom';

type RouteHandle = {
  crumb: (data: any) => React.ReactElement | undefined;
};

export const Breadcrumbs = () => {
  const matches = useMatches();

  const breadcrumbs = matches
    .filter((match) => match.handle && (match.handle as RouteHandle).crumb)
    .map((match) => ({
      breadcrumb: (match.handle as RouteHandle).crumb(match.data),
      match,
    }));

  return (
    <Breadcrumb separator={<FaAngleRight />}>
      <BreadcrumbItem color="secondary" fontWeight="semibold" lineHeight={0} fontSize="lg">
        <BreadcrumbLink as={NavLink} to={'/'}>
          <Icon as={FaHome} />
        </BreadcrumbLink>
      </BreadcrumbItem>
      {breadcrumbs.map(({ match, breadcrumb }, index) => (
        <BreadcrumbItem
          key={match.pathname}
          color={index === breadcrumbs.length - 1 ? 'gray.700' : 'secondary'}
          fontWeight="semibold"
        >
          <BreadcrumbLink as={NavLink} to={match.pathname}>
            {breadcrumb}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};
