import gql from 'graphql-tag';

export const DeleteParticipantFieldGroupTemplate = gql`
  mutation DeleteParticipantFieldGroupTemplate(
    $organizationId: ID!
    $participantFieldGroupTemplateId: ID!
  ) {
    deleteParticipantFieldGroupTemplate(
      organizationId: $organizationId
      participantFieldGroupTemplateId: $participantFieldGroupTemplateId
    )
  }
`;
