import { Button, ButtonGroup, Heading, HStack, Icon, Spacer, Stack } from '@chakra-ui/react';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { showModal } from '../../components/generic/Modal';
import { ContentLayout } from '../../components/layouts/ContentLayout';
import { useUsersFilterQuery } from '../../graphql/hooks/useUsersQuery';
import { useUsersFilter } from '../../hooks/useUsersFilter';
import { UserInviteModal } from './UserInviteModal';
import { UsersFilterControls } from './UsersFilterControls';
import { UsersTable } from './UsersTable';

export const Users = () => {
  const filter = useUsersFilter();

  const { isLoading, error, users, refetch, pagination } = useUsersFilterQuery(filter);

  return (
    <ContentLayout
      headerContent={
        <HStack align="start">
          <Heading size="lg">Users</Heading>
          <Spacer />
          <ButtonGroup>
            <Button
              leftIcon={<Icon as={FaPlus} />}
              colorScheme="purple"
              size="sm"
              onClick={() => showModal(UserInviteModal)}
            >
              Add user
            </Button>
          </ButtonGroup>
        </HStack>
      }
    >
      <Stack spacing={4} px={6}>
        <UsersFilterControls filter={filter} />
        <UsersTable
          error={error}
          isLoading={isLoading}
          users={users}
          refetch={refetch}
          pagination={pagination}
        />
      </Stack>
    </ContentLayout>
  );
};
