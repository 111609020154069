import { Text } from '@chakra-ui/react';
import { WebSocketStatus } from '@hocuspocus/provider';
import { ScrollAreaAutosize } from '@piccolohealth/ui';
import Collaboration from '@tiptap/extension-collaboration';
import CollaborationCursor from '@tiptap/extension-collaboration-cursor';
import { Editor as TiptapEditor, Extensions } from '@tiptap/react';
import React from 'react';
import { Editor } from '../../components/tiptap/Editor';
import { useEditor } from '../../components/tiptap/hooks/useEditor';
import { useHocuspocusProvider } from '../../components/tiptap/hooks/useHocuspocusProvider';
import { EditorLayout } from '../../components/tiptap/layout/EditorLayout';
import { TableOfContentView } from '../../components/tiptap/TableOfContentView';

interface Props {
  documentId: string;
  extensions: Extensions;
  isEditable?: boolean;
  title?: (options: {
    editor: TiptapEditor;
    isSaving: boolean;
    status: WebSocketStatus;
  }) => React.ReactNode;
}

export const DocumentTemplateEditor = (props: Props) => {
  const { title, documentId, extensions, isEditable } = props;

  const { provider, user, status, isSaving } = useHocuspocusProvider({
    documentId,
  });

  const { editor } = useEditor(
    {
      isEditable,
      extensions: [
        ...extensions,
        Collaboration.configure({
          document: provider.document,
        }),
        CollaborationCursor.configure({
          provider,
          user,
        }),
      ],
    },
    [isEditable],
  );

  if (!editor) {
    return null;
  }

  return (
    <EditorLayout
      documentId={documentId}
      status={status}
      headerContent={title?.({ editor, isSaving, status })}
      isSidebarOpen={true}
      mainContent={<Editor editor={editor} />}
      sidebarContent={
        <>
          <Text px={3} pt={2} fontWeight="semibold">
            Outline
          </Text>
          <ScrollAreaAutosize h="full" overflowY="auto">
            <TableOfContentView editor={editor} />
          </ScrollAreaAutosize>
        </>
      }
    />
  );
};
