import { Role, User } from '@piccolohealth/pbs-common';
import {
  MultiSelect,
  MultiSelectOption,
  MultiSelectProps,
  OnChangeRequest,
  PaginationSelectFooter,
} from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { useUsersQuery } from '../../graphql/hooks/useUsersQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { UserChooserSelectComponents } from './UserChooser';

export interface UserMultiChooserProps
  extends Omit<MultiSelectProps<string>, 'onChange' | 'options' | 'selectedValues'> {
  participantId: string;
  value: string[];
  roles?: Role[];
  onChange: (value: string[]) => void;
}

export const UserMultiChooser = (props: UserMultiChooserProps) => {
  const { participantId, value, roles, onChange, ...rest } = props;

  const { organization } = useAppContext();

  const { data, isFetching, isFetched, refetch } = useUsersQuery(
    {
      organizationId: organization.id,
      request: {
        participantId,
        roles,
        pagination: {
          limit: 10000,
        },
      },
    },
    {
      enabled: !P.isEmpty(value ?? []),
      keepPreviousData: true,
      cacheTime: 0,
    },
  );

  const pagination = organization?.users?.pagination;

  const options: MultiSelectOption<User>[] = React.useMemo(() => {
    return ((data?.organization?.users.results as User[]) ?? []).map((user) => {
      return {
        value: user.id,
        label: user.name,
        raw: user,
      };
    });
  }, [data?.organization?.users.results]);

  const onChangePrime = React.useCallback(
    (req: OnChangeRequest<User>) => {
      onChange(req.values.map((item) => item.value));
    },
    [onChange],
  );

  const selectedValues = React.useMemo(() => {
    return (value ?? []).flatMap((v) => {
      return options.find((o) => P.isEqual(o.raw.id, v)) ?? [];
    });
  }, [options, value]);

  const onOpen = React.useCallback(() => {
    if (!isFetched) {
      refetch();
    }
  }, [isFetched, refetch]);

  return (
    <MultiSelect
      {...rest}
      options={options}
      selectedValues={selectedValues}
      isLoading={isFetching}
      onChange={onChangePrime}
      onOpen={onOpen}
      components={{
        Option: UserChooserSelectComponents.Option,
        Value: UserChooserSelectComponents.Value,
        Footer: () =>
          pagination ? (
            <PaginationSelectFooter
              items="user"
              hasMore={false}
              total={pagination.total}
              isLoading={isFetching}
              fetchNextPage={P.noop}
            />
          ) : null,
      }}
    />
  );
};
