import { Alert, AlertIcon, Text } from '@chakra-ui/react';
import {
  isParticipantDisabled,
  ParticipantStatus,
  renderParticipantStatus,
} from '@piccolohealth/pbs-common';
import React from 'react';

interface Props {
  status: ParticipantStatus;
}

export const ParticipantDisabledStatus = (props: Props) => {
  const { status } = props;

  const isDisabled = isParticipantDisabled(status);
  const renderedStatus = renderParticipantStatus(status);

  if (!isDisabled) {
    return null;
  }

  return (
    <Alert status="info">
      <AlertIcon />
      <Text fontSize="sm" fontWeight="semibold">
        {`This participant's status is ${renderedStatus}. It is now read-only and cannot be edited. If
        you would like to make changes, you must change the status from ${renderedStatus}.`}
      </Text>
    </Alert>
  );
};
