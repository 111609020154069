import gql from 'graphql-tag';

export const UpdateOrganization = gql`
  mutation UpdateOrganization(
    $organizationId: ID!
    $updateOrganizationRequest: UpdateOrganizationRequest!
  ) {
    updateOrganization(organizationId: $organizationId, request: $updateOrganizationRequest) {
      id
      name
      logo
      timezone
      locale
      featureFlags
    }
  }
`;
