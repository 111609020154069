import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Template, TemplateStatus, UpdateTemplateRequest } from '@piccolohealth/pbs-common';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { HookedSubmitButton } from '../../components/forms/HookedSubmitButton';
import { createModal } from '../../components/generic/Modal';
import { useUpdateTemplateMutation } from '../../graphql/hooks/useUpdateTemplateMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { TemplateForm } from './TemplateForm';

type FormValues = UpdateTemplateRequest;

const schema: z.ZodSchema<FormValues> = z.object({
  name: z.string().nullish(),
  labelIds: z.array(z.string()).nullish(),
  status: z.nativeEnum(TemplateStatus).nullish(),
});

interface Props {
  template: Template;
}

export const TemplateEditModal = createModal<Props>((props) => {
  const { template, modal } = props;
  const { errorToast, successToast, organization } = useAppContext();

  const { hide, visible } = modal;

  const initialValues: Partial<FormValues> = {
    name: template.name,
    labelIds: template.labels.map((label) => label.id),
    status: template.status,
  };

  const methods = useForm({
    defaultValues: initialValues,
    mode: 'all',
    resolver: zodResolver(schema),
  });

  const mutation = useUpdateTemplateMutation();

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      await mutation
        .mutateAsync({
          organizationId: organization.id,
          templateId: template.id,
          request: values,
        })
        .then(() => {
          hide();
          successToast('Template updated successfully');
        })
        .catch((err) => {
          errorToast(`Error updating template: ${err.message}`);
        });
    },
    [mutation, organization.id, template.id, hide, successToast, errorToast],
  );

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={modal.remove} size="xl">
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <Box as="form" display="contents" onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalHeader>
              <Flex align="start">
                <Box>
                  <Heading size="md">Edit a template</Heading>
                  <Text fontSize="md" fontWeight="normal" color="secondary" mt={2}>
                    Please complete the following to proceed
                  </Text>
                </Box>
                <Spacer />
                <ModalCloseButton position="unset" top="unset" right="unset" />
              </Flex>
            </ModalHeader>
            <ModalBody>
              <TemplateForm isEditing />
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button size="sm" onClick={hide}>
                  Close
                </Button>
                <HookedSubmitButton>Save</HookedSubmitButton>
              </HStack>
            </ModalFooter>
          </Box>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
