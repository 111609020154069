import { Tag, TagLabel, TagProps } from '@chakra-ui/react';
import { Method } from '@piccolohealth/pbs-common';
import React from 'react';

export const methodColorName = (method: Pick<Method, '__typename'>) => {
  switch (method.__typename) {
    case 'AbcMethod':
      return ['blue', 'ABC'];
    case 'DurationMethod':
      return ['yellow', 'Duration'];
    case 'EpisodicSeverityMethod':
      return ['green', 'Episodic Severity'];
    case 'FrequencyMethod':
      return ['red', 'Frequency'];
    default:
      return ['gray', 'Unimplemented method'];
  }
};

interface Props extends TagProps {
  method: Pick<Method, '__typename'>;
}

export const MethodTag = (props: Props) => {
  const { method, ...rest } = props;

  const [color, label] = methodColorName(method);

  return (
    <Tag size="sm" colorScheme={color} {...rest}>
      <TagLabel>{label}</TagLabel>
    </Tag>
  );
};
