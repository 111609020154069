import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Incident, IncidentStatus } from '@piccolohealth/pbs-common';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FaAngleDown } from 'react-icons/fa';
import { FormStack } from '../../components/forms/FormStack';
import { HookedFormItem } from '../../components/forms/HookedFormItem';
import { HookedSubmitButton } from '../../components/forms/HookedSubmitButton';
import { HookedTextArea } from '../../components/forms/HookedTextArea';
import { createModal, showModal } from '../../components/generic/Modal';
import { useUpdateIncidentStatusMutation } from '../../graphql/hooks/useUpdateIncidentStatusMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { usePermission } from '../../hooks/usePermission';
import { incidentStatusColorName } from './IncidentStatusTag';

interface FormValues {
  actions: string;
}

interface Props {
  incident: Incident;
}

const ReviewedModal = createModal<Props>((props) => {
  const { incident, modal } = props;
  const { organization, successToast, errorToast } = useAppContext();

  const methods = useForm<FormValues>({
    defaultValues: {
      actions: incident.actions ?? undefined,
    },
  });

  const mutation = useUpdateIncidentStatusMutation();

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      await mutation
        .mutateAsync({
          organizationId: organization.id,
          incidentId: incident.id,
          request: {
            status: IncidentStatus.Reviewed,
            actions: values.actions,
          },
        })
        .then(() => {
          successToast('Incident marked as reviewed');
          modal.hide();
        })
        .catch(() => {
          errorToast('Error updating incident status');
        });
    },
    [mutation, organization.id, incident.id, modal, successToast, errorToast],
  );

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove} size="xl">
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <Box as="form" display="contents" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <ModalHeader>Do you want to mark the incident as reviewed?</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormStack>
                <HookedFormItem
                  name="actions"
                  label="Actions to be taken"
                  helperText="Describe what actions will be taken, and who is responsible, to prevent recurrence"
                >
                  <HookedTextArea
                    name="actions"
                    placeholder='e.g. "Incident report to be reviewed by manager"'
                  />
                </HookedFormItem>
              </FormStack>
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button size="sm" onClick={modal.hide}>
                  Close
                </Button>
                <HookedSubmitButton size="sm">Review</HookedSubmitButton>
              </HStack>
            </ModalFooter>
          </Box>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});

const AwaitingReviewModal = createModal<Props>((props) => {
  const { incident, modal } = props;
  const { organization, successToast, errorToast } = useAppContext();

  const mutation = useUpdateIncidentStatusMutation();

  const onClick = React.useCallback(() => {
    mutation
      .mutateAsync({
        organizationId: organization.id,
        incidentId: incident.id,
        request: {
          status: IncidentStatus.AwaitingReview,
        },
      })
      .then(() => {
        successToast('Incident marked as awaiting review');
        modal.hide();
      })
      .catch(() => {
        errorToast('Error updating incident status');
      });
  }, [mutation, organization.id, incident.id, modal, successToast, errorToast]);

  return (
    <Modal isOpen={modal.visible} onClose={modal.hide} onCloseComplete={modal.remove} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Do you want to mark the incident as awaiting review?</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={4}>
            <Text>Are you sure you want to continue?</Text>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup size="sm">
            <Button onClick={modal.hide}>Close</Button>
            <Button onClick={onClick} isLoading={mutation.isLoading} colorScheme="purple">
              OK
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

const AwaitingReviewMenuItemOption = (props: Props) => {
  const { incident } = props;
  const hasPermission = true;

  const isChecked = incident.status === IncidentStatus.AwaitingReview;
  const isDisabled = !hasPermission || isChecked;

  return (
    <MenuItemOption
      onClick={() => showModal(AwaitingReviewModal, { incident })}
      isDisabled={isDisabled}
      isChecked={isChecked}
    >
      Awaiting review
    </MenuItemOption>
  );
};

const ReviewedMenuItemOption = (props: Props) => {
  const { incident } = props;
  const hasPermission = true;

  const isChecked = incident.status === IncidentStatus.Reviewed;
  const isDisabled = !hasPermission || isChecked;

  return (
    <MenuItemOption
      onClick={() => showModal(ReviewedModal, { incident })}
      isDisabled={isDisabled}
      isChecked={isChecked}
    >
      Reviewed
    </MenuItemOption>
  );
};

export const IncidentUpdateStatusControl = (props: Props) => {
  const { incident } = props;

  const [_color, name] = incidentStatusColorName(incident.status);

  const hasPermission = usePermission('review', 'incident');

  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        as={Button}
        isDisabled={!hasPermission}
        rightIcon={<FaAngleDown />}
        colorScheme="purple"
        size="sm"
      >
        {name}
      </MenuButton>
      <MenuList>
        <MenuOptionGroup type="radio">
          <AwaitingReviewMenuItemOption incident={incident} />
          <ReviewedMenuItemOption incident={incident} />
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};
