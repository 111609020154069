import gql from 'graphql-tag';

export const Labels = gql`
  query Labels($organizationId: ID!, $type: [LabelType!]!) {
    organization(organizationId: $organizationId) {
      id
      labels(type: $type) {
        id
        type
        name
        description
        color
      }
    }
  }
`;
