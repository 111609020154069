import React from 'react';

import { Box } from '@chakra-ui/react';
import type { EChartsOption } from 'echarts';
import type EChartsReact from 'echarts-for-react';
import type { EChartsInstance } from 'echarts-for-react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart, LineChart, ScatterChart } from 'echarts/charts';
import {
  DatasetComponent,
  DataZoomComponent,
  DataZoomSliderComponent,
  GraphicComponent,
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  TimelineComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { forwardRef } from 'react';
import { useMeasure } from 'react-use';

echarts.use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  LineChart,
  ScatterChart,
  TimelineComponent,
  LegendComponent,
  DataZoomComponent,
  DataZoomSliderComponent,
  DatasetComponent,
  MarkLineComponent,
  GraphicComponent,
]);

interface Props {
  options: EChartsOption;
  onChartReady: (instance: EChartsInstance) => void;
}

export const ECharts = forwardRef<EChartsReact, Props>((props, ref) => {
  const { options, onChartReady } = props;

  const [containerRef, size] = useMeasure<HTMLDivElement>();
  const isVisible = size.height > 0 && size.width > 0;

  return (
    <Box ref={containerRef} h="full" w="full">
      <ReactEChartsCore
        ref={ref}
        echarts={echarts}
        notMerge={true}
        lazyUpdate={true}
        onChartReady={onChartReady}
        option={options}
        style={{
          width: '100%',
          height: '100%',
          visibility: isVisible ? 'visible' : 'hidden',
        }}
      />
    </Box>
  );
});
