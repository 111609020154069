import { User } from '@piccolohealth/pbs-common';
import { useDebouncedCallback } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { PaginationFilter } from '../graphql/fetcher';

const DEFAULT_PAGE_NUMBER = 0;
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_NAME_FILTER = '';
const DEFAULT_LABEL_FILTER: string[] = [];
const DEFAULT_LOCATION_FILTER: string[] = [];

export interface UserParticipantsFilterRequest {
  user: User;
}

type Params = Partial<{
  page: number;
  pageSize: number;
  name: string;
  label: string[];
  location: string[];
}>;

export interface UserParticipantsFilter extends PaginationFilter {
  user: User;
  nameFilter: string;
  locationFilter: string[] | undefined;
  labelFilter: string[] | undefined;
  activeFilterCount: number;
  onNameFilter: (name: string) => void;
  onLocationFilter: (locations?: string[]) => void;
  onLabelFilter: (labels?: string[]) => void;
  reset: () => void;
}

export const useUserParticipantsFilter: (
  request: UserParticipantsFilterRequest,
) => UserParticipantsFilter = (request: UserParticipantsFilterRequest) => {
  const [params, setParams] = React.useState<Params>({
    page: DEFAULT_PAGE_NUMBER,
    pageSize: DEFAULT_PAGE_SIZE,
    name: undefined,
    location: undefined,
    label: undefined,
  });

  const setCurrentPageNumber = (value: number) => {
    setParams((prev) => ({
      ...prev,
      page: value,
    }));
  };

  const setPageSize = (value: number) =>
    setParams((prev) => ({
      ...prev,
      pageSize: value,
      page: 0,
    }));

  const onNameFilter = useDebouncedCallback(
    (value: string) =>
      setParams((prev) => ({
        ...prev,
        page: 0,
        name: value === '' ? undefined : value,
      })),
    1000,
  );

  const onLocationFilter = (value?: string[]) =>
    setParams((prev) => ({
      ...prev,
      page: 0,
      location: P.isEmpty(value ?? []) ? undefined : value,
    }));

  const onLabelFilter = (value?: string[]) =>
    setParams((prev) => ({
      ...prev,
      page: 0,
      label: P.isEmpty(value ?? []) ? undefined : value,
    }));

  const showTotal = (total: number, range: [number, number]) =>
    `${range[0]}-${range[1]} of ${total} participants`;

  const reset = () => {
    setParams({
      page: DEFAULT_PAGE_NUMBER,
      pageSize: DEFAULT_PAGE_SIZE,
      name: undefined,
      location: undefined,
      label: undefined,
    });
  };

  const activeFilterCount = P.compact([params.name, params.location, params.label]).length;

  return {
    pageSizeOptions: [10, 20, 50, 100],
    nameFilter: params.name ?? DEFAULT_NAME_FILTER,
    labelFilter: params.label ?? DEFAULT_LABEL_FILTER,
    locationFilter: params.location ?? DEFAULT_LOCATION_FILTER,
    pageSize: params.pageSize ?? DEFAULT_PAGE_SIZE,
    currentPageNumber: params.page ?? DEFAULT_PAGE_NUMBER,
    user: request.user,
    activeFilterCount,
    showTotal,
    setPageSize,
    setCurrentPageNumber,
    onNameFilter,
    onLabelFilter,
    onLocationFilter,
    reset,
  };
};
