import { MenuItem } from '@chakra-ui/react';
import React from 'react';
import { FaDownload } from 'react-icons/fa';
import { useExportIncidentsCsvMutation } from '../../graphql/hooks/useExportIncidentsCsvMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { IncidentsFilter } from '../../hooks/useIncidentsFilter';
import { usePermission } from '../../hooks/usePermission';

interface Props {
  filter: IncidentsFilter;
}

export const IncidentExportCsvMenuItem = (props: Props) => {
  const { filter } = props;
  const { organization, successToast, errorToast } = useAppContext();

  const hasPermission = usePermission('read', 'incident');

  const mutation = useExportIncidentsCsvMutation();

  const onClick = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        request: {
          start: filter.startDateFilter,
          end: filter.endDateFilter,
          label: filter.labelFilter,
          location: filter.locationFilter,
          participant: filter.participantFilter,
          severity: filter.severityFilter,
          status: filter.statusFilter,
        },
      })
      .then((res) => {
        successToast('Incidents exported successfully');
        res && window.open(res.exportIncidentsCsv, '_self');
      })
      .catch((err) => {
        errorToast(`Error exporting incidents: ${err.message}`);
      });
  }, [mutation, organization.id, successToast, errorToast, filter]);

  return (
    <MenuItem isDisabled={!hasPermission} onClick={onClick} icon={<FaDownload />}>
      Export incidents (CSV)
    </MenuItem>
  );
};
