import gql from 'graphql-tag';

export const UpdateIncidentFile = gql`
  mutation UpdateIncidentFile(
    $organizationId: ID!
    $incidentId: ID!
    $incidentFileId: ID!
    $request: UpdateIncidentFileRequest!
  ) {
    updateIncidentFile(
      organizationId: $organizationId
      incidentId: $incidentId
      incidentFileId: $incidentFileId
      request: $request
    ) {
      id
    }
  }
`;
