import { Button, chakra, Flex, Spacer } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

export const AdminNavbar = () => {
  return (
    <chakra.nav>
      <Flex px={4} py={3} w="full" bg="extraDarkPurple">
        <Flex gridColumnGap={4}>
          <Link to={`/admin/onboard`}>
            <Button size="sm" colorScheme="purple">
              Onboard
            </Button>
          </Link>
          <Link to={`/admin/graphiql`}>
            <Button size="sm" colorScheme="purple">
              GraphiQL
            </Button>
          </Link>
        </Flex>
        <Spacer />
        <Flex gridColumnGap={4}></Flex>
      </Flex>
    </chakra.nav>
  );
};
