import { Heading, Tab, TabList, TabPanel, TabPanels } from '@chakra-ui/react';
import React from 'react';
import { TabsRouter } from '../../components/generic/TabsRouter';
import { ContentLayout } from '../../components/layouts/ContentLayout';
import { BasicSettings } from './BasicSettings';
import { FieldSettings } from './FieldSettings';
import { FormSettings } from './FormSettings';
import { LabelSettings } from './LabelSettings';
import { LocationSettings } from './LocationSettings';

export const Settings = () => {
  return (
    <ContentLayout headerContent={<Heading size="lg">Settings</Heading>}>
      <TabsRouter
        variant="soft-rounded"
        orientation="vertical"
        defaultIndex={0}
        size="sm"
        w="full"
        h="full"
        isLazy
        lazyBehavior="keepMounted"
        routes={['basic', 'locations', 'fields', 'labels', 'forms']}
      >
        <TabList minW="120px" flexShrink={0} py={2} ml={6}>
          <Tab key="basic">Basic</Tab>
          <Tab key="locations">Locations</Tab>
          <Tab key="fields">Fields</Tab>
          <Tab key="labels">Labels</Tab>
          <Tab key="forms">Forms</Tab>
        </TabList>
        <TabPanels overflowY="auto" h="full" w="full" px={6} py={2}>
          <TabPanel>
            <BasicSettings />
          </TabPanel>
          <TabPanel>
            <LocationSettings />
          </TabPanel>
          <TabPanel>
            <FieldSettings />
          </TabPanel>
          <TabPanel>
            <LabelSettings />
          </TabPanel>
          <TabPanel>
            <FormSettings />
          </TabPanel>
        </TabPanels>
      </TabsRouter>
    </ContentLayout>
  );
};
