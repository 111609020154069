import gql from 'graphql-tag';

export const Incident = gql`
  query Incident($organizationId: ID!, $incidentId: ID!) {
    organization(organizationId: $organizationId) {
      id
      incident(incidentId: $incidentId) {
        id
        shortId
        organizationId
        location {
          id
          name
          color
          isArchived
        }
        timestamp
        setting
        status
        severity
        reporter {
          id
          name
          email
          title
          picture
        }
        reviewers {
          id
          name
          email
          title
          picture
        }
        participant {
          id
          firstName
          lastName
          ndisNumber
        }
        labels {
          id
          name
          color
          description
        }
        description
        ndisReportableIncident
        witnesses {
          id
          name
          phone
          email
          description
        }
        actions
        createdAt
        updatedAt
      }
    }
  }
`;

export const IncidentMinimal = gql`
  query IncidentMinimal($organizationId: ID!, $incidentId: ID!) {
    organization(organizationId: $organizationId) {
      id
      incident(incidentId: $incidentId) {
        id
        organizationId
        shortId
        status
        severity
      }
    }
  }
`;

export const IncidentFiles = gql`
  query IncidentFiles($organizationId: ID!, $incidentId: ID!) {
    organization(organizationId: $organizationId) {
      id
      incident(incidentId: $incidentId) {
        id
        organizationId
        status
        files {
          id
          incidentId
          name
          uploader {
            id
            name
            email
            title
            picture
          }
          size
          viewUrl
          downloadUrl
          createdAt
          updatedAt
        }
      }
    }
  }
`;
