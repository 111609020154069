import gql from 'graphql-tag';

export const UpdateIncident = gql`
  mutation UpdateIncident(
    $organizationId: ID!
    $incidentId: ID!
    $request: UpdateIncidentRequest!
  ) {
    updateIncident(organizationId: $organizationId, incidentId: $incidentId, request: $request) {
      id
    }
  }
`;
