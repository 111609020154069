import { Button, ButtonGroup, forwardRef, IconButton } from '@chakra-ui/react';
import { TableSeries } from '@piccolohealth/pbs-common';
import React from 'react';
import { FaTimes } from 'react-icons/fa';
import { BadgeItem } from '../../../../components/generic/BadgeItem';
import { UseTableReturn } from '../../hooks/useTable';
import { METHOD_TYPE_OPTIONS_MAP } from '../../utils';

interface Props {
  table: UseTableReturn;
  series: TableSeries;
  onClick: () => void;
}

export const TableSeriesButtonGroup = forwardRef<Props, 'div'>((props, ref) => {
  const { table, series, onClick } = props;

  const onRemove = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    table.onRemoveSeries(series.id);
  };

  const methodTypeOption = METHOD_TYPE_OPTIONS_MAP[series.source.method];

  return (
    <ButtonGroup ref={ref} size="sm" isAttached variant="outline" onClick={onClick}>
      <Button px={1.5}>
        <BadgeItem label={methodTypeOption.label} color={methodTypeOption.color} />
      </Button>
      <IconButton aria-label="Remove method" icon={<FaTimes />} onClick={onRemove} />
    </ButtonGroup>
  );
});
