import {
  ParticipantDocument,
  ParticipantDocumentStatus,
  PiccoloError,
} from '@piccolohealth/pbs-common';
import { useRequiredParams } from '@piccolohealth/ui';
import Collaboration from '@tiptap/extension-collaboration';
import CollaborationCursor from '@tiptap/extension-collaboration-cursor';
import React from 'react';
import { useLocalStorage } from 'react-use';
import { Error } from '../../components/generic/Error';
import { Editor } from '../../components/tiptap/Editor';
import { useEditor } from '../../components/tiptap/hooks/useEditor';
import { useHocuspocusProvider } from '../../components/tiptap/hooks/useHocuspocusProvider';
import { EditorLayout } from '../../components/tiptap/layout/EditorLayout';
import { getParticipantDocumentExtensions } from '../../components/tiptap/utils';
import { useParticipantDocumentQuery } from '../../graphql/hooks/useParticipantQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { ParticipantDocumentSidebar } from './ParticipantDocumentSidebar';
import { ParticipantDocumentTitleBar } from './ParticipantDocumentTitleBar';

interface ParticipantDocumentEditorContentProps {
  participantDocument: ParticipantDocument;
  isEditable: boolean;
}

export const ParticipantDocumentEditorContent = (props: ParticipantDocumentEditorContentProps) => {
  const { participantDocument, isEditable } = props;

  const { config, organization } = useAppContext();

  const imageUploadUrl = `${config.api.url}/organizations/${organization.id}/participants/${participantDocument.participantId}/participantDocuments/${participantDocument.id}/images`;
  const documentId = `participantDocument/${participantDocument.id}`;
  const variables = participantDocument.variables;

  const [sidebarOpen, setIsSidebarOpen] = useLocalStorage('editor-sidebar-open', true);
  const isSidebarOpen = sidebarOpen ?? true;

  const toggleSidebarOpen = React.useCallback(() => {
    setIsSidebarOpen(!isSidebarOpen);
  }, [isSidebarOpen, setIsSidebarOpen]);

  const { provider, user, status, isSaving } = useHocuspocusProvider({
    documentId,
  });

  const extensions = getParticipantDocumentExtensions(variables, imageUploadUrl, provider.document);

  const { editor } = useEditor(
    {
      isEditable,
      extensions: [
        ...extensions,
        Collaboration.configure({
          document: provider.document,
        }),
        CollaborationCursor.configure({
          provider,
          user,
        }),
      ],
    },
    [isEditable],
  );

  if (!editor) {
    return null;
  }

  return (
    <EditorLayout
      documentId={documentId}
      status={status}
      isSidebarOpen={isSidebarOpen}
      headerContent={
        <ParticipantDocumentTitleBar
          editor={editor}
          participantDocument={participantDocument}
          status={status}
          isSaving={isSaving}
          isSidebarOpen={isSidebarOpen}
          toggleSidebarOpen={toggleSidebarOpen}
        />
      }
      mainContent={<Editor editor={editor} />}
      sidebarContent={
        <ParticipantDocumentSidebar participantDocument={participantDocument} editor={editor} />
      }
    />
  );
};

interface Props {
  participantId: string;
  isDisabled?: boolean;
}

export const ParticipantDocumentEditor = (props: Props) => {
  const { participantId, isDisabled } = props;
  const { documentId } = useRequiredParams<{
    documentId: string;
  }>();

  const { organization } = useAppContext();

  const [, setCurrentDocument, unsetCurrentDocument] = useLocalStorage(`${participantId}-document`);

  const { isLoading, error, data } = useParticipantDocumentQuery({
    organizationId: organization.id,
    participantId,
    participantDocumentId: documentId,
  });

  const participantDocument = data?.organization?.participant
    ?.document as ParticipantDocument | null;

  React.useEffect(() => {
    if (participantDocument) {
      setCurrentDocument(participantDocument?.id);
    }
  }, [participantDocument, setCurrentDocument, unsetCurrentDocument]);

  if (isLoading) {
    return null;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (!participantDocument) {
    return (
      <Error
        error={
          new PiccoloError({
            type: 'ParticipantDocumentNotFound',
            message: 'Participant document not found',
          })
        }
      />
    );
  }

  const isEditable = participantDocument.status === ParticipantDocumentStatus.Draft && !isDisabled;

  return (
    <ParticipantDocumentEditorContent
      participantDocument={participantDocument}
      isEditable={isEditable}
    />
  );
};
