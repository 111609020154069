import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { RestoreParticipantNoteVersion } from '../mutations/restoreParticipantNoteVersion.mutation';
import {
  RestoreParticipantNoteVersionMutation,
  RestoreParticipantNoteVersionMutationVariables,
} from '../types';

export const useRestoreParticipantNoteVersionMutation = (
  options?: MutationOptions<
    RestoreParticipantNoteVersionMutation,
    RestoreParticipantNoteVersionMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(RestoreParticipantNoteVersion), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('participant'),
      });
    },
  });
};
