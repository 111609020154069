import { Box, Divider, Heading, HStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { CreateOrganizationRequest, OrganizationStatus } from '@piccolohealth/pbs-common';
import { localeList, timezoneList } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { FormSection } from '../../../components/forms/FormSection';
import { HookedFormItem } from '../../../components/forms/HookedFormItem';
import { HookedImageUploader } from '../../../components/forms/HookedImageUploader';
import { HookedInput } from '../../../components/forms/HookedInput';
import { HookedResetButton } from '../../../components/forms/HookedResetButton';
import { HookedSelect } from '../../../components/forms/HookedSelect';
import { HookedSubmitButton } from '../../../components/forms/HookedSubmitButton';
import { ContentLayout } from '../../../components/layouts/ContentLayout';
import { useCreateOrganizationMutation } from '../../../graphql/hooks/useCreateOrganizationMutation';
import { useAppContext } from '../../../hooks/useAppContext';

export const Onboard = () => {
  const { successToast, errorToast } = useAppContext();

  const mutation = useCreateOrganizationMutation();

  const initialValues: CreateOrganizationRequest = {
    name: '',
    status: OrganizationStatus.Trial,
    logo: null,
    timezone: 'Australia/Brisbane',
    locale: 'en-au',
    supportEmail: 'dom@piccolohealth.com',
    stripeCustomerId: null,
    stripeSubscriptionId: null,
  };

  const validationSchema = z.object({
    name: z.string(),
    status: z.string(),
    logo: z.string().nullable(),
    timezone: z.string(),
    locale: z.string(),
    supportEmail: z.string(),
    stripeCustomerId: z.string().nullable(),
    stripeSubscriptionId: z.string().nullable(),
  });

  const methods = useForm({
    defaultValues: initialValues,
    resolver: zodResolver(validationSchema),
  });

  const onSubmit = async (values: CreateOrganizationRequest) => {
    await mutation
      .mutateAsync({
        createOrganizationRequest: values,
      })
      .then(() => {
        successToast('Organization created successfully');
      })
      .catch((err) => {
        errorToast(`Error creating organization: ${err.message}`);
      });
  };

  const statusOptions = Object.keys(OrganizationStatus).map((key) => {
    return {
      key,
      value: OrganizationStatus[key as keyof typeof OrganizationStatus],
      label: key,
      raw: OrganizationStatus[key as keyof typeof OrganizationStatus],
    };
  });

  const timezoneOptions = timezoneList.map((timezone) => ({
    value: timezone,
    label: timezone,
    raw: timezone,
  }));

  const localeOptions = localeList.map((locale) => ({
    key: locale,
    value: locale,
    label: locale,
    raw: locale,
  }));

  return (
    <ContentLayout headerContent={<Heading size="lg">Onboard</Heading>}>
      <Box maxW="400px" px={6}>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <FormSection heading="Create an organization">
              <HookedFormItem label="Name" name="name">
                <HookedInput name="name" placeholder="Company name" />
              </HookedFormItem>
              <HookedFormItem label="Status" name="status">
                <HookedSelect name="status" options={statusOptions} />
              </HookedFormItem>
              <HookedFormItem label="Logo" name="logo">
                <HookedImageUploader name="logo" maxWidth={160} maxHeight={120} />
              </HookedFormItem>
              <HookedFormItem label="Timezone" name="timezone">
                <HookedSelect name="timezone" options={timezoneOptions} />
              </HookedFormItem>
              <HookedFormItem label="Locale" name="locale">
                <HookedSelect name="locale" options={localeOptions} />
              </HookedFormItem>
              <HookedFormItem label="Support Email" name="supportEmail">
                <HookedInput name="supportEmail" placeholder="Support Email" />
              </HookedFormItem>
              <HookedFormItem label="Stripe Customer ID" name="stripeCustomerId">
                <HookedInput name="stripeCustomerId" placeholder="Stripe Customer ID" />
              </HookedFormItem>
              <HookedFormItem label="Stripe Subscription ID" name="stripeSubscriptionId">
                <HookedInput name="stripeSubscriptionId" placeholder="Stripe Subscription ID" />
              </HookedFormItem>
            </FormSection>
            <Divider my={3} />
            <HStack alignItems="center">
              <HookedResetButton>Reset</HookedResetButton>
              <HookedSubmitButton>Save</HookedSubmitButton>
            </HStack>
          </form>
        </FormProvider>
      </Box>
    </ContentLayout>
  );
};
