import { Stack } from '@chakra-ui/react';
import { FILE_SIZE_LIMIT_32MB_BYTES, renderPiccoloError } from '@piccolohealth/pbs-common';
import { FileUploadControl, useFileUpload } from '@piccolohealth/ui';
import { Editor } from '@tiptap/core';
import axios from 'axios';
import React from 'react';
import { useAppContext } from '../../../../hooks/useAppContext';
import { asPiccoloError } from '../../../../utils/errors';

interface Props {
  editor: Editor;
  url?: string;
  onExit: () => void;
}

export const ImageUploadPage = (props: Props) => {
  const { editor, url, onExit } = props;

  const { auth } = useAppContext();
  const { getAccessTokenSilently } = auth;

  const onAdd = React.useCallback(
    (imageUrl: string | null) => {
      if (imageUrl) {
        editor.chain().focus().setImage({ src: imageUrl }).run();
      }
      onExit();
    },
    [editor, onExit],
  );

  const sendFile = React.useCallback(
    async (
      file: File,
      onProgress: (opts: { percentage: number; total?: number; loaded?: number }) => void,
    ) => {
      const accessToken = await getAccessTokenSilently();

      const formData = new FormData();
      formData.append('files', file);

      if (!url) {
        return;
      }

      const response = await axios(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        onUploadProgress: (event) => {
          onProgress({
            percentage: (event.loaded / event.total) * 100,
            total: event.total,
            loaded: event.loaded,
          });
        },
      }).catch((error) => {
        const piccoloError = asPiccoloError(error);
        piccoloError.message = renderPiccoloError(piccoloError).message;
        throw piccoloError;
      });

      onProgress({
        percentage: 100,
      });
      onAdd(response.data.url);
    },
    [url, getAccessTokenSilently, onAdd],
  );

  const { status, files, onFilesChange } = useFileUpload({
    sendFile,
    concurrency: 1,
    autoStart: true,
    maxFileSize: FILE_SIZE_LIMIT_32MB_BYTES,
  });

  return (
    <Stack w="sm" p={4}>
      <FileUploadControl
        multiple={false}
        files={files}
        status={status}
        onFilesChange={onFilesChange}
        accept="image/*, .heic"
      />
    </Stack>
  );
};
