import gql from 'graphql-tag';

export const ParticipantFieldGroupTemplates = gql`
  query ParticipantFieldGroupTemplates($organizationId: ID!) {
    organization(organizationId: $organizationId) {
      id
      participantFieldGroupTemplates {
        id
        name
        index
        fields {
          id
          name
          type
          attributes
          index
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
    }
  }
`;
