import React from 'react';
import { createModal } from '../../components/generic/Modal';
import { useDeleteParticipantFileMutation } from '../../graphql/hooks/useDeleteParticipantFileMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { FileDeleteModal } from './FileDeleteModal';

interface Props {
  participantId: string;
  participantFileId: string;
}

export const ParticipantFileDeleteModal = createModal<Props>((props) => {
  const { participantId, participantFileId, ...rest } = props;

  const { hide } = rest.modal;
  const { organization, successToast, errorToast } = useAppContext();
  const mutation = useDeleteParticipantFileMutation();

  const onDelete = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        participantId,
        participantFileId,
      })
      .then(async () => {
        hide();
        successToast('File deleted successfully');
      })
      .catch((err) => {
        errorToast(`Error deleting file: ${err.message}`);
      });
  }, [mutation, organization.id, participantId, participantFileId, hide, successToast, errorToast]);

  return <FileDeleteModal onDelete={onDelete} isLoading={mutation.isLoading} {...rest} />;
});
