import { Box } from '@chakra-ui/react';
import { RangeDatepicker } from '@piccolohealth/ui';
import { DateTime } from '@piccolohealth/util';
import React from 'react';

interface Props {
  startDate: DateTime;
  endDate: DateTime;
  onStartDateChange: (date: DateTime) => void;
  onEndDateChange: (date: DateTime) => void;
}

export const ParticipantWidgetTimeRangeControl = (props: Props) => {
  return (
    <Box w="220px">
      <RangeDatepicker
        startDate={props.startDate}
        endDate={props.endDate}
        onStartDateChange={(v) => v && props.onStartDateChange(v)}
        onEndDateChange={(v) => v && props.onEndDateChange(v)}
      />
    </Box>
  );
};
