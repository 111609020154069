import gql from 'graphql-tag';

export const CreateParticipantBehaviour = gql`
  mutation CreateParticipantBehaviour(
    $organizationId: ID!
    $request: CreateParticipantBehaviourRequest!
  ) {
    createParticipantBehaviour(organizationId: $organizationId, request: $request) {
      id
    }
  }
`;
