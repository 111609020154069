import React from 'react';
import { useController } from 'react-hook-form';
import { UserMultiChooser, UserMultiChooserProps } from './UserMultiChooser';

interface Props extends Omit<UserMultiChooserProps, 'onChange' | 'value'> {
  name: string;
}

export const HookedUserMultiChooser = (props: Props) => {
  const { name, ...rest } = props;
  const { field } = useController({ name });

  return <UserMultiChooser {...rest} value={field.value} onChange={field.onChange} />;
};
