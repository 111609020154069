import { Label, LabelType } from '@piccolohealth/pbs-common';
import { OnChangeRequest } from '@piccolohealth/ui';
import React from 'react';
import { useCreateLabelMutation } from '../../graphql/hooks/useCreateLabelMutation';
import { useCreateIncidentLabelMutation } from '../../graphql/hooks/useCreateIncidentLabelMutation';
import { useDeleteIncidentLabelMutation } from '../../graphql/hooks/useDeleteIncidentLabelMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { usePermission } from '../../hooks/usePermission';
import { LabelsControl } from './LabelsControl';

export interface ManageIncidentLabelsControlProps {
  incidentId: string;
  value: string[];
}

export const ManageIncidentLabelsControl = (props: ManageIncidentLabelsControlProps) => {
  const { incidentId, value } = props;
  const { organization, errorToast } = useAppContext();

  const createLabelMutation = useCreateLabelMutation({ retry: false });
  const createIncidentLabelMutation = useCreateIncidentLabelMutation({ retry: false });
  const deleteIncidentLabelMutation = useDeleteIncidentLabelMutation({ retry: false });

  const labelManagePermission = usePermission('manage', 'label');
  const incidentLabelManagePermission = usePermission('manage', 'incidentLabel');

  const onChange = React.useCallback(
    (request: OnChangeRequest<Label>) => {
      switch (request.action) {
        case 'CREATE': {
          return createLabelMutation
            .mutateAsync({
              organizationId: organization.id,
              request: {
                type: LabelType.Incident,
                color: 'blue',
                name: request.value.value,
              },
            })
            .catch((err: Error) => errorToast(`Error creating label ${err.message}`));
        }
        case 'ADD': {
          return createIncidentLabelMutation
            .mutateAsync({
              organizationId: organization.id,
              incidentId,
              labelId: request.value.value,
            })
            .catch((err: Error) => errorToast(`Error adding label to incident: ${err.message}`));
        }

        case 'REMOVE': {
          return deleteIncidentLabelMutation
            .mutateAsync({
              organizationId: organization.id,
              incidentId,
              labelId: request.value.value,
            })
            .catch((err: Error) =>
              errorToast(`Error removing label from incident: ${err.message}`),
            );
        }
      }
    },
    [
      organization,
      incidentId,
      createLabelMutation,
      createIncidentLabelMutation,
      deleteIncidentLabelMutation,
      errorToast,
    ],
  );

  return (
    <LabelsControl
      onChange={onChange}
      value={value}
      isDisabled={!incidentLabelManagePermission}
      canManage={labelManagePermission}
      variant="selectGhost"
      type={LabelType.Incident}
    />
  );
};
