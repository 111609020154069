import { Button, Divider, Flex, Icon, IconButton } from '@chakra-ui/react';
import { CreateParticipantKeyContactRequest, LabelType } from '@piccolohealth/pbs-common';
import { uuid } from '@piccolohealth/util';
import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { FormStack } from './FormStack';
import { HookedFormItem } from './HookedFormItem';
import { HookedInput } from './HookedInput';
import { HookedLabelChooser } from './HookedLabelChooser';

interface Props {
  name: string;
  isDisabled?: boolean;
}

export const HookedKeyContactsControl = (props: Props) => {
  const { name, isDisabled } = props;
  const { fields, append, remove } = useFieldArray({
    name,
  });

  const contacts = fields as unknown as CreateParticipantKeyContactRequest[];

  const onAddContact = React.useCallback(() => {
    append({
      id: uuid(),
      name: null,
      phone: null,
      email: null,
      description: null,
      labelIds: [],
    });
  }, [append]);

  const onRemoveContact = React.useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  return (
    <FormStack>
      {contacts.map((contact, index) => {
        return (
          <>
            <FormStack orientation="horizontal" key={`contact-${contact.id}`} alignItems="stretch">
              <FormStack spacing={4} pl={4} ml={1} borderLeftWidth="2px" borderColor="purple.400">
                <FormStack orientation="horizontal">
                  <HookedFormItem
                    name={`${name}.${index}.name`}
                    label="Name"
                    labelSize="sm"
                    helperText="The contacts name"
                    isDisabled={isDisabled}
                  >
                    <HookedInput
                      name={`${name}.${index}.name`}
                      size="sm"
                      placeholder="Enter a name"
                    />
                  </HookedFormItem>
                  <HookedFormItem
                    name={`${name}.${index}.email`}
                    label="Email"
                    labelSize="sm"
                    helperText="The contacts email"
                    isDisabled={isDisabled}
                  >
                    <HookedInput
                      name={`${name}.${index}.email`}
                      size="sm"
                      placeholder="Enter an email "
                    />
                  </HookedFormItem>
                </FormStack>
                <FormStack orientation="horizontal">
                  <HookedFormItem
                    name={`${name}.${index}.phone`}
                    label="Phone"
                    labelSize="sm"
                    helperText="The contacts phone"
                    isDisabled={isDisabled}
                  >
                    <HookedInput
                      name={`${name}.${index}.phone`}
                      size="sm"
                      placeholder="Enter a phone number"
                    />
                  </HookedFormItem>
                  <HookedFormItem
                    name={`${name}.${index}.description`}
                    label="Description/relationship"
                    labelSize="sm"
                    helperText="Who is the contact?"
                    isDisabled={isDisabled}
                  >
                    <HookedInput
                      name={`${name}.${index}.description`}
                      size="sm"
                      placeholder="Enter a description or relationship"
                    />
                  </HookedFormItem>
                </FormStack>
                <FormStack orientation="horizontal">
                  <HookedFormItem
                    name={`${name}.${index}.labelIds`}
                    label="Labels"
                    labelSize="sm"
                    isDisabled={isDisabled}
                  >
                    <HookedLabelChooser
                      name={`${name}.${index}.labelIds`}
                      type={LabelType.ParticipantKeyContact}
                      size="sm"
                    />
                  </HookedFormItem>
                </FormStack>
              </FormStack>
              <Flex alignItems="center" w="4">
                <IconButton
                  aria-label="Remove key contact"
                  size="xs"
                  variant="ghost"
                  icon={<FaTrash />}
                  isDisabled={isDisabled}
                  onClick={() => onRemoveContact(index)}
                />
              </Flex>
            </FormStack>
            {index !== contacts.length - 1 && <Divider />}
          </>
        );
      })}
      <Button
        size="sm"
        isDisabled={isDisabled}
        onClick={() => onAddContact()}
        leftIcon={<Icon as={FaPlus} />}
      >
        Add a contact
      </Button>
    </FormStack>
  );
};
