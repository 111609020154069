import {
  Button,
  ButtonGroup,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import React from 'react';
import { createModal } from '../../../components/generic/Modal';
import { useDeleteLocationMutation } from '../../../graphql/hooks/useDeleteLocationMutation';
import { useAppContext } from '../../../hooks/useAppContext';

interface Props {
  locationId: string;
}

export const LocationDeleteModal = createModal<Props>((props) => {
  const { locationId, modal } = props;
  const { organization, successToast, errorToast } = useAppContext();
  const mutation = useDeleteLocationMutation({});
  const { hide, remove, visible } = modal;

  const onClick = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        locationId,
      })
      .then(async () => {
        hide();
        successToast('Location deleted successfully');
      })
      .catch((err) => {
        errorToast(`Error deleting location: ${err.message}`);
      });
  }, [mutation, hide, organization, locationId, successToast, errorToast]);

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={remove}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Do you want to delete this location?</ModalHeader>
        <ModalCloseButton />
        <ModalFooter>
          <ButtonGroup size="sm">
            <Button key="close" onClick={hide}>
              Close
            </Button>
            <Button
              key="submit"
              colorScheme="red"
              isLoading={mutation.isLoading}
              loadingText="Deleting..."
              type="submit"
              onClick={onClick}
            >
              Delete
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
