import gql from 'graphql-tag';

export const UpdateForm = gql`
  mutation UpdateForm($organizationId: ID!, $formId: ID!, $request: UpdateFormRequest!) {
    updateForm(organizationId: $organizationId, formId: $formId, request: $request) {
      id
      type
      name
      url
      labelMappings {
        create {
          id
          name
          description
          color
        }
        update {
          id
          name
          description
          color
        }
      }
    }
  }
`;
