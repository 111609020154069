import gql from 'graphql-tag';

export const UpdateParticipantBehaviour = gql`
  mutation UpdateParticipantBehaviour(
    $organizationId: ID!
    $participantBehaviourId: ID!
    $request: UpdateParticipantBehaviourRequest!
  ) {
    updateParticipantBehaviour(
      organizationId: $organizationId
      participantBehaviourId: $participantBehaviourId
      request: $request
    ) {
      id
    }
  }
`;
