import { Box, IconButton } from '@chakra-ui/react';
import { Command, FloatingPopover } from '@piccolohealth/ui';
import { Editor } from '@tiptap/react';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { getEditingPages } from '../extensions/slashmenu/pages';

interface Props {
  editor: Editor;
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}

export const AddNodeMenu = (props: Props) => {
  const { editor, isOpen, setOpen } = props;

  const state = {
    editor: editor,
    range: {
      from: editor.state.selection.anchor,
      to: editor.state.selection.anchor + 1,
    },
  };

  const variables = editor.storage?.variable?.variables ?? [];

  const getPages = getEditingPages(variables ?? []);

  return (
    <FloatingPopover
      isPortal
      shouldInitialFocus
      open={isOpen}
      setOpen={setOpen}
      placement="bottom-start"
      render={({ context }) => (
        <Command.CommandMenu ctx={state} onOpenChange={context.onOpenChange} getPages={getPages} />
      )}
    >
      <Box lineHeight="normal">
        <IconButton
          variant="ghost"
          tabIndex={-1}
          icon={<FaPlus />}
          aria-label="Add node"
          size="xs"
          color="gray.500"
        />
      </Box>
    </FloatingPopover>
  );
};
