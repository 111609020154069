import gql from 'graphql-tag';

export const DeleteParticipantUserLabel = gql`
  mutation DeleteParticipantUserLabel(
    $organizationId: ID!
    $participantId: ID!
    $participantUserId: ID!
    $labelId: ID!
  ) {
    deleteParticipantUserLabel(
      organizationId: $organizationId
      participantId: $participantId
      participantUserId: $participantUserId
      labelId: $labelId
    )
  }
`;
