import { Divider, IconButton, Stack, useDisclosure } from '@chakra-ui/react';
import { PhaseChangeLine } from '@piccolohealth/pbs-common';
import { FloatingPopover } from '@piccolohealth/ui';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FaPencilAlt } from 'react-icons/fa';
import { FormSection } from '../../../../components/forms/FormSection';
import { HookedSubmitButton } from '../../../../components/forms/HookedSubmitButton';
import { UseTimeseriesChartReturn } from '../../../participantData/hooks/useTimeseriesChart';
import { PhaseChangeLineForm } from './PhaseChangeLineForm';

type FormValues = PhaseChangeLine;

interface PhaseChangeLineEditFormProps {
  chart: UseTimeseriesChartReturn;
  phaseChangeLine: PhaseChangeLine;
  onClose: () => void;
}

const PhaseChangeLineEditForm = (props: PhaseChangeLineEditFormProps) => {
  const { chart, phaseChangeLine, onClose } = props;

  const methods = useForm({
    defaultValues: phaseChangeLine,
  });

  const onSubmit = (values: FormValues) => {
    chart.onEditPhaseChangeLine(values.id, values);
    onClose();
  };

  return (
    <Stack
      bg="white"
      spacing={4}
      w="md"
      layerStyle="bordered"
      shadow="popover"
      rounded="md"
      overflow="hidden"
      p={4}
    >
      <FormProvider {...methods}>
        <FormSection
          as="form"
          heading="Edit phase change line"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Divider />
          <PhaseChangeLineForm />
          <HookedSubmitButton w="full" size="sm" colorScheme="purple">
            Save
          </HookedSubmitButton>
        </FormSection>
      </FormProvider>
    </Stack>
  );
};

interface Props {
  chart: UseTimeseriesChartReturn;
  phaseChangeLine: PhaseChangeLine;
}

export const PhaseChangeLineEditControl = (props: Props) => {
  const { chart, phaseChangeLine } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <FloatingPopover
      open={isOpen}
      placement="right-end"
      setOpen={(v) => (v ? onOpen() : onClose())}
      render={() => (
        <PhaseChangeLineEditForm
          chart={chart}
          phaseChangeLine={phaseChangeLine}
          onClose={onClose}
        />
      )}
    >
      <IconButton icon={<FaPencilAlt />} aria-label="Edit" size="xs" variant="ghost" />
    </FloatingPopover>
  );
};
