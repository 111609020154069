import { Card, CardBody, CardHeader, Heading, Stack, Text } from '@chakra-ui/react';
import { Incident } from '@piccolohealth/pbs-common';
import React from 'react';
import { FormItem } from '../../components/forms/FormItem';
import { FormStack } from '../../components/forms/FormStack';
import { UserDescription } from '../../components/user/UserDescription';

interface Props {
  incident: Incident;
}

export const IncidentTeamCard = (props: Props) => {
  const { incident } = props;

  return (
    <Card h="full">
      <CardHeader>
        <Stack spacing={0}>
          <Heading size="md">Team members</Heading>
          <Text fontSize="sm" color="secondary">
            The reviewer and reporter of this incident
          </Text>
        </Stack>
      </CardHeader>
      <CardBody>
        <FormStack orientation="horizontal">
          <FormItem label="Reporter" helperText="The person who reported this incident">
            <UserDescription
              name={incident.reporter.name}
              secondary={incident.reporter.email}
              picture={incident.reporter.picture}
            />
          </FormItem>
          <FormItem label="Reviewers" helperText="The people assigned to review this incident">
            <Stack spacing={4}>
              {incident.reviewers.map((reviewer) => {
                return (
                  <UserDescription
                    key={reviewer.id}
                    name={reviewer.name}
                    secondary={reviewer.email}
                    picture={reviewer.picture}
                  />
                );
              })}
            </Stack>
          </FormItem>
        </FormStack>
      </CardBody>
    </Card>
  );
};
