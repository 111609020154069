import gql from 'graphql-tag';

export const UpdateTemplate = gql`
  mutation UpdateTemplate(
    $organizationId: ID!
    $templateId: ID!
    $request: UpdateTemplateRequest!
  ) {
    updateTemplate(organizationId: $organizationId, templateId: $templateId, request: $request) {
      id
    }
  }
`;
