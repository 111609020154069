import { renderIncidentShortId } from '@piccolohealth/pbs-common';
import { useRequiredParams } from '@piccolohealth/ui';
import React from 'react';
import { useIncidentMinimalQuery } from '../../graphql/hooks/useIncidentQuery';
import { useAppContext } from '../../hooks/useAppContext';

export const IncidentCrumb = () => {
  const { incidentId } = useRequiredParams<{ incidentId: string }>();

  const { organization } = useAppContext();

  const { isLoading, data, error } = useIncidentMinimalQuery({
    organizationId: organization.id,
    incidentId,
  });

  if (isLoading) {
    return <span>...</span>;
  }

  if (error) {
    return <span>_</span>;
  }

  const incident = data?.organization?.incident;

  if (!incident) {
    return <span>_</span>;
  }

  return <span>{renderIncidentShortId(incident.shortId)}</span>;
};
