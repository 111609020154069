import gql from 'graphql-tag';

export const NotifyParticipantDocumentReviewers = gql`
  mutation NotifyParticipantDocumentReviewers(
    $organizationId: ID!
    $participantId: ID!
    $participantDocumentId: ID!
    $userId: ID!
  ) {
    notifyParticipantDocumentReviewers(
      organizationId: $organizationId
      participantId: $participantId
      participantDocumentId: $participantDocumentId
      userId: $userId
    )
  }
`;
