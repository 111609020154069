import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { UpdateParticipantFieldTemplate } from '../mutations/updateParticipantFieldTemplate.mutation';
import {
  UpdateParticipantFieldTemplateMutation,
  UpdateParticipantFieldTemplateMutationVariables,
} from '../types';

export const useUpdateParticipantFieldTemplateMutation = (
  options?: MutationOptions<
    UpdateParticipantFieldTemplateMutation,
    UpdateParticipantFieldTemplateMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(UpdateParticipantFieldTemplate), {
    ...options,

    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('participantFieldGroupTemplate'),
      });
    },
  });
};
