import { Label, LabelType } from '@piccolohealth/pbs-common';
import { OnChangeRequest } from '@piccolohealth/ui';
import React from 'react';
import { useCreateLabelMutation } from '../../graphql/hooks/useCreateLabelMutation';
import { useCreateParticipantUserLabelMutation } from '../../graphql/hooks/useCreateParticipantUserLabelMutation';
import { useDeleteParticipantUserLabelMutation } from '../../graphql/hooks/useDeleteParticipantUserLabelMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { usePermission } from '../../hooks/usePermission';
import { LabelsControl } from './LabelsControl';

export interface ManageParticipantUserLabelsControlProps {
  participantId: string;
  participantUserId: string;
  value: string[];
  isDisabled?: boolean;
}

export const ManageParticipantUserLabelsControl = (
  props: ManageParticipantUserLabelsControlProps,
) => {
  const { participantId, participantUserId, isDisabled, value } = props;
  const { organization, errorToast } = useAppContext();

  const createLabelMutation = useCreateLabelMutation({ retry: false });
  const createParticipantLabelMutation = useCreateParticipantUserLabelMutation({ retry: false });
  const deleteParticipantLabelMutation = useDeleteParticipantUserLabelMutation({ retry: false });

  const labelManagePermission = usePermission('manage', 'label');
  const participantUserLabelManagePermission = usePermission('manage', 'participantUserLabel');

  const onChange = React.useCallback(
    (request: OnChangeRequest<Label>) => {
      switch (request.action) {
        case 'CREATE': {
          return createLabelMutation
            .mutateAsync({
              organizationId: organization.id,
              request: {
                type: LabelType.ParticipantUser,
                color: 'blue',
                name: request.value.value,
              },
            })
            .catch((err: Error) => errorToast(`Error creating label ${err.message}`));
        }
        case 'ADD': {
          return createParticipantLabelMutation
            .mutateAsync({
              organizationId: organization.id,
              participantId,
              participantUserId,
              labelId: request.value.value,
            })
            .catch((err: Error) =>
              errorToast(`Error adding label to participant user: ${err.message}`),
            );
        }

        case 'REMOVE': {
          return deleteParticipantLabelMutation
            .mutateAsync({
              organizationId: organization.id,
              participantId,
              participantUserId,
              labelId: request.value.value,
            })
            .catch((err: Error) =>
              errorToast(`Error removing label from participant: ${err.message}`),
            );
        }
      }
    },
    [
      createLabelMutation,
      organization.id,
      createParticipantLabelMutation,
      participantId,
      participantUserId,
      deleteParticipantLabelMutation,
      errorToast,
    ],
  );

  return (
    <LabelsControl
      onChange={onChange}
      value={value}
      isDisabled={isDisabled || !participantUserLabelManagePermission}
      canManage={labelManagePermission}
      variant="selectGhost"
      type={LabelType.ParticipantUser}
    />
  );
};
