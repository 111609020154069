import { Button, Divider, HStack, IconButton, Stack, Text } from '@chakra-ui/react';
import { FloatingPopover } from '@piccolohealth/ui';
import { Editor } from '@tiptap/react';
import React from 'react';
import { FaTimesCircle } from 'react-icons/fa';
import { COLORS } from '../utils';

const TEXT_COLORS = COLORS;
const BACKGROUND_COLORS = [{ hex: 'transparent', title: 'No fill' }, ...COLORS].filter(
  (item) => item.title !== 'Black',
);

interface Props {
  editor: Editor;
}

export const TextColorSelect = (props: Props) => {
  const { editor } = props;

  const changeColor = React.useCallback(
    (color: string) => {
      editor.chain().focus().setColor(color).run();
    },
    [editor],
  );

  const changeBackground = React.useCallback(
    (color: string) => {
      if (color === 'transparent') {
        editor.chain().focus().unsetHighlight().run();
      } else {
        editor.chain().focus().toggleHighlight({ color }).run();
      }
    },
    [editor],
  );

  const reset = React.useCallback(() => {
    editor.chain().focus().unsetColor().unsetHighlight().run();
  }, [editor]);

  const activeTextColor =
    TEXT_COLORS.find((item) => editor.isActive('textStyle', { color: item.hex })) ?? TEXT_COLORS[0];

  const activeBackgroundColor =
    BACKGROUND_COLORS.find((item) => editor.isActive('highlight', { color: item.hex })) ??
    BACKGROUND_COLORS[0];

  return (
    <FloatingPopover
      placement="bottom"
      isPortal
      render={() => (
        <Stack
          bg="white"
          p={4}
          w="sm"
          rounded="lg"
          shadow="popover"
          spacing={4}
          onMouseDown={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <Stack>
            <Text fontSize="xs" fontWeight="semibold" color="secondary">
              Color
            </Text>
            <HStack>
              {TEXT_COLORS.map((item) => (
                <IconButton
                  key={item.title}
                  variant="outline"
                  bg="white"
                  borderWidth={activeTextColor === item ? '2px' : undefined}
                  borderColor={activeTextColor === item ? item.hex : undefined}
                  size="sm"
                  aria-label={item.title}
                  onClick={() => changeColor(item.hex)}
                  icon={
                    <Text fontWeight="semibold" fontSize="lg" lineHeight="normal" color={item.hex}>
                      A
                    </Text>
                  }
                  _hover={{
                    borderColor: 'black',
                    borderWidth: '2px',
                  }}
                />
              ))}
            </HStack>
          </Stack>
          <Stack>
            <Text fontSize="xs" fontWeight="semibold" color="secondary">
              Background
            </Text>
            <HStack>
              {BACKGROUND_COLORS.map((item) => (
                <IconButton
                  key={item.title}
                  variant="outline"
                  bg={item.hex}
                  borderWidth={activeBackgroundColor === item ? '2px' : undefined}
                  borderColor={activeBackgroundColor === item ? 'black' : undefined}
                  size="sm"
                  aria-label={item.title}
                  onClick={() => changeBackground(item.hex)}
                  icon={
                    <Text fontWeight="semibold" fontSize="lg" lineHeight="normal">
                      A
                    </Text>
                  }
                  _hover={{
                    bg: item.hex,
                    borderColor: 'black',
                    borderWidth: '2px',
                  }}
                />
              ))}
            </HStack>
          </Stack>
          <Divider />
          <Button
            size="xs"
            w="fit-content"
            variant="ghost"
            leftIcon={<FaTimesCircle />}
            onClick={() => reset()}
          >
            Reset formatting
          </Button>
        </Stack>
      )}
    >
      <IconButton
        as="div"
        tabIndex={0}
        bg={activeBackgroundColor.hex}
        rounded="lg"
        aria-label="text-colors"
        icon={
          <Text fontWeight="semibold" fontSize="lg" lineHeight="normal" color={activeTextColor.hex}>
            A
          </Text>
        }
        variant="ghost"
        size="sm"
      />
    </FloatingPopover>
  );
};
