import { useInfiniteQuery } from '@tanstack/react-query';
import { createGqlQuery, InfiniteQueryOptions } from '../fetcher';
import {
  Template,
  TemplateMinimal,
  TemplateVersion,
  TemplateVersions,
} from '../queries/template.query';

import * as Types from '../types';

export const getTemplateMinimalBaseKey = (variables: Types.TemplateMinimalQueryVariables) => [
  'TemplateMinimal',
  variables,
];

export const useTemplateQuery = createGqlQuery<Types.TemplateQueryVariables, Types.TemplateQuery>(
  (variables) => ['organization', variables.organizationId, 'template', variables.templateId],
  Template,
);

export const useTemplateMinimalQuery = createGqlQuery<
  Types.TemplateMinimalQueryVariables,
  Types.TemplateMinimalQuery
>(
  (variables) => [
    'organization',
    variables.organizationId,
    'template',
    variables.templateId,
    'minimal',
  ],
  TemplateMinimal,
);

export const useTemplateVersionsQuery = createGqlQuery<
  Types.TemplateVersionsQueryVariables,
  Types.TemplateVersionsQuery
>(
  (variables) => ['organization', variables, 'template', variables.templateId, 'versions'],
  TemplateVersions,
);

export const useTemplateVersionQuery = createGqlQuery<
  Types.TemplateVersionQueryVariables,
  Types.TemplateVersionQuery
>(
  (variables) => ['organization', variables, 'template', variables.templateId, 'version'],
  TemplateVersion,
);

export const useTemplateVersionsInfiniteQuery = (
  variables: Types.TemplateVersionsQueryVariables,
  options?: InfiniteQueryOptions<Types.TemplateVersionsQuery>,
) => {
  const fetcher = useTemplateVersionsQuery.getFetcher();

  const query = useInfiniteQuery(
    useTemplateVersionsQuery.getKey(variables),
    async ({ pageParam }) => {
      return fetcher({
        ...variables,
        request: {
          ...variables.request,
          pagination: {
            ...variables.request?.pagination,
            offset: pageParam,
          },
        },
      });
    },
    {
      ...options,
      getNextPageParam: (lastPage: any) => {
        const pagination = lastPage?.organization?.template?.versions?.pagination;

        if (!pagination?.hasNextPage) {
          return undefined;
        }

        return pagination?.currentPage * pagination?.limit;
      },
    },
  );

  return query;
};
