import { Button, ButtonGroup } from '@chakra-ui/react';
import * as UI from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props extends UI.ErrorProps {
  type: 'Unauthorized' | 'Forbidden' | 'NotFound';
}

export const PageError = (props: Props) => {
  const { type, ...rest } = props;

  const extra = (
    <ButtonGroup>
      <Button colorScheme="purple" onClick={() => history.go()}>
        Reload
      </Button>
      <Link to={`/`}>
        <Button>Go back</Button>
      </Link>
    </ButtonGroup>
  );

  const { title, description } = P.run(() => {
    switch (type) {
      case 'Unauthorized':
      case 'Forbidden':
        return {
          title: 'Unauthorized',
          description: 'Sorry, you are not authorized to access this page',
        };
      case 'NotFound':
        return {
          title: 'Not Found',
          description: 'Sorry, we could not find the page you were looking for',
        };
    }
  });

  return <UI.Error title={title} description={description} extra={extra} {...rest} />;
};
