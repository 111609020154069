import { HStack, Tag } from '@chakra-ui/react';
import { Label } from '@piccolohealth/pbs-common';
import React from 'react';

interface Props {
  labels: Label[];
}

export const LabelsStack = (props: Props) => {
  return (
    <HStack>
      {props.labels.map((label) => (
        <Tag key={label.id} colorScheme={label.color} size="sm">
          {label.name}
        </Tag>
      ))}
    </HStack>
  );
};
