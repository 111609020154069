import { defineStyle, StyleFunctionProps } from '@chakra-ui/react';

const defaultProps = {
  colorScheme: 'purple',
};

const baseStyle = {
  tabpanel: {
    p: 0,
    w: 'full',
  },
  tab: {
    border: 'none',
    bg: 'white',
  },
  tablist: {
    whiteSpace: 'nowrap',
  },
};

const softRounded = (props: StyleFunctionProps) => {
  return {
    tab: {
      flex: props.isFitted ? 1 : 0,
      rounded: 'md',
      fontSize: 'sm',
      py: 2,
      px: 4,
      bg: 'transparent',
      justifyContent: props.isFitted ? 'center' : 'flex-start',
      _selected: {
        color: 'gray.600',
        bg: 'gray.100',
      },
    },
  };
};

const solid = defineStyle((props) => {
  return {
    tab: {
      bg: 'gray.100',
      color: 'gray.400',
      rounded: 'md',
      fontSize: 'sm',
      fontWeight: 'semibold',
      _selected: {
        bg: 'white',
        color: 'gray.600',
      },
    },
    tablist: {
      p: 1,
      bg: 'gray.100',
      rounded: 'lg',
      w: 'fit-content',
    },
    tabpanels: {
      borderTopWidth: '1px',
      borderTopColor: 'gray.200',
      mt: 4,
    },
  };
});

const ios = defineStyle(() => {
  return {
    tab: {
      p: 0,
      m: 0,
      h: 'full',
      w: 'full',
      bg: 'gray.50',
      color: 'gray.500',
      fontSize: 'sm',
      fontWeight: 'semibold',
      _selected: {
        color: 'iosBlue',
      },
    },
    tablist: {
      w: 'full',
      h: '50px',
      p: 0,
      m: 0,
      borderTopWidth: '1px',
      borderTopColor: 'gray.200',
      bg: 'gray.100',
      rounded: 'lg',
    },
    tabpanels: {
      borderTopWidth: '1px',
      borderTopColor: 'gray.200',
      bg: 'gray.50',
    },
    tabpanel: {
      mt: 4,
    },
  };
});

const variants = {
  solid,
  'soft-rounded': softRounded,
  ios,
};

export const Tabs = {
  baseStyle,
  defaultProps,
  variants,
};
