import { Box, HStack, Text, Button, Spacer, Stack, Divider } from '@chakra-ui/react';
import * as Common from '@piccolohealth/pbs-common';
import { LabelType } from '@piccolohealth/pbs-common';
import {
  FloatingPopover,
  MultiSelect,
  MultiSelectOption,
  NumberedBadge,
  OnChangeRequest,
  RangeDatepicker,
} from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FaFilter, FaCaretDown } from 'react-icons/fa';
import { FormItem } from '../../components/forms/FormItem';
import { FormStack } from '../../components/forms/FormStack';
import { useLabelsQuery } from '../../graphql/hooks/useLabelsQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { IncidentsFilter } from '../../hooks/useIncidentsFilter';
import { incidentSeverityDetails, IncidentSeverityTag } from './IncidentSeverityTag';

interface Props {
  filter: IncidentsFilter;
}

const IncidentDateFilterControl = (props: Props) => {
  return (
    <RangeDatepicker
      startDate={props.filter.startDateFilter ?? null}
      endDate={props.filter.endDateFilter ?? null}
      onStartDateChange={(date) => props.filter.onStartDateFilter(date ?? undefined)}
      onEndDateChange={(date) => props.filter.onEndDateFilter(date ?? undefined)}
    />
  );
};

const IncidentLocationFilterControl = (props: Props) => {
  const { organization } = useAppContext();
  const options: MultiSelectOption<Common.Location>[] = React.useMemo(
    () =>
      organization.locations.map((location) => {
        return {
          label: location.name,
          value: location.id,
          raw: location,
          color: location.color,
        };
      }),
    [organization.locations],
  );

  const selectedValues: MultiSelectOption<Common.Location>[] = React.useMemo(() => {
    return (props.filter.locationFilter ?? []).flatMap((location) => {
      return options.find((o) => P.isEqual(o.raw.id, location)) ?? [];
    });
  }, [props.filter, options]);

  const onChange = React.useCallback(
    (req: OnChangeRequest<Common.Location>) => {
      props.filter.onLocationFilter(req.values.map(({ raw }) => raw.id));
    },
    [props.filter],
  );

  return (
    <MultiSelect
      selectedValues={selectedValues}
      options={options}
      onChange={onChange}
      size="sm"
      placeholder="All locations"
      optionVariant="tag"
    />
  );
};

const IncidentSeverityFilterControl = (props: Props) => {
  const options: MultiSelectOption<Common.IncidentSeverity>[] = React.useMemo(
    () =>
      Object.values(Common.IncidentSeverity).map((severity) => {
        const [color, label] = incidentSeverityDetails(severity);
        return {
          value: severity,
          raw: severity,
          label,
          color,
        };
      }),
    [],
  );

  const selectedValues: MultiSelectOption<Common.IncidentSeverity>[] = React.useMemo(() => {
    return (props.filter.severityFilter ?? []).flatMap((severity) => {
      return options.find((o) => P.isEqual(o.raw, severity)) ?? [];
    });
  }, [props.filter, options]);

  const onChange = React.useCallback(
    (req: OnChangeRequest<Common.IncidentSeverity>) => {
      props.filter.onSeverityFilter(req.values.map(({ raw }) => raw));
    },
    [props.filter],
  );

  return (
    <MultiSelect
      selectedValues={selectedValues}
      options={options}
      onChange={onChange}
      size="sm"
      placeholder="All severities"
      optionVariant="tag"
      components={{
        Option: ({ option }) => {
          return <IncidentSeverityTag severity={option.raw} size="sm" />;
        },
      }}
    />
  );
};

const IncidentStatusFilterControl = (props: Props) => {
  const options: MultiSelectOption<Common.IncidentStatus>[] = React.useMemo(
    () => [
      {
        label: 'Awaiting Review',
        value: Common.IncidentStatus.AwaitingReview,
        raw: Common.IncidentStatus.AwaitingReview,
        color: 'yellow',
      },
      {
        label: 'Reviewed',
        value: Common.IncidentStatus.Reviewed,
        raw: Common.IncidentStatus.Reviewed,
        color: 'green',
      },
    ],
    [],
  );

  const selectedValues: MultiSelectOption<Common.IncidentStatus>[] = React.useMemo(() => {
    return (props.filter.statusFilter ?? []).flatMap((status) => {
      return options.find((o) => P.isEqual(o.raw, status)) ?? [];
    });
  }, [props.filter, options]);

  const onChange = React.useCallback(
    (req: OnChangeRequest<Common.IncidentStatus>) => {
      props.filter.onStatusFilter(req.values.map(({ raw }) => raw));
    },
    [props.filter],
  );

  return (
    <MultiSelect
      selectedValues={selectedValues}
      options={options}
      onChange={onChange}
      size="sm"
      placeholder="All statuses"
      optionVariant="tag"
    />
  );
};

const IncidentLabelFilterControl = (props: Props) => {
  const { organization } = useAppContext();
  const { data, isLoading } = useLabelsQuery({
    organizationId: organization.id,
    type: LabelType.Incident,
  });

  const options: MultiSelectOption<Common.Label>[] = React.useMemo(() => {
    const labels = (data?.organization?.labels as Common.Label[]) ?? [];
    return labels.map((label) => {
      return {
        label: label.name,
        value: label.id,
        raw: label,
        color: label.color,
      };
    });
  }, [data?.organization?.labels]);

  const selectedValues: MultiSelectOption<Common.Label>[] = React.useMemo(() => {
    return (props.filter.labelFilter ?? []).flatMap((label) => {
      return options.find((o) => P.isEqual(o.raw.id, label)) ?? [];
    });
  }, [props.filter, options]);

  const onChange = React.useCallback(
    (req: OnChangeRequest<Common.Label>) => {
      props.filter.onLabelFilter(req.values.map(({ raw }) => raw.id));
    },
    [props.filter],
  );

  return (
    <MultiSelect
      isLoading={isLoading}
      selectedValues={selectedValues}
      options={options}
      onChange={onChange}
      size="sm"
      placeholder="All labels"
      optionVariant="tag"
    />
  );
};

export const IncidentsFilterControls = (props: Props) => {
  return (
    <FormStack orientation="horizontal">
      <FloatingPopover
        shouldInitialFocus
        isPortal
        render={() => (
          <Stack bg="white" spacing={0} w="xs" shadow="popover" rounded="md" overflow="hidden">
            <HStack py={2} px={4} borderBottomWidth="1px">
              <Text fontWeight="semibold">Filters</Text>
              <Spacer />
              <NumberedBadge count={props.filter.activeFilterCount} />
            </HStack>
            <FormStack py={4} px={4} orientation="vertical">
              <FormItem label="Date">
                <IncidentDateFilterControl filter={props.filter} />
              </FormItem>

              <FormItem label="Location">
                <IncidentLocationFilterControl filter={props.filter} />
              </FormItem>

              <FormItem label="Severity">
                <IncidentSeverityFilterControl filter={props.filter} />
              </FormItem>

              <FormItem label="Status">
                <IncidentStatusFilterControl filter={props.filter} />
              </FormItem>

              <FormItem label="Labels">
                <IncidentLabelFilterControl filter={props.filter} />
              </FormItem>

              <Divider />

              <Button
                w="fit-content"
                size="sm"
                variant="outline"
                isDisabled={props.filter.activeFilterCount === 0}
                onClick={props.filter.reset}
              >
                Reset
              </Button>
            </FormStack>
          </Stack>
        )}
      >
        <Button
          size="sm"
          variant="outline"
          lineHeight="unset"
          leftIcon={<FaFilter />}
          rightIcon={<FaCaretDown />}
        >
          <HStack>
            <Text>Filter</Text>
            <Box w="28px">
              <NumberedBadge count={props.filter.activeFilterCount} />
            </Box>
          </HStack>
        </Button>
      </FloatingPopover>
    </FormStack>
  );
};
