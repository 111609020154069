import gql from 'graphql-tag';

export const DeleteParticipantUsers = gql`
  mutation DeleteParticipantUsers($organizationId: ID!, $participantId: ID!, $userIds: [ID!]!) {
    deleteParticipantUsers(
      organizationId: $organizationId
      participantId: $participantId
      userIds: $userIds
    )
  }
`;
