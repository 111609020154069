import { FieldTemplateType } from '@piccolohealth/pbs-common';

export const gridItemSize = (type: FieldTemplateType) => {
  switch (type) {
    case FieldTemplateType.Datepicker:
    case FieldTemplateType.Input:
    case FieldTemplateType.Select:
    case FieldTemplateType.MultiSelect:
      return 1;
    case FieldTemplateType.Textarea:
      return 2;
  }
};
