import gql from 'graphql-tag';

export const DeleteParticipantFile = gql`
  mutation DeleteParticipantFile(
    $organizationId: ID!
    $participantId: ID!
    $participantFileId: ID!
  ) {
    deleteParticipantFile(
      organizationId: $organizationId
      participantId: $participantId
      participantFileId: $participantFileId
    )
  }
`;
