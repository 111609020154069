import gql from 'graphql-tag';

export const UpdateLabel = gql`
  mutation UpdateLabel($organizationId: ID!, $labelId: ID!, $request: UpdateLabelRequest!) {
    updateLabel(organizationId: $organizationId, labelId: $labelId, request: $request) {
      id
      name
      description
      color
    }
  }
`;
