import gql from 'graphql-tag';

export const UpdateParticipantFieldGroupTemplate = gql`
  mutation UpdateParticipantFieldGroupTemplate(
    $organizationId: ID!
    $participantFieldGroupTemplateId: ID!
    $request: UpdateParticipantFieldGroupTemplateRequest!
  ) {
    updateParticipantFieldGroupTemplate(
      organizationId: $organizationId
      participantFieldGroupTemplateId: $participantFieldGroupTemplateId
      request: $request
    ) {
      id
      name
      index
      createdAt
      updatedAt
    }
  }
`;
