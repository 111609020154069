import { BubbleMenu as TiptapBubbleMenu, Editor, isTextSelection } from '@tiptap/react';
import React from 'react';
import { EditorMenu } from './EditorMenu';
import { Editor as EditorCore } from '@tiptap/core';

import 'tippy.js/animations/shift-away.css';
import { EditorView } from '@tiptap/pm/view';
import { EditorState } from '@tiptap/pm/state';

function shouldShow(
  this: any,
  {
    editor,
    view,
    state,
    from,
    to,
  }: {
    editor: EditorCore;
    view: EditorView;
    state: EditorState;
    from: number;
    to: number;
  },
) {
  const { doc, selection } = state;

  if (!isTextSelection(selection)) {
    return false;
  }

  const { empty } = selection;

  // Sometime check for `empty` is not enough.
  // Doubleclick an empty paragraph returns a node size of 2.
  // So we check also for an empty text size.
  const isEmptyTextBlock = !doc.textBetween(from, to).length && isTextSelection(state.selection);

  // When clicking on a element inside the bubble menu the editor "blur" event
  // is called and the bubble menu item is focussed. In this case we should
  // consider the menu as part of the editor and keep showing the menu

  const isChildOfMenu = this.element.contains(document.activeElement);
  const hasEditorFocus = view.hasFocus() || isChildOfMenu;
  const isInImage = editor.isActive('image');
  const isInVariable = editor.isActive('variable');

  if (isInVariable) {
    return true;
  }

  if (!hasEditorFocus || empty || isEmptyTextBlock || !this.editor.isEditable || isInImage) {
    return false;
  }

  return true;
}

interface Props {
  editor: Editor;
}

export const EditorBubbleMenu = (props: Props) => {
  const { editor } = props;

  return (
    <TiptapBubbleMenu
      editor={editor}
      tippyOptions={{
        duration: 100,
        maxWidth: 'calc(100vw - 100px)',
        placement: 'top-start',
        arrow: false,
        appendTo: 'parent',
        animation: 'shift-away',
        inertia: true,
      }}
      shouldShow={shouldShow}
    >
      <EditorMenu editor={editor} />
    </TiptapBubbleMenu>
  );
};
