import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { DeleteParticipantLabel } from '../mutations/deleteParticipantLabel.mutation';
import { DeleteParticipantLabelMutation, DeleteParticipantLabelMutationVariables } from '../types';

export const useDeleteParticipantLabelMutation = (
  options?: MutationOptions<
    DeleteParticipantLabelMutation,
    DeleteParticipantLabelMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(DeleteParticipantLabel), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('participant'),
      });
    },
  });
};
