import { renderParticipantName } from '@piccolohealth/pbs-common';
import { useRequiredParams } from '@piccolohealth/ui';
import React from 'react';
import { useParticipantMinimalQuery } from '../../graphql/hooks/useParticipantQuery';
import { useAppContext } from '../../hooks/useAppContext';

export const ParticipantCrumb = () => {
  const { participantId } = useRequiredParams<{ participantId: string }>();

  const { organization } = useAppContext();

  const { isLoading, data, error } = useParticipantMinimalQuery({
    organizationId: organization.id,
    participantId,
  });

  if (isLoading) {
    return <span>...</span>;
  }

  if (error) {
    return <span>_</span>;
  }

  const participant = data?.organization?.participant;

  if (!participant) {
    return <span>_</span>;
  }

  return <span>{renderParticipantName(participant)}</span>;
};
