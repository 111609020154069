import { AvatarGroup, Stack, Tag, Text } from '@chakra-ui/react';
import {
  Incident,
  PiccoloError,
  renderIncidentShortId,
  renderParticipantName,
} from '@piccolohealth/pbs-common';
import {
  createColumnHelper,
  DataTable,
  Empty,
  FancyDate,
  PaginationOptions,
  RowSelectionOptions,
} from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { TextLink } from '../../components/generic/TextLink';
import { UserAvatar } from '../../components/user/UserAvatar';
import { useAppContext } from '../../hooks/useAppContext';
import { ManageIncidentLabelsControl } from '../labels/ManageIncidentLabelsControl';
import { IncidentSeverityTag } from './IncidentSeverityTag';
import { IncidentStatusTag } from './IncidentStatusTag';

interface Props {
  isLoading: boolean;
  error: PiccoloError | null;
  incidents: Incident[];
  refetch: () => Promise<void>;
  pagination?: PaginationOptions;
  rowSelection?: RowSelectionOptions<Incident>;
}

export const IncidentsTable = (props: Props) => {
  const { organization } = useAppContext();

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<Incident>();

    return [
      columnHelper.accessor('id', {
        header: 'ID',
        minSize: 160,
        maxSize: 160,
        cell: (ps) => {
          const incident = ps.row.original;

          return (
            <Stack spacing={1}>
              <TextLink
                to={`/organizations/${organization.id}/incidents/${incident.id}`}
                fontWeight="bold"
                color="purple.600"
                fontSize="md"
              >
                {renderIncidentShortId(incident.shortId)}
              </TextLink>
              <FancyDate
                date={incident.createdAt}
                fontSize="xs"
                spacing={0}
                showHumanized={false}
              />
            </Stack>
          );
        },
      }),
      columnHelper.accessor('participant.firstName', {
        header: 'Participant',
        minSize: 180,
        maxSize: 180,
        cell: (ps) => {
          const incident = ps.row.original;

          const name = P.run(() => {
            if (incident.participant) {
              return renderParticipantName(incident.participant);
            }

            return '-';
          });

          return <Text fontSize="sm">{name}</Text>;
        },
      }),
      columnHelper.accessor('location.name', {
        header: 'Location',
        minSize: 240,
        maxSize: 240,
        cell: (ps) => {
          return ps.row.original.location ? (
            <Tag size="sm" colorScheme={ps.row.original.location?.color}>
              {ps.row.original.location?.name}
            </Tag>
          ) : (
            <Text>-</Text>
          );
        },
      }),
      columnHelper.accessor((p) => p.severity.toString(), {
        header: 'Severity',
        minSize: 100,
        maxSize: 120,
        cell: (ps) => {
          return <IncidentSeverityTag severity={ps.row.original.severity} size="sm" />;
        },
      }),
      columnHelper.accessor((p) => p.status.toString(), {
        header: 'Status',
        minSize: 140,
        maxSize: 140,
        cell: (ps) => {
          return <IncidentStatusTag status={ps.row.original.status} size="sm" />;
        },
      }),
      columnHelper.accessor('labels.name', {
        header: 'Labels',
        minSize: 200,
        cell: (ps) => {
          return (
            <ManageIncidentLabelsControl
              incidentId={ps.row.original.id}
              value={ps.row.original.labels.map((label) => label.id)}
            />
          );
        },
      }),
      columnHelper.display({
        id: 'reviewers-reporters',
        header: 'Reviewers & Reporters',
        minSize: 200,
        cell: (ps) => {
          const members = ps.row.original.reviewers.concat(ps.row.original.reporter);

          return (
            <AvatarGroup spacing={-3}>
              {members.map((member) => (
                <UserAvatar
                  key={member.id}
                  name={member.name}
                  secondary={member.email}
                  picture={member.picture}
                  showTooltip
                  size="sm"
                />
              ))}
            </AvatarGroup>
          );
        },
      }),
    ];
  }, [organization.id]);

  return (
    <DataTable
      columns={columns}
      data={props.incidents}
      isLoading={props.isLoading}
      error={props.error}
      renderEmpty={() => <Empty title="No incidents found" />}
      pagination={props.pagination}
      rowSelection={props.rowSelection}
      size="md"
    />
  );
};
