import { PureEditorContent } from '@tiptap/react';
import { flushSync } from 'react-dom';

export const monkeyPatch = () => {
  PureEditorContent.prototype.maybeFlushSync = function maybeFlushSync(fn) {
    if (this.initialized) {
      flushSync(fn);
      this.initialized = false;
    } else {
      fn();
    }
  };
};
