import gql from 'graphql-tag';

export const CreateParticipantDocument = gql`
  mutation CreateParticipantDocument(
    $organizationId: ID!
    $request: CreateParticipantDocumentRequest!
  ) {
    createParticipantDocument(organizationId: $organizationId, request: $request) {
      id
    }
  }
`;
