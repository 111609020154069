import { Label } from '@piccolohealth/pbs-common';
import { OnChangeRequest } from '@piccolohealth/ui';
import React from 'react';
import { useController } from 'react-hook-form';
import { LabelsControl, LabelsControlProps } from '../../features/labels/LabelsControl';
import { useCreateLabelMutation } from '../../graphql/hooks/useCreateLabelMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { usePermission } from '../../hooks/usePermission';

interface Props extends Omit<LabelsControlProps, 'value' | 'onChange' | 'canManage'> {
  name: string;
}

export const HookedLabelChooser = (props: Props) => {
  const { name, type, isDisabled, ...rest } = props;
  const { organization, errorToast } = useAppContext();
  const { field } = useController({ name });

  const { onChange, value } = field;
  const labelIds = value as string[];

  const createLabelMutation = useCreateLabelMutation({ retry: false });
  const labelManagePermission = usePermission('manage', 'label');

  const onDelete = (labelId: string) => {
    onChange(labelIds.filter((id) => id !== labelId));
  };

  const onChangePrime = React.useCallback(
    (request: OnChangeRequest<Label>) => {
      switch (request.action) {
        case 'CREATE': {
          return createLabelMutation
            .mutateAsync({
              organizationId: organization.id,
              request: {
                type,
                color: 'blue',
                name: request.value.value,
              },
            })
            .catch((err: Error) => errorToast(`Error creating label: ${err.message}`));
        }
        case 'ADD': {
          onChange([...labelIds, request.value.value]);
          break;
        }
        case 'REMOVE': {
          onChange(labelIds.filter((id) => id !== request.value.value));
          break;
        }
      }
    },
    [createLabelMutation, organization.id, type, errorToast, onChange, labelIds],
  );

  return (
    <LabelsControl
      value={labelIds}
      onChange={onChangePrime}
      onDelete={onDelete}
      variant="outline"
      type={type}
      isDisabled={isDisabled}
      canManage={labelManagePermission}
      {...rest}
    />
  );
};
