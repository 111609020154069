import gql from 'graphql-tag';

export const UpdateParticipantFieldTemplate = gql`
  mutation UpdateParticipantFieldTemplate(
    $organizationId: ID!
    $participantFieldTemplateId: ID!
    $request: UpdateParticipantFieldTemplateRequest!
  ) {
    updateParticipantFieldTemplate(
      organizationId: $organizationId
      participantFieldTemplateId: $participantFieldTemplateId
      request: $request
    ) {
      id
      name
      type
      attributes
      index
      createdAt
      updatedAt
    }
  }
`;
