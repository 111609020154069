import { Text } from '@chakra-ui/react';
import { P } from '@piccolohealth/util';
import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import React from 'react';
import { Variable } from '../utils';

export const HighlightedVariableValue = (props: NodeViewProps) => {
  const { id } = props.node.attrs;
  const { variables } = props.extension.options;

  const variable = ((variables as Variable[]) ?? []).find((v) => v.id === id);

  if (!variable) {
    return null;
  }

  const rendered = P.isNil(variable.value) ? (
    <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
  ) : (
    <>{variable.value}</>
  );

  return (
    <NodeViewWrapper as="span">
      <Text as="span" variant="highlighted" zIndex={10} userSelect="text">
        {rendered}
      </Text>
    </NodeViewWrapper>
  );
};
