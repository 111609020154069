import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { CreateIncidentRequest, IncidentSeverity } from '@piccolohealth/pbs-common';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { HookedSubmitButton } from '../../components/forms/HookedSubmitButton';
import { createModal } from '../../components/generic/Modal';
import { useCreateIncidentMutation } from '../../graphql/hooks/useCreateIncidentMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { dateTimeSchema } from '../../utils/zod';
import { IncidentForm } from './IncidentForm';

type FormValues = CreateIncidentRequest & {
  participant?: {
    id: string;
  } | null;
  reviewers: string[];
};

const schema: z.ZodSchema<Partial<FormValues>> = z.object({
  description: z.string(),
  setting: z.string(),
  participant: z
    .object({
      id: z.string(),
    })
    .nullish(),
  reviewers: z.array(z.string()),
  severity: z.nativeEnum(IncidentSeverity),
  timestamp: dateTimeSchema,
  ndisReportableIncident: z.boolean(),
  witnesses: z.array(
    z.object({
      id: z.string(),
      name: z.string(),
      phone: z.string().nullish(),
      email: z.string().email().nullish(),
      description: z.string().nullish(),
    }),
  ),
  labelIds: z.array(z.string()),
});

export const IncidentCreateModal = createModal<{}>((props) => {
  const { modal } = props;
  const { errorToast, successToast, organization } = useAppContext();

  const { hide, remove, visible } = modal;

  const methods = useForm<FormValues>({
    defaultValues: {
      description: undefined,
      setting: undefined,
      participant: undefined,
      reviewers: [],
      severity: undefined,
      ndisReportableIncident: false,
      timestamp: undefined,
      witnesses: [],
      labelIds: [],
    },
    mode: 'all',
    resolver: zodResolver(schema),
  });

  const mutation = useCreateIncidentMutation();

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      const { participant, reviewers, ...rest } = values;

      await mutation
        .mutateAsync({
          organizationId: organization.id,
          request: {
            ...P.deepTrim(rest),
            participantId: participant?.id,
            reviewerIds: reviewers,
          },
        })
        .then(() => {
          hide();
          successToast('Incident created successfully');
        })
        .catch((err) => {
          errorToast(`Error creating incident: ${err.message}`);
        });
    },
    [mutation, organization.id, hide, successToast, errorToast],
  );

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={remove} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <Box as="form" display="contents" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <ModalHeader>
              <Flex align="start">
                <Box>
                  <Heading size="md">Add an incident</Heading>
                  <Text fontSize="md" fontWeight="normal" color="secondary" mt={2}>
                    Please complete the following to proceed
                  </Text>
                </Box>
                <Spacer />
                <ModalCloseButton position="unset" top="unset" right="unset" />
              </Flex>
            </ModalHeader>
            <ModalBody>
              <IncidentForm />
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button size="sm" onClick={hide}>
                  Close
                </Button>
                <HookedSubmitButton size="sm">Create</HookedSubmitButton>
              </HStack>
            </ModalFooter>
          </Box>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
