const baseStyle = () => ({
  rounded: 'md',
  p: 2,
  bg: 'gray.700',
  color: 'white',
  openDelay: 300,
});

export const Tooltip = {
  baseStyle,
};
