import gql from 'graphql-tag';

export const CreateLocation = gql`
  mutation CreateLocation($organizationId: ID!, $request: CreateLocationRequest!) {
    createLocation(organizationId: $organizationId, request: $request) {
      id
      name
      color
    }
  }
`;
