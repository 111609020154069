import React from 'react';
import { useController } from 'react-hook-form';
import { EpisodicSeverityScales } from './EpisodicSeverityScales';

interface Props {
  name: string;
}

export const HookedEpisodicSeverityScales = (props: Props) => {
  const { name } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({ name });

  return <EpisodicSeverityScales value={inputProps.value} onChange={inputProps.onChange} />;
};
