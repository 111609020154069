import gql from 'graphql-tag';

export const RestoreParticipantDocumentVersion = gql`
  mutation RestoreParticipantDocumentVersion(
    $organizationId: ID!
    $participantId: ID!
    $participantDocumentId: ID!
    $versionId: ID!
  ) {
    restoreParticipantDocumentVersion(
      organizationId: $organizationId
      participantId: $participantId
      participantDocumentId: $participantDocumentId
      versionId: $versionId
    ) {
      id
      versionId
      participantId
    }
  }
`;
