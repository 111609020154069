import gql from 'graphql-tag';

export const DeleteIncidentFile = gql`
  mutation DeleteIncidentFile($organizationId: ID!, $incidentId: ID!, $incidentFileId: ID!) {
    deleteIncidentFile(
      organizationId: $organizationId
      incidentId: $incidentId
      incidentFileId: $incidentFileId
    )
  }
`;
