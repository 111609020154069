import gql from 'graphql-tag';

export const DeleteParticipantNote = gql`
  mutation DeleteParticipantNote(
    $organizationId: ID!
    $participantId: ID!
    $participantNoteId: ID!
  ) {
    deleteParticipantNote(
      organizationId: $organizationId
      participantId: $participantId
      participantNoteId: $participantNoteId
    )
  }
`;
