import { Button, HStack, Icon, Tag, TagLabel } from '@chakra-ui/react';
import * as Common from '@piccolohealth/pbs-common';
import { createColumnHelper, DataTable, Empty } from '@piccolohealth/ui';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { FormSection } from '../../components/forms/FormSection';
import { FormStack } from '../../components/forms/FormStack';
import { showModal } from '../../components/generic/Modal';
import { useAppContext } from '../../hooks/useAppContext';
import { LocationCreateModal } from './locations/LocationCreateModal';
import { LocationDeleteModal } from './locations/LocationDeleteModal';
import { LocationUpdateModal } from './locations/LocationUpdateModal';

export const LocationSettings = () => {
  const { organization } = useAppContext();

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<Common.Location>();

    return [
      columnHelper.accessor('name', {
        header: 'Name',
        cell: (props) => (
          <Tag colorScheme={props.row.original.color} size="sm">
            <TagLabel>{props.row.original.name}</TagLabel>
          </Tag>
        ),
        minSize: 200,
      }),
      columnHelper.display({
        id: 'actions',
        header: 'Actions',
        minSize: 100,
        maxSize: 120,
        cell: (props) => (
          <HStack>
            <Button
              size="xs"
              variant="link"
              onClick={() =>
                showModal(LocationUpdateModal, {
                  location: props.row.original,
                })
              }
            >
              Edit
            </Button>
            <Button
              size="xs"
              variant="link"
              colorScheme="red"
              onClick={() =>
                showModal(LocationDeleteModal, {
                  locationId: props.row.original.id,
                })
              }
            >
              Delete
            </Button>
          </HStack>
        ),
      }),
    ];
  }, []);

  return (
    <FormSection
      heading="Locations"
      description="The locations your organization operates in"
      maxW="lg"
    >
      <FormStack>
        <DataTable
          columns={columns}
          data={organization.locations}
          renderEmpty={() => <Empty title="No locations found" />}
          size="sm"
        />
        <Button
          flexShrink={0}
          size="sm"
          leftIcon={<Icon as={FaPlus} />}
          onClick={() => showModal(LocationCreateModal)}
        >
          Add new location
        </Button>
      </FormStack>
    </FormSection>
  );
};
