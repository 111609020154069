import { Button, ButtonProps, Icon, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import React from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { IncidentsFilter } from '../../hooks/useIncidentsFilter';
import { IncidentExportCsvMenuItem } from './IncidentExportCsvMenuItem';

interface Props extends Omit<ButtonProps, 'filter'> {
  filter: IncidentsFilter;
}

export const IncidentsActionMenu = (props: Props) => {
  const { filter, ...rest } = props;
  return (
    <Menu isLazy lazyBehavior="keepMounted">
      <MenuList>
        <IncidentExportCsvMenuItem filter={filter} />
      </MenuList>
      <MenuButton as={Button} variant="outline" rightIcon={<Icon as={FaAngleDown} />} {...rest}>
        Actions
      </MenuButton>
    </Menu>
  );
};
