import gql from 'graphql-tag';

export const CreateParticipantFileLabel = gql`
  mutation CreateParticipantFileLabel(
    $organizationId: ID!
    $participantId: ID!
    $participantFileId: ID!
    $labelId: ID!
  ) {
    createParticipantFileLabel(
      organizationId: $organizationId
      participantId: $participantId
      participantFileId: $participantFileId
      labelId: $labelId
    ) {
      id
    }
  }
`;
