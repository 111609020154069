import gql from 'graphql-tag';

export const Organizations = gql`
  query Organizations {
    organizations {
      id
      name
      logo
      timezone
      locale
      forms {
        id
        type
        name
        url
        labelMappings {
          create {
            id
            name
            description
            color
          }
          update {
            id
            name
            description
            color
          }
        }
      }
      featureFlags
    }
  }
`;
