export const tiptapCss = {
  // Client side only. Won't be applied in SSR
  '.csr .ProseMirror': {
    minH: 'full',
    pb: '140px',
    px: 16,

    'div[data-type="pageBreak"]': {
      my: 1,
      opacity: 1,
      display: 'flex',
      flexDir: 'column',
      h: '22.75px',
      justifyContent: 'center',
      rounded: 'md',

      '&.ProseMirror-selectednode': {
        opacity: 1,
        bg: 'blue.50',
      },

      hr: {
        borderColor: 'gray.400',
        borderWidth: '1px',
        borderStyle: 'dashed',
      },
    },

    // Comments
    'span[data-comment][data-status="open"]': {
      background: 'rgba(255,212,0,.14)',
      borderBottom: '2px solid rgb(255, 212, 0)',
      py: '2px',
      _hover: {
        background: 'rgba(255,212,0,.36)',
      },
    },
    'span[data-comment][data-status="resolved"]': {
      _hover: {
        background: 'rgba(255,212,0,.36)',
        borderBottom: '2px solid rgb(255, 212, 0)',
        py: '2px',
      },
    },

    // Give a remote user a caret
    '.collaboration-cursor__caret': {
      borderLeft: '1px solid #0d0d0d',
      borderRight: '1px solid #0d0d0d',
      marginLeft: '-1px',
      marginRight: '-1px',
      pointerEvents: 'none',
      position: 'relative',
      wordBreak: 'normal',
    },

    // Render the username above the caret
    '.collaboration-cursor__label': {
      borderRadius: '3px 3px 3px 0',
      color: '#0d0d0d',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '600',
      left: '-1px',
      lineHeight: 'normal',
      padding: '0.1rem 0.3rem',
      position: 'absolute',
      top: '-1.4em',
      userSelect: 'none',
      whiteSpace: 'nowrap',
    },

    '.is-empty': {
      _before: {
        color: 'gray.400',
        content: 'attr(data-placeholder)',
        float: 'left',
        height: 0,
        pointerEvents: 'none',
      },
    },
  },

  // SSR only. Won't be applied in client
  // Why is no space between the selectors? If I add space, doesnt work in puppeteer
  '.ssr.ProseMirror': {
    overflowY: 'hidden',
  },

  // Applies in client and SSR mode
  '.ProseMirror': {
    // All elements should have a max width, and be centered.
    '& > *': {
      ml: 'auto',
      mr: 'auto',
      maxW: '858px',
    },

    // All elements will have some margin on the top and bottom.
    p: {
      fontSize: 'md',
      lineHeight: '1.625',
      my: 1,
      _empty: {
        _before: {
          content: "' '",
          whiteSpace: 'pre',
        },
      },
    },

    // Avoid breaking inside headings
    h1: {
      fontSize: '4xl',
      fontWeight: 'bold',
      breakInside: 'avoid',
      my: 3,
    },

    h2: {
      fontSize: '2xl',
      fontWeight: 'bold',
      breakInside: 'avoid',
      my: 3,
    },

    h3: {
      fontSize: 'lg',
      fontWeight: 'bold',
      breakInside: 'avoid',
      my: 3,
    },

    blockquote: {
      rounded: 'md',
      bg: 'gray.100',
      breakInside: 'avoid',
      px: 6,
      py: 3,
      my: 3,
    },

    ul: {
      px: 8,
      my: 3,
    },

    ol: {
      px: 8,
      my: 3,
      listStyleType: 'decimal',
    },

    // Nested ol 1 layer deep, show a, b, c
    'ol > li > ol': {
      listStyleType: 'lower-alpha',
    },

    // Nested ol 2 layers deep, show i, ii, iii
    'ol > li > ol > li > ol': {
      listStyleType: 'lower-roman',
    },

    li: {
      my: 1,
      fontSize: 'md',
      lineHeight: '1.625',
    },

    table: {
      width: 'full',
      tableLayout: 'fixed',
      overflow: 'hidden',
      borderWidth: '2px',
      borderColor: 'gray.200',
      borderCollapse: 'collapse',
      borderLeft: 'none',
      borderRight: 'none',
      my: 3,

      p: {
        // No padding for paragraphs inside tables
        my: 0,
      },

      th: {
        fontWeight: 'semibold',
        lineHeight: '1.5',
        bg: 'gray.100',
        borderWidth: '2px',
        borderColor: 'gray.200',
        borderCollapse: 'collapse',
        h: '26px',
        minHeight: 6,
        textAlign: 'left',
        verticalAlign: 'top',
        position: 'relative',
        py: 1,
        px: 2,
      },

      td: {
        fontWeight: 'normal',
        lineHeight: '1.5',
        borderWidth: '2px',
        borderColor: 'gray.200',
        borderCollapse: 'collapse',
        h: '26px',
        minHeight: 6,
        textAlign: 'left',
        verticalAlign: 'top',
        position: 'relative',
        py: 1,
        px: 2,
      },

      '.selectedCell': {
        _after: {
          zIndex: 2,
          position: 'absolute',
          content: '""',
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          background: '#6694ff45',
          pointerEvents: 'none',
        },
      },

      '.column-resize-handle': {
        position: 'absolute',
        right: '-1px',
        top: '0px',
        bottom: '-1px',
        width: '2px',
        bg: 'blue.200',
        pointerEvents: 'none',
      },
    },

    'div[data-type="pageBreak"]': {
      my: 1,
      opacity: 0,
      display: 'flex',
      h: '22.75px',
    },

    'div[data-type="horizontalRule"]': {
      my: 1,
      display: 'flex',
      flexDir: 'column',
      h: '22.75px',
      justifyContent: 'center',
      rounded: 'md',

      '&.ProseMirror-selectednode': {
        bg: 'blue.50',
      },

      hr: {
        borderTopColor: 'gray.400',
        borderTopWidth: '1px',
        borderTopStyle: 'solid',
      },
    },
  },

  '@keyframes highlight': {
    from: { bg: 'blue.200' },
    to: { bg: 'transparent' },
  },

  '.highlighted': {
    animation: 'highlight 2s',
  },
};
