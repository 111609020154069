import { useAuth0 } from '@auth0/auth0-react';
import { useIntercom } from 'react-use-intercom';
import { User } from '@piccolohealth/pbs-common';
import React from 'react';
import { Spin } from '@piccolohealth/ui';
import { useCurrentUserQuery } from '../graphql/hooks/useUserQuery';
import { Error } from '../components/generic/Error';

export interface UserContext extends User {
  isLoading: boolean;
}

export const UserContext = React.createContext<UserContext>(null as any);

export const UserContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props;
  const { isAuthenticated, isLoading: auth0IsLoading } = useAuth0();
  const { boot: bootIntercom } = useIntercom();
  const {
    isLoading: userIsLoading,
    data,
    error,
  } = useCurrentUserQuery({}, { enabled: isAuthenticated });

  const user = data?.user as User;
  const isLoading = auth0IsLoading || (userIsLoading && isAuthenticated);

  const content = () => {
    if (error) {
      return <Error error={error} />;
    }

    if (isLoading) {
      return <Spin />;
    }

    return children;
  };

  React.useEffect(() => {
    if (user && !isLoading) {
      bootIntercom({
        userId: user.id,
        name: user.name,
        email: user.email,
        userHash: user.intercomHash,
      });
    }
  }, [user, isLoading, bootIntercom]);

  const value: UserContext = {
    ...user,
    isLoading,
  };

  return <UserContext.Provider value={value}>{content()}</UserContext.Provider>;
};

export const useUser: () => UserContext = () => {
  return React.useContext(UserContext);
};
