import { Flex, Stack, HStack, SkeletonText, Spacer, SkeletonCircle, Box } from '@chakra-ui/react';
import React from 'react';
import { PAGE_WIDTH } from '../utils';

export const ConnectingContent = () => {
  return (
    <Flex flexDir="column" h="full" w="full" maxW={PAGE_WIDTH} px={6} alignSelf="center">
      <Stack w="full" mt={2} spacing={4}>
        <HStack>
          <SkeletonText noOfLines={1} skeletonHeight="32px" w="460px" />
          <Spacer />
          <SkeletonCircle size="28px" speed={0} />
          <Box w="10px" />
        </HStack>
        <SkeletonText noOfLines={1} skeletonHeight="28px" />
        <SkeletonText noOfLines={4} spacing={4} skeletonHeight="2" w="xs" />
      </Stack>
    </Flex>
  );
};
