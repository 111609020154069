import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  CreateParticipantFieldTemplateRequest,
  FieldTemplateType,
  ParticipantFieldGroupTemplate,
} from '@piccolohealth/pbs-common';
import { SelectOption } from '@piccolohealth/ui';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { HookedSubmitButton } from '../../../components/forms/HookedSubmitButton';
import { createModal } from '../../../components/generic/Modal';
import { useCreateParticipantFieldTemplateMutation } from '../../../graphql/hooks/useCreateParticipantFieldTemplateMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { ParticipantFieldTemplateForm } from './ParticipantFieldTemplateForm';

type FormValues = CreateParticipantFieldTemplateRequest;

const schema: z.ZodSchema<FormValues> = z.object({
  participantFieldGroupTemplateId: z.string(),
  name: z.string(),
  type: z.nativeEnum(FieldTemplateType),
  index: z.number(),
  attributes: z.any(),
});

interface Props {
  participantFieldGroupTemplateId: string;
  index: number;
  fieldGroupTemplates: ParticipantFieldGroupTemplate[];
}

export const ParticipantFieldTemplateCreateModal = createModal<Props>((props) => {
  const { participantFieldGroupTemplateId, index, fieldGroupTemplates, modal } = props;
  const { hide, remove, visible } = modal;
  const { errorToast, successToast, organization } = useAppContext();

  const initialValues: FormValues = {
    participantFieldGroupTemplateId,
    name: '',
    type: FieldTemplateType.Input,
    attributes: {},
    index,
  };

  const methods = useForm({
    defaultValues: initialValues,
    mode: 'all',
    resolver: zodResolver(schema),
  });

  const mutation = useCreateParticipantFieldTemplateMutation();

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      if (values.attributes.options) {
        values.attributes.options = values.attributes.options.map(
          (option: SelectOption<string>) => ({
            value: option.value,
            label: option.value,
            raw: option.value,
          }),
        );
      }

      await mutation
        .mutateAsync({
          organizationId: organization.id,
          request: values,
        })
        .then(() => {
          hide();
          successToast('Participant field template created successfully');
        })
        .catch((err) => {
          errorToast(`Error creating participant field template: ${err.message}`);
        });
    },
    [mutation, organization.id, hide, successToast, errorToast],
  );

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={remove} size="xl">
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <Box as="form" display="contents" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <ModalHeader>
              <Flex align="start">
                <Box>
                  <Heading size="md">Add a field</Heading>
                  <Text fontSize="md" fontWeight="normal" color="secondary" mt={2}>
                    Please complete the following to proceed
                  </Text>
                </Box>
                <Spacer />
                <ModalCloseButton position="unset" top="unset" right="unset" />
              </Flex>
            </ModalHeader>
            <ModalBody>
              <ParticipantFieldTemplateForm fieldGroupTemplates={fieldGroupTemplates} />
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button size="sm" onClick={hide}>
                  Close
                </Button>
                <HookedSubmitButton size="sm">Create field</HookedSubmitButton>
              </HStack>
            </ModalFooter>
          </Box>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
