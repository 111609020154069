import { Button, Divider, Flex, Icon, IconButton } from '@chakra-ui/react';
import { CreateIncidentWitnessInput } from '@piccolohealth/pbs-common';
import { uuid } from '@piccolohealth/util';
import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { FormStack } from './FormStack';
import { HookedFormItem } from './HookedFormItem';
import { HookedInput } from './HookedInput';

interface Props {
  name: string;
}

export const HookedWitnessesControl = (props: Props) => {
  const { name } = props;
  const { fields, append, remove } = useFieldArray({
    name,
  });

  const witnesses = fields as unknown as CreateIncidentWitnessInput[];

  const onAddWitness = React.useCallback(() => {
    append({
      id: uuid(),
      name: null,
      phone: null,
      email: null,
      description: null,
    });
  }, [append]);

  const onRemoveWitness = React.useCallback(
    (index: number) => {
      remove(index);
    },
    [remove],
  );

  return (
    <FormStack>
      {witnesses.map((witness, index) => {
        return (
          <>
            <FormStack orientation="horizontal" key={`witness-${index}`} alignItems="stretch">
              <FormStack spacing={4} pl={4} ml={1} borderLeftWidth="2px" borderColor="purple.400">
                <FormStack orientation="horizontal">
                  <HookedFormItem
                    name={`${name}.${index}.name`}
                    label="Name"
                    labelSize="sm"
                    helperText="The witnesses name"
                  >
                    <HookedInput
                      name={`${name}.${index}.name`}
                      size="sm"
                      placeholder="Enter a name"
                    />
                  </HookedFormItem>
                  <HookedFormItem
                    name={`${name}.${index}.email`}
                    label="Email"
                    labelSize="sm"
                    helperText="The witnesses email"
                  >
                    <HookedInput
                      name={`${name}.${index}.email`}
                      size="sm"
                      placeholder="Enter an email"
                    />
                  </HookedFormItem>
                </FormStack>
                <FormStack orientation="horizontal">
                  <HookedFormItem
                    name={`${name}.${index}.phone`}
                    label="Phone"
                    labelSize="sm"
                    helperText="The witnesses phone"
                  >
                    <HookedInput
                      name={`${name}.${index}.phone`}
                      size="sm"
                      placeholder="Enter a phone number"
                    />
                  </HookedFormItem>
                  <HookedFormItem
                    name={`${name}.${index}.description`}
                    label="Description"
                    labelSize="sm"
                    helperText="Who is the witness?"
                  >
                    <HookedInput
                      name={`${name}.${index}.description`}
                      size="sm"
                      placeholder="Enter a description"
                    />
                  </HookedFormItem>
                </FormStack>
              </FormStack>
              <Flex alignItems="center" w="4">
                <IconButton
                  aria-label="Remove witness"
                  size="xs"
                  variant="ghost"
                  icon={<FaTrash />}
                  onClick={() => onRemoveWitness(index)}
                />
              </Flex>
            </FormStack>
            {index !== witnesses.length - 1 && <Divider />}
          </>
        );
      })}
      <Button size="sm" onClick={() => onAddWitness()} leftIcon={<Icon as={FaPlus} />}>
        Add a witness
      </Button>
    </FormStack>
  );
};
