import { DateTime, P } from '@piccolohealth/util';
import { GridStack } from 'gridstack';
import React from 'react';

export interface GridItem {
  id: string;
  x: number;
  y: number;
  w: number;
  h: number;
}

export interface GridDivElement extends HTMLDivElement {
  gridstack?: GridStack;
}

export interface UseGridOptions<A> {
  items: A[];
  startDate: DateTime;
  endDate: DateTime;
  onStartDateChange: (date: DateTime) => void;
  onEndDateChange: (date: DateTime) => void;
  onUpdate: (items: A[]) => void;
}

export const useGrid = <A extends GridItem>(options: UseGridOptions<A>) => {
  const { items, startDate, endDate, onStartDateChange, onEndDateChange, onUpdate } = options;

  const refs = React.useRef<Record<string, React.RefObject<GridDivElement>>>({});
  const gridRef = React.useRef<GridDivElement>(null);

  items.forEach((item) => {
    if (!refs.current[item.id]) {
      refs.current[item.id] = React.createRef();
    }
  });

  const onSave = React.useCallback(() => {
    const grid = gridRef.current?.gridstack;

    if (!grid) {
      return;
    }

    const requestItems = P.compact(
      grid.engine.nodes.map((node) => {
        const item = items.find((item) => item.id === node.id);

        if (!item) {
          return null;
        }

        return {
          ...item,
          id: item.id,
          x: node.x ?? 0,
          y: node.y ?? 0,
          w: node.w ?? 1,
          h: node.h ?? 1,
        };
      }),
    );

    onUpdate(requestItems);
  }, [items, onUpdate]);

  React.useEffect(() => {
    if (!gridRef.current) {
      return;
    }

    const grid = GridStack.init(
      {
        cellHeight: 250,
        cellHeightThrottle: 100,
        minRow: 6,
        column: 4,
        animate: false,
        columnOpts: {
          columnWidth: 480, // wanted width
          layout: 'moveScale',
        },
        float: false,
        handleClass: 'grid-stack-item-draghandle',
      },
      gridRef.current,
    );

    grid.on('dragstop', () => {
      onSave();
    });

    grid.on('resizestop', () => {
      onSave();
    });

    grid.batchUpdate();
    grid.removeAll(false);

    items.forEach((item) => {
      const itemRef = refs.current?.[item.id]?.current;

      if (!itemRef) {
        return;
      }

      grid.makeWidget(itemRef, {
        id: item.id,
        w: item.w,
        h: item.h,
        x: item.x,
        y: item.y,
      });
    });

    grid.batchUpdate(false);
  }, [items, onSave]);

  React.useEffect(() => {
    const grid = gridRef.current?.gridstack;

    if (!grid) {
      return;
    }

    grid.load(
      items.map((item) => {
        const el = refs.current?.[item.id]?.current;

        return {
          ...item,
          id: item.id,
          x: item.x,
          y: item.y,
          w: item.w,
          h: item.h,
          el,
        };
      }),
    );
  }, [items]);

  const onAdd = React.useCallback(
    async (item: A) => {
      const grid = gridRef.current?.gridstack;

      if (!grid) {
        return;
      }

      const proposedPosition = P.run(() => {
        for (let y = 0; y < grid.getRow(); y++) {
          for (let x = 0; x < grid.getColumn(); x++) {
            if (
              grid.willItFit({ x, y, w: item.w, h: item.h }) &&
              grid.isAreaEmpty(x, y, item.w, item.h)
            ) {
              return { x, y };
            }
          }
        }

        return null;
      });

      if (!proposedPosition) {
        throw new Error('No available position');
      }

      await onUpdate([
        ...items,
        {
          ...item,
          x: proposedPosition.x,
          y: proposedPosition.y,
        },
      ]);
    },
    [items, onUpdate],
  );

  const onRemove = React.useCallback(
    async (id: string) => {
      await onUpdate(items.filter((item) => item.id !== id));
    },
    [items, onUpdate],
  );

  const onEdit = React.useCallback(
    async (editedItem: A) => {
      const existingItems = items.map((item) => {
        if (item.id !== editedItem.id) {
          return item;
        }

        return {
          ...editedItem,
          id: item.id,
          x: item.x,
          y: item.y,
          w: item.w,
          h: item.h,
        };
      });

      await onUpdate(P.compact(existingItems));
    },
    [items, onUpdate],
  );

  return {
    gridRef,
    refs,
    startDate,
    endDate,
    onAdd,
    onEdit,
    onRemove,
    onStartDateChange,
    onEndDateChange,
  };
};

export type UseGridReturn<A extends GridItem> = ReturnType<typeof useGrid<A>>;
