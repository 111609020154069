import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { CreateLocationRequest } from '@piccolohealth/pbs-common';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { HookedSubmitButton } from '../../../components/forms/HookedSubmitButton';
import { createModal } from '../../../components/generic/Modal';
import { useCreateLocationMutation } from '../../../graphql/hooks/useCreateLocationMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { LocationForm } from './LocationForm';

type FormValues = CreateLocationRequest;

const schema: z.ZodSchema<FormValues> = z.object({
  name: z.string(),
  color: z.string(),
});

export const LocationCreateModal = createModal((props) => {
  const { modal } = props;
  const { hide, remove, visible } = modal;
  const { errorToast, successToast, organization } = useAppContext();

  const initialValues: FormValues = {
    name: '',
    color: 'blue',
  };

  const methods = useForm({
    defaultValues: initialValues,
    mode: 'all',
    resolver: zodResolver(schema),
  });

  const mutation = useCreateLocationMutation();

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      await mutation
        .mutateAsync({
          organizationId: organization.id,
          request: values,
        })
        .then(() => {
          hide();
          successToast('Location created successfully');
        })
        .catch((err) => {
          errorToast(`Error creating location: ${err.message}`);
        });
    },
    [mutation, organization.id, hide, successToast, errorToast],
  );

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={remove} size="xl">
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <Box as="form" display="contents" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <ModalHeader>
              <Flex align="start">
                <Box>
                  <Heading size="md">Add a location</Heading>
                  <Text fontSize="md" fontWeight="normal" color="secondary" mt={2}>
                    Please complete the following to proceed
                  </Text>
                </Box>
                <Spacer />
                <ModalCloseButton position="unset" top="unset" right="unset" />
              </Flex>
            </ModalHeader>
            <ModalBody>
              <LocationForm />
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button size="sm" onClick={modal.hide}>
                  Close
                </Button>
                <HookedSubmitButton size="sm">Create location</HookedSubmitButton>
              </HStack>
            </ModalFooter>
          </Box>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
