import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Flex,
  Heading,
  HStack,
  SkeletonCircle,
  SkeletonText,
  Stack,
  Tag,
  TagLabel,
} from '@chakra-ui/react';
import { Template } from '@piccolohealth/pbs-common';
import { FadeImage } from '@piccolohealth/ui';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { showModal } from '../../components/generic/Modal';
import { useAppContext } from '../../hooks/useAppContext';
import { usePermission } from '../../hooks/usePermission';
import { TemplateDeleteModal } from './TemplateDeleteModal';
import { TemplateEditModal } from './TemplateEditModal';
import { TemplateStatusTag } from './TemplateStatusTag';

interface Props {
  template: Template;
}

export const TemplateCard = (props: Props) => {
  const { template } = props;

  const { organization } = useAppContext();
  const editTemplatePermission = usePermission('update', 'template');
  const deleteTemplatePermission = usePermission('delete', 'template');

  return (
    <Card w="2xs" h="xs">
      <Flex
        h="sm"
        as={NavLink}
        to={`/organizations/${organization.id}/templates/${template.id}`}
        flexDir="column"
        cursor="pointer"
        overflow="hidden"
      >
        <CardHeader mb={0} flexShrink={1} py={4} px={4}>
          <Stack h="full" spacing={1}>
            <Stack spacing={2}>
              <Heading size="xs" noOfLines={1}>
                {template.name}
              </Heading>
              <HStack>
                <TemplateStatusTag status={template.status} />
                {template.labels.map((label) => (
                  <Tag key={label.id} size="sm" colorScheme={label.color}>
                    <TagLabel>{label.name}</TagLabel>
                  </Tag>
                ))}
              </HStack>
            </Stack>
          </Stack>
        </CardHeader>
        <Divider />
        <CardBody px={3} py={3} flexShrink={1}>
          <Box h="xs" overflow="hidden">
            {template.thumbnailUrl ? (
              <FadeImage src={template.thumbnailUrl} />
            ) : (
              <Box p={4}>
                <SkeletonCircle size="10" speed={0} />
                <SkeletonText mt="4" noOfLines={16} spacing="2" skeletonHeight="2" />
              </Box>
            )}
          </Box>
        </CardBody>
      </Flex>
      <CardFooter px={4} py={4} flexShrink={0}>
        <HStack>
          <Button
            variant="link"
            color="danger"
            size="xs"
            isDisabled={!deleteTemplatePermission}
            onClick={(e) => {
              e.preventDefault();
              showModal(TemplateDeleteModal, {
                templateId: template.id,
              });
            }}
          >
            Delete
          </Button>
          <Button
            variant="link"
            color="secondary"
            size="xs"
            isDisabled={!editTemplatePermission}
            onClick={(e) => {
              e.preventDefault();
              showModal(TemplateEditModal, {
                template,
              });
            }}
          >
            Settings
          </Button>
        </HStack>
      </CardFooter>
    </Card>
  );
};
