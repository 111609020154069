import { Input as ChakraInput } from '@chakra-ui/react';

const sizes = {
  sm: {
    field: {
      borderRadius: 'md',
    },
  },
  xs: {
    field: {
      borderRadius: 'md',
    },
  },
};

const variants = {
  outline: () => {
    return {};
  },
  unstyled: () => {
    return {
      field: {
        height: 'var(--input-height)',
        px: 'var(--input-padding)',
        py: 2,
        _hover: {
          bg: 'gray.100',
        },
        _disabled: {
          opacity: '1',
        },
        _focus: {
          bg: 'gray.100',
        },
        _focusVisible: {
          bg: 'gray.100',
        },
        _focusWithin: {
          bg: 'gray.100',
        },
        _expanded: {
          bg: 'gray.100',
        },
      },
    };
  },
};

ChakraInput.defaultProps = {
  ...ChakraInput.defaultProps,
  focusBorderColor: 'purple.500',
  autoComplete: 'off',
  autoCorrect: 'off',
  autoCapitalize: 'off',
} as any;

export const Input = {
  sizes,
  variants,
};
