import { Box, Flex } from '@chakra-ui/react';
import { ScrollArea } from '@piccolohealth/ui';
import React from 'react';
import { useScrollRestoration } from '../../../hooks/useScrollRestoration';

interface Props {
  documentId: string;
}

export const ConnectedContent = (props: React.PropsWithChildren<Props>) => {
  const { children, documentId } = props;

  const { opacity, ref } = useScrollRestoration(documentId);

  return (
    <ScrollArea scrollRef={ref} opacity={opacity}>
      <Box display="grid" flexDir="column" h="full" w="full">
        <Flex>{children}</Flex>
      </Box>
    </ScrollArea>
  );
};
