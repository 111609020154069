import { RadioGroup, RadioGroupProps } from '@piccolohealth/ui';
import React from 'react';
import { useController } from 'react-hook-form';

interface Props extends Omit<RadioGroupProps, 'value' | 'onChange'> {
  name: string;
}

export const HookedBooleanRadioGroup = (props: Props) => {
  const { name, ...rest } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({ name });

  const value = inputProps.value === true ? 'true' : 'false';

  const onChange = React.useCallback(
    (value: string) => {
      inputProps.onChange(value === 'true' ? true : false);
    },
    [inputProps],
  );

  return <RadioGroup {...rest} value={value} onChange={onChange} />;
};
