import gql from 'graphql-tag';

export const DeleteParticipantDocumentLabel = gql`
  mutation DeleteParticipantDocumentLabel(
    $organizationId: ID!
    $participantId: ID!
    $participantDocumentId: ID!
    $labelId: ID!
  ) {
    deleteParticipantDocumentLabel(
      organizationId: $organizationId
      participantId: $participantId
      participantDocumentId: $participantDocumentId
      labelId: $labelId
    )
  }
`;
