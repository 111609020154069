import { Tag, TagProps } from '@chakra-ui/react';
import { IncidentStatus } from '@piccolohealth/pbs-common';
import React from 'react';

export const incidentStatusColorName = (severity: IncidentStatus) => {
  switch (severity) {
    case IncidentStatus.AwaitingReview:
      return ['yellow', 'Awaiting review'];
    case IncidentStatus.Reviewed:
      return ['green', 'Reviewed'];
  }
};

interface Props extends TagProps {
  status: IncidentStatus;
}

export const IncidentStatusTag = (props: Props) => {
  const { status, ...rest } = props;

  const [color, name] = incidentStatusColorName(status);

  return (
    <Tag colorScheme={color} {...rest}>
      {name}
    </Tag>
  );
};
