import { Box, Button, Divider, HStack, Icon, Spacer, Stack, Text } from '@chakra-ui/react';
import { ParticipantDocument } from '@piccolohealth/pbs-common';
import { NumberedBadge, Result, ScrollArea, Select, SelectOption } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import { Editor } from '@tiptap/react';
import React from 'react';
import { FaBell } from 'react-icons/fa';
import { UserMultiChooser } from '../../components/forms/UserMultiChooser';
import {
  CommentStatus,
  getCommentThreads,
} from '../../components/tiptap/extensions/comment/Comment';
import { CommentThreadContent } from '../../components/tiptap/menus/comment/CommentThreadContent';
import { useNotifyParticipantDocumentAuthorsMutation } from '../../graphql/hooks/useNotifyParticipantDocumentAuthorsMutation';
import { useNotifyParticipantDocumentReviewersMutation } from '../../graphql/hooks/useNotifyParticipantDocumentReviewersMutation';
import { useUpdateParticipantDocumentMutation } from '../../graphql/hooks/useUpdateParticipantDocumentMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { scrollIntoView } from '../../utils/scrollIntoView';
import { ParticipantDocumentStatusMenu } from './ParticipantDocumentStatusMenu';

interface StatusProps {
  participantDocument: ParticipantDocument;
}

export const Status = (props: StatusProps) => {
  const { participantDocument } = props;

  return (
    <Stack py={2} px={3}>
      <Text fontWeight="semibold" fontSize="sm">
        Status
      </Text>
      <Box>
        <ParticipantDocumentStatusMenu participantDocument={participantDocument} />
      </Box>
    </Stack>
  );
};

interface AuthorsProps {
  participantDocument: ParticipantDocument;
}

export const Authors = (props: AuthorsProps) => {
  const { participantDocument } = props;

  const { organization, user, successToast, errorToast } = useAppContext();
  const updateParticipantDocumentMutation = useUpdateParticipantDocumentMutation();
  const notifyAuthorsMutation = useNotifyParticipantDocumentAuthorsMutation();

  const authorIds = participantDocument.authors.map((author) => author.id) ?? [];

  const onClick = async () => {
    await notifyAuthorsMutation
      .mutateAsync({
        organizationId: organization.id,
        participantId: participantDocument.participantId,
        participantDocumentId: participantDocument.id,
        userId: user.id,
      })
      .then(async () => {
        successToast('Participant document authors notified successfully');
      })
      .catch((err) => {
        errorToast(`Error notifying participant document authors: ${err.message}`);
      });
  };

  const onChange = async (authors: string[]) => {
    await updateParticipantDocumentMutation
      .mutateAsync({
        organizationId: organization.id,
        participantId: participantDocument.participantId,
        participantDocumentId: participantDocument.id,
        request: {
          authorIds: authors,
        },
      })
      .catch((err) => {
        errorToast(`Error updating participant document authors: ${err.message}`);
      });
  };

  return (
    <Stack py={2} px={3}>
      <Text fontWeight="semibold" fontSize="sm">
        Authors
      </Text>

      <UserMultiChooser
        onChange={onChange}
        value={authorIds}
        participantId={participantDocument.participantId}
        placeholder="Select some authors"
      />
      <Button
        size="xs"
        variant="solid"
        leftIcon={<Icon as={FaBell} />}
        isLoading={notifyAuthorsMutation.isLoading}
        loadingText="Notifying authors..."
        onClick={onClick}
      >
        Notify authors
      </Button>
    </Stack>
  );
};

interface ReviewersProps {
  participantDocument: ParticipantDocument;
}

export const Reviewers = (props: ReviewersProps) => {
  const { participantDocument } = props;

  const { organization, user, successToast, errorToast } = useAppContext();
  const updateParticipantDocumentMutation = useUpdateParticipantDocumentMutation();
  const notifyReviewersMutation = useNotifyParticipantDocumentReviewersMutation();

  const reviewerIds = participantDocument.reviewers.map((reviewer) => reviewer.id) ?? [];

  const onClick = async () => {
    await notifyReviewersMutation
      .mutateAsync({
        organizationId: organization.id,
        participantId: participantDocument.participantId,
        participantDocumentId: participantDocument.id,
        userId: user.id,
      })
      .then(async () => {
        successToast('Participant document reviewers notified successfully');
      })
      .catch((err) => {
        errorToast(`Error notifying participant document reviewers: ${err.message}`);
      });
  };

  const onChange = async (reviewers: string[]) => {
    await updateParticipantDocumentMutation
      .mutateAsync({
        organizationId: organization.id,
        participantId: participantDocument.participantId,
        participantDocumentId: participantDocument.id,
        request: {
          reviewerIds: reviewers,
        },
      })
      .catch((err) => {
        errorToast(`Error updating participant document reviewers: ${err.message}`);
      });
  };

  return (
    <Stack py={2} px={3}>
      <Text fontWeight="semibold" fontSize="sm">
        Reviewers
      </Text>

      <UserMultiChooser
        onChange={onChange}
        value={reviewerIds}
        participantId={participantDocument.participantId}
        placeholder="Select some reviewers"
      />
      <Button
        size="xs"
        variant="solid"
        leftIcon={<Icon as={FaBell} />}
        isLoading={notifyReviewersMutation.isLoading}
        loadingText="Notifying reviewers..."
        onClick={onClick}
      >
        Notify reviewers
      </Button>
    </Stack>
  );
};

interface CommentsProps {
  editor: Editor | null;
}

export const Comments = (props: CommentsProps) => {
  const { editor } = props;
  const [filter, setFilter] = React.useState<SelectOption<CommentStatus>>({
    label: 'Open',
    value: 'open',
    raw: 'open',
  });

  const document = editor?.storage?.comment?.document;

  const filterOptions: SelectOption<CommentStatus>[] = [
    { label: 'Open', value: 'open', raw: 'open' },
    { label: 'Resolved', value: 'resolved', raw: 'resolved' },
  ];

  const commentThreads = document ? getCommentThreads(document) : [];

  const filteredCommentThreads = commentThreads.filter(
    (thread) => (thread.status ?? 'open') === filter.value,
  );

  if (!editor) {
    return null;
  }

  const onClickComment = (commentThreadId: string) => {
    const element = editor.view.dom.querySelector(`[data-comment="${commentThreadId}"`);

    if (!element) {
      return;
    }

    scrollIntoView(element);

    element.classList.add('highlighted');

    // Remove the highlight class after 2 seconds
    setTimeout(() => {
      element.classList.remove('highlighted');
    }, 2000);
  };

  const content = P.run(() => {
    if (P.isEmpty(filteredCommentThreads)) {
      return (
        <Result
          status="success"
          title={`You're all caught up`}
          description={
            <Text fontSize="sm" color="secondary">
              No comments to review
            </Text>
          }
        />
      );
    }

    return (
      <Stack spacing={0} divider={<Divider />}>
        {filteredCommentThreads.map((thread) => {
          return (
            <CommentThreadContent
              key={thread.id}
              commentThread={thread}
              editor={editor}
              py={4}
              px={2}
              _hover={{
                bg: 'gray.50',
              }}
              onClick={() => onClickComment(thread.id)}
            />
          );
        })}
      </Stack>
    );
  });

  return (
    <Stack spacing={0}>
      <HStack py={2} px={3}>
        <Text fontWeight="semibold" fontSize="sm">
          Comments
        </Text>
        <Spacer />
        <Select
          options={filterOptions}
          value={filter}
          onChange={setFilter}
          variant="ghost"
          w="fit-content"
          size="xs"
          showArrow={false}
          components={{
            Header: () => null,
            Value: (props) => (
              <HStack>
                <Text>{props.option.label}</Text>
                <NumberedBadge fontSize="9" count={filteredCommentThreads.length} />
              </HStack>
            ),
          }}
        />
      </HStack>
      {content}
    </Stack>
  );
};

interface ParticipantDocumentReviewSectionProps {
  participantDocument: ParticipantDocument;
  editor: Editor | null;
}

export const ParticipantDocumentReviewSection = (props: ParticipantDocumentReviewSectionProps) => {
  const { editor, participantDocument } = props;

  return (
    <ScrollArea w="full" h="full">
      <Stack>
        <Status participantDocument={participantDocument} />
        <Divider />
        <Stack spacing={0}>
          <Authors participantDocument={participantDocument} />
          <Reviewers participantDocument={participantDocument} />
        </Stack>
        <Divider />
        <Comments editor={editor} />
      </Stack>
    </ScrollArea>
  );
};
