import { Heading, HStack, Spacer, Stack } from '@chakra-ui/react';
import { WebSocketStatus } from '@hocuspocus/provider';
import { Template } from '@piccolohealth/pbs-common';
import { Editor } from '@tiptap/react';
import React from 'react';
import { SaveStatusIndicator } from '../../components/tiptap/SaveStatusIndicator';
import { UserStatusIndicator } from '../../components/tiptap/UserStatusIndicator';
import { TemplateActionMenu } from './TemplateActionMenu';

interface Props {
  editor: Editor;
  template: Template;
  status: WebSocketStatus;
  isSaving: boolean;
}

export const TemplateTitleBar = (props: Props) => {
  const { editor, template, status, isSaving } = props;

  return (
    <Stack spacing={4} mt={4}>
      <HStack>
        <Heading size="lg" noOfLines={1}>
          {template.name}
        </Heading>
        <Spacer />
        <HStack justify="end" w="70px">
          <SaveStatusIndicator isSaving={isSaving} />
        </HStack>
        <UserStatusIndicator editor={editor} status={status} />
        <TemplateActionMenu template={template} variant="outline" />
      </HStack>
    </Stack>
  );
};
