import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Tag,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  CreateParticipantNoteRequest,
  Label,
  LabelType,
  Template,
  TemplateStatus,
  TemplateType,
} from '@piccolohealth/pbs-common';
import {
  MultiSelect,
  MultiSelectOption,
  OnChangeRequest,
  ScrollArea,
  Spin,
} from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FaFile } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';
import { FormItem } from '../../components/forms/FormItem';
import { HookedSubmitButton } from '../../components/forms/HookedSubmitButton';
import { Error } from '../../components/generic/Error';
import { createModal, showModal } from '../../components/generic/Modal';
import { useCreateParticipantNoteMutation } from '../../graphql/hooks/useCreateParticipantNoteMutation';
import { useLabelsQuery } from '../../graphql/hooks/useLabelsQuery';
import { useTemplatesQuery } from '../../graphql/hooks/useTemplatesQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { ParticipantDocumentTemplatePreview } from '../templates/ParticipantDocumentTemplatePreview';
import { TemplateStatusTag } from '../templates/TemplateStatusTag';
import { ParticipantNoteEditModal } from './ParticipantNoteEditModal';

interface TemplateLabelFilterControlProps {
  value: string[];
  onChange: (value: string[]) => void;
}

const TemplateLabelFilterControl = (props: TemplateLabelFilterControlProps) => {
  const { value, onChange } = props;

  const { organization } = useAppContext();
  const { data, isLoading } = useLabelsQuery({
    organizationId: organization.id,
    type: LabelType.Template,
  });

  const options: MultiSelectOption<Label>[] = React.useMemo(() => {
    const labels = (data?.organization?.labels as Label[]) ?? [];
    return labels.map((label) => {
      return {
        label: label.name,
        value: label.id,
        raw: label,
        color: label.color,
      };
    });
  }, [data?.organization?.labels]);

  const selectedValues: MultiSelectOption<Label>[] = React.useMemo(() => {
    return (value ?? []).flatMap((label) => {
      return options.find((o) => P.isEqual(o.raw.id, label)) ?? [];
    });
  }, [value, options]);

  const onChangePrime = React.useCallback(
    (req: OnChangeRequest<Label>) => {
      onChange(req.values.map(({ raw }) => raw.id));
    },
    [onChange],
  );

  return (
    <MultiSelect
      isLoading={isLoading}
      selectedValues={selectedValues}
      options={options}
      onChange={onChangePrime}
      size="sm"
      placeholder="All labels"
      optionVariant="tag"
    />
  );
};

interface TemplateSidebarProps {
  templates: Template[];
  activeTemplateId: string | undefined;
  onClick: (template: Template) => void;
}

const TemplatesSidebar = (props: TemplateSidebarProps) => {
  const { templates, activeTemplateId, onClick } = props;

  const [name, setName] = React.useState('');
  const [labels, setLabels] = React.useState<string[]>([]);

  const filteredTemplates = React.useMemo(() => {
    return templates.filter((template) => {
      const nameMatches = template.name.toLowerCase().includes(name.trim().toLowerCase());
      const labelsMatch =
        P.isEmpty(labels) || template.labels.some((label) => labels.includes(label.id));
      return nameMatches && labelsMatch;
    });
  }, [labels, name, templates]);

  return (
    <Stack h="full" w="280px" spacing={4} flexShrink={0}>
      <Stack>
        <FormItem label="Name" labelSize="sm">
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Search templates..."
            size="sm"
          />
        </FormItem>

        <FormItem label="Labels" labelSize="sm">
          <TemplateLabelFilterControl value={labels} onChange={setLabels} />
        </FormItem>
      </Stack>

      <Divider />

      <ScrollArea flexDir="column" overflowY="auto">
        <Stack pr={4}>
          <Text fontSize="xs" fontWeight="bold" color="secondary">
            Participant
          </Text>
          {P.isEmpty(filteredTemplates) && (
            <Text color="secondary" fontSize="sm">
              No templates found...
            </Text>
          )}
          {filteredTemplates.map((template) => (
            <Button
              key={template.id}
              isActive={activeTemplateId === template.id}
              onClick={() => onClick(template)}
              variant="ghost"
              color="
              gray.600"
              size="sm"
              justifyContent="start"
              whiteSpace="initial"
              h="auto"
              leftIcon={<Icon as={FaFile} />}
              px={3}
              py={2}
              rounded="md"
              fontSize="sm"
              fontWeight="semibold"
              textAlign="left"
            >
              {template.name}
            </Button>
          ))}
        </Stack>
      </ScrollArea>
    </Stack>
  );
};

type FormValues = CreateParticipantNoteRequest;

const schema: z.ZodSchema<FormValues> = z.object({
  id: z.string().optional(),
  participantId: z.string(),
  userId: z.string(),
  labelIds: z.array(z.string()),
  templateId: z.string().optional(),
});

interface ParticipantNoteCreateModalProps {
  participantId: string;
}

export const ParticipantNoteCreateModal = createModal<ParticipantNoteCreateModalProps>((props) => {
  const { participantId, modal } = props;
  const { errorToast, successToast, organization, user } = useAppContext();

  const { hide, remove, visible } = modal;

  const query = useTemplatesQuery({
    organizationId: organization.id,
    request: {
      type: [TemplateType.ParticipantNote],
      status: [TemplateStatus.Published],
    },
  });

  const mutation = useCreateParticipantNoteMutation();

  const methods = useForm<FormValues>({
    defaultValues: {
      participantId,
      userId: user.id,
      templateId: undefined,
      labelIds: [],
    },
    mode: 'all',
    resolver: zodResolver(schema),
  });

  const { handleSubmit, watch, setValue } = methods;

  const activeTemplateId = watch('templateId') ?? undefined;

  const templates = React.useMemo(() => {
    return (query.data?.organization?.templates.results as Template[]) ?? [];
  }, [query.data?.organization?.templates]);

  const activeTemplate = templates.find((template) => template.id === activeTemplateId);

  const selectTemplate = React.useCallback(
    (template: Template) => {
      setValue('templateId', template.id, { shouldDirty: true });
    },
    [setValue],
  );

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      await mutation
        .mutateAsync({
          organizationId: organization.id,
          request: {
            id: values.id,
            participantId: values.participantId,
            templateId: values.templateId,
            userId: user.id,
            labelIds: values.labelIds,
          },
        })
        .then((res) => {
          hide();
          successToast('Note created successfully');
          showModal(ParticipantNoteEditModal, {
            participantId,
            participantNoteId: res.createParticipantNote.id,
          });
        })
        .catch((err) => {
          errorToast(`Error creating note: ${err.message}`);
        });
    },
    [errorToast, hide, mutation, organization.id, participantId, successToast, user.id],
  );

  React.useEffect(() => {
    if (templates.length > 0) {
      selectTemplate(templates[0]);
    }
  }, [templates, selectTemplate]);

  const content = P.run(() => {
    if (query.isLoading) {
      return <Spin />;
    }

    if (query.error) {
      return <Error error={query.error} />;
    }

    if (P.isEmpty(templates)) {
      return <Text size="sm">No templates found</Text>;
    }

    return (
      <HStack w="full" h="full" spacing={4}>
        <TemplatesSidebar
          templates={templates}
          activeTemplateId={activeTemplateId}
          onClick={selectTemplate}
        />
        <Divider orientation="vertical" />
        <Stack w="full" h="full" align="center">
          <Box h="full" w="full" overflowY="auto" layerStyle="fade">
            {activeTemplateId && (
              <ParticipantDocumentTemplatePreview
                templateId={activeTemplateId}
                participantId={participantId}
              />
            )}
          </Box>
          <HStack
            w="full"
            bg="gray.50"
            layerStyle="bordered"
            px={8}
            py={4}
            rounded="md"
            align="end"
          >
            {activeTemplate && (
              <Stack w="full">
                <Text fontSize="lg" fontWeight="bold">
                  {activeTemplate?.name}
                </Text>
                <HStack>
                  <TemplateStatusTag status={activeTemplate.status} />
                  {activeTemplate.labels.map((label) => (
                    <Tag key={label.id} size="sm" colorScheme={label.color}>
                      {label.name}
                    </Tag>
                  ))}
                </HStack>
              </Stack>
            )}
            <Spacer />
            <HStack>
              <Button size="sm" onClick={hide}>
                Close
              </Button>
              <HookedSubmitButton onClick={handleSubmit(onSubmit)} size="sm">
                Use template
              </HookedSubmitButton>
            </HStack>
          </HStack>
        </Stack>
      </HStack>
    );
  });

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={remove} size="outline">
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <Box as="form" display="contents" onSubmit={handleSubmit(onSubmit)} noValidate>
            <ModalHeader>
              <Flex align="start">
                <Box>
                  <Heading size="md">Create a new note</Heading>
                  <Text fontSize="md" fontWeight="normal" color="secondary" mt={2}>
                    Please complete the following to proceed
                  </Text>
                </Box>
                <Spacer />
                <ModalCloseButton position="unset" top="unset" right="unset" />
              </Flex>
            </ModalHeader>
            <ModalBody overflowY="auto" pb={4}>
              {content}
            </ModalBody>
          </Box>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
