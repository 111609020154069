import { Tag, TagLabel, TagProps } from '@chakra-ui/react';
import { TemplateStatus } from '@piccolohealth/pbs-common';
import React from 'react';

export const templateStatusColorName = (status: TemplateStatus) => {
  switch (status) {
    case TemplateStatus.Draft:
      return ['blue', 'Draft'];
    case TemplateStatus.Archived:
      return ['gray', 'Archived'];
    case TemplateStatus.Published:
      return ['green', 'Published'];
  }
};

interface Props extends TagProps {
  status: TemplateStatus;
}

export const TemplateStatusTag = (props: Props) => {
  const { status, ...rest } = props;

  const [color, label] = templateStatusColorName(status);

  return (
    <Tag size="sm" colorScheme={color} {...rest}>
      <TagLabel>{label}</TagLabel>
    </Tag>
  );
};
