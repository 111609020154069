import { Label, LabelType } from '@piccolohealth/pbs-common';
import { OnChangeRequest } from '@piccolohealth/ui';
import React from 'react';
import { useCreateLabelMutation } from '../../graphql/hooks/useCreateLabelMutation';
import { useCreateParticipantLabelMutation } from '../../graphql/hooks/useCreateParticipantLabelMutation';
import { useDeleteParticipantLabelMutation } from '../../graphql/hooks/useDeleteParticipantLabelMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { usePermission } from '../../hooks/usePermission';
import { LabelsControl } from './LabelsControl';

export interface ManageLabelsControlProps {
  participantId: string;
  value: string[];
  isDisabled?: boolean;
}

export const ManageParticipantLabelsControl = (props: ManageLabelsControlProps) => {
  const { participantId, isDisabled, value } = props;
  const { organization, errorToast } = useAppContext();

  const createLabelMutation = useCreateLabelMutation({ retry: false });
  const createParticipantLabelMutation = useCreateParticipantLabelMutation({ retry: false });
  const deleteParticipantLabelMutation = useDeleteParticipantLabelMutation({ retry: false });

  const labelManagePermission = usePermission('manage', 'label');
  const participantLabelManagePermission = usePermission('manage', 'participantLabel');

  const onChange = React.useCallback(
    (request: OnChangeRequest<Label>) => {
      switch (request.action) {
        case 'CREATE': {
          return createLabelMutation
            .mutateAsync({
              organizationId: organization.id,
              request: {
                type: LabelType.Participant,
                color: 'blue',
                name: request.value.value,
              },
            })
            .catch((err: Error) => errorToast(`Error creating label ${err.message}`));
        }
        case 'ADD': {
          return createParticipantLabelMutation
            .mutateAsync({
              organizationId: organization.id,
              participantId,
              labelId: request.value.value,
            })
            .catch((err: Error) => errorToast(`Error adding label to participant: ${err.message}`));
        }

        case 'REMOVE': {
          return deleteParticipantLabelMutation
            .mutateAsync({
              organizationId: organization.id,
              participantId,
              labelId: request.value.value,
            })
            .catch((err: Error) =>
              errorToast(`Error removing label from participant: ${err.message}`),
            );
        }
      }
    },
    [
      organization,
      participantId,
      createLabelMutation,
      createParticipantLabelMutation,
      deleteParticipantLabelMutation,
      errorToast,
    ],
  );

  return (
    <LabelsControl
      onChange={onChange}
      value={value}
      isDisabled={isDisabled || !participantLabelManagePermission}
      canManage={labelManagePermission}
      variant="selectGhost"
      type={LabelType.Participant}
    />
  );
};
