const variants = {
  elevated: () => ({
    container: {
      borderWidth: '1px',
      borderColor: 'gray.200',
      shadow: 'sm',
      rounded: 'xl',
    },
    header: {
      pb: 0,
    },
  }),
};

export const Card = {
  variants,
};
