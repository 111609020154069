import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { NotifyParticipantDocumentAuthors } from '../mutations/notifyParticipantDocumentAuthors.mutation';
import {
  NotifyParticipantDocumentAuthorsMutation,
  NotifyParticipantDocumentAuthorsMutationVariables,
} from '../types';

export const useNotifyParticipantDocumentAuthorsMutation = (
  options?: MutationOptions<
    NotifyParticipantDocumentAuthorsMutation,
    NotifyParticipantDocumentAuthorsMutationVariables
  >,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(NotifyParticipantDocumentAuthors), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('participant'),
      });
    },
  });
};
