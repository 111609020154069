import React from 'react';
import { useController } from 'react-hook-form';
import { ParticipantChooser, ParticipantChooserProps } from './ParticipantChooser';

interface Props extends Omit<ParticipantChooserProps, 'onChange' | 'value'> {
  name: string;
}

export const HookedParticipantChooser = (props: Props) => {
  const { name, ...rest } = props;
  const { field } = useController({ name });

  return <ParticipantChooser {...rest} value={field.value} onChange={field.onChange} />;
};
