import { useMutation } from '@tanstack/react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { CreatePasswordChangeTicketUrl } from '../mutations/createPasswordChangeTicketUrl.mutation';
import {
  CreatePasswordChangeTicketUrlMutation,
  CreatePasswordChangeTicketUrlMutationVariables,
} from '../types';

export const useCreatePasswordChangeTicketUrlMutation = (
  options?: MutationOptions<
    CreatePasswordChangeTicketUrlMutation,
    CreatePasswordChangeTicketUrlMutationVariables
  >,
) => useMutation(gqlFetcher(CreatePasswordChangeTicketUrl), options);
