import { Button, Divider, Icon, Stack } from '@chakra-ui/react';
import { PhaseChangeLine, PhaseChangeType } from '@piccolohealth/pbs-common';
import { FloatingPopover } from '@piccolohealth/ui';
import { DateTime, uuid } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FaPlus } from 'react-icons/fa';
import { FormSection } from '../../../../components/forms/FormSection';
import { HookedSubmitButton } from '../../../../components/forms/HookedSubmitButton';
import { UseTimeseriesChartReturn } from '../../../participantData/hooks/useTimeseriesChart';
import { COLORS } from '../../../participantData/utils';
import { PhaseChangeLineForm } from './PhaseChangeLineForm';

type FormValues = PhaseChangeLine;

interface PhaseChangeLineAddFormProps {
  chart: UseTimeseriesChartReturn;
  onSubmit: () => void;
}

const PhaseChangeLineAddForm = (props: PhaseChangeLineAddFormProps) => {
  const methods = useForm({
    defaultValues: {
      id: uuid(),
      name: '',
      timestamp: DateTime.now(),
      color: COLORS.red,
      type: PhaseChangeType.Minor,
    },
  });

  const onSubmit = (values: FormValues) => {
    props.chart.onAddPhaseChangeLine(values);
    props.onSubmit();
  };

  return (
    <Stack
      bg="white"
      spacing={4}
      w="md"
      layerStyle="bordered"
      shadow="popover"
      rounded="md"
      overflow="hidden"
      p={4}
    >
      <FormProvider {...methods}>
        <FormSection
          as="form"
          heading="Add phase change line"
          description="Add a new phase change line to the view"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <Divider />
          <PhaseChangeLineForm />
          <HookedSubmitButton w="full" size="sm" colorScheme="purple">
            Save
          </HookedSubmitButton>
        </FormSection>
      </FormProvider>
    </Stack>
  );
};

interface Props {
  chart: UseTimeseriesChartReturn;
}

export const PhaseChangeLineAddControl = (props: Props) => {
  const { chart } = props;

  return (
    <FloatingPopover
      placement="right-end"
      render={({ context }) => (
        <PhaseChangeLineAddForm chart={chart} onSubmit={() => context.onOpenChange(false)} />
      )}
    >
      <Button w="full" leftIcon={<Icon as={FaPlus} />} variant="outline" size="sm">
        New phase change line
      </Button>
    </FloatingPopover>
  );
};
