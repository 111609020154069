import { NumberedBadge } from '@piccolohealth/ui';
import React from 'react';
import {
  useParticipantDocumentsQuery,
  useParticipantFilesQuery,
} from '../../graphql/hooks/useParticipantQuery';
import { useAppContext } from '../../hooks/useAppContext';

interface Props {
  participantId: string;
}

export const ParticipantDocumentsBadge = (props: Props) => {
  const { participantId } = props;
  const { organization } = useAppContext();

  const filesQuery = useParticipantFilesQuery({
    organizationId: organization.id,
    participantId,
  });

  const documentsQuery = useParticipantDocumentsQuery({
    organizationId: organization.id,
    participantId,
  });

  const files = filesQuery.data?.organization?.participant?.files || [];
  const documents = documentsQuery.data?.organization?.participant?.documents || [];
  const count =
    filesQuery.isLoading || documentsQuery.isLoading ? 0 : files.length + documents.length;

  return <NumberedBadge count={count} />;
};
