import gql from 'graphql-tag';

export const CreateParticipantLabel = gql`
  mutation CreateParticipantLabel($organizationId: ID!, $participantId: ID!, $labelId: ID!) {
    createParticipantLabel(
      organizationId: $organizationId
      participantId: $participantId
      labelId: $labelId
    ) {
      id
    }
  }
`;
