import gql from 'graphql-tag';

export const CreateOrganization = gql`
  mutation CreateOrganization($createOrganizationRequest: CreateOrganizationRequest!) {
    createOrganization(request: $createOrganizationRequest) {
      id
      name
      status
      timezone
      locale
      stripeCustomerId
      stripeSubscriptionId
      featureFlags
    }
  }
`;
