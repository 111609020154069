import { Divider, HStack } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { UpdateUserRequest, User } from '@piccolohealth/pbs-common';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormSection } from '../../components/forms/FormSection';
import { FormStack } from '../../components/forms/FormStack';
import { HookedSubmitButton } from '../../components/forms/HookedSubmitButton';
import { useUpdateUserMutation } from '../../graphql/hooks/useUpdateUserMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { UserForm } from './UserForm';
import { userSchema } from './UserInviteModal';

type FormValues = UpdateUserRequest & {
  organizationId: string;
};

interface Props {
  user: User;
}

export const UserBasic = (props: Props) => {
  const { user } = props;
  const { organization, successToast, errorToast } = useAppContext();

  const mutation = useUpdateUserMutation();

  const organizationMembership = user.organizationMemberships.find(
    (membership) => membership.organizationId === organization.id,
  );

  const defaultValues = React.useMemo(() => {
    return {
      organizationId: organization.id,
      name: user.name,
      email: user.email,
      title: user.title,
      practitionerNumber: user.practitionerNumber,
      picture: user.picture,
      roles: organizationMembership?.roles ?? [],
      locations: (organizationMembership?.locations ?? []).map((l) => l.id),
      settings: user.settings,
    };
  }, [
    organization.id,
    organizationMembership?.locations,
    organizationMembership?.roles,
    user.email,
    user.name,
    user.picture,
    user.practitionerNumber,
    user.settings,
    user.title,
  ]);

  const methods = useForm<FormValues>({
    defaultValues,
    resolver: zodResolver(userSchema),
  });

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      const request = P.pickBy(values, (value, key) => {
        return !P.isEqual(value, defaultValues[key]);
      });

      await mutation
        .mutateAsync({
          organizationId: organization.id,
          userId: user.id,
          request: P.deepTrim(request),
        })
        .then(() => {
          successToast('User updated successfully');
        })
        .catch((err) => {
          errorToast(`Error updating user: ${err.message}`);
        });
    },
    [mutation, organization.id, user.id, defaultValues, successToast, errorToast],
  );

  return (
    <FormProvider {...methods}>
      <FormStack as="form" onSubmit={methods.handleSubmit(onSubmit)} maxW="lg">
        <FormSection heading="Basic" description="Basic information about the user">
          <Divider />
          <UserForm />
        </FormSection>
        <Divider />
        <HStack>
          <HookedSubmitButton>Save</HookedSubmitButton>
        </HStack>
      </FormStack>
    </FormProvider>
  );
};
