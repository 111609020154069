import NiceModal from '@ebay/nice-modal-react';
import { KnockFeedProvider } from '@knocklabs/react-notification-feed';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { VersionCheckObserver } from '../../components/generic/VersionCheckObserver';
import { SidebarLayout } from '../../components/layouts/SidebarLayout';
import { OrganizationContextProvider } from '../../context/OrganizationContext';
import { useAppContext } from '../../hooks/useAppContext';
import { OrganizationSidebar } from './OrganizationSidebar';

export const OrganizationLayout = () => {
  const { user, config } = useAppContext();

  return (
    <OrganizationContextProvider>
      <KnockFeedProvider
        apiKey={config.knock.apiKey}
        feedId={config.knock.feedId}
        userId={user.id}
        defaultFeedOptions={{
          page_size: 10,
        }}
        rootless
      >
        <NiceModal.Provider>
          <VersionCheckObserver />
          <SidebarLayout
            sidebarContent={(props) => <OrganizationSidebar {...props} />}
            mainContent={<Outlet />}
          />
        </NiceModal.Provider>
      </KnockFeedProvider>
    </OrganizationContextProvider>
  );
};
