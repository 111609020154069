import gql from 'graphql-tag';

export const UpdateLocation = gql`
  mutation UpdateLocation(
    $organizationId: ID!
    $locationId: ID!
    $request: UpdateLocationRequest!
  ) {
    updateLocation(organizationId: $organizationId, locationId: $locationId, request: $request) {
      id
      name
      color
    }
  }
`;
