import { Button, ButtonProps, Icon, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { ParticipantBehaviour } from '@piccolohealth/pbs-common';
import React from 'react';
import { FaCaretDown, FaPencilAlt, FaRegTrashAlt } from 'react-icons/fa';
import { showModal } from '../../components/generic/Modal';
import { ParticipantBehaviourDeleteModal } from './ParticipantBehaviourDeleteModal';
import { ParticipantBehaviourEditModal } from './ParticipantBehaviourEditModal';

interface Props extends ButtonProps {
  participantBehaviour: ParticipantBehaviour;
  isDisabled?: boolean;
}

export const ParticipantBehaviourActionMenu = (props: Props) => {
  const { participantBehaviour, isDisabled, ...rest } = props;

  return (
    <Menu isLazy lazyBehavior="unmount" size="sm">
      <MenuButton
        as={Button}
        isDisabled={isDisabled}
        variant="link"
        size="sm"
        rightIcon={<Icon as={FaCaretDown} />}
        {...rest}
      >
        Actions
      </MenuButton>
      <MenuList>
        <MenuItem
          icon={<FaPencilAlt />}
          isDisabled={isDisabled}
          onClick={() =>
            showModal(ParticipantBehaviourEditModal, {
              participantBehaviour,
            })
          }
        >
          Edit
        </MenuItem>
        <MenuItem
          color="danger"
          icon={<FaRegTrashAlt />}
          isDisabled={isDisabled}
          onClick={() =>
            showModal(ParticipantBehaviourDeleteModal, {
              participantBehaviourId: participantBehaviour.id,
            })
          }
        >
          Delete
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
