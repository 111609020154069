import { Button, HStack, Text, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { useAppContext } from '../../../../hooks/useAppContext';
import { Reaction } from '../../extensions/comment/Comment';

interface Props {
  id: string;
  isDisabled?: boolean;
  reactions: Reaction[];
  onAddReaction: (id: string) => void;
  onRemoveReaction: (id: string) => void;
}

export const CommentReactionButton = (props: Props) => {
  const { id, reactions, isDisabled, onAddReaction, onRemoveReaction } = props;

  const { user } = useAppContext();

  const onClick = React.useCallback(() => {
    const reaction = reactions.find((reaction) => reaction.userId === user.id);

    if (reaction) {
      onRemoveReaction(id);
    } else {
      onAddReaction(id);
    }
  }, [id, reactions, user.id, onAddReaction, onRemoveReaction]);

  return (
    <Tooltip
      label={
        <HStack>
          <Text>{reactions.map((reaction) => reaction.username).join(', ')} reacted with</Text>
          <em-emoji id={id}></em-emoji>
        </HStack>
      }
    >
      <Button size="sm" p="2" rounded="full" bg="white" onClick={onClick} isDisabled={isDisabled}>
        <HStack spacing={1}>
          <em-emoji id={id} />
          <Text fontSize="2xs">{reactions.length}</Text>
        </HStack>
      </Button>
    </Tooltip>
  );
};
