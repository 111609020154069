import { Box, Icon, Stack } from '@chakra-ui/react';
import { Empty } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { BiSolidBarChartAlt2 } from 'react-icons/bi';
import { ECharts } from '../../../../components/generic/ECharts';
import { UseTimeseriesChartReturn } from '../../hooks/useTimeseriesChart';

interface Props {
  chart: UseTimeseriesChartReturn;
}

export const TimeSeriesChartWidgetContent = (props: Props) => {
  const { chart } = props;

  const content = P.run(() => {
    if (P.isEmpty(chart.widget.series ?? [])) {
      return (
        <Stack h="full" w="full" align="center" justify="center" textAlign="center">
          <Empty
            title="No series added"
            description="Add series to view them in the chart. Series are used to display data on the chart."
            icon={<Icon as={BiSolidBarChartAlt2} fontSize="48" color="gray.300" />}
          />
        </Stack>
      );
    }

    return <ECharts ref={chart.ref} options={chart.options} onChartReady={chart.onChartReady} />;
  });

  return (
    <Box h="full" w="full" overflow="hidden" userSelect="none">
      {content}
    </Box>
  );
};
