import { Divider } from '@chakra-ui/react';
import { FormType, LabelType } from '@piccolohealth/pbs-common';
import { SelectOption, Spin } from '@piccolohealth/ui';
import React from 'react';
import { FormSection } from '../../../components/forms/FormSection';
import { FormStack } from '../../../components/forms/FormStack';
import { HookedFormItem } from '../../../components/forms/HookedFormItem';
import { HookedInput } from '../../../components/forms/HookedInput';
import { HookedMultiSelect } from '../../../components/forms/HookedMultiSelect';
import { HookedSelect } from '../../../components/forms/HookedSelect';
import { Error } from '../../../components/generic/Error';
import { useLabelsQuery } from '../../../graphql/hooks/useLabelsQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import { formTypeColorName } from './FormTypeTag';

interface Props {
  type: FormType;
}

const formTypeOptions: SelectOption<FormType>[] = Object.values(FormType).map((type) => {
  const [color, label] = formTypeColorName(type);
  return {
    label,
    color,
    value: type,
    raw: type,
  };
});

export const FormForm = (props: Props) => {
  const { type } = props;

  const { organization } = useAppContext();

  const { data, isLoading, error } = useLabelsQuery({
    organizationId: organization.id,
    type: [type as unknown as LabelType],
  });

  const labels = data?.organization?.labels ?? [];

  const labelOptions = labels.map((label) => {
    return {
      label: label.name,
      value: label.id,
      raw: label,
      color: label.color,
    };
  });

  if (isLoading) {
    return <Spin />;
  }

  if (error) {
    return <Error error={error} />;
  }

  return (
    <FormStack spacing={4}>
      <FormSection heading="Basic" description="Basic information about the form">
        <HookedFormItem name="name" label="Name">
          <HookedInput name="name" size="sm" placeholder="Enter a name" />
        </HookedFormItem>

        <HookedFormItem name="url" label="URL">
          <HookedInput name="url" size="sm" placeholder="Enter a url" />
        </HookedFormItem>

        <HookedFormItem name="type" label="Type">
          <HookedSelect
            name="type"
            size="sm"
            placeholder="Choose the type of form this relates to"
            options={formTypeOptions}
            optionVariant="tag"
          />
        </HookedFormItem>
      </FormSection>

      <Divider />

      <FormSection
        heading="Labels"
        description="What labels should be automatically attached for the following events?"
      >
        <HookedFormItem
          name="labelMappings.create"
          label="Create event"
          helperText="Labels to attach when a form submission is received and triggers creation"
        >
          <HookedMultiSelect
            name="labelMappings.create"
            options={labelOptions}
            size="sm"
            placeholder="Select labels to add after creation"
            optionVariant="tag"
          />
        </HookedFormItem>

        <HookedFormItem
          name="labelMappings.update"
          label="Update event"
          helperText="Labels to attach when a form submission is received and triggers an update"
        >
          <HookedMultiSelect
            name="labelMappings.update"
            options={labelOptions}
            size="sm"
            placeholder="Select labels to add after updating"
            optionVariant="tag"
          />
        </HookedFormItem>
      </FormSection>
    </FormStack>
  );
};
