import { Tag, TagLabel, TagProps } from '@chakra-ui/react';
import { ParticipantBehaviourStatus } from '@piccolohealth/pbs-common';
import React from 'react';

export const statusColorName = (status: ParticipantBehaviourStatus) => {
  switch (status) {
    case ParticipantBehaviourStatus.Active:
      return ['green', 'Active'];
    case ParticipantBehaviourStatus.Inactive:
      return ['yellow', 'Inactive'];
  }
};

interface Props extends TagProps {
  status: ParticipantBehaviourStatus;
}

export const ParticipantBehaviourStatusTag = (props: Props) => {
  const { status, ...rest } = props;

  const [color, label] = statusColorName(status);

  return (
    <Tag size="sm" colorScheme={color} {...rest}>
      <TagLabel>{label}</TagLabel>
    </Tag>
  );
};
