import { useMutation } from '@tanstack/react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { ExportParticipantsCsv } from '../mutations/exportParticipantsCsv.mutation';
import { ExportParticipantsCsvMutation, ExportParticipantsCsvMutationVariables } from '../types';

export const useExportParticipantsCsvMutation = (
  options?: MutationOptions<ExportParticipantsCsvMutation, ExportParticipantsCsvMutationVariables>,
) => {
  return useMutation(gqlFetcher(ExportParticipantsCsv), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);
    },
  });
};
