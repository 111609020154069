import { HStack, IconButton, useEditableControls } from '@chakra-ui/react';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FaCheck, FaPen, FaTimes, FaTrash } from 'react-icons/fa';

interface Props {
  isHovering: boolean;
  onRemove: () => void;
}

export const EditableControls = (props: Props) => {
  const { isHovering, onRemove } = props;
  const { isEditing, getSubmitButtonProps, getEditButtonProps, getCancelButtonProps } =
    useEditableControls();

  return (
    <HStack spacing={0} visibility={isHovering || isEditing ? 'visible' : 'hidden'}>
      {isEditing ? (
        <>
          <IconButton
            aria-label="Save"
            size="xs"
            variant="ghost"
            icon={<FaCheck />}
            {...P.omit(getSubmitButtonProps(), ['ref'])}
          />
          <IconButton
            aria-label="Cancel"
            size="xs"
            variant="ghost"
            icon={<FaTimes />}
            {...P.omit(getCancelButtonProps(), ['ref'])}
          />
        </>
      ) : (
        <IconButton
          aria-label="Edit"
          size="xs"
          variant="ghost"
          icon={<FaPen />}
          {...P.omit(getEditButtonProps(), ['ref'])}
        />
      )}
      <IconButton
        icon={<FaTrash />}
        aria-label="Remove"
        size="xs"
        variant="ghost"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onRemove();
        }}
      />
    </HStack>
  );
};
