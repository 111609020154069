import { Image, Avatar, AvatarProps, forwardRef, Tooltip } from '@chakra-ui/react';
import React from 'react';
import AvatarMaleImage from '../../assets/avatar-male.png';
import { UserDescription } from './UserDescription';

interface Props extends AvatarProps {
  name: string;
  secondary?: string;
  picture?: string | null;
  showTooltip?: boolean;
}

export const UserAvatar = forwardRef<Props, 'span'>((props, ref) => {
  const { name, secondary, picture, showTooltip, ...rest } = props;

  const src = picture ?? AvatarMaleImage;
  const tooltipDisabled = showTooltip === false;
  const [isLoaded, setLoaded] = React.useState(false);

  return (
    <Tooltip
      label={<UserDescription name={name} secondary={secondary} picture={picture} />}
      isDisabled={tooltipDisabled}
    >
      <Avatar
        ref={ref}
        src={src}
        icon={<Image src={AvatarMaleImage} />}
        onLoad={() => setLoaded(true)}
        onError={() => setLoaded(true)}
        opacity={isLoaded ? 1 : 0}
        transition="opacity 0.2s ease-in-out"
        {...rest}
      />
    </Tooltip>
  );
});
