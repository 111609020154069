import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import React from 'react';
import { createModal } from '../../components/generic/Modal';
import { useDeleteLabelMutation } from '../../graphql/hooks/useDeleteLabelMutation';
import { useAppContext } from '../../hooks/useAppContext';

interface Props {
  labelId: string;
  onDelete?: (labelId: string) => void;
}

export const LabelDeleteModal = createModal<Props>((props) => {
  const { organization, successToast, errorToast } = useAppContext();
  const { labelId, onDelete, modal } = props;

  const deleteLabelMutation = useDeleteLabelMutation();
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const onDeleteLabel = React.useCallback(() => {
    deleteLabelMutation
      .mutateAsync({
        organizationId: organization.id,
        labelId,
      })
      .then(() => {
        successToast('Label deleted successfully');
        onDelete?.(labelId);
        modal.hide();
      })
      .catch((err) => {
        errorToast(`Error deleting label: ${err.message}`);
      });
  }, [onDelete, labelId, deleteLabelMutation, organization.id, successToast, modal, errorToast]);

  return (
    <AlertDialog
      isOpen={modal.visible}
      leastDestructiveRef={cancelRef}
      onClose={modal.hide}
      onCloseComplete={modal.resolveHide}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete label
          </AlertDialogHeader>

          <AlertDialogBody>
            Are you sure you want to delete this label? You can&apos;t undo this action afterwards.
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button size="sm" ref={cancelRef} onClick={modal.hide}>
              Cancel
            </Button>
            <Button
              ml={3}
              colorScheme="red"
              size="sm"
              onClick={onDeleteLabel}
              isLoading={deleteLabelMutation.isLoading}
            >
              Delete
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
});
