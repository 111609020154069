import {
  Button,
  ButtonProps,
  Icon,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Portal,
} from '@chakra-ui/react';
import { Template } from '@piccolohealth/pbs-common';
import React from 'react';
import { FaCaretDown, FaHistory, FaPencilAlt, FaRegTrashAlt } from 'react-icons/fa';
import { showModal } from '../../components/generic/Modal';
import { TemplateDeleteModal } from './TemplateDeleteModal';
import { TemplateEditModal } from './TemplateEditModal';
import { TemplateVersionHistoryModal } from './TemplateVersionHistoryModal';

interface Props extends ButtonProps {
  template: Template;
}

export const TemplateActionMenu = (props: Props) => {
  const { template, ...rest } = props;

  return (
    <Menu isLazy lazyBehavior="keepMounted" size="sm">
      <MenuButton
        as={Button}
        variant="link"
        size="sm"
        rightIcon={<Icon as={FaCaretDown} />}
        flexShrink={0}
        {...rest}
      >
        Actions
      </MenuButton>
      <Portal>
        <MenuList>
          <MenuGroup title="Template">
            <MenuItem
              icon={<FaHistory />}
              onClick={() =>
                showModal(TemplateVersionHistoryModal, {
                  template,
                })
              }
            >
              View version history
            </MenuItem>
            <MenuItem
              icon={<FaPencilAlt />}
              onClick={() =>
                showModal(TemplateEditModal, {
                  template,
                })
              }
            >
              Edit
            </MenuItem>
            <MenuItem
              color="danger"
              icon={<FaRegTrashAlt />}
              onClick={() =>
                showModal(TemplateDeleteModal, {
                  templateId: template.id,
                })
              }
            >
              Delete
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Portal>
    </Menu>
  );
};
