import gql from 'graphql-tag';

export const UpdateParticipantDocument = gql`
  mutation UpdateParticipantDocument(
    $organizationId: ID!
    $participantId: ID!
    $participantDocumentId: ID!
    $request: UpdateParticipantDocumentRequest!
  ) {
    updateParticipantDocument(
      organizationId: $organizationId
      participantId: $participantId
      participantDocumentId: $participantDocumentId
      request: $request
    ) {
      id
    }
  }
`;
