import { Button, Divider, Icon, Wrap, WrapItem } from '@chakra-ui/react';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { FormSection } from '../../components/forms/FormSection';
import { FormStack } from '../../components/forms/FormStack';
import { showModal } from '../../components/generic/Modal';
import { useAppContext } from '../../hooks/useAppContext';
import { usePermission } from '../../hooks/usePermission';
import { FormCard } from './forms/FormCard';
import { FormCreateModal } from './forms/FormCreateModal';

export const FormSettings = () => {
  const { organization } = useAppContext();
  const { forms } = organization;

  const createFormPermission = usePermission('create', 'form');

  return (
    <FormStack maxW="lg">
      <FormSection
        heading="Forms"
        description="Your forms to share on social media, messaging apps or via email."
      >
        <Divider />

        <Wrap spacing={4} w="full">
          {forms.map((form) => (
            <WrapItem key={form.id} w="full">
              <FormCard form={form} />
            </WrapItem>
          ))}
        </Wrap>
      </FormSection>
      <Button
        isDisabled={!createFormPermission}
        w="fit-content"
        size="sm"
        colorScheme="purple"
        leftIcon={<Icon as={FaPlus} />}
        onClick={() => showModal(FormCreateModal)}
      >
        Add new form
      </Button>
    </FormStack>
  );
};
