import { HStack, Stack, Text } from '@chakra-ui/react';
import { PiccoloError, User } from '@piccolohealth/pbs-common';
import {
  createColumnHelper,
  DataTable,
  Empty,
  PaginationOptions,
  RowSelectionOptions,
} from '@piccolohealth/ui';
import React from 'react';
import { TextLink } from '../../components/generic/TextLink';
import { UserAvatar } from '../../components/user/UserAvatar';
import { useAppContext } from '../../hooks/useAppContext';
import { RoleTag } from './RoleTag';
import { UserActionMenu } from './UsersActionMenu';

interface Props {
  isLoading: boolean;
  error: PiccoloError | null;
  users: User[];
  refetch: () => Promise<void>;
  pagination?: PaginationOptions;
  rowSelection?: RowSelectionOptions<User>;
}

export const UsersTable = (props: Props) => {
  const { organization } = useAppContext();

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<User>();

    return [
      columnHelper.accessor('name', {
        header: 'Name',
        minSize: 320,
        maxSize: 380,
        cell: (props) => (
          <TextLink to={`/organizations/${organization.id}/users/${props.row.original.id}`}>
            <HStack spacing={4} fontSize="sm">
              <UserAvatar
                secondary={props.row.original.email}
                picture={props.row.original.picture}
                name={props.row.original.name}
                w="40px"
                h="40px"
                showTooltip={false}
              />
              <Stack spacing={1} lineHeight={1} align="start">
                <Text fontWeight="semibold" color="purple.600" fontSize="md">
                  {props.row.original.name}
                </Text>
                <Text color="secondary">{props.row.original.email}</Text>
              </Stack>
            </HStack>
          </TextLink>
        ),
      }),
      columnHelper.accessor('title', {
        header: 'Title',
        minSize: 200,
        maxSize: 380,
        cell: (props) => <Text fontSize="sm">{props.row.original.title}</Text>,
      }),
      columnHelper.accessor('practitionerNumber', {
        header: 'Practitioner number',
        minSize: 250,
        maxSize: 250,
        cell: (props) => <Text fontSize="sm">{props.row.original.practitionerNumber ?? '-'}</Text>,
      }),
      columnHelper.accessor('organizationMemberships.roles', {
        header: 'Roles',
        minSize: 300,
        cell: (props) => {
          const membership = props.row.original.organizationMemberships.find(
            (organizationMembership) => organizationMembership.organizationId === organization.id,
          );
          const roles = membership?.roles ?? [];

          return (
            <HStack>
              {roles.map((role) => (
                <RoleTag key={role} role={role} size="sm" />
              ))}
            </HStack>
          );
        },
      }),

      columnHelper.display({
        header: 'Actions',
        id: 'actions',
        minSize: 140,
        cell: (props) => <UserActionMenu user={props.row.original} size="sm" />,
      }),
    ];
  }, [organization.id]);

  return (
    <DataTable
      columns={columns}
      data={props.users}
      isLoading={props.isLoading}
      error={props.error}
      renderEmpty={() => <Empty title="No users found" />}
      pagination={props.pagination}
      rowSelection={props.rowSelection}
      size="md"
    />
  );
};
