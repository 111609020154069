import gql from 'graphql-tag';

export const UpdateIncidentStatus = gql`
  mutation UpdateIncidentStatus(
    $organizationId: ID!
    $incidentId: ID!
    $request: UpdateIncidentStatusRequest!
  ) {
    updateIncidentStatus(
      organizationId: $organizationId
      incidentId: $incidentId
      request: $request
    ) {
      id
    }
  }
`;
