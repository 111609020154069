import { ColorPickerHex, ColorPickerProps } from '@piccolohealth/ui';
import React from 'react';
import { useController } from 'react-hook-form';

interface Props extends Omit<ColorPickerProps, 'value' | 'onChange'> {
  name: string;
}

export const HookedColorPickerHex = (props: Props) => {
  const { name, ...rest } = props;

  const {
    field: { ref, ...inputProps },
  } = useController({ name });

  return <ColorPickerHex {...rest} {...inputProps} />;
};
