import { Button, Card, CardBody, CardHeader, Heading, Icon, Stack, Text } from '@chakra-ui/react';
import { Participant } from '@piccolohealth/pbs-common';
import { Empty } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { showModal } from '../../components/generic/Modal';
import { usePermission } from '../../hooks/usePermission';
import { ParticipantTeamMember } from './ParticipantTeamMember';
import { ParticipantTeamMemberManageModal } from './ParticipantTeamMemberManageModal';

interface Props {
  participant: Participant;
  isDisabled?: boolean;
}

export const ParticipantTeamCard = (props: Props) => {
  const { participant } = props;

  const createParticipantUserPermission = usePermission('create', 'participantUser');
  const isDisabled = props.isDisabled || !createParticipantUserPermission;

  const content = P.run(() => {
    if (P.isEmpty(participant.assignedUsers)) {
      return <Empty h="full" title="No team members assigned yet" p={0} />;
    }

    return (
      <>
        {participant.assignedUsers.map((participantUser) => (
          <ParticipantTeamMember key={participantUser.user.id} participantUser={participantUser} />
        ))}
      </>
    );
  });

  return (
    <Card h="full">
      <CardHeader>
        <Stack spacing={0}>
          <Heading size="md">Team</Heading>
          <Text fontSize="sm" color="secondary">
            {`Manage this participant's team members`}
          </Text>
        </Stack>
      </CardHeader>
      <CardBody>
        <Stack spacing={6}>
          {content}
          <Button
            isDisabled={isDisabled}
            size="sm"
            leftIcon={<Icon as={FaPlus} />}
            onClick={() =>
              showModal(ParticipantTeamMemberManageModal, {
                participant: participant,
              })
            }
          >
            Manage team members
          </Button>
        </Stack>
      </CardBody>
    </Card>
  );
};
