import gql from 'graphql-tag';

export const DeleteParticipantFieldTemplate = gql`
  mutation DeleteParticipantFieldTemplate($organizationId: ID!, $participantFieldTemplateId: ID!) {
    deleteParticipantFieldTemplate(
      organizationId: $organizationId
      participantFieldTemplateId: $participantFieldTemplateId
    )
  }
`;
