import gql from 'graphql-tag';

export const CreateParticipantDocumentLabel = gql`
  mutation CreateParticipantDocumentLabel(
    $organizationId: ID!
    $participantId: ID!
    $participantDocumentId: ID!
    $labelId: ID!
  ) {
    createParticipantDocumentLabel(
      organizationId: $organizationId
      participantId: $participantId
      participantDocumentId: $participantDocumentId
      labelId: $labelId
    ) {
      id
    }
  }
`;
