import { ParticipantNote, PiccoloError } from '@piccolohealth/pbs-common';
import Collaboration from '@tiptap/extension-collaboration';
import CollaborationCursor from '@tiptap/extension-collaboration-cursor';
import React from 'react';
import { Error } from '../../components/generic/Error';
import { Editor } from '../../components/tiptap/Editor';
import { useEditor } from '../../components/tiptap/hooks/useEditor';
import { useHocuspocusProvider } from '../../components/tiptap/hooks/useHocuspocusProvider';
import { EditorLayout } from '../../components/tiptap/layout/EditorLayout';
import { getParticipantNoteExtensions } from '../../components/tiptap/utils';
import { useParticipantNoteQuery } from '../../graphql/hooks/useParticipantQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { ParticipantNoteTitleBar } from './ParticipantNoteTitleBar';

interface ParticipantNoteEditorContentProps {
  participantNote: ParticipantNote;
  isEditable: boolean;
  onDelete: () => void;
}

export const ParticipantNoteEditorContent = (props: ParticipantNoteEditorContentProps) => {
  const { participantNote, isEditable, onDelete } = props;

  const { config, organization } = useAppContext();

  const imageUploadUrl = `${config.api.url}/organizations/${organization.id}/participants/${participantNote.participantId}/participantNotes/${participantNote.id}/images`;
  const documentId = `participantNote/${participantNote.id}`;

  const { provider, user, status, isSaving } = useHocuspocusProvider({
    documentId,
  });

  const extensions = getParticipantNoteExtensions(imageUploadUrl);

  const { editor } = useEditor(
    {
      isEditable,
      extensions: [
        ...extensions,
        Collaboration.configure({
          document: provider.document,
        }),
        CollaborationCursor.configure({
          provider,
          user,
        }),
      ],
    },
    [isEditable],
  );

  if (!editor) {
    return null;
  }

  return (
    <EditorLayout
      documentId={documentId}
      status={status}
      isSidebarOpen={false}
      headerContent={
        <ParticipantNoteTitleBar
          participantNote={participantNote}
          editor={editor}
          status={status}
          isSaving={isSaving}
          isDisabled={!isEditable}
          onDelete={onDelete}
        />
      }
      mainContent={<Editor editor={editor} />}
      sidebarContent={null}
    />
  );
};

interface Props {
  participantId: string;
  participantNoteId: string;
  isDisabled?: boolean;
  onDelete: () => void;
}

export const ParticipantNoteEditor = (props: Props) => {
  const { participantId, participantNoteId, isDisabled, onDelete } = props;

  const { organization } = useAppContext();

  const { isLoading, error, data } = useParticipantNoteQuery({
    organizationId: organization.id,
    participantId,
    participantNoteId,
  });

  const participantNote = data?.organization?.participant?.note as ParticipantNote | null;

  if (isLoading) {
    return null;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (!participantNote) {
    return (
      <Error
        error={
          new PiccoloError({
            type: 'ParticipantNoteNotFound',
            message: 'Participant note not found',
          })
        }
      />
    );
  }

  return (
    <ParticipantNoteEditorContent
      participantNote={participantNote}
      isEditable={!isDisabled}
      onDelete={onDelete}
    />
  );
};
