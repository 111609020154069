import { DateTime } from '@piccolohealth/util';
import React from 'react';
import { useController } from 'react-hook-form';
import { SingleDatepicker, SingleDatepickerProps } from '@piccolohealth/ui';

interface Props extends Omit<SingleDatepickerProps, 'onChange' | 'date'> {
  name: string;
}

export const HookedSingleDatepicker = (props: Props) => {
  const { name, ...rest } = props;
  const {
    field: { ref, ...inputProps },
  } = useController({ name });

  const onChange = (date: DateTime | null) => {
    inputProps.onChange(date ? date.toISO() : null);
  };

  return (
    <SingleDatepicker
      onChange={onChange}
      date={inputProps.value ? DateTime.fromISO(inputProps.value) : null}
      {...rest}
    />
  );
};
