import { Box } from '@chakra-ui/react';
import {
  AggregationType,
  ParticipantBehaviour,
  ParticipantWidgetLayoutItem,
  SymbolType,
  TimeSeries,
  TimeSeriesChartWidget,
} from '@piccolohealth/pbs-common';
import { DateTime, P, uuid } from '@piccolohealth/util';
import { EChartsOption, SeriesOption } from 'echarts';
import React from 'react';
import { ECharts } from '../../../components/generic/ECharts';
import { useParticipantBehavioursRecordingsQuery } from '../../../graphql/hooks/useParticipantQuery';
import { useAppContext } from '../../../hooks/useAppContext';
import { useTimeseriesChart } from '../hooks/useTimeseriesChart';

interface Props {
  participantId: string;
  items: ParticipantWidgetLayoutItem[];
  startDate: DateTime;
  endDate: DateTime;
  onStartDateChange: (date: DateTime) => void;
  onEndDateChange: (date: DateTime) => void;
}

export const ParticipantWidgetDataZoom = (props: Props) => {
  const { participantId, items, startDate, endDate, onStartDateChange, onEndDateChange } = props;

  const { organization } = useAppContext();

  const participantBehaviourQuery = useParticipantBehavioursRecordingsQuery({
    organizationId: organization.id,
    participantId,
  });

  const participantBehaviours =
    (participantBehaviourQuery.data?.organization?.participant
      ?.behaviours as ParticipantBehaviour[]) ?? [];

  const widget = {
    __typename: 'TimeSeriesChartWidget',
    id: uuid(),
    title: 'New time series',
    series: items.flatMap((item) => {
      switch (item.widget.__typename) {
        case 'TimeSeriesChartWidget':
          return item.widget.series.map((series) => {
            return {
              ...series,
              trendline: null,
            };
          });
        case 'TableWidget':
          return item.widget.series.map((series) => {
            return {
              __typename: 'LineTimeSeries',
              id: series.id,
              source: series.source,
              aggregation: AggregationType.Sum,
              color: '#000000',
              symbol: SymbolType.Circle,
              trendline: null,
            } as TimeSeries;
          });
      }
      return [];
    }),
    phaseChangeLines: items.flatMap((item) => {
      switch (item.widget.__typename) {
        case 'TimeSeriesChartWidget':
          return item.widget.phaseChangeLines;
        default:
          return [];
      }
    }),
  } as TimeSeriesChartWidget;

  const chart = useTimeseriesChart({
    participantBehaviours,
    widget,
    startDate: startDate,
    endDate: endDate,
    onEndDateChange,
    onStartDateChange,
    setWidget: P.noop,
  });

  const dataZoomOptions = React.useMemo(
    () =>
      ({
        dataset: chart.options.dataset,
        series: (chart.options.series as SeriesOption[]).map((series) => ({
          ...series,
          silent: true,
          visible: false,
          lineStyle: {
            width: 0,
          },
          showSymbol: false,
          markLine: undefined,
        })),
        xAxis: {
          type: 'time',
          boundaryGap: false,
          show: false,
        },
        yAxis: {
          type: 'value',
          boundaryGap: ['0.5', '100%'],
          show: false,
        },
        dataZoom: [
          {
            show: true,
            type: 'slider',
            height: '28px',
            startValue: startDate.toJSDate(),
            endValue: endDate.toJSDate(),
            top: 0,
            bottom: 0,
            left: 4,
            right: 8,
            borderColor: '#E2E8F0',
            borderRadius: 6,
            showDataShadow: false,
            moveHandleSize: '6px',
            textStyle: {
              fontWeight: 'bold',
            },

            labelFormatter: (value: number) =>
              DateTime.fromMillis(value).toLocaleString(DateTime.DATE_FULL),
          },
        ],
      } as EChartsOption),
    [chart.options.dataset, chart.options.series, endDate, startDate],
  );

  const dataOptions = React.useMemo(
    () =>
      ({
        grid: {
          top: 8,
          bottom: 70,
          left: 4,
          right: 8,
        },
        dataset: chart.options.dataset,
        series: (chart.options.series as SeriesOption[]).map((series) => ({
          ...series,
          symbol: 'emptyCircle',
          symbolSize: 0.5,
          lineStyle: {
            width: 2,
          },
          markLine: {
            ...series.markLine,
            label: {
              show: false,
            },
          },
        })),
        xAxis: {
          type: 'time',
          boundaryGap: false,
          show: false,
        },
        yAxis: {
          type: 'value',
          show: false,
        },
      } as EChartsOption),
    [chart.options.dataset, chart.options.series],
  );

  return (
    <Box pos="relative" w="full" h="34px" overflow="hidden">
      <Box pos="absolute" top={0} left={0} w="full">
        <ECharts options={dataOptions} onChartReady={chart.onChartReady} />
      </Box>
      <Box pos="absolute" top={0} left={0} w="full">
        <ECharts options={dataZoomOptions} onChartReady={chart.onChartReady} />
      </Box>
    </Box>
  );
};
