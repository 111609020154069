import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Heading,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  Tag,
  TagLabel,
  Text,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { Form, Label } from '@piccolohealth/pbs-common';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FaCopy } from 'react-icons/fa';
import { FormItem } from '../../../components/forms/FormItem';
import { FormStack } from '../../../components/forms/FormStack';
import { showModal } from '../../../components/generic/Modal';
import { usePermission } from '../../../hooks/usePermission';
import { FormDeleteModal } from './FormDeleteModal';
import { FormTypeTag } from './FormTypeTag';
import { FormUpdateModal } from './FormUpdateModal';

interface FormLabelsProps {
  labels: Label[];
}

export const FormLabels = (props: FormLabelsProps) => {
  const { labels } = props;

  if (P.isEmpty(labels)) {
    return (
      <Text fontSize="xs" color="secondary">
        No labels
      </Text>
    );
  }

  return (
    <Wrap pt={2}>
      {labels.map((label) => {
        return (
          <WrapItem key={label.id}>
            <Tag size="sm" colorScheme={label.color}>
              <TagLabel>{label.name}</TagLabel>
            </Tag>
          </WrapItem>
        );
      })}
    </Wrap>
  );
};

interface FormCardProps {
  form: Form;
}

export const FormCard = (props: FormCardProps) => {
  const { form } = props;

  const updateFormPermission = usePermission('update', 'form');
  const deleteFormPermission = usePermission('delete', 'form');

  return (
    <Card w="full" h="full">
      <CardHeader py={4} px={4}>
        <HStack>
          <Heading size="xs" noOfLines={1}>
            {form.name}
          </Heading>
          <Spacer />
          <FormTypeTag type={form.type} />
        </HStack>
      </CardHeader>
      <Divider />
      <CardBody py={4} px={4}>
        <FormStack spacing={6}>
          <FormItem label="Share form" labelSize="sm">
            <InputGroup size="sm">
              <Input pr="4.5rem" size="sm" isDisabled value={form.url ?? ''} />
              <InputRightElement width="4.5rem">
                <Button
                  h="1.45rem"
                  variant="solid"
                  colorScheme={'purple'}
                  size="xs"
                  mr={1}
                  leftIcon={<Icon as={FaCopy} />}
                  onClick={() => navigator.clipboard.writeText(form.url ?? '')}
                >
                  Copy
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormItem>
          <FormItem label="Creation labels" labelSize="sm">
            <FormLabels labels={form.labelMappings.create} />
          </FormItem>
          <FormItem label="Update labels" labelSize="sm">
            <FormLabels labels={form.labelMappings.update} />
          </FormItem>
        </FormStack>
      </CardBody>
      <CardFooter px={4} py={4} flexShrink={0}>
        <HStack>
          <Button
            variant="link"
            color="danger"
            size="xs"
            isDisabled={!deleteFormPermission}
            onClick={(e) => {
              e.preventDefault();
              showModal(FormDeleteModal, {
                formId: form.id,
              });
            }}
          >
            Delete
          </Button>
          <Button
            variant="link"
            color="secondary"
            size="xs"
            isDisabled={!updateFormPermission}
            onClick={(e) => {
              e.preventDefault();
              showModal(FormUpdateModal, {
                form,
              });
            }}
          >
            Settings
          </Button>
        </HStack>
      </CardFooter>
    </Card>
  );
};
