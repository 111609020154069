import React from 'react';

import { Box, Link, Stack, Text } from '@chakra-ui/react';
import { TableOfContentDataItem } from '@tiptap-pro/extension-table-of-content';
import { TextSelection } from '@tiptap/pm/state';
import { Editor } from '@tiptap/react';
import { scrollIntoView } from '../../utils/scrollIntoView';

interface TableOfContentItemProps {
  item: TableOfContentDataItem;
  onItemClick: (e: React.MouseEvent<HTMLDivElement>, id: string) => void;
}

export const TableOfContentItem = (props: TableOfContentItemProps) => {
  const { item, onItemClick } = props;

  return (
    <Box pl={2 * (item.level - 1)}>
      <Box
        as={Link}
        px={2}
        py={0.5}
        rounded="md"
        fontSize="sm"
        fontWeight="normal"
        display="block"
        href={`#${item.id}`}
        onClick={(e) => onItemClick(e, item.id)}
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
      >
        {item.itemIndex}. {item.textContent}
      </Box>
    </Box>
  );
};

export const TableOfContentEmptyState = () => {
  return (
    <Text color="secondary" px={3} fontSize="sm">
      Start editing your document to see the outline.
    </Text>
  );
};

interface Props {
  editor: Editor | null;
}

export const TableOfContentView = (props: Props) => {
  const { editor } = props;

  const items: TableOfContentDataItem[] = editor?.storage?.tableOfContent?.content ?? [];

  if (items.length === 0 || editor?.isEmpty) {
    return <TableOfContentEmptyState />;
  }

  const onItemClick = (e: React.MouseEvent<HTMLDivElement>, id: string) => {
    e.preventDefault();

    if (editor) {
      const element = editor.view.dom.querySelector(`[data-toc-id="${id}"`);

      if (!element) {
        return;
      }

      const pos = editor.view.posAtDOM(element, 0);

      // set focus
      const tr = editor.view.state.tr;

      tr.setSelection(new TextSelection(tr.doc.resolve(pos)));

      editor.view.dispatch(tr);

      editor.view.focus();

      scrollIntoView(element);
    }
  };

  return (
    <Stack spacing={1} px={2}>
      {items.map((item) => (
        <TableOfContentItem key={item.id} item={item} onItemClick={onItemClick} />
      ))}
    </Stack>
  );
};
