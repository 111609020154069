import { Button, HStack, Icon, Text } from '@chakra-ui/react';
import * as Common from '@piccolohealth/pbs-common';
import { createColumnHelper, DataTable, Empty, Spin } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { FormStack } from '../../../components/forms/FormStack';
import { Error } from '../../../components/generic/Error';
import { FieldTemplateTypeTag } from '../../../components/generic/FieldTemplateTypeTag';
import { showModal } from '../../../components/generic/Modal';
import { useParticipantFieldGroupTemplateQuery } from '../../../graphql/hooks/useParticipantFieldGroupTemplateQuery';
import { useUpdateParticipantFieldTemplateMutation } from '../../../graphql/hooks/useUpdateParticipantFieldTemplateMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { ParticipantFieldTemplateCreateModal } from './ParticipantFieldTemplateCreateModal';
import { ParticipantFieldTemplateDeleteModal } from './ParticipantFieldTemplateDeleteModal';
import { ParticipantFieldTemplateUpdateModal } from './ParticipantFieldTemplateUpdateModal';

interface Props {
  fieldGroupTemplateId: string;
  fieldGroupTemplates: Common.ParticipantFieldGroupTemplate[];
}

export const ParticipantFieldTemplates = (props: Props) => {
  const { fieldGroupTemplateId, fieldGroupTemplates } = props;
  const { organization, errorToast } = useAppContext();

  const [fieldTemplatesPrime, setFieldTemplatesPrime] = React.useState<
    Common.ParticipantFieldTemplate[] | null
  >(null);

  const { data, isLoading, error } = useParticipantFieldGroupTemplateQuery(
    {
      organizationId: organization.id,
      participantFieldGroupTemplateId: fieldGroupTemplateId ?? '',
    },
    {
      enabled: !!fieldGroupTemplateId,
    },
  );

  const fieldTemplateGroup = data?.organization?.participantFieldGroupTemplate;

  React.useEffect(() => {
    const fieldTemplates =
      fieldTemplateGroup?.fields as unknown as Common.ParticipantFieldTemplate[];

    setFieldTemplatesPrime(fieldTemplates);
  }, [fieldTemplateGroup]);

  const updateMutation = useUpdateParticipantFieldTemplateMutation();

  const onRowMove = React.useCallback(
    async (fromIndex: number, toIndex: number) => {
      const existingFieldTemplates = fieldTemplatesPrime;

      if (fromIndex === toIndex) {
        return;
      }

      const fromFieldTemplate = fieldTemplatesPrime?.[fromIndex];

      if (!fromFieldTemplate) {
        return;
      }

      const reorderedFieldTemplates = P.moveArrayItem(fieldTemplatesPrime, fromIndex, toIndex).map(
        (template, index) => ({
          ...template,
          index,
        }),
      );

      setFieldTemplatesPrime(reorderedFieldTemplates);

      await updateMutation
        .mutateAsync({
          organizationId: organization.id,
          participantFieldTemplateId: fromFieldTemplate.id,
          request: {
            index: toIndex,
          },
        })
        .catch((err) => {
          setFieldTemplatesPrime(existingFieldTemplates);
          errorToast(`Error updating participant field templates: ${err.message}`);
        });
    },
    [fieldTemplatesPrime, updateMutation, organization.id, errorToast],
  );

  const columns = React.useMemo(() => {
    const columnHelper = createColumnHelper<Common.ParticipantFieldTemplate>();

    return [
      columnHelper.accessor('name', {
        header: 'Name',
        minSize: 300,
        maxSize: 300,
        cell: (props) => <Text>{props.row.original.name}</Text>,
      }),
      columnHelper.accessor('type', {
        header: 'Type',
        minSize: 240,
        maxSize: 240,
        cell: (props) => <FieldTemplateTypeTag type={props.row.original.type} size="sm" />,
      }),
      columnHelper.display({
        header: 'Actions',
        id: 'actions',
        minSize: 100,
        maxSize: 120,
        cell: (props) => (
          <HStack>
            <Button
              size="xs"
              variant="link"
              onClick={() =>
                showModal(ParticipantFieldTemplateUpdateModal, {
                  participantFieldGroupTemplateId: fieldGroupTemplateId,
                  participantFieldTemplate: props.row.original,
                  fieldGroupTemplates,
                })
              }
            >
              Edit
            </Button>
            <Button
              size="xs"
              variant="link"
              colorScheme="red"
              onClick={() =>
                showModal(ParticipantFieldTemplateDeleteModal, {
                  participantFieldTemplateId: props.row.original.id,
                })
              }
            >
              Delete
            </Button>
          </HStack>
        ),
      }),
    ];
  }, [fieldGroupTemplates, fieldGroupTemplateId]);

  if (isLoading || !fieldTemplatesPrime) {
    return <Spin showAfterDuration={2000} />;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (P.isEmpty(fieldGroupTemplates)) {
    return <Empty title="No field groups found" />;
  }

  return (
    <FormStack>
      <DataTable
        size="sm"
        columns={columns}
        data={fieldTemplatesPrime}
        renderEmpty={() => <Empty title="No fields found" />}
        getRowId={(row) => row.id}
        rowSortable={{ onRowMove }}
      />
      <Button
        flexShrink={0}
        size="sm"
        leftIcon={<Icon as={FaPlus} />}
        onClick={() =>
          showModal(ParticipantFieldTemplateCreateModal, {
            participantFieldGroupTemplateId: fieldGroupTemplateId,
            index: fieldTemplatesPrime.length,
            fieldGroupTemplates,
          })
        }
      >
        Add new field
      </Button>
    </FormStack>
  );
};
