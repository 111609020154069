import { Divider, Stack } from '@chakra-ui/react';
import { User } from '@piccolohealth/pbs-common';
import React from 'react';
import { FormSection } from '../../components/forms/FormSection';
import { FormStack } from '../../components/forms/FormStack';
import { useUserParticipantsFilterQuery } from '../../graphql/hooks/useUserQuery';
import { useUserParticipantsFilter } from '../../hooks/useUserParticipantsFilter';
import { UserParticipantsFilterControls } from './UserParticipantFilterControls';
import { UserParticipantsTable } from './UserParticipantsTable';

interface Props {
  user: User;
}

export const UserParticipants = (props: Props) => {
  const { user } = props;

  const filter = useUserParticipantsFilter({ user });
  const { isLoading, participants, error, pagination, refetch } =
    useUserParticipantsFilterQuery(filter);

  return (
    <FormStack maxW="8xl">
      <FormSection
        heading="Participants"
        description="You can manage a user's access to participants here."
      >
        <Divider />

        <Stack spacing={6} w="full">
          <UserParticipantsFilterControls filter={filter} />

          <UserParticipantsTable
            user={user}
            participants={participants}
            isLoading={isLoading}
            error={error}
            refetch={refetch}
            pagination={pagination}
          />
        </Stack>
      </FormSection>
    </FormStack>
  );
};
