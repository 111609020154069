import { Flex, Stack, Square, Icon, Button, Text } from '@chakra-ui/react';
import React from 'react';
import { FaWifi, FaSyncAlt } from 'react-icons/fa';
import { PAGE_WIDTH } from '../utils';

export const DisconnectedContent = () => {
  return (
    <Flex flexDir="column" h="full" w="full" maxW={PAGE_WIDTH} px={6} alignSelf="center">
      <Stack maxW="md" py={6} spacing={4}>
        <Square size="16" rounded="2xl" bg="red.50">
          <Icon as={FaWifi} color="red.400" boxSize="10" />
        </Square>
        <Text fontWeight="bold" fontSize="4xl">
          You are offline
        </Text>
        <Text fontSize="xl" fontWeight="semibold" color="gray.600">
          It seems there was a problem with your connection. Please check your network status.
        </Text>
        <Button leftIcon={<FaSyncAlt />} onClick={() => window.location.reload()}>
          Refresh to reconnect
        </Button>
      </Stack>
    </Flex>
  );
};
