import { Button, ButtonGroup, Heading, HStack, Icon, Spacer, Stack } from '@chakra-ui/react';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { showModal } from '../../components/generic/Modal';
import { ContentLayout } from '../../components/layouts/ContentLayout';
import { useIncidentsFilterQuery } from '../../graphql/hooks/useIncidentsQuery';
import { useIncidentsFilter } from '../../hooks/useIncidentsFilter';
import { IncidentCreateModal } from './IncidentCreateModal';
import { IncidentsActionMenu } from './IncidentsActionMenu';
import { IncidentsFilterControls } from './IncidentsFilterControls';
import { IncidentsTable } from './IncidentsTable';

export const Incidents = () => {
  const filter = useIncidentsFilter();

  const { isLoading, error, incidents, refetch, pagination, rowSelection } =
    useIncidentsFilterQuery(filter);

  return (
    <ContentLayout
      headerContent={
        <HStack align="start">
          <Heading size="lg">Incidents</Heading>
          <Spacer />
          <ButtonGroup>
            <Button
              leftIcon={<Icon as={FaPlus} />}
              colorScheme="purple"
              size="sm"
              onClick={() => showModal(IncidentCreateModal)}
            >
              Add incident
            </Button>

            <IncidentsActionMenu filter={filter} size="sm" />
          </ButtonGroup>
        </HStack>
      }
    >
      <Stack spacing={4} px={6}>
        <IncidentsFilterControls filter={filter} />
        <IncidentsTable
          error={error}
          isLoading={isLoading}
          incidents={incidents}
          refetch={refetch}
          pagination={pagination}
          rowSelection={rowSelection}
        />
      </Stack>
    </ContentLayout>
  );
};
