import { Modal, ModalBody, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import { ModalCloseButton } from '@chakra-ui/react';
import { TableWidget } from '@piccolohealth/pbs-common';
import { uuid } from '@piccolohealth/util';
import React from 'react';
import { createModal } from '../../../../components/generic/Modal';
import { TableWidgetModalContent } from './TableWidgetModalContent';

interface Props {
  participantId: string;
  onAdd: (item: TableWidget) => void;
}

export const TableWidgetAddModal = createModal<Props>((props) => {
  const { modal, participantId, onAdd } = props;

  const { visible, hide, remove } = modal;

  const onSubmit = (widget: TableWidget) => {
    hide();
    onAdd(widget);
  };

  const widget: TableWidget = React.useMemo(
    () => ({
      __typename: 'TableWidget',
      id: uuid(),
      series: [],
      title: 'New table',
    }),
    [],
  );

  return (
    <Modal
      isOpen={visible}
      onClose={hide}
      onCloseComplete={remove}
      size="outline"
      scrollBehavior="inside"
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>Add table</ModalHeader>
        <ModalCloseButton />
        <ModalBody p={2} overflowY="auto">
          <TableWidgetModalContent
            participantId={participantId}
            onSubmit={onSubmit}
            widget={widget}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});
