import gql from 'graphql-tag';

export const RestoreTemplateVersion = gql`
  mutation RestoreTemplateVersion($organizationId: ID!, $templateId: ID!, $versionId: ID!) {
    restoreTemplateVersion(
      organizationId: $organizationId
      templateId: $templateId
      versionId: $versionId
    ) {
      id
      versionId
    }
  }
`;
