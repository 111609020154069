import { useRequiredParams } from '@piccolohealth/ui';
import React from 'react';
import { useUserMinimalQuery } from '../../graphql/hooks/useUserQuery';
import { useAppContext } from '../../hooks/useAppContext';

export const UserCrumb = () => {
  const { userId } = useRequiredParams<{ userId: string }>();

  const { organization } = useAppContext();

  const { isLoading, data, error } = useUserMinimalQuery({
    organizationId: organization.id,
    userId,
  });

  if (isLoading) {
    return <span>...</span>;
  }

  if (error) {
    return <span>_</span>;
  }

  const user = data?.organization?.user;

  if (!user) {
    return <span>_</span>;
  }

  return <span>{user.name}</span>;
};
