import gql from 'graphql-tag';

export const GenerateParticipantDocumentPdf = gql`
  mutation GenerateParticipantDocumentPdf(
    $organizationId: ID!
    $participantId: ID!
    $participantDocumentId: ID!
  ) {
    generateParticipantDocumentPdf(
      organizationId: $organizationId
      participantId: $participantId
      participantDocumentId: $participantDocumentId
    ) {
      downloadUrl
      viewUrl
    }
  }
`;
