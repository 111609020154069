import { accordionAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  accordionAnatomy.keys,
);

const baseStyle = definePartsStyle({
  container: {
    borderBottomWidth: '1px',
    borderTopWidth: 0,
  },
  button: {
    px: 6,
  },
  panel: {
    px: 6,
  },
});

export const Accordion = defineMultiStyleConfig({ baseStyle });
