import { Grid, GridItem, Stack } from '@chakra-ui/react';
import * as Common from '@piccolohealth/pbs-common';
import { PiccoloError } from '@piccolohealth/pbs-common';
import { Spin } from '@piccolohealth/ui';
import React from 'react';
import { Error } from '../../components/generic/Error';
import { useParticipantQuery } from '../../graphql/hooks/useParticipantQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { ParticipantDisabledStatus } from '../participant/ParticipantDisabledStatus';
import { ParticipantDocumentsCard } from './ParticipantDocumentsCard';
import { ParticipantNotesCard } from './ParticipantNotesCard';
import { ParticipantProfileCard } from './ParticipantProfileCard';
import { ParticipantTeamCard } from './ParticipantTeamCard';

interface Props {
  participantId: string;
  isDisabled: boolean;
}

export const ParticipantDashboard = (props: Props) => {
  const { participantId, isDisabled } = props;

  const { organization } = useAppContext();

  const { data, error, isLoading } = useParticipantQuery({
    organizationId: organization.id,
    participantId,
  });

  const participant = data?.organization?.participant as Common.Participant | undefined;

  if (isLoading) {
    return <Spin />;
  }

  if (error) {
    return <Error error={error} />;
  }

  if (!participant) {
    return (
      <Error
        error={
          new PiccoloError({
            type: 'ParticipantNotFound',
            message: 'Participant not found',
          })
        }
      />
    );
  }

  return (
    <Grid
      h="full"
      templateRows="repeat(1, 1fr)"
      templateColumns={{ sm: 'repeat(1, 1fr)', '2xl': 'repeat(3, 1fr)' }}
      gap={4}
      pb="16px"
      px={6}
      py={4}
    >
      <GridItem colSpan={{ sm: 1, '2xl': 2 }}>
        <Stack spacing={4}>
          <ParticipantDisabledStatus status={participant.status} />
          <ParticipantProfileCard participant={participant} isDisabled={isDisabled} />
        </Stack>
      </GridItem>
      <GridItem colSpan={1} pb="16px">
        <Stack spacing={4}>
          <ParticipantTeamCard participant={participant} isDisabled={isDisabled} />
          <ParticipantNotesCard participantId={participantId} isDisabled={isDisabled} />
          <ParticipantDocumentsCard participantId={participantId} isDisabled={isDisabled} />
        </Stack>
      </GridItem>
    </Grid>
  );
};
