import { Button, Icon, Stack } from '@chakra-ui/react';
import * as Common from '@piccolohealth/pbs-common';
import { DndKitCore, DndKitSortable } from '@piccolohealth/ui';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { FormStack } from '../../../components/forms/FormStack';
import { useCreateParticipantFieldGroupTemplateMutation } from '../../../graphql/hooks/useCreateParticipantFieldGroupTemplateMutation';
import { useDeleteParticipantFieldGroupTemplateMutation } from '../../../graphql/hooks/useDeleteParticipantFieldGroupTemplateMutation';
import { useUpdateParticipantFieldGroupTemplateMutation } from '../../../graphql/hooks/useUpdateParticipantFieldGroupTemplatesMutation';
import { useAppContext } from '../../../hooks/useAppContext';
import { ParticipantFieldGroupTemplate } from './ParticipantFieldGroupTemplate';

interface Props {
  fieldGroupTemplates: Common.ParticipantFieldGroupTemplate[];
  currentFieldGroupTemplateId: string;
  onChangeCurrentFieldGroupTemplateId: (value: string) => void;
}

export const ParticipantFieldGroupTemplates = (props: Props) => {
  const { fieldGroupTemplates, currentFieldGroupTemplateId, onChangeCurrentFieldGroupTemplateId } =
    props;
  const { organization, errorToast } = useAppContext();

  const [fieldGroupTemplatesPrime, setFieldGroupTemplatesPrime] =
    React.useState<Common.ParticipantFieldGroupTemplate[]>(fieldGroupTemplates);

  React.useEffect(() => {
    if (fieldGroupTemplates) {
      setFieldGroupTemplatesPrime(fieldGroupTemplates);
    }
  }, [fieldGroupTemplates]);

  const sensors = DndKitCore.useSensors(
    DndKitCore.useSensor(DndKitCore.PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  const createMutation = useCreateParticipantFieldGroupTemplateMutation();
  const updateMutation = useUpdateParticipantFieldGroupTemplateMutation();
  const deleteMutation = useDeleteParticipantFieldGroupTemplateMutation();

  const onClick = (id: string) => {
    onChangeCurrentFieldGroupTemplateId(id);
  };

  const onAdd = React.useCallback(async () => {
    const newTemplate = {
      name: 'New group',
      index: fieldGroupTemplatesPrime.length,
    } as Common.ParticipantFieldGroupTemplate;

    const existingFieldGroupTemplates = fieldGroupTemplatesPrime;
    setFieldGroupTemplatesPrime((prev) => [...prev, newTemplate]);

    await createMutation
      .mutateAsync({
        organizationId: organization.id,
        request: {
          name: newTemplate.name,
          index: newTemplate.index,
        },
      })
      .catch((err) => {
        setFieldGroupTemplatesPrime(existingFieldGroupTemplates);
        errorToast(`Error creating participant field template group: ${err.message}`);
      });
  }, [createMutation, organization.id, errorToast, fieldGroupTemplatesPrime]);

  const onEdit = (id: string) => async (name: string) => {
    const existingFieldGroupTemplates = fieldGroupTemplatesPrime;

    setFieldGroupTemplatesPrime((prev) => {
      return prev.map((template) => {
        if (template.id === id) {
          return {
            ...template,
            name,
          };
        }

        return template;
      });
    });

    await updateMutation
      .mutateAsync({
        organizationId: organization.id,
        participantFieldGroupTemplateId: id,
        request: {
          name,
        },
      })
      .catch((err) => {
        setFieldGroupTemplatesPrime(existingFieldGroupTemplates);
        errorToast(`Error updating participant field template group: ${err.message}`);
      });
  };

  const onRemove = React.useCallback(
    async (id: string) => {
      const existingFieldGroupTemplates = fieldGroupTemplatesPrime;

      setFieldGroupTemplatesPrime((prev) => {
        const newFieldGroupTemplates = prev.filter((item) => item.id !== id);
        onChangeCurrentFieldGroupTemplateId(P.first(newFieldGroupTemplates)?.id ?? '');
        return newFieldGroupTemplates;
      });

      await deleteMutation
        .mutateAsync({
          organizationId: organization.id,
          participantFieldGroupTemplateId: id,
        })
        .catch((err) => {
          setFieldGroupTemplatesPrime(existingFieldGroupTemplates);
          errorToast(`Error deleting participant field template group: ${err.message}`);
        });
    },
    [
      deleteMutation,
      fieldGroupTemplatesPrime,
      onChangeCurrentFieldGroupTemplateId,
      organization.id,
      errorToast,
    ],
  );

  const onDragEnd = React.useCallback(
    async (event: DndKitCore.DragEndEvent) => {
      const { active, over } = event;

      const existingFieldGroupTemplates = fieldGroupTemplatesPrime;

      if (over && active.id !== over.id) {
        const oldIndex = fieldGroupTemplatesPrime.findIndex((item) => item.id === active.id);
        const newIndex = fieldGroupTemplatesPrime.findIndex((item) => item.id === over.id);

        if (oldIndex === newIndex) {
          return;
        }

        const fromFieldTemplate = fieldGroupTemplatesPrime[oldIndex];

        if (!fromFieldTemplate) {
          return;
        }

        const reorderedFieldTemplates = P.moveArrayItem(
          fieldGroupTemplatesPrime,
          oldIndex,
          newIndex,
        ).map((template, index) => ({
          ...template,
          index,
        }));

        setFieldGroupTemplatesPrime(reorderedFieldTemplates);

        await updateMutation
          .mutateAsync({
            organizationId: organization.id,
            participantFieldGroupTemplateId: fieldGroupTemplatesPrime[oldIndex].id,
            request: {
              index: newIndex,
            },
          })
          .catch((err) => {
            setFieldGroupTemplatesPrime(existingFieldGroupTemplates);
            errorToast(`Error updating field template groups: ${err.message}`);
          });
      }
    },
    [fieldGroupTemplatesPrime, organization.id, updateMutation, errorToast],
  );

  return (
    <FormStack as="form">
      <DndKitCore.DndContext
        sensors={sensors}
        collisionDetection={DndKitCore.closestCenter}
        onDragEnd={onDragEnd}
      >
        <DndKitSortable.SortableContext
          strategy={DndKitSortable.verticalListSortingStrategy}
          items={fieldGroupTemplatesPrime.map(({ id }) => id)}
        >
          <Stack spacing={2}>
            {fieldGroupTemplatesPrime.map((fieldGroupTemplate) => (
              <ParticipantFieldGroupTemplate
                key={fieldGroupTemplate.id}
                fieldGroupTemplate={fieldGroupTemplate}
                isActive={fieldGroupTemplate.id === currentFieldGroupTemplateId}
                onClick={() => onClick(fieldGroupTemplate.id)}
                onEdit={onEdit(fieldGroupTemplate.id)}
                onRemove={() => onRemove(fieldGroupTemplate.id)}
              />
            ))}
          </Stack>
        </DndKitSortable.SortableContext>
      </DndKitCore.DndContext>
      <Button size="sm" leftIcon={<Icon as={FaPlus} />} onClick={onAdd}>
        Add new group
      </Button>
    </FormStack>
  );
};
