import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const outline = defineStyle({
  rounded: 'md',
  resize: 'none',
});

const defaultProps = {
  size: 'sm',
  focusBorderColor: 'purple.500',
};

export const Textarea = defineStyleConfig({
  defaultProps,
  variants: { outline },
});
