import {
  Button,
  ButtonProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Tag,
  TagLabel,
  TagRightIcon,
} from '@chakra-ui/react';
import { ParticipantDocument, ParticipantDocumentStatus } from '@piccolohealth/pbs-common';
import React from 'react';
import { FaAngleDown } from 'react-icons/fa';
import { showModal } from '../../components/generic/Modal';
import { ParticipantDocumentChangeStatusModal } from './ParticipantDocumentChangeStatusModal';
import {
  participantDocumentStatusColorName,
  ParticipantDocumentStatusTag,
} from './ParticipantDocumentStatusTag';

interface Props extends ButtonProps {
  participantDocument: ParticipantDocument;
}

export const ParticipantDocumentStatusMenu = (props: Props) => {
  const { participantDocument, ...rest } = props;

  const onClick = async (status: ParticipantDocumentStatus) => {
    showModal(ParticipantDocumentChangeStatusModal, {
      participantDocument,
      status,
    });
  };

  const [color, currentStatus] = participantDocumentStatusColorName(participantDocument.status);
  const statuses = Object.values(ParticipantDocumentStatus).map((status) => {
    return (
      <MenuItem
        key={status}
        onClick={() => onClick(status)}
        isDisabled={participantDocument.status === status}
      >
        <ParticipantDocumentStatusTag status={status} />
      </MenuItem>
    );
  });

  return (
    <Menu isLazy lazyBehavior="keepMounted" size="sm">
      <MenuButton as={Button} variant="link" size="sm" {...rest}>
        <Tag size="sm" colorScheme={color}>
          <TagLabel>{currentStatus}</TagLabel>
          <TagRightIcon as={FaAngleDown} />
        </Tag>
      </MenuButton>
      <Portal>
        <MenuList>{statuses}</MenuList>
      </Portal>
    </Menu>
  );
};
