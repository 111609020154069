import gql from 'graphql-tag';

export const CreateParticipantFieldGroupTemplate = gql`
  mutation CreateParticipantFieldGroupTemplate(
    $organizationId: ID!
    $request: CreateParticipantFieldGroupTemplateRequest!
  ) {
    createParticipantFieldGroupTemplate(organizationId: $organizationId, request: $request) {
      id
      name
      index
      createdAt
      updatedAt
    }
  }
`;
