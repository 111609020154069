import { useMutation, useQueryClient } from '@tanstack/react-query';
import { gqlFetcher, MutationOptions } from '../fetcher';
import { UpdateForm } from '../mutations/updateForm.mutation';
import { UpdateFormMutation, UpdateFormMutationVariables } from '../types';

export const useUpdateFormMutation = (
  options?: MutationOptions<UpdateFormMutation, UpdateFormMutationVariables>,
) => {
  const queryClient = useQueryClient();

  return useMutation(gqlFetcher(UpdateForm), {
    ...options,
    onSettled: async (resp, error, variables, ctx) => {
      await options?.onSettled?.(resp, error, variables, ctx);

      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes('organization'),
      });
    },
  });
};
