import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { createModal } from '../../components/generic/Modal';
import { useParticipantNotesQuery } from '../../graphql/hooks/useParticipantQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { ParticipantNoteEditor } from './ParticipantNoteEditor';

interface Props {
  participantNoteId: string;
  participantId: string;
  isDisabled?: boolean;
}

export const ParticipantNoteEditModal = createModal<Props>((props) => {
  const { participantId, participantNoteId, isDisabled, modal } = props;

  const { queryClient, organization } = useAppContext();
  const { hide, visible } = modal;

  const onClose = React.useCallback(() => {
    // Close modal, and invalidate the notes after 1s.
    // Enough time for hocus to save. I don't have a better way of doing this right now
    hide();
    setTimeout(() => {
      queryClient.invalidateQueries(
        useParticipantNotesQuery.getKey({
          organizationId: organization.id,
          participantId,
        }),
      );
    }, 2000);
  }, [hide, organization.id, participantId, queryClient]);

  return (
    <Modal isOpen={visible} onClose={onClose} onCloseComplete={modal.remove} size="note">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Flex align="start">
            <Box>
              <Heading size="md">Edit a note</Heading>
              <Text fontSize="md" fontWeight="normal" color="secondary" mt={2}>
                Please complete the following to proceed
              </Text>
            </Box>
            <Spacer />
            <ModalCloseButton position="unset" top="unset" right="unset" />
          </Flex>
        </ModalHeader>
        <ModalBody p={0} overflowY="auto">
          <Box overflowY="auto" h="full">
            <ParticipantNoteEditor
              participantId={participantId}
              participantNoteId={participantNoteId}
              isDisabled={isDisabled}
              onDelete={onClose}
            />
          </Box>
        </ModalBody>
        <ModalFooter>
          <HStack>
            <Button size="sm" onClick={hide}>
              Close
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});
