import { Divider, HStack, Stack } from '@chakra-ui/react';
import { ParticipantBehaviour, TableWidget } from '@piccolohealth/pbs-common';
import { Empty, Spin, useDebouncedCallback } from '@piccolohealth/ui';
import { DateTime } from '@piccolohealth/util';
import React from 'react';
import { FormItem } from '../../../../components/forms/FormItem';
import { useParticipantBehavioursRecordingsQuery } from '../../../../graphql/hooks/useParticipantQuery';
import { useAppContext } from '../../../../hooks/useAppContext';
import { useTable } from '../../hooks/useTable';
import { ParticipantWidgetTimeRangeControl } from '../ParticipantWidgetTimeRangeControl';
import { TableWidgetContent } from './TableWidgetContent';
import { TableWidgetModalSidebar } from './TableWidgetModalSidebar';

interface Props {
  participantId: string;
  widget: TableWidget;
  onSubmit: (widget: TableWidget) => void;
}

export const TableWidgetModalContent = (props: Props) => {
  const { participantId, onSubmit } = props;

  const { organization } = useAppContext();

  const [widget, setWidget] = React.useState<TableWidget>(props.widget);

  const [startDate, setStartDate] = React.useState(DateTime.now().minus({ days: 30 }));
  const [endDate, setEndState] = React.useState(DateTime.now());

  const onStartDateChange = useDebouncedCallback((date: DateTime) => {
    setStartDate(date);
  }, 100);

  const onEndDateChange = useDebouncedCallback((date: DateTime) => {
    setEndState(date);
  }, 100);

  const participantBehaviourQuery = useParticipantBehavioursRecordingsQuery({
    organizationId: organization.id,
    participantId,
  });

  const participantBehaviours =
    (participantBehaviourQuery.data?.organization?.participant
      ?.behaviours as ParticipantBehaviour[]) ?? [];

  const table = useTable({
    participantBehaviours,
    widget,
    startDate,
    endDate,
    setWidget,
  });

  if (participantBehaviourQuery.isLoading) {
    return <Spin />;
  }

  if (!participantBehaviours) {
    return <Empty />;
  }

  return (
    <HStack w="full" h="full" spacing={2} divider={<Divider orientation="vertical" />}>
      <TableWidgetModalSidebar table={table} onSubmit={onSubmit} />
      <Stack w="full" h="full" p={4}>
        <FormItem label="Date">
          <ParticipantWidgetTimeRangeControl
            startDate={startDate}
            endDate={endDate}
            onStartDateChange={onStartDateChange}
            onEndDateChange={onEndDateChange}
          />
        </FormItem>
        <TableWidgetContent table={table} />
      </Stack>
    </HStack>
  );
};
