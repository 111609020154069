import { ParticipantUser } from '@piccolohealth/pbs-common';
import React from 'react';
import { LabelsStack } from '../../components/generic/LabelsStack';
import { ListItem } from '../../components/generic/ListItem';
import { UserAvatar } from '../../components/user/UserAvatar';

interface Props {
  participantUser: ParticipantUser;
}

export const ParticipantTeamMember = (props: Props) => {
  const { participantUser } = props;
  const { user } = participantUser;

  return (
    <ListItem
      icon={
        <UserAvatar
          secondary={user.email}
          picture={user.picture}
          name={`${user.name} - ${user.title}`}
          w={10}
          h={10}
          showTooltip={false}
        />
      }
      title={user.name}
      secondary={user.title}
      tertiary={<LabelsStack labels={participantUser.labels} />}
      right={null}
    />
  );
};
