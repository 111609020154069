import { Badge, HStack, Text } from '@chakra-ui/react';
import React from 'react';

interface Props {
  label: string;
  color?: string;
  icon?: React.ReactNode;
}

export const BadgeItem = (props: Props) => {
  return (
    <Badge px={2} py={0.5} colorScheme={props.color} rounded="md">
      <HStack align="center" lineHeight="normal">
        {props.icon && props.icon}
        <Text>{props.label}</Text>
      </HStack>
    </Badge>
  );
};
