import { Tag, TagProps } from '@chakra-ui/react';
import { ParticipantBehaviourRecording } from '@piccolohealth/pbs-common';
import React from 'react';

export const participantBehaviourTypeColorName = (
  type: ParticipantBehaviourRecording['__typename'],
) => {
  switch (type) {
    case 'ParticipantBehaviourRecordingAbc':
      return ['blue', 'ABC'];
    case 'ParticipantBehaviourRecordingDuration':
      return ['yellow', 'Duration'];
    case 'ParticipantBehaviourRecordingEpisodicSeverity':
      return ['green', 'Episodic Severity'];
    case 'ParticipantBehaviourRecordingFrequency':
      return ['red', 'Frequency'];
    case undefined:
      return ['gray', 'Unknown'];
  }
};

interface Props extends TagProps {
  type: ParticipantBehaviourRecording['__typename'];
}

export const ParticipantBehaviourRecordingTypeTag = (props: Props) => {
  const { type, ...rest } = props;

  const [color, name] = participantBehaviourTypeColorName(type);

  return (
    <Tag colorScheme={color} {...rest}>
      {name}
    </Tag>
  );
};
