import gql from 'graphql-tag';

export const UpdateParticipant = gql`
  mutation UpdateParticipant(
    $organizationId: ID!
    $participantId: ID!
    $request: UpdateParticipantRequest!
  ) {
    updateParticipant(
      organizationId: $organizationId
      participantId: $participantId
      request: $request
    ) {
      id
    }
  }
`;
