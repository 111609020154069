import { Stack } from '@chakra-ui/react';
import React from 'react';
import { UseTableReturn } from '../../hooks/useTable';
import { SeriesListItem } from '../SeriesListItem';
import { TableSeriesControl } from './TableSeriesControl';

interface Props {
  table: UseTableReturn;
}

export const TableSeriesList = (props: Props) => {
  const { table } = props;

  return (
    <Stack w="full">
      {table.participantBehaviours.map((behaviour) => (
        <SeriesListItem key={behaviour.id} behaviour={behaviour}>
          <TableSeriesControl participantBehaviourId={behaviour.id} table={table} />
        </SeriesListItem>
      ))}
    </Stack>
  );
};
