import { Spacer, Stack } from '@chakra-ui/react';
import React from 'react';
import {
  FaChartArea,
  FaCog,
  FaExclamationTriangle,
  FaUser,
  FaUserAlt,
  FaUsers,
} from 'react-icons/fa';
import {
  SidebarButtonLink,
  SidebarDivider,
  SidebarSection,
} from '../../components/layouts/SidebarLayout';
import { useAppContext } from '../../hooks/useAppContext';
import { usePermission } from '../../hooks/usePermission';
import { NotificationCenterButton } from '../notification/NotificationCenterButton';
import { UserMenu } from '../user/UserMenu';

interface Props {
  isShrunk: boolean;
}

export const OrganizationSidebar = (props: Props) => {
  const { isShrunk } = props;
  const { organization } = useAppContext();

  const incidentsPermission = usePermission('read', 'incident');
  const usersPermission = usePermission('create', 'user');
  const settingsPermission = usePermission('update', 'organization');
  const templatesPermission = usePermission('create', 'template');

  return (
    <Stack h="full" spacing={0}>
      <SidebarDivider />

      <SidebarSection>
        <NotificationCenterButton isShrunk={isShrunk} />
        <SidebarButtonLink
          to={`/organizations/${organization.id}/participants`}
          leftIcon={<FaUserAlt />}
          isShrunk={isShrunk}
        >
          Participants
        </SidebarButtonLink>

        <SidebarButtonLink
          to={`/organizations/${organization.id}/incidents`}
          leftIcon={<FaExclamationTriangle />}
          isDisabled={!incidentsPermission}
          isShrunk={isShrunk}
        >
          Incidents
        </SidebarButtonLink>
        <SidebarButtonLink
          to={`/organizations/${organization.id}/users`}
          leftIcon={<FaUsers />}
          isDisabled={!usersPermission}
          isShrunk={isShrunk}
        >
          Users
        </SidebarButtonLink>
        <SidebarButtonLink
          to={`/organizations/${organization.id}/templates`}
          isDisabled={!templatesPermission}
          leftIcon={<FaChartArea />}
          isShrunk={isShrunk}
        >
          Templates
        </SidebarButtonLink>
      </SidebarSection>
      <Spacer />
      <SidebarSection>
        <SidebarButtonLink
          to={`/organizations/${organization.id}/user/profile`}
          leftIcon={<FaUser />}
          color="gray.400"
          isShrunk={isShrunk}
        >
          Profile
        </SidebarButtonLink>
        <SidebarButtonLink
          to={`/organizations/${organization.id}/settings`}
          isDisabled={!settingsPermission}
          leftIcon={<FaCog />}
          color="gray.400"
          isShrunk={isShrunk}
        >
          Settings
        </SidebarButtonLink>
      </SidebarSection>
      <SidebarDivider />
      <SidebarSection>
        <UserMenu isShrunk={isShrunk} />
      </SidebarSection>
    </Stack>
  );
};
