import React from 'react';
import { createModal } from '../../components/generic/Modal';
import { getIncidentBaseKey } from '../../graphql/hooks/useIncidentQuery';
import { useAppContext } from '../../hooks/useAppContext';
import { FileUploadModal } from '../files/FileUploadModal';

interface Props {
  incidentId: string;
}

export const IncidentFileUploadModal = createModal<Props>((props) => {
  const { incidentId, ...rest } = props;
  const { hide } = rest.modal;

  const { queryClient, organization, config } = useAppContext();
  const url = `${config.api.url}/organizations/${organization.id}/incidents/${incidentId}/files`;

  const onUploaded = React.useCallback(
    (status: 'success' | 'error') => {
      queryClient.invalidateQueries(
        getIncidentBaseKey({
          organizationId: organization.id,
          incidentId,
        }),
      );
      if (status === 'success') {
        hide();
      }
    },
    [incidentId, organization.id, queryClient, hide],
  );

  return <FileUploadModal url={url} onUploaded={onUploaded} {...rest} />;
});
