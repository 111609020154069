import { AppState, Auth0Provider, Auth0ProviderOptions } from '@auth0/auth0-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useConfig } from './ConfigContext';
import { P } from '@piccolohealth/util';
import mobile from 'is-mobile';

export const AuthContextProvider = (
  props: React.PropsWithChildren<Partial<Auth0ProviderOptions>>,
) => {
  const { config } = useConfig();
  const navigate = useNavigate();

  // Use localstorage for cacheLocation on Firefox and Safari
  // The reason is that the memory cacheLocation is not working properly on these browsers
  const [cacheLocation, useRefreshTokens] = P.run(() => {
    if (config.auth.cacheLocation) {
      return [config.auth.cacheLocation, false];
    }

    const isMobile = mobile({ tablet: true, featureDetect: true });
    return isMobile ? ['localstorage' as const, true] : ['memory' as const, false];
  });

  const onAuthRedirectCallback = React.useCallback(
    (appState: AppState | undefined) => {
      if (appState?.targetUrl) {
        navigate(appState.targetUrl || window.location.pathname);
      }
    },
    [navigate],
  );

  return (
    <Auth0Provider
      domain={config.auth.domain}
      audience={config.auth.audience}
      clientId={config.auth.clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onAuthRedirectCallback}
      cacheLocation={cacheLocation}
      useRefreshTokens={useRefreshTokens}
      {...props}
    >
      {props.children}
    </Auth0Provider>
  );
};
