import gql from 'graphql-tag';

export const CreateForm = gql`
  mutation CreateForm($organizationId: ID!, $request: CreateFormRequest!) {
    createForm(organizationId: $organizationId, request: $request) {
      id
      type
      name
      url
      labelMappings {
        create {
          id
          name
          description
          color
        }
        update {
          id
          name
          description
          color
        }
      }
    }
  }
`;
