import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { InviteUserRequest, Role } from '@piccolohealth/pbs-common';
import { P } from '@piccolohealth/util';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { HookedSubmitButton } from '../../components/forms/HookedSubmitButton';
import { createModal } from '../../components/generic/Modal';
import { useInviteUserMutation } from '../../graphql/hooks/useInviteUserMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { UserForm } from './UserForm';

type FormValues = InviteUserRequest;

const baseSchema = z.object({
  organizationId: z.string(),
  name: z.string(),
  email: z.string().email(),
  title: z.string(),
  practitionerNumber: z.string().nullish(),
  picture: z.string().nullish(),
});

const rolesSchema = z
  .object({
    roles: z.array(z.nativeEnum(Role)).nonempty('You must select at least one role'),
    locations: z.array(z.string()),
  })
  .refine(
    (values) => {
      const isTeamLeaderRole = values.roles?.includes(Role.TeamLeader);
      const hasNoLocations = P.isEmpty(values.locations ?? []);
      return !(isTeamLeaderRole && hasNoLocations);
    },
    {
      message: 'You must select at least one location for a team leader',
      path: ['locations'],
    },
  );

export const userSchema: z.ZodSchema<FormValues> = z.intersection(baseSchema, rolesSchema);

export const UserInviteModal = createModal((props) => {
  const { modal } = props;
  const { visible, hide, remove } = modal;
  const { organization, successToast, errorToast } = useAppContext();

  const mutation = useInviteUserMutation();

  const methods = useForm<FormValues>({
    defaultValues: {
      organizationId: organization.id,
      name: undefined,
      email: undefined,
      title: undefined,
      practitionerNumber: undefined,
      picture: null,
      roles: [],
      locations: [],
    },
    resolver: zodResolver(userSchema),
  });

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      await mutation
        .mutateAsync({
          request: P.deepTrim({
            organizationId: organization.id,
            name: values.name,
            email: values.email,
            picture: values.picture,
            title: values.title,
            practitionerNumber: values.practitionerNumber,
            roles: values.roles,
            locations: values.locations,
          }),
        })
        .then(() => {
          hide();
          successToast('User invited successfully');
        })
        .catch((err) => {
          errorToast(`Error inviting user: ${err.message}`);
        });
    },
    [mutation, organization.id, errorToast, hide, successToast],
  );

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={remove} size="xl">
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <Box as="form" display="contents" onSubmit={methods.handleSubmit(onSubmit)} noValidate>
            <ModalHeader>
              <Flex align="start">
                <Box>
                  <Heading size="md">Add a user</Heading>
                  <Text fontSize="md" fontWeight="normal" color="secondary" mt={2}>
                    Please complete the following to proceed
                  </Text>
                </Box>
                <Spacer />
                <ModalCloseButton position="unset" top="unset" right="unset" />
              </Flex>
            </ModalHeader>
            <ModalBody>
              <UserForm />
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button size="sm" onClick={hide}>
                  Close
                </Button>
                <HookedSubmitButton size="sm">Invite</HookedSubmitButton>
              </HStack>
            </ModalFooter>
          </Box>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
