import { MenuItem } from '@chakra-ui/react';
import React from 'react';
import { FaDownload } from 'react-icons/fa';
import { useExportParticipantsCsvMutation } from '../../graphql/hooks/useExportParticipantsMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { ParticipantsFilter } from '../../hooks/useParticipantsFilter';
import { usePermission } from '../../hooks/usePermission';

interface Props {
  filter: ParticipantsFilter;
}

export const ParticipantExportCsvMenuItem = (props: Props) => {
  const { filter } = props;
  const { organization, successToast, errorToast } = useAppContext();

  const hasPermission = usePermission('read', 'participant');

  const mutation = useExportParticipantsCsvMutation();

  const onClick = React.useCallback(async () => {
    await mutation
      .mutateAsync({
        organizationId: organization.id,
        request: {
          start: filter.startDateFilter,
          end: filter.endDateFilter,
          ndisNumber: filter.ndisNumberFilter,
          label: filter.labelFilter,
          location: filter.locationFilter,
          assignedTo: filter.assignedToFilter,
          name: filter.nameFilter,
          status: filter.statusFilter,
        },
      })
      .then((res) => {
        successToast('Participants exported successfully');
        res && window.open(res.exportParticipantsCsv, '_self');
      })
      .catch((err) => {
        errorToast(`Error exporting participants: ${err.message}`);
      });
  }, [mutation, organization.id, successToast, errorToast, filter]);

  return (
    <MenuItem isDisabled={!hasPermission} onClick={onClick} icon={<FaDownload />}>
      Export participants (CSV)
    </MenuItem>
  );
};
