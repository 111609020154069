import gql from 'graphql-tag';

export const DeleteParticipantLabel = gql`
  mutation DeleteParticipantLabel($organizationId: ID!, $participantId: ID!, $labelId: ID!) {
    deleteParticipantLabel(
      organizationId: $organizationId
      participantId: $participantId
      labelId: $labelId
    )
  }
`;
