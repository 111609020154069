import gql from 'graphql-tag';

export const Template = gql`
  query Template($organizationId: ID!, $templateId: ID!, $versionId: ID) {
    organization(organizationId: $organizationId) {
      template(templateId: $templateId, versionId: $versionId) {
        id
        versionId
        type
        name
        value
        status
        creator {
          id
          name
          email
          picture
        }
        labels {
          id
          name
          description
          color
        }
        createdAt
      }
    }
  }
`;

export const TemplateMinimal = gql`
  query TemplateMinimal($organizationId: ID!, $templateId: ID!) {
    organization(organizationId: $organizationId) {
      template(templateId: $templateId) {
        id
        versionId
        name
        type
        status
        creator {
          id
          name
          email
          picture
        }
        createdAt
      }
    }
  }
`;

export const TemplateVersions = gql`
  query TemplateVersions(
    $organizationId: ID!
    $templateId: ID!
    $request: GetPaginatedTemplateVersionsRequest
  ) {
    organization(organizationId: $organizationId) {
      template(templateId: $templateId) {
        id
        versionId
        type
        name
        value
        status
        createdAt
        versions(request: $request) {
          pagination {
            total
            remaining
            offset
            limit
            currentPage
            totalPages
            hasNextPage
            hasPreviousPage
          }
          results {
            versionId
            createdAt
            users {
              id
              name
              email
              picture
            }
          }
        }
      }
    }
  }
`;

export const TemplateVersion = gql`
  query TemplateVersion($organizationId: ID!, $templateId: ID!, $versionId: ID!) {
    organization(organizationId: $organizationId) {
      template(templateId: $templateId, versionId: $versionId) {
        id
        versionId
        type
        name
        value
        status
        createdAt
      }
    }
  }
`;
