import gql from 'graphql-tag';

export const UsersMinimal = gql`
  query UsersMinimal($organizationId: ID!, $request: GetPaginatedUsersRequest) {
    organization(organizationId: $organizationId) {
      users(request: $request) {
        pagination {
          total
          remaining
          offset
          limit
          currentPage
          totalPages
          hasNextPage
          hasPreviousPage
        }
        results {
          id
          name
        }
      }
    }
  }
`;

export const Users = gql`
  query Users($organizationId: ID!, $request: GetPaginatedUsersRequest) {
    organization(organizationId: $organizationId) {
      users(request: $request) {
        pagination {
          total
          remaining
          offset
          limit
          currentPage
          totalPages
          hasNextPage
          hasPreviousPage
        }
        results {
          id
          name
          email
          title
          practitionerNumber
          picture
          featureFlags
          extraPermissions
          organizationMemberships {
            organizationId
            name
            roles
            locations {
              id
              name
              isArchived
            }
          }
        }
      }
    }
  }
`;
