import { FormControl, FormHelperText, FormLabel, HStack, Spacer, Stack } from '@chakra-ui/react';
import React from 'react';
import { HookedSwitch } from './HookedSwitch';

interface Props {
  name: string;
  label: string;
  helperText: string;
  labelSize?: 'md' | 'sm';
}

export const HookedMethodFormItem = (props: React.PropsWithChildren<Props>) => {
  const { name, label, labelSize, helperText, children } = props;

  return (
    <FormControl as={HStack}>
      <Stack
        w="full"
        spacing={1}
        borderColor="gray.200"
        borderWidth="1px"
        rounded="md"
        py={3}
        px={4}
        align="start"
      >
        <HStack w="full">
          <FormLabel fontWeight="semibold" mb={0} color="gray.700" fontSize={labelSize}>
            {label}
          </FormLabel>
          <Spacer />
          <HookedSwitch name={`${name}.enabled`} />
        </HStack>
        {helperText && (
          <FormHelperText fontSize="sm" whiteSpace="pre-wrap">
            {helperText}
          </FormHelperText>
        )}
        {children}
      </Stack>
    </FormControl>
  );
};
