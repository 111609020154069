import { useDebouncedCallback } from '@piccolohealth/ui';
import React from 'react';
import { useScroll, useSessionStorage } from 'react-use';

export const useScrollRestoration = (key: string) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const { y } = useScroll(ref);
  const [scrollY, setScrollY] = useSessionStorage<number>(key, 0);
  const [opacity, setOpacity] = React.useState(0);

  const debouncedSetScrollY = useDebouncedCallback(setScrollY, 200);

  React.useEffect(() => {
    debouncedSetScrollY(y);
  }, [y, debouncedSetScrollY]);

  React.useEffect(() => {
    if (!ref.current || !scrollY) {
      setOpacity(1);
      return;
    }

    // inside a timeout as we need to wait for dom to be ready
    // tiptap reactnoderenderer has bug
    setTimeout(() => {
      if (ref.current && ref.current.scrollTop !== scrollY) {
        ref.current.scrollTop = scrollY;
      }
      setOpacity(1);
    }, 1);
  }, [scrollY]);

  return {
    opacity,
    ref,
  };
};
