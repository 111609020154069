import { useRequiredParams } from '@piccolohealth/ui';
import React from 'react';
import { useTemplateMinimalQuery } from '../../graphql/hooks/useTemplateQuery';
import { useAppContext } from '../../hooks/useAppContext';

export const TemplateCrumb = () => {
  const { templateId } = useRequiredParams<{ templateId: string }>();

  const { organization } = useAppContext();

  const { isLoading, data, error } = useTemplateMinimalQuery({
    organizationId: organization.id,
    templateId,
  });

  if (isLoading) {
    return <span>...</span>;
  }

  if (error) {
    return <span>_</span>;
  }

  const template = data?.organization?.template;

  if (!template) {
    return <span>_</span>;
  }

  return <span>{template.name}</span>;
};
