import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { CreateParticipantBehaviourRequest } from '@piccolohealth/pbs-common';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import { HookedSubmitButton } from '../../components/forms/HookedSubmitButton';
import { createModal } from '../../components/generic/Modal';
import { useCreateParticipantBehaviourMutation } from '../../graphql/hooks/useCreateParticipantBehaviourMutation';
import { useAppContext } from '../../hooks/useAppContext';
import { ParticipantBehaviourForm } from './ParticipantBehaviourForm';

type FormValues = CreateParticipantBehaviourRequest;

export const methodsSchema = z.object({
  abc: z.object({
    enabled: z.boolean(),
  }),
  duration: z.object({
    enabled: z.boolean(),
  }),
  frequency: z.object({
    enabled: z.boolean(),
  }),
  episodicSeverity: z
    .object({
      enabled: z.boolean(),
      scales: z.array(
        z.object({
          id: z.string(),
          description: z.string(),
        }),
      ),
    })
    .superRefine(({ enabled, scales }, ctx) => {
      if (!enabled) {
        return true;
      }

      if (scales.length < 2) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Please add at least 2 episodic severity scales',
          path: ['scales'],
        });
        return false;
      }

      if (scales.length > 10) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'You have reached the limit of 10 episodic severity scales',
          path: ['scales'],
        });
        return false;
      }
    }),
});

const schema: z.ZodSchema<FormValues> = z.object({
  participantId: z.string(),
  name: z.string().nonempty('Please enter a name'),
  description: z.string().nonempty('Please enter a description'),
  example: z.string().nonempty('Please enter an example'),
  nonExample: z.string().nonempty('Please enter a non-example'),
  methods: methodsSchema,
});

interface Props {
  participantId: string;
}

export const ParticipantBehaviourCreateModal = createModal<Props>((props) => {
  const { modal } = props;
  const { hide, visible } = modal;
  const { organization, successToast, errorToast } = useAppContext();

  const mutation = useCreateParticipantBehaviourMutation();

  const methods = useForm<FormValues>({
    defaultValues: {
      participantId: props.participantId,
      name: undefined,
      description: undefined,
      example: undefined,
      nonExample: undefined,
      methods: {
        abc: {
          enabled: false,
        },
        duration: {
          enabled: false,
        },
        episodicSeverity: {
          enabled: false,
          scales: [],
        },
        frequency: {
          enabled: false,
        },
      },
    },
    resolver: zodResolver(schema),
    mode: 'all',
  });

  const onSubmit = React.useCallback(
    async (values: FormValues) => {
      await mutation
        .mutateAsync({
          organizationId: organization.id,
          request: values,
        })
        .then(() => {
          hide();
          successToast('Participant behaviour updated successfully');
        })
        .catch((err) => {
          errorToast(`Error updating participant behaviour: ${err.message}`);
        });
    },
    [errorToast, hide, mutation, organization.id, successToast],
  );

  return (
    <Modal isOpen={visible} onClose={hide} onCloseComplete={modal.remove} size="5xl">
      <ModalOverlay />
      <ModalContent>
        <FormProvider {...methods}>
          <Box as="form" display="contents" onSubmit={methods.handleSubmit(onSubmit)}>
            <ModalHeader>
              <Flex align="start">
                <Box>
                  <Heading size="md">Create a participant behaviour</Heading>
                  <Text fontSize="md" fontWeight="normal" color="secondary" mt={2}>
                    Please complete the following to proceed
                  </Text>
                </Box>
                <Spacer />
                <ModalCloseButton position="unset" top="unset" right="unset" />
              </Flex>
            </ModalHeader>
            <ModalBody>
              <ParticipantBehaviourForm />
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button size="sm" onClick={hide}>
                  Close
                </Button>
                <HookedSubmitButton size="sm">Create behaviour</HookedSubmitButton>
              </HStack>
            </ModalFooter>
          </Box>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
});
