import { Button, Icon, useDisclosure } from '@chakra-ui/react';
import { FloatingPopover } from '@piccolohealth/ui';
import React from 'react';
import { FaRegClock } from 'react-icons/fa';
import { ParticipantTimelineContents } from './ParticipantTimelineContents';

interface Props {
  participantId: string;
}

export const ParticipantTimelineControl = (props: Props) => {
  const { participantId } = props;
  const disclosure = useDisclosure();

  return (
    <FloatingPopover
      isPortal={true}
      isRoot={true}
      open={disclosure.isOpen}
      setOpen={(open) => (open ? disclosure.onOpen() : disclosure.onClose())}
      render={() => <ParticipantTimelineContents participantId={participantId} />}
    >
      <Button
        variant="ghost"
        size="sm"
        leftIcon={<Icon as={FaRegClock} />}
        onClick={disclosure.onToggle}
        lineHeight={1}
      >
        History
      </Button>
    </FloatingPopover>
  );
};
