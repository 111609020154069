import { Button, ButtonGroup, Heading, HStack, Icon, Spacer, Stack } from '@chakra-ui/react';
import React from 'react';
import { FaPlus } from 'react-icons/fa';
import { showModal } from '../../components/generic/Modal';
import { ContentLayout } from '../../components/layouts/ContentLayout';
import { useParticipantsFilterQuery } from '../../graphql/hooks/useParticipantsQuery';
import { useParticipantsFilter } from '../../hooks/useParticipantsFilter';
import { usePermission } from '../../hooks/usePermission';
import { ParticipantCreateModal } from './ParticipantCreateModal';
import { ParticipantsActionMenu } from './ParticipantsActionMenu';
import { ParticipantsFilterControls } from './ParticipantsFilterControls';
import { ParticipantsTable } from './ParticipantsTable';

export const Participants = () => {
  const filter = useParticipantsFilter();

  const createParticipantPermission = usePermission('create', 'participant');

  const { isLoading, error, participants, refetch, pagination, rowSelection } =
    useParticipantsFilterQuery(filter);

  return (
    <ContentLayout
      headerContent={
        <HStack align="start">
          <Heading size="lg">Participants</Heading>
          <Spacer />
          <ButtonGroup>
            <Button
              isDisabled={!createParticipantPermission}
              leftIcon={<Icon as={FaPlus} />}
              colorScheme="purple"
              size="sm"
              onClick={() => showModal(ParticipantCreateModal)}
            >
              Add Participant
            </Button>
            <ParticipantsActionMenu filter={filter} size="sm" />
          </ButtonGroup>
        </HStack>
      }
    >
      <Stack spacing={4} px={6}>
        <ParticipantsFilterControls filter={filter} />
        <ParticipantsTable
          error={error}
          isLoading={isLoading}
          participants={participants}
          refetch={refetch}
          pagination={pagination}
          rowSelection={rowSelection}
        />
      </Stack>
    </ContentLayout>
  );
};
