import { Box, Divider, HStack } from '@chakra-ui/react';
import { MethodType } from '@piccolohealth/pbs-common';
import React from 'react';
import { useWatch } from 'react-hook-form';
import { FormSection } from '../../../../components/forms/FormSection';
import { FormStack } from '../../../../components/forms/FormStack';
import { HookedColorPickerHex } from '../../../../components/forms/HookedColorPickerHex';
import { HookedFormItem } from '../../../../components/forms/HookedFormItem';
import { HookedSelect } from '../../../../components/forms/HookedSelect';
import { BadgeItem } from '../../../../components/generic/BadgeItem';
import {
  AGGREGATION_TYPE_OPTIONS,
  CHART_TYPE_OPTIONS,
  COLORS,
  methodTypenameToMethodType,
  METHOD_TYPE_OPTIONS,
  SYMBOL_TYPE_OPTIONS,
} from '../../../participantData/utils';
import { UseTimeseriesChartReturn } from '../../hooks/useTimeseriesChart';
import { AggregationTypeIcon } from './AggregationTypeIcon';
import { ChartTypeIcon } from './ChartTypeIcon';
import { MethodOption } from './MethodOption';
import { SymbolTypeIcon } from './SymbolTypeIcon';
import { TrendlineForm } from './TrendlineForm';

interface Props {
  chart: UseTimeseriesChartReturn;
}

export const TimeSeriesForm = (props: Props) => {
  const { chart } = props;

  const participantBehaviourId = useWatch({ name: 'source.participantBehaviourId' });

  const methodCount = React.useMemo(() => {
    const participantBehaviour = chart.participantBehaviours.find((pb) =>
      pb.recordings.find((r) => r.participantBehaviourId === participantBehaviourId),
    );

    return (participantBehaviour?.recordings ?? []).reduce((acc, recording) => {
      const method = methodTypenameToMethodType(recording.method.__typename);
      acc[method] = (acc[method] ?? 0) + 1;
      return acc;
    }, {} as Record<MethodType, number | undefined>);
  }, [chart.participantBehaviours, participantBehaviourId]);

  return (
    <FormStack w="full">
      <FormSection heading="Basic" description="Set the basic details of the chart.">
        <FormStack orientation="horizontal">
          <HookedFormItem name="__typename" label="Type">
            <HookedSelect
              name="__typename"
              options={CHART_TYPE_OPTIONS}
              components={{
                Value: (ps) => (
                  <BadgeItem
                    label={ps.option.label}
                    color={ps.option.color}
                    icon={<ChartTypeIcon chartType={ps.option.raw} size={16} />}
                  />
                ),
                Option: (ps) => (
                  <BadgeItem
                    label={ps.option.label}
                    color={ps.option.color}
                    icon={<ChartTypeIcon chartType={ps.option.raw} size={16} />}
                  />
                ),
              }}
            />
          </HookedFormItem>
          <Box w="full" />
        </FormStack>
      </FormSection>

      <FormSection heading="Data" description="Select the data to display on the chart.">
        <FormStack orientation="horizontal">
          <HookedFormItem name="source.method" label="Method" flex={2}>
            <HookedSelect
              name="source.method"
              options={METHOD_TYPE_OPTIONS.filter((option) => option.raw !== MethodType.Abc)}
              components={{
                Value: (ps) => (
                  <MethodOption option={ps.option} count={methodCount[ps.option.raw] ?? 0} />
                ),
                Option: (ps) => (
                  <MethodOption option={ps.option} count={methodCount[ps.option.raw] ?? 0} />
                ),
              }}
            />
          </HookedFormItem>
          <HookedFormItem name="aggregation" label="Aggregation" flex={1}>
            <HookedSelect
              name="aggregation"
              options={AGGREGATION_TYPE_OPTIONS}
              components={{
                Value: (ps) => (
                  <BadgeItem
                    label={ps.option.label}
                    color={ps.option.color}
                    icon={<AggregationTypeIcon aggregationType={ps.option.raw} size={16} />}
                  />
                ),
                Option: (ps) => (
                  <BadgeItem
                    label={ps.option.label}
                    color={ps.option.color}
                    icon={<AggregationTypeIcon aggregationType={ps.option.raw} size={16} />}
                  />
                ),
              }}
            />
          </HookedFormItem>
        </FormStack>
      </FormSection>
      <Divider />
      <FormSection
        heading="Styling"
        description="Customize the appearance of the data on the chart."
      >
        <FormStack orientation="horizontal">
          <HookedFormItem name="symbol" label="Symbol">
            <HookedSelect
              name="symbol"
              options={SYMBOL_TYPE_OPTIONS}
              components={{
                Value: (ps) => (
                  <BadgeItem
                    label={ps.option.label}
                    color={ps.option.color}
                    icon={
                      <SymbolTypeIcon symbolType={ps.option.raw} size={8} color={COLORS.darkGray} />
                    }
                  />
                ),
                Option: (ps) => (
                  <BadgeItem
                    label={ps.option.label}
                    color={ps.option.color}
                    icon={
                      <SymbolTypeIcon symbolType={ps.option.raw} size={8} color={COLORS.darkGray} />
                    }
                  />
                ),
              }}
            />
          </HookedFormItem>
          <HookedFormItem name="color" label="Color" alignItems="middle">
            <HStack h="full" justify="middle">
              <HookedColorPickerHex name="color" />
            </HStack>
          </HookedFormItem>
        </FormStack>
      </FormSection>
      <Divider />
      <TrendlineForm />
    </FormStack>
  );
};
